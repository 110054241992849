import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { tap } from 'rxjs/operators';

export type VestResponse = {
  data:any
}

@Injectable({
  providedIn: 'root'
})
export class VestService {

  constructor(private http: HttpClient) {
  }

  private _nombreVeste:number = 0;
  
  public get nombreVestes() : number {
    return this._nombreVeste;
  }
  

  getListe(arena_id:number = 1) {
    return this.http.get('/api/vestes/list/')
      .pipe(
        tap( (response:any) => {
          this._nombreVeste = response[0].nombreVestes;
        })
      );
  };

  enregistrer(vestes:any):Observable<VestResponse> {
    let _lst:Observable<any>[] = [];

    return new Observable<VestResponse> ( (observer) => {
      _.each(vestes, (nb, arenaId) => {
        if (arenaId) {
            var data = {
                arena_id : arenaId,
                nombreVestes : nb
            };
            this.http.post('/api/vestes/enregistrer/' + arenaId, data).subscribe( (resp:any) => {
              observer.next(resp);
            });
        }
      });
    });
    
};


}
