import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from '@common/services';
import _ from 'lodash';
import moment from 'moment';

declare var Handlebars:any;

/**
 * Section pour les courriels
 */
Handlebars.registerHelper('inc', function(value: any, options: any) {
    if (options == undefined || typeof options !== "number") {
        options = 1;
    }
    return (value + options);
});


Handlebars.registerHelper('formatDate', function(value: any, format: any) {
    var curDate = moment(value);
    return (curDate.format(format));
});

Handlebars.registerHelper('AdjustFormatDate', function(value: any, format: any, adjust: any, adjustType: any) {
    var curDate = moment(value);
    if (adjust !== undefined && adjustType !== undefined) {
        curDate.add(adjust, adjustType)
    }
    return (curDate.format(format));
});

@Component({
    selector: 'd1-email-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './email-form.component.html',
    styleUrls: ['email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
    public myForm!:FormGroup;
    public lstGabarits:any[] = [];
    public gabaritModel = { dataSource: this.lstGabarits }
    @Input('data') data:any = undefined
    @Output('send') sendEvent:EventEmitter<any> = new EventEmitter<any>();
    @Output('cancel') cancelEvent:EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private fb:FormBuilder, private s$:AppSettingsService) {
        this.myForm = fb.group( {
            nom: '',
            courriel: ['', [Validators.required, Validators.email]],
            sujet: ['', [Validators.required, Validators.minLength(1)]],
            message: ['', [Validators.required, Validators.minLength(1)]],
            gabarit: {}
        })
    }

    ngOnInit() {
        // Avons-nous des infos de groupe?
        if (this.data) {
            if (this.data.nomOrganisateur) this.myForm.controls["nom"].setValue(this.data.nomOrganisateur)
            if (this.data.courriel) this.myForm.controls["courriel"].setValue(this.data.courriel)
            if (this.data.parties && this.data.parties === undefined) this.data.parties = this.data.parties;
        }

        this.s$.getGabarits().then( r => {
            this.lstGabarits = r;
            this.gabaritModel.dataSource = _.sortBy(_.map(r, g => {
                return {text: g.name, value: g.id}
            }), 'text');
        });

        this.myForm.controls["gabarit"].valueChanges.subscribe( g => {
            const selGabarit = _.find(this.lstGabarits, { id: g})
            if (selGabarit) {
                const gabaritVersion = _.find(selGabarit.versions, {active : 1})
                if (gabaritVersion) {
                    this.myForm.controls["sujet"].setValue(gabaritVersion.subject)
                    if (this.data) {
                        let template = Handlebars.compile(gabaritVersion.html_content);
                        const rendered =  template(this.data);
                        this.myForm.controls["message"].setValue(rendered)
                    } else {
                        this.myForm.controls["message"].setValue(gabaritVersion.html_content)
                    }
                }
            }
        })

    }

    ok() {
        this.sendEvent.emit(this.myForm.value)
    }
    cancel() {
        this.cancelEvent.emit(false)
    }
}
