import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'd1-checkbox',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
    @Input('label') label = '';
    @Input('id') id = undefined;
    @Input('checked') checked = false;
    @Input('true-value') trueValue:any;
    @Input('false-value') falseValue:any;
    @Input('labelPosition') labelPosition = 'before';
    @Input() disabled = false;
    @Output() checkedChange = new EventEmitter<any>();
    @Output() change = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
        if (this.trueValue === undefined) {
            this.trueValue = true;
        }
        if (this.falseValue === undefined) {
            this.falseValue = false;
        }
        // if (this.id === undefined) {
        //     this.id = this.label;
        // }
        this.checked = ( this.checked === this.trueValue );
    }

    onChange(event:any) {
        // console.log("onChange(event)", event);
        this.checkedChange.emit(event.checked);
        this.checked = (event.checked ? this.trueValue : this.falseValue);
        this.change.emit(event.checked ? this.trueValue : this.falseValue);
    }
}
