import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppNotificationService } from '@common/services/appnotification.service';
import { AppMessageService } from '@common/services/messages.service';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { RoomPickupInfo, RoomsDialogPickComponent } from '@modules/rooms/containers';
import _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'd1-reservation-quick-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservation-quick-view.component.html',
    styleUrls: ['reservation-quick-view.component.scss'],
})
export class ReservationQuickViewComponent implements OnInit, OnChanges {
    @Input('booking') reservation!:ReservationModel;
    @Output() backClick:EventEmitter<any> = new EventEmitter();

    constructor(private r$:ReservationsService, private msg$:AppMessageService, private notify$:AppNotificationService, public dialog: MatDialog) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reservation) {
            // console.log('New booking', changes.reservation.currentValue)
        }
    }

    getParties() {
        if (this.reservation) {
            return _.map(this.reservation.parties, (p) => moment(p.partieTemps).format('HH:mm'))
        } else {
            return [];
        }
    }

    getSalles() {
        return _.map(this.reservation.salles, (s) => {
            return (s?.salleNom || 'Inconnu');
        } )
    }

    ngOnInit() {
        if (!this.reservation) {
            // Bad init
            this.reservation = new ReservationModel();
        }
    }

    updateStatut(newStatut:number) {
        if (this.reservation) {
            this.r$.updateStatut(this.reservation.groupeId, newStatut)
            this.msg$.sendMessageReservation(this.reservation);
            this.notify$.showMessage('Statut mis à jour');
        }
    }

    updateStatutPaiement(newStatut:number) {
        if (this.reservation) {
            this.r$.updateStatutPaiement(this.reservation.groupeId, newStatut)
            this.msg$.sendMessageReservation(this.reservation);
            this.notify$.showMessage('Statut de paiement mis à jour');
        }
    }

    updateStatutConfirmation(newStatut:number) {
        if (this.reservation) {
            this.r$.updateStatutConfirmation(this.reservation.groupeId, newStatut)
            this.msg$.sendMessageReservation(this.reservation);
            this.notify$.showMessage('Statut de confirmation mis à jour');
        }
    }

    goBackClick(event:any) {
        this.backClick.emit(event);
    }

    changeTags(tags: any) {
        this.reservation.groupe_tags = tags;
        this.r$.updateTags(this.reservation);
        this.msg$.sendMessageReservation(this.reservation);
        this.notify$.showMessage('Tags sauvegardés')
    }

    pickupSalle() {
        const dialogRef = this.dialog.open(RoomsDialogPickComponent, {
            width: '800px',
            data: {showDate: false, date : this.reservation.reservationDate, multiple:false, dialogTitle: "Disponibilités des salles"} as RoomPickupInfo,
          });
      
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
          });
    }
}
