<div class="d-flex d-flex-row justify-content-end mb-2">
    <div class="btn-group btn-group-toggle mr-2">
        <label class="btn-primary" ngbButtonLabel> <span *ngIf="hideUnavailable">Voir les non-disponibles</span><span *ngIf="!hideUnavailable">Cacher les non-disponibles</span><input type="checkbox" ngbButton (change)="OnShowUnavailable()" [(ngModel)]="hideUnavailable"/></label><label class="btn-primary" ngbButtonLabel>Override<input type="checkbox" ngbButton/></label>
    </div>
</div>
<d1-spinner *ngIf="loading"></d1-spinner>
<div class="row" *ngIf="!loading">
    <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 btn-group-toggle mb-2" *ngFor="let ced of listePartiesDisponibles">
        <label ngbButtonLabel ngbTooltip="Il y a un total de {{ced.nbJoueurs}} joueurs répartis dans {{ced.nbGroupes}} groupes" [class.active]="isScheduleSelected(ced)" [class.disabled]="IsScheduleDisabled(ced)" [class.btn-info]="ced.numDisponible &gt; 20" [class.btn-warning]="(ced.numDisponible &gt; 8 &amp;&amp; ced.numDisponible &lt;= 20)" [class.btn-danger]="ced.numDisponible &lt;= 8"
            >{{ced.partieHeures}} <br />{{(ced.numDisponible)}} places<input type="checkbox" ngbButton [disabled]="IsScheduleDisabled(ced)" (change)="OnSelect(ced)" #ceduleInput/>
            <div class="clearfix"></div>
            <input type="number" *ngIf="isScheduleSelected(ced)" (change)="OnSelectNbJoueurs(ced, ceduleNbJoueursInput)" #ceduleNbJoueursInput [value]="GroupSizeInScheduledGame(ced)" min="1" [max]="maxGroupSize(ced)" size="3"
        /></label>
    </div>
</div>
