export class GameBookingModel {
    arena_id: number = 1;
    ceduleNombre: number = 0;
    groupeId: number = 0;
    groupeNombreJoueur: number = 0;
    groupeTitre: string = '';
    groupeTypeCouleur: string = '';
    groupeTypeId: number = 0;
    groupeTypeNom: string = '';
    id: number = 0;
    nomOrganisateur: string = '';
    nombreJoueurs: number = 0;
    notesMarshall: string = '';
    notesMarshallCouleur: string = '';
    partieDate: string = '';
    partieDuree: number = 0;
    partieHeures: string = '';
    partieTemps: string = '';
    codebarre: string = '';
    partieType: number = 0;
    presence: number = 0;

    journeeTemps= '';
    groupeNombre= 0;
    statutGroupe= 0;
    ceduleNombreJoueurs= 0;
    nomFete='';
    groupeAvecExclusif= 0;
    braceletCouleur= '';
    ceduleId= 0;
    ceduleTemps= '';
    ceduleInformation= '';
    nombreVestes= 0;
    numDisponible= 0;

    constructor(game?: GameBookingModel) {
        if (game !== undefined) {
            this.arena_id = game.arena_id;
            this.ceduleNombre = game.ceduleNombre;
            this.groupeId = game.groupeId;
            this.groupeNombreJoueur = game.groupeNombreJoueur;
            this.groupeTitre = game.groupeTitre;
            this.groupeTypeCouleur = game.groupeTypeCouleur;
            this.groupeTypeId = game.groupeTypeId;
            this.groupeTypeNom = game.groupeTypeNom;
            this.id = game.id;
            this.nomOrganisateur = game.nomOrganisateur;
            this.nombreJoueurs = game.nombreJoueurs;
            this.notesMarshall = game.notesMarshall;
            this.notesMarshallCouleur = game.notesMarshallCouleur;
            this.partieDate = game.partieDate;
            this.partieDuree = game.partieDuree;
            this.partieHeures = game.partieHeures;
            this.partieTemps = game.partieTemps;
            this.partieType = game.partieType;
            this.codebarre = game.codebarre;
            
            this.journeeTemps = game.journeeTemps;
            this.groupeNombre = game.groupeNombre;
            this.statutGroupe = game.statutGroupe;
            this.ceduleNombreJoueurs = game.ceduleNombreJoueurs;
            this.nomFete = game.nomFete;
            this.groupeAvecExclusif = game.groupeAvecExclusif;
            this.braceletCouleur = game.braceletCouleur;
            this.ceduleId = game.ceduleId;
            this.ceduleTemps = game.ceduleTemps;
            this.ceduleInformation = game.ceduleInformation;
            this.nombreVestes = game.nombreVestes;
            this.numDisponible = game.numDisponible;
            this.presence = game.presence;
        }
    }
};

export class GameCeduleModel {
    id: number = 0;
    nombreJoueurs: number = 0;
    partieTemps: Date | undefined = undefined;
    groupeId: number = 0;
    partieDuree: number = 0;
    partieType: number = 0;
    referenceId: string = '';
    referenceLog: string = '';
    arena_id: number = 1;

    constructor(game?: GameCeduleModel) {
        if (game !== undefined) {
            this.id = game.id;
            this.nombreJoueurs = game.nombreJoueurs;
            this.partieTemps = game.partieTemps;
            this.groupeId = game.groupeId;
            this.partieDuree = game.partieDuree;
            this.partieType = game.partieType;
            this.referenceId = game.referenceId;
            this.referenceLog = game.referenceLog;
            this.arena_id = game.arena_id;
        }
    }
};
export class GameModel {
    partieId: number = 0;
    partieDate: string = '';
    partieDuree: number = 0;
    partieTemps: string = '';
    partieType: number = 1;
    nombreJoueurs: number = 0;
    arena_id: number = 1;
    presence: number = 0;

    constructor(game?: GameModel) {
        if (game !== undefined) {
            this.partieId = game.partieId;
            this.partieDate = game.partieDate;
            this.partieDuree = game.partieDuree;
            this.partieTemps = game.partieTemps;
            this.partieType = game.partieType;
            this.nombreJoueurs = game.nombreJoueurs;
            this.arena_id = game.arena_id;
            this.presence = game.presence;
        }
    }
};

