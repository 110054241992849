/* tslint:disable: ordered-imports*/
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/* Environment */
import { environment } from '../../environments/environment';

/* Modules */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '@modules/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';

/* Material */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {TextFieldModule} from '@angular/cdk/text-field';

/* Divers */
import {MatSliderModule} from '@angular/material/slider';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxBarcode6Module } from 'ngx-barcode6';

/* Socket */
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const socketConfig: SocketIoConfig = { url: environment.socketURL, options: {} };

const modules = [
    IconsModule, 
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule, 
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    TextFieldModule,
    TranslateModule,
    TsNgLibModule,
    NgSelectModule
];

/* Containers */
import * as appCommonContainers from './containers';

/* Components */
import * as appCommonComponents from './components';

/* Directives */
import * as appCommonDirectives from './directives';

/* Guards */
import * as appCommonGuards from './guards';

/* Pipes */
import * as appCommonPipes from './pipes';

/* Services */
import * as appCommonServices from './services';
import * as authServices from '@modules/auth/services';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '@modules/auth/services/auth-interceptor.service';
import { AppVersionInterceptorService } from './services/appversion-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TsNgLibModule } from 'ts-ng-lib';
import { NgSelectModule } from '@ng-select/ng-select';

/* Configurations */
export const MOMENT_DATE_FORMATS: MatDateFormats = {
    parse: {
      dateInput: ['DD-MM-YYYY', 'DDMMYYYY']
    },
    display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'MMMM Y',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM Y'
    }
  };

@NgModule({
    imports: [CommonModule, RouterModule, NgxBarcode6Module
        // , SocketIoModule.forRoot(socketConfig)
        , ...modules],
    declarations: [
        ...appCommonContainers.containers,
        ...appCommonComponents.components,
        ...appCommonDirectives.directives,
        ...appCommonPipes.pipes,
    ],
    exports: [
        ...appCommonContainers.containers,
        ...appCommonComponents.components,
        ...appCommonDirectives.directives,
        ...appCommonPipes.pipes,
        ...modules,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptorService, multi: true },
        {provide: MAT_DATE_LOCALE, useValue: 'fr-CA'},
        // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS}
]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                ...appCommonServices.services,
                ...authServices.services,
                ...appCommonGuards.guards,
                ...appCommonPipes.pipes,
                { provide: 'window', useValue: window },
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptorService, multi: true },
            ],
        };
    }
}
