import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NoteModel } from '@common/models/notes.model';
import _ from 'lodash';
import { MessageBoxService } from 'ts-ng-lib';

@Component({
    selector: 'd1-notes',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './notes.component.html',
    styleUrls: ['notes.component.scss'],
})
export class NotesComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('inputNoteInitiales') inputNoteInitiales!: ElementRef<HTMLInputElement>;
    @ViewChild('inputNote') inputNote!: ElementRef<HTMLInputElement>;

    @Input() timeline: NoteModel[] = [] 
    @Input() initiales: string = ''
    @Input() clearInitialesOnAdd: boolean = false;
    @Output() timelineChange: EventEmitter<NoteModel[]> = new EventEmitter()

    // public _timeline: NoteModel[] = [] 

    constructor(private ref:ChangeDetectorRef, private msg$ : MessageBoxService) {}

    ngAfterViewInit(): void { }

    ngOnInit() {
        if (this.timeline == undefined) {
          this.timeline = [];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.timeline && changes.timeline.currentValue) {
        this.timeline = [...changes.timeline.currentValue]
        this.ref.markForCheck();
      }
    }

    checkKey(event: KeyboardEvent) {
      try {
        if (event.key === 'Enter' && this.inputNote.nativeElement.value && this.inputNoteInitiales.nativeElement.value) {
          this.ajouterNote(this.inputNote.nativeElement)
        }

      } catch (error) {
        // Catch all, on retourne la totale
        console.error(error);
      }
    }

    deleteNote(index : number) {
      this.msg$.ConfirmDelete().subscribe(r=> {
        if(r == 'Effacer') {
          this.timeline.splice(index, 1)
          this.timelineChange.emit(this.timeline)
        }
      })
    }

    ajouterNote(note: HTMLInputElement) {
      if (note.value.length) {
        const newNote: NoteModel = new NoteModel();
        newNote.description = this.inputNote.nativeElement.value;
        newNote.auteur = this.inputNoteInitiales.nativeElement.value

        this.timeline.unshift(newNote);

        this.timelineChange.emit(this.timeline);

        // Clear
        if (this.clearInitialesOnAdd) {
          this.inputNoteInitiales.nativeElement.value = ''
        }
        this.inputNote.nativeElement.value = ''
      }
    }

    log(msg:any) {
      console.log(msg)
    }
}
