import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppSettingsService } from '@common/services';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'd1-schedule-overview',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-overview.component.html',
    styleUrls: ['schedule-overview.component.scss'],
})
export class ScheduleOverviewComponent implements OnInit {
    public dateSelected: moment.Moment = moment();
    viewActualTimeOnly = true;
    hideEmptySchedule = true;
    viewCompact = true;
    public moment = moment;
    public features: string[] = [];
    public videoListe!:Observable<any>;
    public imageListe!:Observable<any>;
    public slideShowListe!:Observable<any>;
    
    constructor(public s$:ScheduleService, private settings: AppSettingsService) {

        this.settings.configurations.subscribe( cfg => {
            // console.log('ScheduleOverviewComponent configs', cfg);
        })

        this.videoListe = this.s$.mediaListe().pipe( 
            map( (r:any) => _.filter(r.slideShowItems, {type : 'video'})), 
        );

        this.imageListe = this.s$.mediaListe().pipe( 
            map( (r:any) => _.filter(r.slideShowItems, {type : 'image'})), 
        );

        this.slideShowListe = this.s$.mediaListe().pipe( 
            map( (r:any) => r.slideShowLists),
            map( (r: string) => _.keys(r)), 
        );

        this.settings.features.subscribe( f => this.features = f);
    }

    ngOnInit() {
        this.s$.SelectedDate.subscribe( d => {
            this.dateSelected = d;
        })
    }

    onDateChange(date:moment.Moment) {
        if (date.isValid()) {
            if (date.format('YYYY-MM-DD') !== this.dateSelected.format('YYYY-MM-DD')) {
                this.s$.SelectedDate.next(date);
                this.dateSelected = date;
            }
        } 
      }

    _scrollToFragment(fragment: string) {
        const foundElement = document.getElementById(fragment);
        if (foundElement) {
            foundElement.scrollIntoView();
        }
    }

    refresh() {
        this.s$.SelectedDate.next(this.dateSelected);
    }
}
