import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ColumnDefinitionsModel, ClickEventArg } from '@common/components';
import { RentalsAvailabilityEditComponent } from '@modules/rentals/components';
import { Slot } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import moment from 'moment';
import _ from 'lodash';

@Component({
    selector: 'd1-rentals-availability-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-availability-view.component.html',
    styleUrls: ['rentals-availability-view.component.scss'],
})
export class RentalsAvailabilityViewComponent implements OnInit, AfterViewInit {
    public ListeSlots: Slot[] = [];
    public listes = [];
    public curDate = new Date();
    public numEquipement = 0;

    public isCollapsed = true;
    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: 'Slot #', visible: true, name: 'slot_id', sortable: false },
        { headerText: 'Date', visible: true, name: 'start_sec', sortable: true, format: f => moment(f).format('DD-MM-YYYY @ HH:mm') },
        { headerText: 'Durée', visible: true, name: 'duration_sec', sortable: true, format: f => { return `${Math.floor(f/60)}h${(f % 60).toString().length<2 ? '0' :''}${(f % 60)}`; } },
        { headerText: 'status', visible: true, name: 'status', sortable: true },
        { headerText: 'Nb. Locations', visible: true, name: 'numRentals', sortable: false },
        { headerText: 'Tags', visible: true, showAsPills: true, name: 'availability_tag', sortable: false },
        { headerText: 'Inventaire', visible: true, name: 'inventory_count', sortable: true },
        { headerText: 'Disponible', visible: true, name: 'availability_count', sortable: true },
    ];

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private dialog$: MatDialog
    ) {
        this.fetchData();
    }

    ngAfterViewInit(): void {
    }

    ngOnInit() {
    }

    onChange() {}
    onDateChange(date:any) {
        this.curDate = moment(date).endOf('day').toDate();
        console.log('change date to', date, this.curDate)
        this.fetchData()
    }

    fetchData() {
        this.rentals$.getSlots$(this.curDate).subscribe((r) => {
            this.ListeSlots = _.sortBy(r, 'start_sec') as Slot[];
            this.ref.markForCheck();
        });
    }

    OnAdd() {
        // this.router.navigate(['add'], { relativeTo: this.nav });
        const slot: Slot = {
            slot_id : 0,
            availability_count : 0,
            availability_tag : '',
            confirmation_mode : 1,
            duration_sec : 0,
            status : 1,
            inventory_count : 0,
            service_id : '1',
            start_sec : new Date()
        }
        const dialogRef = this.dialog$.open(RentalsAvailabilityEditComponent, {data : { slot : slot} });
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result.action}`, result.data);
            if (result !== 'annuler') {
                console.log(`Dialog result: ${result.action}`, result.data);
                this.rentals$.createSlot$(result.data).subscribe( r => {
                    console.log('Après le post', r);
                    this.fetchData();
                })

            }
        });
    }

    OnRowClick(event: ClickEventArg) {
        console.log("OnRowClick", event);

        const r = event.rowData as Slot;

        switch (event.event) {
            case 'edit':
                // this.router.navigate([r.slot_id, 'edit'], { relativeTo: this.nav });
                const dialogRef = this.dialog$.open(RentalsAvailabilityEditComponent, {data : { slot : r} });
                dialogRef.afterClosed().subscribe(result => {
                    if (result !== 'annuler') {
                        console.log(`Dialog result: ${result.action}`, result.data);
                        if (r.slot_id) {
                            this.rentals$.updateSlot$(result.data).subscribe( r => {
                                console.log('Après le post', r);
                                this.fetchData();
                            })
                        } else {
                            this.rentals$.createSlot$(result.data).subscribe( r => {
                                console.log('Après le post', r);
                                this.fetchData();
                            })
                        }

                    }
                });
                break;
            case 'delete':
                break;

            default:
                this.router.navigate([r.slot_id], { relativeTo: this.nav });
                break;
        }
    }

    selectedSlot(event: any) {
        console.log('main view selected slot', event);
        if (+event.id) {
            this.router.navigate([event.id], { relativeTo : this.nav})
        } else {
            this.router.navigate([event.id], { relativeTo : this.nav, queryParams : event.extendedProps.slot})
        }
    }
}
