export interface ResourceModel {
    ressourceId: number;
    ressourceName: string;
    ressourceTypeId: number;
    ressourceTypeName: string;
    ressourceSKU: string;
    ressourceImage: string;
    ressourceTags: string;
    ressourceTypeImage: string;
}

export interface ResourceTypeModel {
    ressourceTypeId: number;
    ressourceTypeName: string;
}

export const ResourceTypeListe = [
    { text: 'Battle Riffle Pro', value: 1 },
    { text: 'Battle Riffle', value: 2 },
    { text: 'Laserblat vest', value: 3 },
]
