<div class="form-inline">
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text" (click)="prevDay()" style="cursor: pointer;">
                    <i class="fa fa-angle-left"></i>
                </div>
            </div>
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="dateReservation" ngbDatepicker #d="ngbDatepicker" (dateSelect)="changeDate()" (ngModelChange)="changeDate()" />
            <div class="input-group-append">
                <div class="input-group-text" (click)="nextDay()" style="cursor: pointer;">
                    <i class="fa fa-angle-right"></i>
                </div>
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                <button (click)="rafraichir()" class="btn btn-outline-secondary"><i class="fa fa-refresh"></i></button>
                <button (click)="aujourdhui()" class="btn btn-outline-secondary"><span>Aujourd'hui</span></button>
            </div>
        </div>
    </div>
</div>
