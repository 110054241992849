import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, DEFAULT_CURRENCY_CODE, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

// Translation modules, services and configs
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { DATE_PIPE_DEFAULT_TIMEZONE, registerLocaleData } from '@angular/common';
// Défaut francais
registerLocaleData(localeFr, 'fr-CA', localeFrExtra);

// AngularJS Shit
import { UrlHandlingStrategy } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AuthInterceptorService } from '@modules/auth/services/auth-interceptor.service';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as authGuard from '@modules/auth/guards';
import { ShopifyService } from '@modules/shopify/services';
import { ShopifyModule } from '@modules/shopify/shopify.module';
import { AppSettingsService } from '@common/services';
import { setUpLocationSync } from '@angular/router/upgrade';

// AngularJS => Angular route sharing
export class Ng1Ng2UrlHandlingStrategy implements UrlHandlingStrategy {
    shouldProcessUrl(url:any) { 
      return true; 
      // return url.toString().startsWith("/ng1") === false && url.toString().startsWith("/reservations") === false && url.toString().startsWith("/salles") === false && url.toString().startsWith("/configurations") === false;
    }
    extract(url:any) { return url; }
    merge(url:any, whole:any) { return url; }
  }
  

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule, 
        AppRoutingModule,
        HttpClientModule,
        AppCommonModule.forRoot(),
        NavigationModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
          }),
        ShopifyModule,
        UpgradeModule,
        LocationUpgradeModule.config({useHash: false}),
    ],
    providers: [CookieService, ...authGuard.guards, 
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }, 
        {provide: LOCALE_ID, useValue: 'fr-CA' },
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'CAD'},
        TranslateService, ShopifyService, AppSettingsService,
        { provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy },
        // {provide : DATE_PIPE_DEFAULT_TIMEZONE, useValue: '-4'}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private settings: AppSettingsService, private upgrade: UpgradeModule) {  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }