<div class="btn-group mr-3" role="group">
    <div ngbDropdown class="btn-group">
        <button class="btn btn-warning dropdown-toggle-split" id="startVideo" ngbDropdownToggle>Démarrer une vidéo</button>
        <div ngbDropdownMenu aria-labelledby="startVideo">
            <button *ngFor="let lr of videoListe" ngbDropdownItem (click)="PlayVideo(lr.url)">{{lr.url}}</button>
        </div>
    </div>
    <div ngbDropdown class="btn-group">
        <button class="btn btn-warning dropdown-toggle-split" id="showImage" ngbDropdownToggle>Afficher une image</button>
        <div ngbDropdownMenu aria-labelledby="showImage">
            <button *ngFor="let lr of imageListe" ngbDropdownItem (click)="ShowImage(lr.url)">{{lr.url}}</button>
        </div>
    </div>
    <div ngbDropdown class="btn-group">
        <button class="btn btn-warning dropdown-toggle-split" id="startVideo2" ngbDropdownToggle>Démarrer un défilement</button>
        <div ngbDropdownMenu aria-labelledby="startVide2o">
            <button *ngFor="let lr of showListe" ngbDropdownItem (click)="ShowSlideshow(lr)">{{lr}}</button>
        </div>
    </div>
</div>
<div class="btn-group" role="group" aria-label="">
    <button type="button" class="btn btn-default" ng-class="{'active': partieType == 1}" ng-click="changerTypePartie(1)"><span translate>Voir le lasertag</span></button>
</div>
<div class="btn-group" role="group" ng-if="arenaListe.length > 1">
    <button mat-button [matMenuTriggerFor]="arenasMenu">Arenas</button>
    <mat-menu #arenasMenu="matMenu" yPosition="below">
        <button *ngFor="let arena of arenaListe" mat-menu-item (click)="changerArena(arena.id)">{{arena.name}}</button>
    </mat-menu>
</div>
<div class="btn-group" role="group" aria-label="">
    <button type="button" class="btn btn-default" ng-class="{'active': modeCedule == 1}" ng-click="modeCedule = 1"><span translate>Voir une journée</span></button>
    <button type="button" class="btn btn-default" ng-class="{'active': modeCedule == 2}" ng-click="modeCedule = 2"><span translate>Voir une semaine</span></button>
</div>
<div class="x_content">
    <label for="voirTout"><span translate>Voir toutes les cédules</span> : <input type="checkbox" ng-model="voirTout" class="" id="voirTout"/></label>
</div>
<label><span translate>Filtrer</span> : </label>
<div class="btn-group" role="group">
    <button type="button" class="btn btn-default" ng-model="statutGroupe" uib-btn-radio="0" uncheckable ng-change1="filtrerGroupes()"><span translate>Tous</span></button>
    <button type="button" class="btn btn-default" ng-model="statutGroupe" uib-btn-radio="2" uncheckable ng-change1="filtrerGroupes()"><span translate>Payés et présents seulement</span></button>
</div>
<button type="button" class="btn btn-sm btn-primary" ng-click="ShowScanCodePopup()">Scan code</button>
