import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { tap } from 'rxjs/operators';
import { AppSettingsService } from './appsettings.service';

export type ConfigResponse = {
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  TypeGroupes = [];
  TypeSalles: [] = [];
  Attributs = [];
  configurations: any;
  whitelist: any;
  api: any;
  Horaires = [];
  features: string[] = [];

  constructor(private http: HttpClient, private s$: AppSettingsService ) { }

  // Aller chercher les configs
  getconfigurations() {
    //var me = this;
    return this.http.get('/api/settings')
      .pipe(
        tap((response: any) => {
          this.configurations = response;

          // Shortucs
          if (this.configurations.horaires) {
            this.Horaires = this.configurations.horaires.horaires;
          }

          // Shortucs
          if (this.configurations.api) {
            this.api = this.configurations.api;
          }

          // Shortucs
          if (this.configurations.whitelist) {
            this.whitelist = this.configurations.whitelist;
          }

          if (this.configurations.features) {
            this.features = this.configurations.features;
          }

          return this.configurations;
        }
        ));
  };


  /**
   * Aller chercher les attributs possibles pour une résrevation
   * @returns {*}
   */
  getAttributs(refresh: boolean) {
    if (this.Attributs.length == 0 || refresh) {
      this.Attributs = [];
      return this.http.get('/api/reservations/attributs')
        .pipe(
          tap((response: any) => {
            this.Attributs = response.data;
            return this.Attributs;
          })
        );
    } else {
      return this.Attributs;
    }
  };

  /**
   * Aller chercher les types de salles possibles pour une résrevation
   * @returns {*}
   */
  getTypeSalles(refresh: boolean): Observable<[]> {
    if ((this.TypeSalles && this.TypeSalles.length == 0) || refresh) {
      this.TypeSalles = [];
      return this.http.get('/api/reservations/typesalles')
        .pipe(
          tap((response: any) => {
            this.TypeSalles = response.data;
            return this.TypeSalles;
          })
        );
    } else {
      return of(this.TypeSalles);
    }
  };


  /**
   * Aller chercher les types de groupes possible pour une réservation
   * @returns {*}
   */
  getTypeGroupes(refresh: boolean) {
    if (this.TypeGroupes.length == 0 || refresh) {
      this.TypeGroupes = [];
      return this.http.get('/api/reservations/typegroupes').pipe(tap((response: any) => {
        this.TypeGroupes = response.data;
        return this.TypeGroupes;
      })
      );
    } else {
      return this.TypeGroupes;
    }
  };

  saveTypeGroupe(groupeType: any) {
    // console.log("saveTypeGroupe", groupeType);
    return this.http.post('/api/config/groupeType', groupeType).pipe(tap((response: any) => {
      // console.log("saveTypeGroupe response.data", response.data);
      // socket.emit('RafraichirConfig', groupeType);
      return response.data;
    })
    );
  };

  effacerTypeGroupe(groupeType: any) {
    // console.log("effacerTypeGroupe", groupeType);
    return this.http.delete('/api/config/groupeType/' + groupeType.groupeTypeId).pipe(tap((response: any) => {
      // console.log("effacerTypeGroupe response.data", response.data);
      // this.socket.emit('RafraichirConfig', groupeType);
      return response.data;
    })
    );
  };

  /**
   * API
   */
  saveAPI(api: any) {
    this.api = api;
    // console.log("saveAPI", this.api);
    return this.http.put('/api/config/api/', api).pipe(tap((response: any) => {
      // console.log("saveAPI response.data", response.data);
      // this.socket.emit('RafraichirConfig', this.api);
      return response.data;
    }));
  };

  /**
   * WHITELIST
   */
  saveWhitelist(whitelist: string) {
    this.whitelist = whitelist;
    // console.log("saveWhitelist", this.whitelist);
    return this.http.put('/api/config/whitelist/', this.whitelist).pipe(tap((response: any) => {
      // console.log("saveWhitelist response.data", response.data);
      // socket.emit('RafraichirConfig', this.api);
      // Afficher l'erreur
      // Notification.success({
      //     message: "La liste des adresses a été sauvegardée avec succès",
      //     title: "Sauvegarde",
      //     positionX: 'center',
      //     positionY: 'top',
      //     delay: 2000,
      //     onClose: function() {
      //         //msg.nePlusAfficher = true;
      //     }
      // });

      return response.data;
    }));
  };

  /**
   * FEATURES
   */
  saveFeatures(features: string[]) {
    this.features = features;
    return this.http.put('/api/config/features/', this.features).pipe(tap((response: any) => {
      this.s$.getConfigurations();
      return response.data;
    }));
  };

  /**
   * HORAIRES
   */
  saveHoraire(horaires: any) {
    this.Horaires = horaires;
    // console.log("saveHoraire", this.Horaires);
    return this.http.put('/api/config/horaires/', this.Horaires).pipe(tap((response: any) => {
      // console.log("saveHoraire response.data", response.data);
      // socket.emit('RafraichirConfig', Horaires);
      return response.data;
    }));
  };

  findLatestSchedule(horaires: any, viewDate: Moment) {
    var horairesLatest: any;

    // Trouver parmis les horaire, lequel on est le plus proche du début en autant qu'on soit dans la période de l'horaire
    _.each(horaires, function (h) {
      if (viewDate.isBetween(h.start_date, h.end_date, 'days', '[]')) {
        if ((horairesLatest || []).length == 0) {
          horairesLatest = h;
        } else {
          // Comparer le start date et prendre le plus près de notre date désirée
          if (viewDate.diff(h.start_date, 'days') <= viewDate.diff(horairesLatest.start_date, 'days')) {
            horairesLatest = h;
          }
        }
      } else {
        //onsole.log("horaire inactif (start, end)", h.start_date, h.end_date);
      }
    });

    // À ce point, on a l'horaire le plus approprié
    //console.log("horairesLatest", horairesLatest);
    return horairesLatest;
  };

  findLatestScheduleHours(horairesLatest: any, viewDate: any) {
    // Trouver la journée actuelle dans l'horaire
    var dayLookup = viewDate.day();
    var heuresLatest: any;

    // On fouille les journées normales
    _.each(horairesLatest.business_days, function (d) {
      if (d.days[dayLookup] == 1) {
        heuresLatest = d;
      }
    });

    // On fouille les exceptions
    _.each(horairesLatest.exception_days, function (d) {
      if (viewDate.isBetween(d.start_date, d.end_date, 'days', '[]')) {
        if (d.days[dayLookup] == 1) {
          heuresLatest = d;
        }
      }
    });

    // À ce point, on a les heures de début et de fin
    var mDebut = moment(heuresLatest.start);
    var hDebut = moment(viewDate).hours(mDebut.hours()).minutes(mDebut.minutes()).seconds(0).milliseconds(0);
    var mFin = moment(heuresLatest.end);
    var hFin = moment(viewDate).hours(mFin.hours()).minutes(mFin.minutes()).seconds(0).milliseconds(0);
    if (mFin.hours() < 6) {
      // On va plus loin que la journée, petites heures du mat
      hFin.add(1, 'days');
    }
    heuresLatest.start_date = hDebut;
    heuresLatest.end_date = hFin;
    //console.log("heuresLatest", heuresLatest, hDebut, hFin);

    return heuresLatest;
  };

}
