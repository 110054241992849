import { formatNumber } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClickEventArg } from '@common/components';
import { ShopifyService } from '@modules/shopify/services';
import moment from 'moment';
import { ColumnDefinitionsModel, SimpleGridComponent } from 'ts-ng-lib';

@Component({
    selector: 'd1-shopify-admin-orders',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-admin-orders.component.html',
    styleUrls: ['shopify-admin-orders.component.scss'],
})
export class ShopifyAdminOrdersComponent implements OnInit, AfterViewInit {
    @ViewChild('grille', { static: true}) grille!:SimpleGridComponent;
    @ViewChild('grilleDraft', { static: true}) grilleDraft!:SimpleGridComponent;
    public lstOrders!:[];
    public lstDrafts!:[];
    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: '#', visible: true, name: 'id', sortable: true, arrowPosition: 'after' },
        { headerText: 'Prénom', visible: true, name: 'customer.first_name', sortable: true, arrowPosition: 'after', format: (v, row) => row.customer.first_name },
        { headerText: 'Nom', visible: true, name: 'customer.last_name', sortable: true, arrowPosition: 'after', format: (v, row) => row.customer.last_name },
        { headerText: 'Courriel', visible: true, name: 'email', sortable: true, arrowPosition: 'after' },
        { headerText: 'Téléphone', visible: true, name: 'customer.phone', sortable: true, arrowPosition: 'after', format: (v, row) => row.customer.phone },
        { headerText: 'Total ($)', visible: true, name: 'total_price', sortable: true, arrowPosition: 'after', format: (v) => {
            return formatNumber(v, 'fr-CA', '1.2-2');
        } },
        { headerText: 'Adresse', visible: true, name: 'shipping_address', sortable: true, arrowPosition: 'after', format: (v) => {
            if (v) {
                return `${v.address1}, ${v.city}`
            } else {
                return v;
            }
        } },
        { headerText: 'Tags', visible: true, name: 'tags', showAsPills:true, sortable: false, arrowPosition: 'after' },
        // { headerText: 'Image', visible: true, name: 'image', sortable: false, arrowPosition: 'after', format: (v) => {
        //     if (v) {
        //         // const test = this.dom.bypassSecurityTrustHtml(`<img src="${this.dom.bypassSecurityTrustResourceUrl(v.src)}" />`)
        //         return `<img class="img-thumbnail img-fluid" src="${v.src}" />`;
        //     } else {
        //         return v;
        //     }
        // } },

    ];
    public columnDefs2: ColumnDefinitionsModel[] = [
        { headerText: '#', visible: true, name: 'id', sortable: true, arrowPosition: 'after' },
        { headerText: 'Date', visible: true, name: 'invoice_sent_at', sortable: true, arrowPosition: 'after', format: (v) => moment(v).format('D MMM yyyy') },
        { headerText: 'Prénom', visible: true, name: 'customer.first_name', sortable: true, arrowPosition: 'after', format: (v, row) => row.customer.first_name },
        { headerText: 'Nom', visible: true, name: 'customer.last_name', sortable: true, arrowPosition: 'after', format: (v, row) => row.customer.last_name },
        { headerText: 'Courriel', visible: true, name: 'email', sortable: true, arrowPosition: 'after' },
        { headerText: 'Téléphone', visible: true, name: 'customer.phone', sortable: true, arrowPosition: 'after', format: (v, row) => row.customer.phone },
        { headerText: 'Total ($)', visible: true, name: 'total_price', sortable: true, arrowPosition: 'after', format: (v) => {
            return formatNumber(v, 'fr-CA', '1.2-2');
        } },
        { headerText: 'Adresse', visible: true, name: 'shipping_address', sortable: true, arrowPosition: 'after', format: (v) => {
            if (v) {
                return `${v.address1}, ${v.city}`
            } else {
                return v;
            }
        } },
        { headerText: 'Tags', visible: true, name: 'tags', showAsPills:true, sortable: false, arrowPosition: 'after' },
        // { headerText: 'Image', visible: true, name: 'image', sortable: false, arrowPosition: 'after', format: (v) => {
        //     if (v) {
        //         // const test = this.dom.bypassSecurityTrustHtml(`<img src="${this.dom.bypassSecurityTrustResourceUrl(v.src)}" />`)
        //         return `<img class="img-thumbnail img-fluid" src="${v.src}" />`;
        //     } else {
        //         return v;
        //     }
        // } },

    ];

    constructor(private s$:ShopifyService, private ref:ChangeDetectorRef, private dom:DomSanitizer) {
    }

    ngAfterViewInit(): void {
        this.grille.loading = true;
        this.grilleDraft.loading = true;
        this.s$.getOrders().subscribe( lst => {
            this.lstOrders = lst;
            if (this.lstOrders.length === 0) {

            }
            this.grille.loading = false;
            this.ref.markForCheck()
        })

        this.s$.getDraftOrders().subscribe( lst => {
            this.lstDrafts = lst;
            if (this.lstDrafts.length === 0) {

            }
            this.grilleDraft.loading = false;
            this.ref.markForCheck()
        })
    }
    ngOnInit() {}

    clickRowOrder(row: ClickEventArg) {
        const order = row.rowData as any;
        console.log('row', row.event, order)
        window.open(`https://district-1-lasertag.myshopify.com/admin/orders/${order.id}`, '_blank')
        // this.s$.updateOrder(order.id, {"order":{"id":order.id,"note_attributes":[{"name":"Reload id","value":"123456"}]}}).subscribe( r => {
        //     console.log('updateOrder ret', r);
        // })
    }
    clickRowOrderDraft(row: ClickEventArg) {
        const order = row.rowData as any;
        console.log('row', row.event, order)
        window.open(`https://district-1-lasertag.myshopify.com/admin/draft_orders/${order.id}`, '_blank')
        // this.s$.updateOrder(order.id, {"order":{"id":order.id,"note_attributes":[{"name":"Reload id","value":"123456"}]}}).subscribe( r => {
        //     console.log('updateOrder ret', r);
        // })
    }

    clickEdit(row: ClickEventArg) {
        const order = row.rowData as any;
        console.log('row', row.event, order)
        // this.s$.updateOrder(order.id, {"order":{"id":order.id,"note_attributes":[{"name":"Reload id","value":"123456"}]}}).subscribe( r => {
        //     console.log('updateOrder ret', r);
        // })
    }

    clickDelete(row: ClickEventArg) {
        console.log('row', row.event, row.rowData)
        const order = row.rowData as any;
        // this.s$.updateOrder(order.id, {"order":{"id":order.id,"note_attributes":[]}}).subscribe( r => {
        //     console.log('updateOrder ret', r);
        // })
    }
}
