import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppSettingsService } from '@common/services/appsettings.service';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services/reservations.service';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleService } from '@modules/schedule/services/schedule.service';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'd1-reservations-add-walkin',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-add-walkin.component.html',
    styleUrls: ['reservations-add-walkin.component.scss'],
})
export class ReservationsAddWalkinComponent implements OnInit {
    // @Input() booking!:ReservationModel;
    @Output() onSave: EventEmitter<ReservationModel> = new EventEmitter();

    public myForm: FormGroup;
    public dateNow: Date = new Date();
    public games: GameModel[] = [];
    public groupeTypeListe:any[] = [];
    public forfaitsGroupes:any[] = [];

    constructor(private fb: FormBuilder, private _appSettings:AppSettingsService, private r$:ReservationsService, private c$:ScheduleService, public dialogRef: MatDialogRef<ReservationsAddWalkinComponent>) {
        this.myForm = this.fb.group({
            groupeId: 0,
            groupeTypeId: [0, [Validators.required]],
            groupeTitre: '',
            groupe_tags: '',
            nomOrganisateur: ['', [Validators.required]],
            nombreJoueurs: [2, [Validators.min(1)]],
            reservationDate: this.c$.reajustTime(moment()).toISOString(),
            courriel: ['', [Validators.required]],
            telephone: ['', [Validators.required]],
            depotFait: false,
            depotMontant: 0,
            depotReference: '',
            nomFete: '',
            salleReservee: 0,
            salleDebut: null,
            salleFin: null,
            notes: '',
            notesAdmin: '',
            initialesEmploye: ['', [Validators.required]],
            statutGroupe: 2,
            statutConfirmation: 1,
            statutPaiement: 1,
            notesMarshall: '',
            responsable: '',
            notesMarshallCouleur: '',
        })

        this._appSettings.TypeGroupes.subscribe( (r: any[]) => {
            this.groupeTypeListe = _.filter(r, (g) => {
                return (g.estDisponible === 1 && g.estFete === 0 && g.requiertCoordonnes === 0 && g.minimumDepot === 0) || g.groupeTypeId === 0
            });
            const byKeys = _.groupBy(this.groupeTypeListe, 'regroupement')
            this.forfaitsGroupes = [];
            _.each(byKeys, (g,k) => {
                this.forfaitsGroupes.push({groupe : k, forfaits: g})
            });
        });

        this.myForm.get('courriel')?.valueChanges.pipe(distinctUntilChanged(), debounceTime(150)).subscribe( (v) => {
            if (v) {
                this.myForm.controls['telephone'].clearValidators();
                this.myForm.controls['telephone'].updateValueAndValidity();
            } else if (!v && !this.myForm.get('telephone')?.value) {
                this.myForm.controls['courriel'].setValidators([Validators.required]);
                this.myForm.controls['courriel'].updateValueAndValidity();
            }            
        })
        
        this.myForm.get('telephone')?.valueChanges.pipe(distinctUntilChanged(), debounceTime(150)).subscribe( (v) => {
            if (v) {
                this.myForm.controls['courriel'].clearValidators();
                this.myForm.controls['courriel'].updateValueAndValidity();
            } else if (!v && !this.myForm.get('courriel')?.value) {
                this.myForm.controls['telephone'].setValidators([Validators.required]);
                this.myForm.controls['telephone'].updateValueAndValidity();
            }
        })
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.booking) {
            // this.fetchData();
        }
    }

    ngOnInit() {
    }

    OnChange(event: any) {
        const earlyDate = this.c$.reajustTime(event[0]).toISOString()
        this.games = event
        this.myForm.get('reservationDate')?.setValue(earlyDate)
    }

    statutPaiementChange(statut: number) {
        this.myForm.controls.statutPaiement.setValue(+statut);
    }

    statutChange(statut: number) {
        this.myForm.controls.statutGroupe.setValue(+statut);
    }

    save() {
        const group:ReservationModel = new ReservationModel(this.myForm.value);
        group.parties = this.games;
        group.groupeType = _.find(this.groupeTypeListe, {groupeTypeId : this.myForm.get('groupeTypeId')?.value});
        // group.reservationDate = moment(group.parties[0] as any).toDate()
        group.partiesDate = group.reservationDate
        this.onSave.emit(group);

        if(this.myForm.valid) {
            this.r$.addRecord(group).subscribe( resp => {
                this.dialogRef.close();
            })
        }
    }
}
