import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNotificationService } from '@common/services/appnotification.service';
import { ConfigService } from '@common/services/config.service';
import { VestService } from '@common/services/vest.service';
import { ScheduleService } from '@modules/schedule/services';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
    selector: 'd1-schedule-list-timeslot',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-list-timeslot.component.html',
    styleUrls: ['schedule-list-timeslot.component.scss'],
})
export class ScheduleListTimeslotComponent implements OnInit {
  
    videoListe: any = [];
    imageListe: any = [];
    showListe: any = [];
    arenaListe: any = [];
    filtered: any = [];
    lstCedules = [];
    lstCedules2 = new Observable<any>();
    arena_id = 1;
    partieType = 1;
    dateReservation:NgbDate;
    dateSelected = new Date();
    Configurations = {};
    nombreVestes:number = 0;
    
    constructor(private scheduleService:ScheduleService
              , private notification:AppNotificationService
              , private calendar: NgbCalendar
              , private configService:ConfigService
              , private route: ActivatedRoute
              , private router: Router
              , private vestService:VestService
              , private ref: ChangeDetectorRef) {
  
      //Init
      this.dateReservation = this.calendar.getToday();
  
      this.vestService.getListe().subscribe( (resp) => {
        this.nombreVestes = this.vestService.nombreVestes;
      })
      
      // const sd = moment().format('YYYY-MM-DD');
      // scheduleService.getListe(sd, null, null, 1).subscribe( (list) => {
      //   this.lstCedules = list;
      // });

      // this.lstCedules2 = scheduleService.getListe(new Date(), null, null, 1);
  
      scheduleService.mediaListe().subscribe( (resp:any) => {
        this.videoListe = _.toArray(_.filter(resp.slideShowItems, {type:'video'}));
        this.imageListe = _.toArray(_.filter(resp.slideShowItems, {type:'image'}));
        this.showListe = _.keys(resp.slideShowLists);
      });
  
      configService.getconfigurations().subscribe( (resp:any) => {
        this.arenaListe = (resp.arenas ? resp.arenas.arenas : []);
      });
  
    }
  
    rafraichir() {
      const sd = moment(this.dateReservation).subtract(1, 'month').format('YYYY-MM-DD');
      // this.scheduleService.getListe(sd, null, null, 1).subscribe( (list: any) => {
      //   this.lstCedules = list;
      // });
    }

    onDateChange(date:Date) {
      // this.dateSelected = date;
      // this.scheduleService.getListe(this.dateSelected, null, null, 1).subscribe( (list: any) => {
      //   this.lstCedules = list;
      //   this.ref.markForCheck()
      // });

      // this.lstCedules2 = this.scheduleService.getListe(this.dateSelected, null, null, 1);

    }
  
    showDate() {
      const testDate:NgbDate | null = NgbDate.from(this.dateReservation);
      if (this.calendar.isValid(testDate)) {
        //const sd = moment(this.dateReservation).subtract(1, 'month').format('YYYY-MM-DD');
        this.dateSelected = new Date(this.dateReservation.year, this.dateReservation.month-1, this.dateReservation.day );
      }
    }
  
  
    ngOnInit() {
      this.route.queryParams.subscribe( (queryParams) => {
        if (queryParams['date']) {
          const curdate = moment(queryParams['date']);
          this.dateReservation.year = curdate.year();
          this.dateReservation.month = curdate.month()+1;
          this.dateReservation.day = curdate.date();
        } else {
          this.dateReservation = this.calendar.getToday();
        }
  
        this.showDate();
      }) 
    }
  
    changerArena(arena_id: number) {
      const $scope = this;
  
      this.arena_id = arena_id;
      // this.scheduleService.getListe($scope.dateReservation, -1, $scope.partieType, $scope.arena_id).subscribe( (list: any) => {
      //     $scope.lstCedules = list;
      // });
  };
  
    // PlayVideo(url:string) {
    //   this.scheduleService.PlayVideo(url)
    //     .subscribe( (resp) => {
    //       this.notification.showMessage("Video [" + url + "] displayed on screens");
    //     }, (err) => {
    //       console.error(err);
    //       this.notification.showError('Failed to play the video');
    //     });
    // }
  
    // ShowImage(url:string) {
    //   this.scheduleService.ShowImage(url)
    //     .subscribe( (resp) => {
    //       this.notification.showMessage("Image [" + url + "] displayed on screens");
    //     }, (err) => {
    //       console.error(err);
    //       this.notification.showError("Failed to show the image [" + url + "]");
    //     });
    // }
  
    // ShowSlideshow(url:string) {
    //   this.scheduleService.ShowSlideshow(url)
    //     .subscribe( (resp) => {
    //       this.notification.showMessage("Slideshow [" + url + "] displayed on screens");
    //     }, (err) => {
    //       console.error(err);
    //       this.notification.showError('Failed to play the slideshow');
    //     });
    // }
  
    ceduleTemps = function(item: any) {
      return moment(item.ceduleTemps).format('HH:mm');
    };
  
    nombreJoueursFiltres = (cedule: any) => {
      var t = _.find(this.filtered, function (item) {
          return item.ceduleTemps == cedule.ceduleTemps
      });
  
      if (t !== undefined) {
          var totGroupe = 0;
          _.each(t, function (g) {
              totGroupe += (g.statutGroupe == 1 || g.statutGroupe == 2 ? g.nombreJoueurs : 0);
          });
          return totGroupe;
      }
  };
}
