<tsng-form-group-input class="m-0 pl-1 w-50" type="text" (change)="changeFiltre($event)" (value)="changeFiltre($event)" label="Filtre"></tsng-form-group-input>
<div *ngFor="let cedule of filtrerCeduleTimes(); let i = index" [id]="'col-' + cedule.partieHeures">
    <mat-card class="m-0 mb-1 p-0" *ngIf="cedule.ceduleNombreJoueurs" [class]="['cedule', 'cedule-accueil-' +  s$.getPasseCouleur(cedule.partieHeures)]" [class.cedule-overbooked]="(nombreVestes - cedule.ceduleNombreJoueurs &lt; 0)"
        ><mat-card-title class="align-middle text-white bg-danger m-0 p-0" *ngIf="(nombreVestes - cedule.ceduleNombreJoueurs &lt; 0) &amp;&amp; cedule.groupeAvecExclusif !== 1"><mat-icon>gpp_maybe</mat-icon><span class="pl-2">Cédule avec trop de joueurs</span></mat-card-title
        ><mat-card-title class="text-white bg-danger m-0 p-0" *ngIf="cedule.groupeAvecExclusif === 1"><mat-icon>groups</mat-icon><span class="align-middle pl-2">Groupe exclusif</span></mat-card-title
        ><mat-card-content class="d-flex align-items-stretch"
            ><div class="time-info text-white p-2 align-middle" [class]="'bg-' +  s$.getPasseCouleur(cedule.partieHeures)">
                <div class="font-weight-bolder">{{ cedule.partieHeures }}</div>
                <div class="font-weight-light">{{cedule.nombreVestes - cedule.ceduleNombreJoueurs}} disp.</div>
            </div>
            <div class="d-flex flex-row flex-wrap"><d1-reservations-group-item class="p-0 mb-1" [class]="'groupe-groupeid-' + group.groupeId" *ngFor="let group of getGamesInSchedule(cedule.partieTemps)" [group]="group" [game]="findGame(group, cedule.partieTemps)" (selected)="showSelected($event)" (mouseover)="highlight($event, group)" (mouseleave)="unhighlight($event, group)"></d1-reservations-group-item></div></mat-card-content></mat-card
    ><mat-card class="m-0 mb-1 p-0" *ngIf="(cedule.ceduleNombreJoueurs &lt; 1 || cedule.ceduleNombreJoueurs === undefined)" [class]="['cedule', 'cedule-accueil-' +  s$.getPasseCouleur(cedule.partieHeures)]" [style.background]="'bg-' + s$.getPasseCouleur(cedule.partieHeures)"
        ><mat-card-content class="d-flex align-items-stretch"
            ><div class="time-info-vide text-white px-2" [class]="'bg-' +  s$.getPasseCouleur(cedule.partieHeures)">
                <div class="font-weight-bolder">{{ cedule.partieHeures }}</div>
                <div class="font-weight-light">{{cedule.nombreVestes - cedule.ceduleNombreJoueurs}} disp.</div>
            </div></mat-card-content
        ></mat-card
    >
</div>
