import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-print-main',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './print-main.component.html',
    styleUrls: ['print-main.component.scss'],
})
export class PrintMainComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
