import { ChangeDetectorRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventApi } from '@fullcalendar/core';
import { GroupeModel } from '@modules/groupe/models';
import { Rental } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-rentals-booking-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-booking-edit.component.html',
    styleUrls: ['rentals-booking-edit.component.scss'],
})
export class RentalsBookingEditComponent implements OnInit, OnDestroy, OnChanges {
    @Input() rental:Rental = {
        booking_id: 0,
        slot_id: 0,
        groupeId: 0,
        geo_lat: 0,
        geo_long: 0,
        status: 0,
        nocivique: '',
        rue: '',
        ville: '',
        province: '',
        pays: '',
        codepostal: '',
        booking_tags: '',
        shipping_type: 0,
        notes: '',
        notesAdmin: '',
        duration_sec: 0
    };
    @Output() rentalChange:EventEmitter<Rental> = new EventEmitter();
    @Output() change:EventEmitter<Rental> = new EventEmitter();
    groupe: GroupeModel = new GroupeModel();
    public curDate:Date = new Date();

    subs: Subscription[] = [];
    myForm!: FormGroup;
    showSlot = false;

    constructor(
        private ref: ChangeDetectorRef,
        private fb: FormBuilder,
        private rentals$: RentalsService,
        private router: Router,
        private nav: ActivatedRoute,
    ) {
        console.log('this.nav.queryParams', this.nav.snapshot.queryParamMap.get('date'))
        if (this.nav.snapshot.queryParamMap.get('date')) {
            this.curDate = moment(this.nav.snapshot.queryParamMap.get('date')).startOf('day').toDate()
        }
        if (this.nav.snapshot.data.booking) {
            this.rental = this.nav.snapshot.data.booking;
            this.groupe.courriel = (this.rental.courriel ? this.rental.courriel : '')
            this.groupe.groupeId = this.rental.groupeId
            this.groupe.nomOrganisateur = (this.rental.nomOrganisateur ? this.rental.nomOrganisateur : '')
            this.groupe.notes = (this.rental.notes ? this.rental.notes : '')
            this.groupe.notesAdmin = (this.rental.notesAdmin ? this.rental.notesAdmin : '')
            this.groupe.telephone = (this.rental.telephone ? this.rental.telephone : '')
        } else {
            if (!this.rental) {
                this.rental = {
                    booking_id : 0,
                    booking_tags : '',
                    codepostal : '',
                    groupeId : 0,
                    shipping_type : 0,
                    nocivique : '',
                    notes : '',
                    notesAdmin : '',
                    pays : '',
                    province: '',
                    rue : '',
                    slot_id: 0,
                    status : 0,
                    nombreJoueurs : 2,
                    geo_lat: 0,
                    geo_long: 0,
                    ville: '',
                    duration_sec: 0,
                    reservationDate : new Date()
                }
            }
        }
        this.myForm = this.fb.group({
            slot_id: [this.rental.slot_id],
            booking_id: [this.rental.booking_id],
            groupeId: [this.rental.groupeId, [Validators.required]],
            status: [ this.rental.status, [Validators.required, Validators.min(0)]],
            nocivique : this.rental.nocivique,
            rue : this.rental.rue,
            ville : this.rental.ville,
            province : this.rental.province,
            pays : this.rental.pays,
            geo_lat : this.rental.geo_lat,
            geo_long : this.rental.geo_long,
            codepostal : this.rental.codepostal,
            nombreJoueurs : this.rental.nombreJoueurs,
            reservationDate : (this.nav.snapshot.queryParamMap.get('date') ? moment(this.nav.snapshot.queryParamMap.get('date')).endOf('day').toDate() : this.rental.reservationDate),
            groupe : this.groupe
        });

        // Soumettre les changements "live"
        this.subs.push(this.myForm.valueChanges.subscribe( v => {
            // Merger les changements du frombuilder et notre objet de départ
            this.rental = {...this.rental,...this.myForm.value};
            this.rentalChange.emit(this.rental);
            this.change.emit(this.rental);
        }));

        this.rentals$.getSlots$(this.curDate, this.curDate).subscribe( slots => {
            console.log('got slots', slots)
            if (slots.length === 1) {
                // Pré-sélectionner
                this.selectedSlot(slots[0] as any)
            }
        })
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.rental) {
            console.log('patchvalue');
            this.myForm.patchValue({
                slot_id: this.rental.slot_id,
                booking_id: this.rental.booking_id,
                groupeId: this.rental.groupeId,
                status: this.rental.status,
                nocivique : this.rental.nocivique,
                rue : this.rental.rue,
                ville : this.rental.ville,
                province : this.rental.province,
                pays : this.rental.pays,
                geo_lat : this.rental.geo_lat,
                geo_long : this.rental.geo_long,
                codepostal : this.rental.codepostal,
                nombreJoueurs : (!this.rental.nombreJoueurs ? 2 :this.rental.nombreJoueurs),
                // reservationDate2 : (!this.rental.reservationDate ? new Date() : new Date(this.rental.reservationDate)),
                reservationDate :(this.nav.snapshot.queryParamMap.get('date') && this.rental.booking_id === 0 ? moment(this.nav.snapshot.queryParamMap.get('date')).startOf('day').toDate() : (!this.rental.reservationDate ? new Date() : new Date(this.rental.reservationDate)))
            });
            if (this.rental.status >= 2) {
                this.myForm.controls.nombreJoueurs.disable()

            }
        }
    }

    ngOnDestroy(): void {
        // Destroy subs
        _.each(this.subs, s => s.unsubscribe());
    }

    public refresh() {
        this.ref.markForCheck();
    }

    onDateChange() {

    }
    
    ngOnInit() {

        // this.myForm.patchValue({
        //     booking_id: this.rental.booking_id,
        //     slot_id: this.rental.slot_id,
        //     status: this.rental.status,
        //     groupeId: this.rental.groupeId,
        // });
    }

    changeSlot() {
        this.showSlot = !this.showSlot;
    }

    selectedSlotEvent(event: EventApi) {
        this.selectedSlot(event.extendedProps.slot);
    }

    selectedSlot(slot: any) {
        console.log('SELECTED SLOT', slot)
        this.showSlot = false;
        this.myForm.controls.reservationDate.setValue(new Date(slot.start_sec ? slot.start_sec : ''));
        this.myForm.controls.slot_id.setValue(slot.slot_id);
        // this.rental.slot_id = slot.extendedProps.slot.slot_id;
        // this.rental.reservationDate = new Date(slot.extendedProps.slot.start_sec);
        this.myForm.controls.nombreJoueurs.setValue(slot.numRentals)
        this.rental.nombreJoueurs = slot.numRentals
        this.rental = {...this.rental, ...slot};
        this.rentalChange.emit(this.rental);
        this.change.emit(this.rental);
    }

    groupeChange(v: any) {
        this.rental = {...this.rental,...v};
        this.rentalChange.emit(this.rental);
        this.change.emit(this.rental);
    }

    retirerGroupe() {
        // let v: GroupeModel = new GroupeModel();
        // this.rental = {...this.rental,...v};
        // this.groupe = v;
        this.rental.groupeId = 0;
        this.groupe.groupeId = 0;
        this.myForm.controls.groupeId.setValue(0);
        this.groupe = {...this.groupe};
        this.rentalChange.emit(this.rental);
        this.change.emit(this.rental);
    }

}
