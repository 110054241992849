<ng-container
    ><div class="card card-group-item m-1 pr-0" ngbTooltip="{{group.nomOrganisateur | titlecase}} ({{groupeType.groupeTypeNom}})" (click)="groupClicked()">
        <div class="row no-gutters">
            <div class="col p-0">
                <div class="p-1 pt-2 text-center align-middle font-weight-bold text-white" [style.background-color]="groupeType.groupeTypeCouleur">{{ groupeType.groupeTypeNom }}</div>
                <div class="d-flex align-items-stretch">
                    <div class="p-1 pt-2 bg-gray-800 card-players font-weight-bold text-white text-center align-middle">{{ (game ? game.nombreJoueurs : group.nombreJoueurs) }}</div>
                    <div class="m-2">
                        <div class="font-weight-bolder">
                            {{ group.nomOrganisateur | titlecase }}<d1-reservations-payment-status class="mr-1" *ngIf="group.statutPaiement !== 1" [statutPaiement]="group.statutPaiement" [showLabel]="false" [showToggle]="false" [showColor]="false" [showIcon]="true"></d1-reservations-payment-status><d1-reservations-confirmation-status class="mr-1" *ngIf="group.statutConfirmation !== 1" [statutConfirmation]="group.statutConfirmation" [showLabel]="false" [showToggle]="false" [showColor]="false" [showIcon]="true"></d1-reservations-confirmation-status><d1-reservations-status [statutGroupe]="group.statutGroupe" [showLabel]="false" [showToggle]="false" [showColor]="false" [showIcon]="true"></d1-reservations-status><mat-icon class="mr-1 text-danger" ngbTooltip="Réservation annulée" *ngIf="group.estAnnule">cancel</mat-icon><mat-icon class="mr-1 text-danger" ngbTooltip="Réservation effacée" *ngIf="group.estEfface">folder_delete</mat-icon
                            ><mat-icon class="mr-1" [ngbTooltip]="group.notesAdmin" container="body" placement="right" *ngIf="group.notesAdmin">speaker_notes</mat-icon>
                        </div>
                        <div class="font-weight-normal" *ngIf="group.nomFete">Fêté : {{ group.nomFete | titlecase }}</div>
                        <div class="font-weight-normal" *ngIf="group.salles &amp;&amp; group.salles.length &gt; 0">Salle : <span *ngFor="let s of group.salles">{{s.salleNom}}</span></div>
                        <div class="p-0 pt-2" *ngIf="group.groupe_tags.length &gt; 0">
                            <d1-display-info class="mr-2" [label]="" [list]="group.groupe_tags"></d1-display-info><mat-chip class="p-0 mt-2 mr-1" *ngIf="group.notesMarshall" ngbTooltip="Notes marshall" container="body" placement="right" [ngStyle]="{'background-color': (group.notesMarshallCouleur ? group.notesMarshallCouleur : '#5bc0de')}"><d1-display-info class="p-1 mr-1" [text]="group.notesMarshall" [hideIfEmpty]="true"></d1-display-info></mat-chip><mat-chip class="p-0 mt-2 pl-1 pr-1" *ngIf="group.braceletCouleur" ngbTooltip="Couleur du bracelet" container="body" placement="right" [ngStyle]="{'background-color': group.braceletCouleur}">BRACELET </mat-chip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></ng-container
>
