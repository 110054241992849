/* tslint:disable: ordered-imports*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as roomsComponents from './components';

/* Containers */
import * as roomsContainers from './containers';

/* Guards */
import * as roomsGuards from './guards';

/* Services */
import * as roomsServices from './services';

// Material
import {DragDropModule} from '@angular/cdk/drag-drop';

/* Full Caldendar */
import { FullCalendarModule } from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
// import timePlugin from '@fullcalendar/timegrid'; // a plugin
// import interactionPlugin from '@fullcalendar/interaction'; // a plugin
// import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
// import adaptivePlugin from '@fullcalendar/adaptive'

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//     dayGridPlugin,
//     timePlugin,
//     interactionPlugin,
//     resourceTimeGridPlugin,
//     resourceTimelinePlugin,
//     // adaptivePlugin 
//   ]);


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppCommonModule,
        DragDropModule,
        FullCalendarModule,
        NavigationModule,
    ],
    providers: [...roomsServices.services, ...roomsGuards.guards],
    declarations: [...roomsContainers.containers, ...roomsComponents.components],
    exports: [...roomsContainers.containers, ...roomsComponents.components],
})
export class RoomsModule {}
