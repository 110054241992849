import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { AppMessageService } from './messages.service';

const apiUrl = '/api/settings';
const apiUrl2 = '/api/reservations';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    /**
    this.TypeGroupes = [];
    this.TypeSalles = [];
    this.Attributs = [];
    this.configurations = {};
    this.Horaires = [];
    this.Arenas = [];
    this.links = [];
     */
    private _refreshSettings!: Observable<any>;
    private _TypeGroupes = [];
    public TypeGroupes: ReplaySubject<[]> = new ReplaySubject();
    private _TypeSalles = [];
    public TypeSalles: ReplaySubject<[]> = new ReplaySubject();
    private _Attributs = [];
    public Attributs: ReplaySubject<[]> = new ReplaySubject();
    private _configurations = [];
    public configurations: ReplaySubject<any> = new ReplaySubject();
    private _Horaires = [];
    public Horaires: ReplaySubject<[]> = new ReplaySubject();
    private _Arenas = [];
    public Arenas: ReplaySubject<[]> = new ReplaySubject();
    private _links = [];
    public links: ReplaySubject<[]> = new ReplaySubject();
    private _api = [];
    public api: ReplaySubject<[]> = new ReplaySubject();
    private _whitelist = [];
    public whitelist: ReplaySubject<[]> = new ReplaySubject();
    private _features = [];
    public features: ReplaySubject<[]> = new ReplaySubject();

    private _init = false;  // Il peut arriver qu'on soit délogué ce qui cause des listes vides et plus jamais de "refresh" se font à cause des ReplaySubject

    constructor (private appMsg:AppMessageService, private http:HttpClient) {
        this._refreshSettings = this.appMsg.SettingsMessage.asObservable()
        this._refreshSettings.subscribe( msg => {
            this.fetchAll();
        })

        this.appMsg.VestesMessage.subscribe( msg => {
            this.getArenas();
        })

        this.fetchAll();
    }

    fetchAll() {
        this.getConfigurations();
        this.getTypeSalles();
        this.getTypeGroupes();
        this.getArenas();
    }

    // Display a message on the screen
    // Aller chercher les configs
    getConfigurations() {
        //var me = this;
        return this.http.get(apiUrl).subscribe( (response: any) => {
            this._configurations = response;
            this.configurations.next(response);

            // Shortcuts
            if (response.horaires) {
                this._Horaires = response.horaires.horaires;
                this.Horaires.next(this._Horaires as []);
            }

            // Shortucs
            if (response.api) {
                this._api = response.api;
                this.api.next(this._api as []);
            }

            // Shortucs
            if (response.links) {
                this._links = response.links;
                this.links.next(this._links as []);
            }

            // Shortucs
            if (response.whitelist) {
                this._whitelist = response.whitelist;
                this.whitelist.next(this._whitelist as []);
            }

            // Shortcuts
            if (response.features) {
                this._features = response.features;
                this.features.next(this._features as []);
            }
        });
    };

    /**
     * Aller chercher les types de salles possibles pour une réservation
     * @returns {*}
     */
    getTypeSalles() {
        this.http.get(`${apiUrl2}/typesalles`).subscribe( (response) => {
            this._TypeSalles = response as any;
            this.TypeSalles.next(this._TypeSalles as []);
        });
    };

    /**
     * Aller chercher les types de groupes possibles pour une réservation
     * @returns {*}
     */
     getTypeGroupes() {
        this.http.get(`${apiUrl2}/typegroupes`).subscribe( (response) => {
            this._TypeGroupes = response as any;
            this.TypeGroupes.next(this._TypeGroupes as []);
        });
    };

    /**
     * Aller chercher les arenes
     * @returns {*}
     */
     getArenas() {
        this.http.get(`${apiUrl}/arenas`).subscribe( (response) => {
            this._Arenas = response as any;
            this.Arenas.next(this._Arenas as []);
        });
    };

    /**
     * Aller chercher les gabarits de courriels
     * @returns {*}
     */
     async getGabarits(): Promise<any> {
        return await lastValueFrom(this.http.get(`/api/courriel/gabarits`));
    };
}
