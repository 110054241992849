<div class="btn-group mr-10">
    <button class="btn border rounded border-dark" type="button" (click)="goBack($event)" queryParamsHandling="preserve"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon></button>
</div>
<div class="btn-group mr-3">
    <button class="btn btn-outline-dark" type="button" (click)="envoiCourriel()">Envoyer un courriel</button>
    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button class="btn btn-outline-primary" ngbDropdownToggle>Imprimer</button>
        <div class="dropdown-menu" ngbDropdownMenu><a ngbDropdownItem [routerLink]="['/booking', 'print', 'unfolding', reservation.groupeId]" target="_blank">Déroulement</a><a ngbDropdownItem [routerLink]="['/booking', 'print', 'birthday', reservation.groupeId]" target="_blank">Bonne fête</a><a ngbDropdownItem [routerLink]="['/booking', 'print', 'receipt', reservation.groupeId]" target="_blank">Reçu</a></div>
    </div>
    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button class="btn btn-outline-primary" ngbDropdownToggle>Plus d'actions</button>
        <div class="dropdown-menu" ngbDropdownMenu><button ngbDropdownItem [routerLink]="['edit']" queryParamsHandling="preserve">Modifier</button><button class="text-danger" ngbDropdownItem>Supprimer</button><button class="text-danger" ngbDropdownItem>Annuler la réservation</button></div>
    </div>
</div>
<div class="btn-group mr-3" *ngIf="reservations.length &gt; 0">
    <div class="btn-group" ngbDropdown role="group" aria-label="Aller à une autre réservation">
        <button class="btn btn-outline-primary" ngbDropdownToggle>Aller à une autre réservation</button>
        <div class="dropdown-menu" ngbDropdownMenu><button ngbDropdownItem *ngFor="let r of reservations" [routerLink]="['../' + r.groupeId]" queryParamsHandling="preserve">{{ r.nomOrganisateur }}</button></div>
    </div>
</div>
