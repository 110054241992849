<mat-card
    ><mat-card-title> <span *ngIf="group.nomFete">Fête de {{ group.nomFete | titlecase }}</span><span *ngIf="!group.nomFete">{{ group.nomOrganisateur | titlecase }}</span><mat-icon class="text-success" *ngIf="group.statutGroupe === 2" matTooltip="Le groupe est arrivé">verified_user</mat-icon></mat-card-title
    ><mat-card-title>{{ group.partieHeures }}</mat-card-title><mat-card-subtitle>{{ group.groupeTypeNom | titlecase }}</mat-card-subtitle
    ><mat-card-content
        ><div class="font-weight-normal" *ngIf="group.nomFete">Responsable : {{ group.nomOrganisateur | titlecase }}</div>
        <div class="badge bg-info p-2" *ngIf="group.notesMarshall" [style.background]="(group.notesMarshallCouleur ? group.notesMarshallCouleur + ' !important' : 'auto')">{{group.notesMarshall}}</div>
        <div class="d-flex">
            <div class="game-card d-flex align-items-center p-0 mr-2" *ngFor="let game of schedules">
                <div class="row no-gutters align-items-center">
                    <div class="p-2 card-players text-center" [class]="getClasses(game)"><div>{{ game.nombreJoueurs }}</div></div>
                    <div class="p-2 text-center"><div class="font-weight-bolder">{{game.partieTemps | date: 'HH:mm'}}</div></div>
                    <div class="p-2 text-center"><div class="font-weight-bolder">{{game.ceduleInformation}}</div></div>
                </div>
            </div>
        </div></mat-card-content
    ><mat-card-actions
        ><mat-button-toggle-group class="align-items-center" *ngIf="voirPresence" [(ngModel)]="group.presence" (change)="changePresence($event)"
            ><mat-button-toggle mat-stroked-button [value]="0" color="default"><mat-icon matTooltip="Présence inconnue">psychology_alt</mat-icon></mat-button-toggle
            ><mat-button-toggle mat-stroked-button [value]="1" color="default"><mat-icon class="text-success" matTooltip="Le groupe est présent">pan_tool</mat-icon></mat-button-toggle
            ><mat-button-toggle mat-stroked-button [value]="2" color="accent"><mat-icon class="text-danger" matTooltip="Le groupe est absent">do_not_touch </mat-icon></mat-button-toggle></mat-button-toggle-group
        ></mat-card-actions
    ></mat-card
>
