// Divers
import * as _ from 'underscore';
import * as moment from 'moment';

angular.module('resources.reservationListe', ['resources.reservation']);
angular.module('resources.reservationListe').factory('ReservationListe', ['$http', 'Reservation', function($http, Reservation) {

    var Reservations = {};

    Reservations.dateReservation = moment().toDate();
    Reservations.voirTout = false;
    Reservations.afficherNonConfirmees = false;

    Reservations.getListe = function(dateReservation, afficherNonConfirmees = false) {
        var q = '';

        if (dateReservation) {
            Reservations.dateReservation = dateReservation;
        }
        q = '?date=' + moment(Reservations.dateReservation).format('YYYY-MM-DD');
        Reservations.afficherNonConfirmees = afficherNonConfirmees;
        if (afficherNonConfirmees) {
            q += '&afficherNonConfirmees=' + afficherNonConfirmees;
        }

        return $http.get('/api/reservations/list' + q).then(function(response) {
            Reservations.listeReservation = [];
            response.data.forEach(function(item) {
                var t = Reservation.parseFromDB(item);
                Reservations.listeReservation.push(t);
            });
            return Reservations.listeReservation;
        });
    };

    Reservations.getListeDepots = function() {
        return $http.get('/api/reservations/depots').then(function(response) {
            Reservations.listeReservation = [];
            response.data.forEach(function(item) {
                var t = Reservation.parseFromDB(item);
                Reservations.listeReservation.push(t);
            });
            return Reservations.listeReservation;
        }, (error) => {
            console.error(error);
            let q = $q.defer();
            q.reject(error);
            return q.promise;
        });
    };

    Reservations.getListeImpayee = function() {
        return $http.get('/api/reservations/impayee').then(function(response) {
            Reservations.listeReservation = [];
            response.data.forEach(function(item) {
                var t = Reservation.parseFromDB(item);
                Reservations.listeReservation.push(t);
            });
            return Reservations.listeReservation;
        });
    };

    Reservations.getListeNouvelles = function() {
        return $http.get('/api/reservations/nouvelles').then(function(response) {
            Reservations.listeReservation = [];
            response.data.forEach(function(item) {
                var t = Reservation.parseFromDB(item);
                Reservations.listeReservation.push(t);
            });
            return Reservations.listeReservation;
        });
    };

    Reservations.getGroupe = function(groupeId) {
        return $http.get('/api/reservations/' + parseInt(groupeId)).then(function(response) {
            var r = {};

            if (response.data) {
                if (Array.isArray(response.data)) {
                    response.data.forEach(function(g, i) {
                        if (g.groupeId == groupeId) {
                            r = Reservation.parseFromDB(g);
                            //r = g;
                        }
                    });
                } else {
                    if (response.data.groupeId) {
                        r = Reservation.parseFromDB(response.data);
                    }
                }
            }
            return r;
        });
    };

    return Reservations;
}]);

/**
 * Created by benoit on 04/10/2016.
 */