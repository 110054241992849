// Divers
import * as _ from 'underscore';
import * as moment from 'moment';
import { io } from 'socket.io-client';

angular.module('resources.vr', [])
    .factory('D1ServiceVR', [ '$http', 'Notification', function ($http, Notification) {
	    /**
         * Factory pour gérer les stations de Réalité virtuelle
         *
         */
        var StationsVR = {
            intervalId : 0,
            init : function () {
                //this.nombreStationsVR = 0;
                StationsVR.listeStationsVR = [];
                StationsVR.getListe();
            },
            listeStationsVR : [],
            synthResponse : {},
            nombreStationsVR : function() {
                return StationsVR.listeStationsVR.length;
            },
            socket : io()
        };

        StationsVR.socket.on('RafraichirVR', function (data) {
            console.log("RafraichirVR", data);
            $rootScope.$broadcast('RafraichirVR', data);
            StationsVR.getListe().then(function(data) {
                var msg = "Attention! Le nombre de stations vr de disponibles vient d'être modifié à " + StationsVR.nombreStationsVR + "!";
                Notification.warning({
                    message: msg
                    , title : "Nombre de stations"
                    , positionX : 'center'
                    , positionY : 'top'
                    , onClose: function () {
                        //console.log(msg);
                        // msg.nePlusAfficher = true;
                    }
                });
            });
        });

        StationsVR.rafraichir = function () {
            StationsVR.getListe().then(function(data) {
                //this.nombreStationsVR = data.length;
                console.log("StationsVR.RafraichirVR() Data", data)
            });
        };

        StationsVR.getListe = function() {
            return StationsVR.listeStationsVR;
        };

	    /**
         * Get the number of available stations
         *
         * @param params
         * @returns {*|Promise.<T>}
         */
        StationsVR.getAvailability_OLD = function(params) {

            var synthOptions = {
                people : params.people,
                date : moment(params.date).format("YYYY-MM-DD"),
                duration : params.duration,
                experience : params.experience
            };

            synthResponse = {};
            return $http.get("https://api.synthesisvr.com/web/a2605f01-463d-5fe7-a5ce-cd63bd4e614f/availability", {params : synthOptions})
                .then(function(response) {
                    // All good!
                    console.log("RESPONSE", response);
                    synthResponse = response.data;
                    StationsVR.socket.emit('RafraichirVR', synthResponse);
                    return synthResponse;
                    
                }, function (err){
                    // error
                    console.log("ERROR", err);
                    synthResponse = err;
                    return synthResponse;
                });

        };
/**
         * Get the number of available stations
         *
         * @param params
         * @returns {*|Promise.<T>}
         */
        StationsVR.getAvailability = function(listDate, experience, duration, people) {

            var synthOptions = {
                people : (people ? people : 1),
                date : moment(listDate).format("YYYY-MM-DD"),
                duration : duration,
                experience : experience
            };

            synthResponse = {};
            return $http.get("/api/vr/availability/" + moment(listDate).format("YYYY-MM-DD"), {params : synthOptions })
                .then(function(response) {
                    // All good!
                    console.log(" StationsVR.getAvailability", response);
                    synthResponse = response.data;
                    StationsVR.socket.emit('RafraichirVR', synthResponse);
                    return synthResponse;

                }, function (err){
                    // error
                    console.log("ERROR", err);
                    synthResponse = err;
                    return synthResponse;
                });

        };

        /**
         * Get the possible time durations
         *
         * @param params
         * @returns {*|Promise.<T>}
         */
        StationsVR.getDurations = function(listDate, experience, duration, people) {

            var synthOptions = {
                people : (people ? people : 1),
                date : moment(listDate).format("YYYY-MM-DD"),
                duration : duration,
                experience : experience
            };

            synthResponse = {};
            return $http.get("/api/vr/availability/" + moment(listDate).format("YYYY-MM-DD"), {params : synthOptions })
                .then(function(response) {
                    // All good!
                    console.log(" StationsVR.getDurations", response);
                    synthResponse = response.data;
                    StationsVR.socket.emit('RafraichirVR', synthResponse);
                    return synthResponse;

                }, function (err){
                    // error
                    console.log("ERROR", err);
                    synthResponse = err;
                    return synthResponse;
                });

        };

        /**
         * Get the list of Experiece Types
         *
         * @param params
         * @returns {*|Promise.<T>}
         */
        StationsVR.getExperiences = function(listDate) {

            synthResponse = {};
            return $http.get("/api/vr/experiences/" + moment(listDate).format("YYYY-MM-DD"))
                .then(function(response) {
                    // All good!
                    synthResponse = response.data;
                    StationsVR.socket.emit('RafraichirVR', synthResponse);
                    return synthResponse;

                }, function (err){
                    // error
                    console.log("ERROR", err);
                    synthResponse = err;
                    return synthResponse;
                });

        };

        StationsVR.enregistrer = function(params) {

            // date=2018-04-23&time=09:04&people=1&experience=1&duration=16
            var synthParams = {
                people : params.people,
                date : moment(params.date).format("YYYY-MM-DD"),
                time : params.time,
                duration : params.duration,
                experience : params.experience
            };

            synthResponse = {};
            var options = {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj)
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    return str.join("&");
                }
            };

            return $http.post("https://api.synthesisvr.com/web/a2605f01-463d-5fe7-a5ce-cd63bd4e614f/book", synthParams, options)
                .then(function(response) {
                    // All good!
                    //console.log("RESPONSE", response);
                    synthResponse = response.data;
                    StationsVR.socket.emit('RafraichirVR', synthResponse);
                    return synthResponse;

                }, function (err){
                    // error
                    console.log("ERROR", err);
                    synthResponse = err;
                    return synthResponse;
                });
        };


        /**
         * Get the game catalog
         *
         * @param params
         * @returns {*|Promise.<T>}
         */
        StationsVR.getGames = function() {
            return $http.get('/api/vr/games').then(function (response) {
                return response.data;
            })
            .catch(function (err) {
                throw err;
            });
        };


        return StationsVR;}
    ]);
