import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RoomsEditComponent } from '../containers';

@Injectable()
export class RoomsGuard implements CanActivate {
    canActivate(): Observable<boolean> {
        return of(true);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateRoomsDetails implements CanDeactivate<RoomsEditComponent>
{
    canDeactivate(
        component: RoomsEditComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        // Get the next route
        let nextRoute: ActivatedRouteSnapshot = nextState.root;
        while ( nextRoute.firstChild )
        {
            nextRoute = nextRoute.firstChild;
        }

        // If the next state doesn't contain '/dossiers'
        // it means we are navigating away from the
        // dossiers app
        if ( !nextState.url.includes('/rooms') )
        {
            // Let it navigate
            return true;
        }

        // If we are navigating to another dossier...
        if ( nextRoute.paramMap.get('id') )
        {
            // Just navigate
            return true;
        }
        // Otherwise...
        else
        {
            // Close the drawer first, and then navigate
            return component.closeDrawer().then(() => {
                return true
            });
        }
    }
}
