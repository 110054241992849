// Divers
import * as _ from 'underscore';

angular.module('resources.usagers', []);
var D1user = angular.module('resources.usagers')
.factory('Usagers', [ '$http', function ($http) {

    var Usagers = {
        getListe : function() {
            return $http.get('/api/usagers/list/').then( (response) => {
                this.usrListe = response.data;
                return this.usrListe;
            });
        },
        getProfile : function() {
            return $http.get('/users/authenticated-user').then( (response) => {
                Usagers.user = response.data.user;
                if (!Usagers.user.uniqueToken) {
                    Usagers.user.uniqueToken = Usagers.create_UUID();
                }
                this.user = Usagers.user;
                this.usager = Usagers.user;
                return Usagers.user;
            });
        },
        usrListe : [],
        usager : {}, // Va recevoir la sélection de l'usager courant
        user : {}, // Va recevoir la sélection de l'usager courant
        su : false, // Superuser mode
        token : '', // User JWT token
        voirModule : function (module) {
            if (this.user && this.user.disabled_modules) {
                return (this.user.disabled_modules[module] == undefined);
            }
            return this.user ? true : false;
        },
        voirSettings : function () {
            if ( (this.usager.realm == 'admin' && this.usager.initiales == 'SU') || this.user.realm == 'admin') {
                return true;
            }
            return (this.usager.initiales == 'SU' && this.su == 1) || (false);
        },
        create_UUID : function () {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (dt + Math.random()*16)%16 | 0;
                dt = Math.floor(dt/16);
                return (c=='x' ? r :(r&0x3|0x8)).toString(16);
            });
            return uuid;
        }
    };

    return Usagers;
}]);

D1user.controller('D1UserCtrl', ['$scope', '$http', '$sce', '$timeout', 'Usagers', '$rootScope', '$interval', '$translate', function($scope, $http, $sce, $timeout, Usagers, $rootScope, $interval, $translate) {
    $scope.Usagers = Usagers;
    $scope.userListe = [];
    $scope.su = 0;
    var tid = 0; // timer id
    var suid = 0; // timer suid

    Usagers.getProfile();

     Usagers.getListe().then(function(data) {
         $scope.userListe = data;
     });

    $scope.socket = io();

    $scope.devenir_su = function () {
        Usagers.su = false;
        $scope.su++;

        if (!tid) {
            tid = $timeout(function() {
                $scope.su = 0;
            }, 2000);
        }

        if ($scope.su == 3) {
            Usagers.su = true;
            $timeout.cancel(tid);
            tid = 0;

            // Auto-logout (15 minutes)
            suid = $timeout(function() {
                Usagers.su = false;
                $scope.$apply();
            }, ( (15 * 60) * 1000) );
        }
    };

    // Message de rafraîchissement
    $scope.socket.on('RafraichirReservations', function (data) {
        $rootScope.$broadcast('RafraichirReservations', data );
    });


}]);
