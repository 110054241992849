import { BookingResolve } from './booking.resolve';
import { RentalsService } from './rentals.service';
import { ResourcesService } from './resources.service';
import { ResourceResolve, ResourcesResolve } from './resource.resolve';

export const services = [RentalsService, BookingResolve, ResourcesService, ResourceResolve, ResourcesResolve];

export * from './rentals.service';
export * from './booking.resolve';
export * from './resources.service';
export * from './resource.resolve';
