<div class="d-inline-flex" id="passes" [class]="wrapperClass">
    <div [class]="passClass">
        <img class="img-fluid" [src]="nextPasses.passeNext" [placement]="placement" [ngbPopover]="popGroupes1" container="body" [popoverTitle]="popTitleGroupes" triggers="mouseenter:mouseleave" />
        <p class="text-center font-weight-bolder mb-0">{{ nextPasses.nextGameTime }}</p>
    </div>
    <div [class]="passClass">
        <img class="img-fluid" [src]="nextPasses.passeNext2" [placement]="placement" [ngbPopover]="popGroupes2" container="body" [popoverTitle]="popTitleGroupes" triggers="mouseenter:mouseleave" />
        <p class="text-center font-weight-bolder mb-0">{{ nextPasses.nextGameTime2 }}</p>
    </div>
    <div [class]="passClass">
        <img class="img-fluid" [src]="nextPasses.passeNext3" [placement]="placement" [ngbPopover]="popGroupes3" container="body" [popoverTitle]="popTitleGroupes" triggers="mouseenter:mouseleave" />
        <p class="text-center font-weight-bolder mb-0">{{ nextPasses.nextGameTime3 }}</p>
    </div>
</div>
<ng-template #popGroupes1
    ><div *ngFor="let g of nextPasses.groupes">{{ g.nomOrganisateur }} - Joueurs: {{g.nombreJoueurs }}</div>
    <div *ngIf="nextPasses.groupes.length === 0">Aucun groupe</div></ng-template
><ng-template #popGroupes2
    ><div *ngFor="let g of nextPasses.groupes2">{{ g.nomOrganisateur }} - Joueurs: {{g.nombreJoueurs }}</div>
    <div *ngIf="nextPasses.groupes2.length === 0">Aucun groupe</div></ng-template
><ng-template #popGroupes3
    ><div *ngFor="let g of nextPasses.groupes3">{{ g.nomOrganisateur }} - Joueurs: {{g.nombreJoueurs }}</div>
    <div *ngIf="nextPasses.groupes3.length === 0">Aucun groupe</div></ng-template
><ng-template #popTitleGroupes> <div class="h4">Liste des groupes:</div></ng-template>
