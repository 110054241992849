<ng-container *ngIf="!rtl"
    ><span [ngClass]='{"nav-fixed": !static}'
        ><sb-top-nav></sb-top-nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav"><sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems" [sideNavSections]="sideNavSections"></sb-side-nav></div>
            <div id="layoutSidenav_content" [ngClass]='{"scrollable": !static}' (click)="closeSideNavIfOpen()">
                <main><ng-container *ngTemplateOutlet="defaultContent"></ng-container></main>
                <sb-footer [dark]="true"></sb-footer>
            </div></div></span></ng-container
><ng-container *ngIf="rtl"
    ><span class="layout-rtl" [ngClass]='{"nav-fixed": !static}'
        ><sb-top-nav [rtl]="true"></sb-top-nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_content" [ngClass]='{"scrollable": !static}' (click)="closeSideNavIfOpen()">
                <main><ng-container *ngTemplateOutlet="defaultContent"></ng-container></main>
                <sb-footer></sb-footer>
            </div>
            <div id="layoutSidenav_nav"><sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems" [sideNavSections]="sideNavSections"></sb-side-nav></div></div></span></ng-container
><ng-template #defaultContent><ng-content></ng-content></ng-template>
