import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';

import { User } from '../models';

const userSubject: ReplaySubject<User[]> = new ReplaySubject(100);
const apiUrl = '/api/usagers/list'; // URL to web api

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private http:HttpClient) { }

    users$(): Observable<User[]> {
        return this.http.get<User[]>(apiUrl)
            .pipe(
                tap( users => {
                    userSubject.next(users);
                } )
            );
    }
}
