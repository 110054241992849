import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AuthService, UserService } from '@modules/auth/services';
import { MessageBoxService } from 'ts-ng-lib';

@Component({
    selector: 'sbpro-top-nav-user',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent implements OnInit {
    @Input() placement = 'bottom-right';
    dropdownClasses: string[] = [];
    constructor(public userService: UserService, private mbox$:MessageBoxService, private auth$: AuthService) {}
    ngOnInit() {}

    confirmLogout() {
        this.mbox$.Confirm('Déconnexion', 'Êtes-vous certain de vouloir quitter l\'application?').subscribe( (r:any) => {
            if (r === 'Oui') {
                this.auth$.logout();
            }
        })
    }
}
