import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ScheduleService } from '@modules/schedule/services';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'd1-schedule-next-passes',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-next-passes.component.html',
    styleUrls: ['schedule-next-passes.component.scss'],
})

export class ScheduleNextPassesComponent implements OnInit, OnDestroy {
    @Input('class') wrapperClass: any = ""
    @Input('passClass') passClass: any = ""
    @Input('placement') placement: string = "auto"
    
    private passeTime = timer(0, 60000);
    private _sub! : Subscription;
    private _sub2! : Subscription;
    public nextPasses: any = {
        groupes:[],
        groupes2:[],
        groupes3:[],
    };

    constructor(public s$: ScheduleService, private ref: ChangeDetectorRef) {
        // Créer un timer à toutes les minutes pour aller chercher les informations sur les prochaines passes
        this._sub2 = this.passeTime.subscribe(x => {
            if (this._sub) this._sub.unsubscribe();
            this._sub = this.s$.getPassesStatus().subscribe(r => {
                this.nextPasses = {...r}
                this.ref.markForCheck()
            })
            
        });        
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        if (this._sub) this._sub.unsubscribe();
        if (this._sub2) this._sub2.unsubscribe();
    }    
}
