import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'd1-reservations-list-games',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-list-games.component.html',
    styleUrls: ['reservations-list-games.component.scss'],
})
export class ReservationsListGamesComponent implements OnInit {
    @Input() label = 'Parties';
    @Input() icon = ["fas", "gamepad"];
    @Input() games: GameModel[] = [];
    @Input() includePlayerNumber:boolean = false;
    @Input() allowEdit:boolean = false;
    @Input() allowDelete:boolean = false;

    constructor(private ref: ChangeDetectorRef, private s$:ScheduleService) {
        if (this.games === undefined) {
            this.games = [];
        }
    }

    // OnChange(event: any) {
    //     this.games = this.games.slice() as [];
    // }
    getPasseCouleur(g:GameModel) {
        return this.s$.getPasseCouleur(moment(g.partieTemps).format('HH:mm'))
    }
    getClasses(g:GameModel) {
        return [`badge-${this.getPasseCouleur(g)}`]
    }

    getGameTimes() {
        // return _.map(_.orderBy(this.games, 'partieHeures'), (g) => g.partieHeures);
    }

    deleteGame(g:GameModel) {
        console.log('should delete game', g)
    }

    increasePlayers(g:GameModel) {
        g.nombreJoueurs = Math.max(0, g.nombreJoueurs+1)
        console.log('++', g);
    }

    decreasePlayers(g:GameModel) {
        g.nombreJoueurs = Math.max(0, g.nombreJoueurs-1)
        console.log('--', g);
    }

    ngOnInit() {
        
    }

    onGameChange(event: any) {
        console.log('onGameChange', event)
    }
}
