import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Component({
    selector: 'd1-schedule-list-navigator',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-list-navigator.component.html',
    styleUrls: ['schedule-list-navigator.component.scss'],
})
export class ScheduleListNavigatorComponent implements OnInit {
    dateReservation:NgbDate;
    dateSelected = new Date();
    @Input('date') date: Date = new Date;
    @Output() dateChange = new EventEmitter<Date>();
    @Output() change = new EventEmitter<Date>();
        
    constructor(private calendar: NgbCalendar, private router: Router) {
        //Init
        this.dateReservation = this.calendar.getToday();
    }

    ngOnInit() {}
    
    rafraichir() {};

    
    changeDate() {
        const testDate:NgbDate | null = NgbDate.from(this.dateReservation);
        if (this.calendar.isValid(testDate) && this.dateReservation.year > 2000 ) {
          this.dateSelected = new Date(this.dateReservation.year, this.dateReservation.month-1, this.dateReservation.day );
          const sd = moment(this.dateSelected).format('YYYY-MM-DD');
          this.dateChange.emit(this.dateSelected)
          this.change.emit(this.dateSelected)          
        }
      }
    
      aujourdhui() {
        this.dateReservation = this.calendar.getToday();
        this.dateSelected = new Date(this.dateReservation.year, this.dateReservation.month-1, this.dateReservation.day );
        this.dateChange.emit(this.dateSelected)
        this.change.emit(this.dateSelected)
      }
    
      prevDay() {
        this.dateReservation = this.calendar.getPrev(this.dateReservation, 'd', 1);
        this.dateSelected = new Date(this.dateReservation.year, this.dateReservation.month-1, this.dateReservation.day );
        this.dateChange.emit(this.dateSelected)
        this.change.emit(this.dateSelected)
      }
    
      nextDay() {
        this.dateReservation = this.calendar.getNext(this.dateReservation, 'd', 1);
        this.dateSelected = new Date(this.dateReservation.year, this.dateReservation.month-1, this.dateReservation.day );
        this.dateChange.emit(this.dateSelected)
        this.change.emit(this.dateSelected)
      }
}
