import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupeService } from '@modules/groupe/services';
import { Rental, RentalResource, ResourceModel } from '@modules/rentals/models';
import { RentalsService, ResourcesService } from '@modules/rentals/services';
import _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
    selector: 'd1-rentals-booking-detail-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-booking-detail-view.component.html',
    styleUrls: ['rentals-booking-detail-view.component.scss'],
})
export class RentalsBookingDetailViewComponent implements OnInit, AfterViewInit {
    rental: Rental = {
        booking_id : 0,
        groupeId : 0,
        slot_id : 0,
        status : 0,
        shipping_type : 0,
        nocivique : '',
        rue : '',
        notes : '',
        notesAdmin : '',
        ville : '',
        province : '',
        pays : '',
        codepostal : '',
        booking_tags : '',
        geo_lat: 0,
        geo_long: 0,
        duration_sec: 0
    };
    public listResources = undefined;

    displayedColumns: string[] = ['id', 'ressourceId', 'commentaire', 'inspecte', 'commentaire_retour', 'need_repair'];

    center: google.maps.LatLngLiteral = {lat: 45.27881060449056, lng: -73.10810172307781};
    zoom = 16;
    display = {lat:0, lng: 0};
    status = [
        'Non préparé',
        'En préparation',
        'Prêt',
        'En livraison',
        'Livré',
        'En retour',
        'Retourné'
    ]
    statusGroupes = [
        'Non confirmé',
        'En attente',
        'En attente et équipements bloqués',
        'Confirmé'
    ]

    typeLivraison = [
        'Lvraison',
        'Ramassage en succursalle'
    ]

    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng) {
            this.center = (event.latLng.toJSON());
        }
    }

    move(event: google.maps.MapMouseEvent) {
        if (event.latLng) {
            this.display = event.latLng.toJSON();
        }
    }

    constructor(
        private rentals$: RentalsService,
        private r$: ResourcesService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private geocoder: MapGeocoder,
        private g$:GroupeService
    ) {
        if (this.nav.snapshot.data.booking) {
            this.rental = this.nav.snapshot.data.booking;
        } else {
            var id = parseInt(this.nav.snapshot.params.id)
            if (id.toString() === 'NaN') {
                this.rental = {
                    booking_id : 0,
                    slot_id : 0,
                    groupeId : 0,
                    status : 1
                } as Rental

            } else {
                this.rentals$.getBooking$(id).subscribe((r) => {
                    this.rental = r as Rental;

                    // Aller placer la map
                    this.center = {lat: this.rental.geo_lat, lng:this.rental.geo_long}
                    this.ref.markForCheck();
                });
            }
        }

        if (this.nav.snapshot.data.resourceList) {
            // this.listResources = this.nav.snapshot.data.resourceList;
            this.listResources = _.map(this.nav.snapshot.data.resourceList, r => {return { value: r.ressourceId, text: `${r.ressourceName} (SKU: ${r.ressourceSKU})`}}) as any;
        } else {
            this.r$.getResources$().pipe(
                map( (list:ResourceModel[]) => {
                    return _.map(list, r => {return { value: r.ressourceId, text: `${r.ressourceName} (SKU: ${r.ressourceSKU})`}}) as any;
                    
                })
            ).subscribe( l => {
                console.log('ressources', l);
                this.listResources = l;
                this.ref.markForCheck();
            })
        }
    }

    ngAfterViewInit(): void {
        let adresse = '';
        if (this.rental.ville) {
            adresse = `${this.rental.nocivique} ${this.rental.rue}, ${this.rental.ville}, ${this.rental.province}, ${this.rental.codepostal}`
        }
        if (adresse) {
            this.geocoder.geocode({
                address: adresse
            }).subscribe(({results}) => {
                console.log("GeoCoder", results);
                if (results) {
                    this.center = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                    this.ref.markForCheck();
                }
            });
            if (this.rental.geo_lat) {
                this.center = {lat: this.rental.geo_lat, lng:this.rental.geo_long}
            }
        }
    }
    ngOnInit() {}

    changeTags(event: any) {
        this.rental.booking_tags = event;
        this.updateRental();
    }
    changeStatut(event: any) {
        this.rental.statutConfirmation = event;
        this.updateRental();
    }
    changeStatutLocation(event: any) {
        this.rental.status = event;
        this.updateRental();
    }
    changeTypeLivraison(event: any) {
        this.rental.shipping_type = event;
        this.updateRental();
    }

    /**
     * Section pour les ressources
     */
    changeComment(event: any, row: RentalResource) {
        row.commentaire = event;
        this.updateResource(row);
    }
    changeInspect(event: any, row: RentalResource) {
        row.inspecte = event;
        this.updateResource(row);
    }
    changeCommentReturn(event: any, row: RentalResource) {
        row.commentaire_retour = event;
        this.updateResource(row);
    }
    changeNeedRepair(event: any, row: RentalResource) {
        row.need_repair = event;
        this.updateResource(row);
    }
    changeResourceId(event: any, row: RentalResource) {
        row.ressourceId = event;
        this.updateResource(row);
    }

    updateRental() {
        this.rentals$.updateBooking$(this.rental).subscribe( r => {
            console.log('Save is good', r);
        }, (error) => {
            console.error('Impossible de sauvegarder la location', error);
        })
    }

    updateResource(rental_R:RentalResource) {
        this.rentals$.updateBookingResource$(rental_R).subscribe( r => {
            console.log('Save is good', r);
        }, (error) => {
            console.error('Impossible de sauvegarder la location', error);
        })
    }
}
