import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogEmailComponent } from '@common/components';
import { UtilityService } from '@common/services';
import { AppNotificationService } from '@common/services/appnotification.service';
import { ReservationModel } from '@modules/reservations/models';

@Component({
    selector: 'd1-reservations-action-bar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-action-bar.component.html',
    styleUrls: ['reservations-action-bar.component.scss'],
})
export class ReservationsActionBarComponent implements OnInit {
    @Input() reservations: ReservationModel[] = [];
    @Input() reservation: ReservationModel = new ReservationModel();
    @Output() backClick:EventEmitter<any> = new EventEmitter();

    public links = ['First', 'Second', 'Third'];
    public activeLink = this.links[0];
    public background: ThemePalette = undefined;

    constructor(private dialog$: MatDialog, private utility$:UtilityService, private n$:AppNotificationService) { }
    
    ngOnInit() {
        // this.background = this.background ? undefined : 'light';
        if (!this.reservations) {
            this.reservations = [];
        }
    }

    goBack(event:any) {
        event.stopPropagation();
        this.backClick.emit(event);
    }

    envoiCourriel() {
        const modalRef = this.dialog$.open(DialogEmailComponent, {
            data: { reservation: this.reservation },
            minWidth : '60vw'
        });
        modalRef.afterClosed().subscribe( r => {
            if (r) {
                this.utility$.sendEmail(r).subscribe( () => {
                    this.n$.showMessage('Communication envoyée avec succès');
                }, (error) => {
                    console.error(error);
                    this.n$.showError('Erreur lors de l\'envoi de la communication');
                })
            }
        })
    }
}
