<mat-card class="mb-2"
    ><div class="d-flex">
        <d1-date-picker class="mr-2" *ngIf="showDate" [(date)]="dateSelected" (dateChange)="onDateChange($event)"></d1-date-picker>
        <div class="text-align-middle">
            <mat-label> <h5>Durée de location :</h5></mat-label><mat-chip-list aria-label="Durée"><mat-chip (click)="setBookingMinutes(60)" [selected]="bookingMinutes === 60" [value]="60">1 heure</mat-chip><mat-chip (click)="setBookingMinutes(90)" [selected]="bookingMinutes === 90" [value]="90">1 heure 30 minutes</mat-chip><mat-chip (click)="setBookingMinutes(120)" [selected]="bookingMinutes === 120" [value]="120">2 heures</mat-chip></mat-chip-list>
        </div>
    </div>
    <mat-slide-toggle [(checked)]="montrerSallesInterne" (change)="toggleSalles($event)">Afficher les salles internes</mat-slide-toggle
    ><ng-container *ngFor="let room of activeRooms"
        ><h4>{{ room.salleNom }}</h4>
        <div class="d-flex flex-row flex-wrap">
            <button class="m-1 text-nowrap" mat-stroked-button [color]="!t.available ? 'warn' : undefined" [class.selected]="t.selected" [class.unavailable]="!t.available" [disabled]="!t.available" (click)="onSelected(t)" *ngFor="let t of room.timeslots">
                <div class="time-wrapper" container="body" [ngbPopover]="roomUsageTpl" [disablePopover]="(t.useBy || []).length === 0" placement="top-left" popoverTitle="Groupes" triggers="mouseenter:mouseleave" (mouseenter)="showInfo(t)">{{ t.moment | date: 'HH:mm'}} <mat-icon class="align-middle">arrow_right_alt</mat-icon>{{ getEndTime(t.moment) | date: 'HH:mm' }}</div>
            </button>
        </div></ng-container
    ></mat-card
><ng-template #roomUsageTpl
    ><span *ngIf="(currentTimeSlot.useBy || []).length === 0">Aucun</span><ng-container *ngIf="(currentTimeSlot.useBy || []).length &gt; 0"><div *ngFor="let g of currentTimeSlot.useBy">{{ g.nomOrganisateur }} #{{ g.groupeId }} De {{ g.salleDebut | date: 'HH:mm'}} à {{ g.salleFin | date: 'HH:mm'}}</div></ng-container></ng-template
>
