<div class="game-card d-flex align-items-center p-0" ngbTooltip="{{ game.nombreJoueurs }} joueurs à {{game.partieTemps | date: 'HH:mm'}}">
    <div class="row no-gutters align-items-center">
        <div class="p-2 card-players text-center" [class]="getClasses(game)">
            <div *ngIf="!allowEdit">{{ game.nombreJoueurs }}</div>
            <input class="player-input" *ngIf="allowEdit" type="number" size="3" [(ngModel)]="game.nombreJoueurs" (ngModelChange)="onChange($event)" />
        </div>
        <div class="p-2 text-center"><div class="font-weight-bolder">{{game.partieTemps | date: 'HH:mm'}}</div></div>
        <ng-container *ngIf="allowDelete"
            ><div class="p-2"><mat-icon (click)="deleteGame(game)">delete</mat-icon></div></ng-container
        >
    </div>
</div>
