import { SideNavItems, SideNavSection } from '@modules/navigation/models';
// import { environment } from 'environments/environment';

export const sideNavSections: SideNavSection[] = [
    {
        text: 'CORE',
        // icon: 'activity',
        items: ['dashboards', 'reservations', 'schedules', 'booking', 'tickets'],
    },
    {
        text: 'Lasertag',
        items: ['ng1', 'rooms_schedules', 'membres', 'reports', 'utils', 'rentals', 'd1config'],
    },
    {
        text: 'Shopify',
        feature: 'shopify',
        items: ['shopify_main', 'shopify_admin', 'shopify_products', 'shopify_clients', 'shopify_orders'],
    },
];

export const sideNavItems: SideNavItems = {
    dashboards: {
        icon: 'activity',
        link: '/dashboard',
        text: 'Dashboard',
    },
    booking: {
        icon: 'book',
        link: '/booking',
        text: 'Cédule - Accueil',
    },
    settings: {
        icon: 'settings',
        link: '/settings',
        text: 'Settings',
        realm: "admin"
    },
    // rooms: {
    //     icon: 'bookmark',
    //     link: '/rooms',
    //     text: 'Salles',
    // },
    ng1: {
        icon: 'book-open',
        // link: '/ng1/reservations',
        text: 'Réservations',
        submenu: [
            {
                link: '/ng1/reservations',
                text: 'Réservations',
            },
            {
                link: '/ng1/reservations/ajouter',
                text: 'Ajouter une réservation',
            },
            {
                link: '/ng1/reservations/recherche',
                text: 'Recherche avancée',
            },
            {
                link: '/ng1/reservations/nouvelles',
                text: 'À confirmer',
            },
            {
                link: '/ng1/rapports/depots',
                text: 'Sans dépôts',
            },
            {
                link: '/ng1/cedules',
                text: 'Ancienne cédule',
            },
        ],
    },
    rooms_schedules: {
        icon: 'layout',
        link: '/rooms/schedules',
        text: 'Salles'
    },
    reservations: {
        icon: 'book-open',
        link: '/ng1/reservations',
        text: 'Réservations'
    },
    schedules: {
        icon: 'calendar',
        text: 'Cédule - Marshall',
        link: '/schedule',
    },
    reports: {
        icon: 'file-text',
        text: 'Rapports ',
        submenu: [
            {
                link: '/ng1/rapports/fetes',
                text: 'Commandes spéciales',
            },
            {
                link: '/ng1/rapports/animateurs',
                text: 'Gestion des fêtes',
            },
            {
                link: '/ng1/rapports/statistiques',
                text: 'Statistiques',
            },
            {
                link: '/ng1/rapports/marketing',
                text: 'Marketing',
            },
            {
                link: '/rapports/sms',
                text: 'SMS envoyés',
                feature: 'sms'
            },
        ],
    },
    utils: {
        icon: 'link',
        text: 'Utilitaires ',
        submenu: [
            {
                link: '/ng1/utils',
                text: 'Gestion des écrans',
                feature: 'remote'
            },
            {
                link: '/ng1/vestes',
                text: 'Gestion des vestes',
            },
            {
                link: '/ng1/documents',
                text: 'Zone de documentation',
                feature: 'documentation'
            },
        ],
    },
    d1config: {
        icon: 'sliders',
        text: 'Configurations',
        link: '/ng1/configurations',
        realm: "admin"
    },
    membres: {
        icon: 'credit-card',
        text: 'Membres',
        link: '/membres',
        feature: 'cyberblast'
    },
    rentals: {
        icon: 'package',
        text: 'Lasertag mobile',
        feature: 'rentals',
        submenu: [
            {
                text: 'Réservations',
                link: '/rentals/bookings',
            },
            {
                text: 'Tableau de bord',
                link: '/rentals',
            },
            {
                text: 'Horaires',
                link: '/rentals/schedules',
            },
            {
                text: 'Disponibilités',
                link: '/rentals/availabilities',
            },
            {
                text: 'Équipements',
                link: '/rentals/resources',
            }
        ]
    },
    shopify_main: {
        icon: 'activity',
        text: 'Dashboard',
        link: '/shopify',
        realm: "admin"
    },
    shopify_admin: {
        icon: 'sliders',
        text: 'Admin',
        link: '/shopify/admin',
        realm: "admin"
    },
    shopify_products: {
        icon: 'shopping-cart',
        text: 'Produits',
        link: '/shopify/products',
        realm: "admin"
    },
    shopify_clients: {
        icon: 'user',
        text: 'Clients',
        link: '/shopify/clients',
        realm: "admin"
    },
    shopify_orders: {
        icon: 'archive',
        text: 'Commandes',
        link: '/shopify/orders'
    },
    tickets: {
        icon: 'tool',
        text: 'Tickets',
        link: '/tickets'
    },
};
