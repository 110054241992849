angular.module('resources.login', ['ui-notification', 'ngRoute'])
    .controller('D1LoginCtrl', LoginController);


function LoginController($scope, $http, Notification, $window, $toastService) {

    var red = '/reservations';

    queries = $window.location.search.replace('?', '').split('&');
    _.each(queries, (q) => {
        s = q.split('=');
        if (s[0] == 'redirect_url') {
            red = s[1];
        }
    });

    $scope.DoLogin = function() {
        var data = {
            username: $scope.username,
            password: $scope.password
        }
        return $http.post('/users/login', data).then(function(response) {
                localStorage.setItem('userData', JSON.stringify(response.data));
                $window.location.href = red;
            })
            .catch((error) => {
                console.log("error", error);
                $toastService.showError("Erreur", "La connexion n'a pas réussie. Message : " + error.data.message)
            });

    }
}