import { Pipe, PipeTransform } from '@angular/core';
const Mustache = require('mustache');

@Pipe({
  name: 'parseContent',
  pure: false
})
export class ParseContentPipe implements PipeTransform {

  transform(template: string, content: any): string {
    // const findings = template.match(/\(([^)]*)\)[^(]*$/);

    const result = Mustache.render(template, content);
    console.log(result);

    // console.log(findings);
    return result;
  }

}