// Divers
import * as _ from 'underscore';

angular.module('resources.validation', []);

//var D1validation = angular.module('resources.validation').factory('Validation', [ '$http', '$interval', function ($http, $interval) {
angular.module('resources.validation').factory('Validations', [ '$http', '$interval', function ($http, $interval) {

    var Validations = {
        problemes : [],
        getProblemes : function() {
            function getProblemes() {
                // Aller chercher le nombre de réservations non-confirmees à chaque minute
                return $http.get('/api/validation/problemes').then(function (response) {
                    //console.log(response.data);
                    Validations.problemes = response.data;
                    return Validations.problemes;
                });
            }
            // On commence par aller chercher la valeur une fois, ensuite on part une intervale
            getProblemes().then( function(data) {
                return data;
            });
            $interval(getProblemes, 60000);

        }
    };

    return Validations;
}]);

angular.module('resources.validation').controller('D1ValidationCtrl', ['$scope', '$http', 'Validations', function($scope, $http, Validations) {
    $scope.Validations = Validations;

    //console.log(Validations);
    //console.log(Validations.getProblemes());
    //console.log(Validations.getProblemes());

}]);
