import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { RoomPickupInfo, RoomsDialogPickComponent } from '../containers';
import { SalleCeduleModel, SalleModel } from '../models';

const apiUrl = '/api/salles'; // URL to web api

@Injectable({
    providedIn: 'root'
})
export class RoomsService {

    constructor(private http: HttpClient, public dialog: MatDialog) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): {} | Observable<{}> | Promise<{}> {
        if (route.paramMap.get('id') !== null) {
            let id = 0
            if (route.paramMap.get('id') !== null) {
                id = parseInt(route.paramMap.get('id') as string)
            }
            return this.get(id);
        } else {
            return this.list();
        }
    }

    list(state?: any): Observable<SalleModel[] | any> {
        const params: any[] = [];
        let paramsQuery = '';

        if (state && state instanceof Object) {
            // { skip: 0, take: 30 }
            if (state.skip || state.take || state.filter) {
                if (state.skip) params.push(`skip=${state.skip}`);
                if (state.take) params.push(`take=${state.take}`);
                if (state.filter) params.push(`filter=${state.filter}`);

                paramsQuery = '?' + params.join('&');
            }
            if (moment.isMoment(state) || moment.isDate(state)) {
                paramsQuery = '?date=' + moment(state).format("YYYY-MM-DD")
            } else {
            }
        }

        // paramsQuery = '?date=2020-09-06'
        return this.http.get<SalleModel[]>(apiUrl).pipe(
            map((response: any) => {
                return _.sortBy(response, ['ordrePriorite', 'SalleNom']);
            })
        );
    }

    get(id: number): Observable<SalleModel | undefined> {
        return this.http.get<SalleModel>(apiUrl + `/${id}`).pipe(
            map((response: any) => {
                return new SalleModel(response);
            })
        );
    }

    getRoomsUsage$(date?: any, end?: any): Observable<SalleCeduleModel[] | any> {
        const params = [];
        let paramsQuery = '';
        let curDate = moment().format("YYYY-MM-DD");
        let endDate = moment().format("YYYY-MM-DD");

        if (date && date instanceof Object) {
            // { skip: 0, take: 30 }
            if (moment.isMoment(date) || moment.isDate(date)) {
                curDate = moment(date).format("YYYY-MM-DD");
            }
            if (end && (moment.isMoment(end) || moment.isDate(end))) {
                endDate = moment(end).format("YYYY-MM-DD");
            } else {
                endDate = curDate
            }
        }

        // paramsQuery = '?date=2020-09-06'
        // return this.http.get<SalleCeduleModel[]>(apiUrl + `/list/${curDate}` ).pipe(
        //     map((response: any) => {
        //         return _.map(response, (r) => new SalleCeduleModel(r) );
        //     })
        // );
        return this.http.get<SalleCeduleModel[]>(apiUrl + `/cedule`, { params: { startDate: curDate, endDate: endDate } }).pipe(
            map((response: any) => {
                return _.map(response, (r) => new SalleCeduleModel(r));
            })
        );
    }

    getTimeSlots$(date?: any, minutes?: number): Observable<any[] | any> {
        const params = [];
        let curDate = moment().format("YYYY-MM-DD");
        minutes = (minutes ? minutes : 60)

        if (date && date instanceof Object) {
            // { skip: 0, take: 30 }
            if (moment.isMoment(date) || moment.isDate(date)) {
                curDate = moment(date).format("YYYY-MM-DD");
            }
        }

        return this.http.get<any[]>(apiUrl + `/disponibilites`, { params: { date: curDate, duree: minutes } });
    }

    voirSallesDisponibilites(showDate: boolean, selectedDate: Date) {
        this.list().subscribe(roomList => {
            this.dialog.open(RoomsDialogPickComponent, {
                width: '800px',
                data: { showDate: showDate, date: selectedDate, multiple: false, duration: 60, dialogTitle: "Disponibilités des salles", rooms: roomList } as RoomPickupInfo,
            });
        })
    }

    /** POST: add a new record  to the server */
    add(rooms: SalleModel): Observable<SalleModel> {
        // you can apply empty string instead of state.data to get failure(error)
        return this.http.post<SalleModel>(apiUrl, rooms);
    }

    /** DELETE: delete the record from the server */
    delete(room: SalleModel): Observable<any> {
        return this.http.delete<any>(`${apiUrl}/${room.salleId}`);
    }

    /** PUT: update the record on the server */
    update(room: SalleModel): Observable<SalleModel> {
        return this.http.put<SalleModel>(`${apiUrl}/${room.salleId}`, room);
    }

    /** PUT: update the record on the server */
    patch(room: Partial<SalleModel>): Observable<any> {
        return this.http.patch(`${apiUrl}/${room.salleId}`, room);
    }
}
