<mat-toolbar class="sticky-toolbar">
    <div class="d-none d-lg-inline-block d-md-inline-block pr-2">Cédule du {{ moment(dateSelected).format('D MMMM YYYY') }}</div>
    <ng-container *ngIf="!features || (features &amp;&amp; features.includes('remote'))"
        ><button class="pr-2" mat-button [matMenuTriggerFor]="menuVideo" aria-label="Démarrer une vidéo"><mat-icon class="mr-2">ondemand_video</mat-icon><span class="d-none d-xl-inline-block">Démarrer une vidéo</span></button
        ><mat-menu #menuVideo="matMenu"
            ><button mat-menu-item *ngFor="let video of videoListe | async" (click)="s$.PlayVideo(video.url)"><mat-icon>ondemand_video</mat-icon><span>{{ video.url }}</span></button></mat-menu
        ><button class="pr-2" mat-button [matMenuTriggerFor]="menuImage" aria-label="Afficher une image"><mat-icon class="mr-2">image</mat-icon><span class="d-none d-xl-inline-block"> Afficher une image</span></button
        ><mat-menu #menuImage="matMenu"
            ><button mat-menu-item *ngFor="let image of imageListe | async" (click)="s$.ShowImage(image.url)"><mat-icon>image</mat-icon><span>{{ image.url }}</span></button></mat-menu
        ><button class="pr-2" mat-button [matMenuTriggerFor]="menuSlide" aria-label="Démarrer un défilement"><mat-icon class="mr-2">photo_library</mat-icon><span class="d-none d-xl-inline-block">Démarrer un défilement</span></button
        ><mat-menu #menuSlide="matMenu"
            ><button mat-menu-item *ngFor="let slide of slideShowListe | async" (click)="s$.ShowSlideshow(slide)"><mat-icon>photo_library</mat-icon><span>{{ slide }}</span></button></mat-menu
        ></ng-container
    ><button class="pr-2" mat-button [matMenuTriggerFor]="menuOptions" aria-label="Options"><mat-icon>settings</mat-icon></button
    ><mat-menu #menuOptions="matMenu"
        ><div mat-menu-item (click)="$event.stopPropagation()"><mat-slide-toggle matInput [(ngModel)]="hideEmptySchedule" labelPosition="before">Cacher les cédules vides</mat-slide-toggle></div>
        <div mat-menu-item (click)="$event.stopPropagation()"><mat-slide-toggle matInput [(ngModel)]="viewActualTimeOnly" labelPosition="before">Montrer l'heure actuelle</mat-slide-toggle></div>
        <div mat-menu-item (click)="$event.stopPropagation()"><mat-slide-toggle matInput [(ngModel)]="viewCompact" labelPosition="before">Afficher la vue compacte</mat-slide-toggle></div></mat-menu
    ><button class="pr-2" mat-button [matMenuTriggerFor]="menuAlerts" aria-label="Options" *ngIf="((s$.warnings | async) || []).length &gt; 0"><mat-icon [matBadge]="((s$.warnings | async) || []).length" matBadgeColor="warn">notification_important</mat-icon></button><mat-menu class="alerts-panel" #menuAlerts="matMenu"><div mat-menu-item disableRipple="true" *ngFor="let alert of s$.warnings | async" (click)="_scrollToFragment('col-' + alert.id)">{{ alert.description }}</div></mat-menu></mat-toolbar
><sbpro-card
    ><div class="card-body p-1">
        <div class="d-flex flex-row p-1"><d1-date-picker [date]="dateSelected" [showRefresh]="true" (dateChange)="onDateChange($event)" (refresh)="refresh()"></d1-date-picker></div>
        <div class="d-flex flex-row p-2"><d1-schedule-list-horizontal class="w-100" [date]="dateSelected" id="horizontal" [hideEmptySchedule]="hideEmptySchedule" [viewActualTimeOnly]="viewActualTimeOnly" [viewCompact]="viewCompact"></d1-schedule-list-horizontal></div></div
></sbpro-card>
