import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'd1-display-info',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './display-info.component.html',
    styleUrls: ['display-info.component.scss'],
})
export class DisplayInfoComponent implements OnInit {
    @Input() label = '';
    @Input() type = 'text';
    @Input() icon = [];
    @Input() list: string | string[] = [];
    @Input() text: any;
    @Input() hideIfEmpty = false;
    @Input() prefix = '';
    @Input() tooltip = '';
    @Input() TooltipPosition = 'below';
    @Input() suffix = '';

    constructor() {}

    ngOnInit() {
        if (this.text === undefined || this.text === null) {
            this.text = '';
        }

        if (this.icon === undefined) {
            this.icon = [];
        }

        if (typeof this.list === 'string') {
            // Convertir en array
            if (this.list.length) {
                this.list = this.list.toString().split('|');
            }
        }

        if (this.list === undefined) {
            this.list = [];
        }

        if (typeof this.text !== 'string') {
            this.text = this.text.toString();
        }
    }
}
