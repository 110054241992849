import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Rental } from '../models';
import { RentalsService } from './rentals.service';

@Injectable({
  providedIn: 'root'
})
export class BookingResolve implements Resolve<Rental> {

  constructor(private http: HttpClient, private r$: RentalsService ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Rental | Observable<Rental> | Promise<Rental> {
    if (route.params.id === undefined) {
      const newRental: Rental = {
        booking_id : 0,
        booking_tags : '',
        codepostal : '',
        groupeId : 0,
        shipping_type : 0,
        nocivique : '',
        notes : '',
        notesAdmin : '',
        pays : 'Canada',
        province: 'Québec',
        rue : '',
        geo_lat: 0,
        geo_long: 0,
        slot_id: 0,
        status : 0,
        ville: '',
        duration_sec: 0
      }
      return newRental;
    } else {
      return this.r$.getBooking$(route.params.id);
    }
  }

}
