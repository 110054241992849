import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppMessageService } from '@common/services/messages.service';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { User } from '../models';

import { UserService } from './user.service';

export class AuthSuccess {
    constructor(
        public payload: {
            email: string;
            userId: string;
            token: string;
            refreshToken: string;
            expirationDate: Date;
            redirectTo?: string;
        }
    ) {}
}

export class AuthFail {
    constructor(public payload: string) {}
}

export interface AuthResponseData {
    id: number;
    username: string;
    nom: string;
    email: string;
    created: Date;
    lastUpdated: Date;
    auth_token: string;
    refreshToken: string;
    auth_token_expireIn: number;
}

const handleAuthentication = (data: any, resData: any) => {
    // const expirationDate = new Date(new Date().getTime() + +resData.expiresIn * 1000);
    const expirationDate = resData.expiresIn;

    return new AuthSuccess({
        email: resData.email,
        userId: resData.localId,
        token: resData.accessToken,
        refreshToken: resData.refreshToken,
        expirationDate,
        redirectTo: '/dashboard',
    });
};

const handleError = (errorRes: any) => {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes.code) {
        return of(new AuthFail(errorMessage));
    } else if (errorRes.code && errorRes.message) {
        return of(new AuthFail(errorRes.message));
    }
    switch (errorRes.code) {
        // LOGIN
        case 'EMAIL_NOT_FOUND':
            errorMessage =
                'There is no user record corresponding to this identifier. The user may have been deleted.';
            break;
        case 'INVALID_PASSWORD':
            errorMessage = 'The password is invalid or the user does not have a password.';
            break;
        case 'USER_DISABLED':
            errorMessage = 'The user account has been disabled by an administrator.';
            break;

        // SIGNUP
        case 'EMAIL_EXISTS':
            errorMessage = 'This email already exists';
            break;
        case 'OPERATION_NOT_ALLOWED':
            errorMessage = 'Password sign-in is disabled for this project.';
            break;
        case 'TOO_MANY_ATTEMPTS_TRY_LATER':
            errorMessage =
                'We have blocked all requests from this device due to unusual activity. Try again later.';
            break;
    }
    return of(new AuthFail(errorMessage));
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    autoLogoutimer: any = 0;
    refreshTokenTimer: any = 0;
    accessToken = '';
    refreshToken = '';
    isLoggedin = false;

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private user$: UserService,
        private route: Router,
        private appMsg:AppMessageService
    ) {
        this.autoLogin();
    }

    isLoggedIn(): boolean {
        return this.isLoggedin;
    }

    autoLogin() {
        const lsData = localStorage.getItem('userData');
        if (lsData) {
            const userData: User = JSON.parse(lsData);
            if (userData) {
                // On devrait recevoir un auth_token_expireIn, on va se faire un refresh une minute avant
                // const expiresInXSeconds = moment().diff(moment.unix(userData.auth_token_expireIn), 's')
                let expiresInXSeconds = moment.unix(userData.auth_token_expireIn).diff(moment(), 's')
                if (expiresInXSeconds > 0) {
                    this.user$.user = userData;
                    this.isLoggedin = true;
                    this.accessToken = userData.auth_token;
                    this.refreshToken = userData.refreshToken;
                    
                    // Calculer le time out
                    // this.setRefreshTokenTimer(10000);
                    console.log('auto login, on part le refresh timer 60 secondes avant la fin', expiresInXSeconds)
                    this.setRefreshTokenTimer((expiresInXSeconds - 60));

                    // Prépare notre auto-logout
                    this.setLogoutTimer(expiresInXSeconds * 1000);
    
                } else {
                    // Le token a expiré déjà, on ne permet pas le auto-login
                    console.log(`AUTO LOGIN EXPIRÉ DEPUIS ${expiresInXSeconds} secondes`, userData)
                }
            }
        }
    }

    logout(): void {
        this.isLoggedin = false;
        this.user$.user = undefined;
        this.accessToken = '';
        this.refreshToken = '';
        localStorage.removeItem('userData');
        this.clearLogoutTimer();
        this.route.navigate(['/auth/login']);
    }

    getToken(): string {
        return this.accessToken;
    }

    getRefreshToken(): string {
        return this.refreshToken;
    }

    setRefreshTokenTimer(expiresInSeconds: number) {
        this.clearRefreshTokenTimer()
        if (isNaN(expiresInSeconds)) {
            expiresInSeconds = 60;
        }
        console.log(`setRefreshTokenTimer, on expire dans ${expiresInSeconds} secondes`)
        this.refreshTokenTimer = setTimeout(() => {
            // Demander un refresh token
            this.getRefreshToken$().subscribe( (rData) => {
            }, (error) => {
                console.error("setRefreshTokenTimer() error", error);
            })
        }, (expiresInSeconds * 1000));
    }

    clearRefreshTokenTimer() {
        if (this.refreshTokenTimer) {
            clearTimeout(this.refreshTokenTimer);
        }
        this.refreshTokenTimer = 0;
    }

    setLogoutTimer(expiresIn: number) {
        this.clearLogoutTimer()
        if (isNaN(expiresIn)) {
            expiresIn = 100;
        }
        // console.log('setLogoutTimer, on expire dans ', expiresIn)
        this.autoLogoutimer = setTimeout(() => {
            // On n'a pas eu notre refresh à temps, on va quitter.
            // console.log('AUTO - LOGOUT EXPIRÉ, On quitte')
            this.logout()
        }, expiresIn);
    }

    clearLogoutTimer() {
        if (this.autoLogoutimer) {
            clearTimeout(this.autoLogoutimer);
        }
        this.autoLogoutimer = 0;
    }

    getAuth$(email: string, password: string): Observable<AuthSuccess> {
        const data = {
            username : email,
            password,
            returnSecureToken: true,
        };
        return this.http.post<AuthResponseData>('/users/login', data, {}).pipe(
            tap(resData => {
                this.accessToken = resData.auth_token;
                this.refreshToken = resData.refreshToken;
                this.isLoggedin = true;
                this.user$.user = resData as User;
                localStorage.setItem('userData', JSON.stringify(resData));

                console.log('auth ok,', resData)
                let expiresInXSeconds = moment.unix(resData.auth_token_expireIn).diff(moment(), 's')
                console.log('login ok, on part le refresh timer', resData.auth_token_expireIn, expiresInXSeconds)
                this.setRefreshTokenTimer((expiresInXSeconds-60));

                // On force à recharger des services 
                this.appMsg.SettingsMessage.next(undefined);

            }),
            map(resData => {
                return handleAuthentication(data, resData);
            }),
            catchError((errorRes: any) => {
                console.error('getAuth$ ERREUR errorRes', errorRes);
                return throwError(errorRes.error);
            })
        );
    }

    getRefreshToken$(): Observable<AuthSuccess | AuthFail> {
        const data = {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            returnSecureToken: true,
        };
        const lsData = localStorage.getItem('userData');
        if (lsData && this.isLoggedin) {
            const userData: User = JSON.parse(lsData);
            if (userData) {
                return this.http.post<AuthResponseData>('/users/refresh_token', data, {}).pipe(
                    tap(resData => {
                        // On devrait recevoir un auth_token_expireIn, on va se faire un refresh une minute avant
                        let expiresInXSeconds = moment.unix(resData.auth_token_expireIn).diff(moment(), 's')
                        console.log('resData', expiresInXSeconds);
                        if (isNaN(expiresInXSeconds)) {
                            console.log('resData PAS FAIT, on le met dans 61 secondes');
                            expiresInXSeconds = 61
                        }
                        this.setRefreshTokenTimer((expiresInXSeconds - 60));
                        // console.log('getRefreshToken$() On va rafraichir dans ', expiresInXSeconds)

                        // On va activer le "auto-logout" 1 minute après le refresh
                        // console.log('On a eu un refresh token, on va refaire notre auto-logout')
                        this.setLogoutTimer(expiresInXSeconds * 60000)

                        userData.auth_token = resData.auth_token;
                        userData.auth_token_expireIn = resData.auth_token_expireIn;
                        userData.refreshToken = resData.auth_token;
                        this.accessToken = resData.auth_token;
                        this.refreshToken = resData.auth_token;
                        this.isLoggedin = true;
                        this.user$.user = userData;
                        localStorage.setItem('userData', JSON.stringify(userData));
                    }),
                    map(resData => {
                        return handleAuthentication(data, resData);
                    }),
                    catchError((errorRes: any) => {
                        console.error('getRefreshToken$() ERREUR errorRes', errorRes);
                        return throwError(errorRes.error);
                    })
                );
            } else {
                return of(new AuthFail('RefreshTokenn() Invalid user Data'));
            }
        } else {
            return of(new AuthFail('RefreshTokenn() Invalid user Data'));
        }
    }
}
