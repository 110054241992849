import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SchedulePickScheduleComponent } from '@modules/schedule/components';
import { GameModel } from '@modules/schedule/models/game.model';
import _ from 'lodash';
import moment from 'moment';
import { ReservationsListGamesComponent } from '../reservations-list-games/reservations-list-games.component';

@Component({
    selector: 'd1-reservations-games',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-games.component.html',
    styleUrls: ['reservations-games.component.scss'],
})
export class ReservationsGamesComponent implements OnInit {
    @Input() games: GameModel[] = [];
    @Input() date?: Date;
    @Input() groupSize: number = -1;
    @Output() gamesChanged: EventEmitter<GameModel[]> = new EventEmitter();

    // @ViewChild('schedulePick') schedulePick!: ElementRef<SchedulePickScheduleComponent>;
    @ViewChild('schedulePick') schedulePick!: SchedulePickScheduleComponent;
    @ViewChild('gameList') gameList!: ElementRef<ReservationsListGamesComponent>;

    selectGames: boolean = false;
    allowEdit: boolean = false;

    constructor(private ref: ChangeDetectorRef) {
        if (this.games === undefined) {
            this.games = [];
        }

        if (this.date === undefined) {
            this.date = new Date()
        }
    }

    OnChange(cedules: moment.Moment[]) {
        // Se créer une copie temporaire pour référence
        const refGames = [...this.games];
        // console.log('schedulePick', this.schedulePick);
        console.log('games changes', cedules);
        // this.games = this.games.slice() as [];
    }

    saveSchedule() {
        console.log('schedulePick', this.schedulePick);

        const selectedCedules = this.schedulePick.selectedCedules
        const newGameList: GameModel[] = [];

        console.log('selectedCedules', selectedCedules);

        _.each(_.sortBy(selectedCedules, (c) => c.toISOString()), (c) => {
            newGameList.push({
                arena_id: 1,
                nombreJoueurs: this.groupSize,
                partieDuree: 20,
                partieDate: '',
                partieId: 0,
                partieTemps: c.toISOString(),
                partieType: 1,
                presence : 0
            })
        })
        this.games = newGameList;

        this.selectGames = !this.selectGames;
    }

    ngOnInit() {
        console.log("ReservationsGamesComponent() ngInit()", this.date, typeof (this.date));
    }
}
