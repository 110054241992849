import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'ng1-menu',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './ng1-menu.component.html',
    styleUrls: ['ng1-menu.component.scss'],
})
export class Ng1MenuComponent implements OnInit {
    ngOnInit() {}
}
