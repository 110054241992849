import { AnimationBuilder } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingsService, UtilityService } from '@common/services';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { RoomPickupInfo, RoomsDialogPickComponent } from '@modules/rooms/containers';
import _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'd1-reservations-overview',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-overview.component.html',
    styleUrls: ['reservations-overview.component.scss'],
})
export class ReservationsOverviewComponent implements OnInit {
    public selectedDate: moment.Moment = moment();
    private _initDone = false;
    @ViewChild('drawer') drawer!: MatDrawer;
    public selectedReservation: ReservationModel | undefined = undefined;
    public groupeTypes: any = [];
    public viewActualTimeOnly = true;
    public viewAll = false;
    public viewSchedule = false;
    public viewTimeline = 0;
    public isDevMode = isDevMode();


    constructor(private router: Router, private nav: ActivatedRoute, private r$: ReservationsService, private utilityService: UtilityService
        , private settings$: AppSettingsService, private _builder: AnimationBuilder
        , public dialog: MatDialog) {
        this.r$.SelectedDate.subscribe(newDate => {
            this.selectedDate = newDate;
        })

        this.settings$.TypeGroupes.subscribe(l => {
            this.groupeTypes = l
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }

    selectedBooking(booking: ReservationModel) {
        if (booking.groupeId) {
            this.selectedReservation = { ...booking };
            this.drawer.open();
        }
    }

    OnDateChange(date: moment.Moment) {
        this.r$.SelectedDate.next(date)

        this.router.navigate(
            [],
            {
                relativeTo: this.nav,
                queryParams: { date: moment(date).format('YYYY-MM-DD') },
                queryParamsHandling: 'merge'
            });
        // const myAnimation = this._builder.build([
        //     style({ height: 0 }),
        //     animate(1000, style({ height: '180px' }))
        //   ]);
        // const el = document.querySelector('.mat-form-field-flex');
        // if (el) {
        //     console.log('reservationFiltre element', el);
        //     const player = myAnimation.create(el);
        //     player.play();
        // }
    }


    ngOnInit() {
        this._initDone = true;
        this.nav.queryParams.subscribe((q) => {
            // this.selectedDate = moment(q.date);
            if (q.date && moment(q.date).format('YYYYMMDD') !== this.selectedDate.format('YYYYMMDD')) {
                this.r$.SelectedDate.next(moment(q.date));
            }
            if (q.viewSchedule) {
                this.viewSchedule = (q.viewSchedule === 'true');
                // this.ref.markForCheck()
            } else {
                let info = this.r$.getStorage();
                this.viewSchedule = (info.mainListView === 'schedule')
            }
        })
    }
    toggleViewSchedule() {
        // On va garder l'info dans
        let info = this.r$.getStorage();
        info.mainListView = (this.viewSchedule ? 'schedule' : 'list')
        this.r$.saveStorage(info);
    }

    copyToClipboard(texte: string) {
        this.utilityService.copyToClipboard(texte);
    }


    getGroupeTypeCouleur(groupe: ReservationModel) {
        const gt = _.find(this.groupeTypes, { groupeTypeId: groupe.groupeTypeId })
        return (gt && gt.groupeTypeCouleur ? gt.groupeTypeCouleur : '#0061f2');
    }

    getGroupeTypeNom(groupe: ReservationModel) {
        const gt = _.find(this.groupeTypes, { groupeTypeId: groupe.groupeTypeId })
        return (gt ? gt.groupeTypeNom : 'TYPE INCONNU');
    }

    isSameDay() {
        return moment().diff(this.selectedDate, 'days') === 0;
    }

    voirSallesDisponibilites() {
        const dialogRef = this.dialog.open(RoomsDialogPickComponent, {
            width: '800px',
            data: { showDate: false, date: this.selectedDate.toDate(), multiple: false, duration: 60, dialogTitle: "Disponibilités des salles", rooms: this.nav.snapshot.data.rooms } as RoomPickupInfo,
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
