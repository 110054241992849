import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-form-group-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-group-input.component.html',
    styleUrls: ['form-group-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FormGroupInputComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => FormGroupInputComponent),
        },
    ],
})
export class FormGroupInputComponent
    implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, Validators {
    constructor(private ref: ChangeDetectorRef) {}
    @ViewChild('editInput') editInput!: ElementRef<HTMLDivElement>;
    @Input() id = '';
    @Input() label = '';
    @Input() type = 'text';
    @Input() value = '';
    @Input() prefix = '';
    @Input() tooltip = '';
    @Input() TooltipPosition = 'below';
    @Input() suffix = '';
    @Input() disabled = false;
    @Input() autocomplete = 'off';
    @Input() required = false;
    @Input() multiline = false;
    @Input() placeholder = '';
    @Input() hint = undefined;
    @Input() minuteSteps = undefined;
    @Input() hoursFormat = 24;
    @Input() model = {
        placeholder: 'Entrez une valeur',
        dataSource: [],
        fields: { text: 'text', value: 'value' },
    };
    // @Input() model:any;
    @Output() valueChange = new EventEmitter<any>();
    @Output() change = new EventEmitter<any>();
    @Output() blur = new EventEmitter<any>();

    @Input('class') classes = '';

    public dateValue: Date = new Date();
    public valueComboText = '';
    public monControle = new FormControl('');
    public hasErrors:string[] = [];

    onChangeSubs: Subscription[] = [];

    ngAfterViewInit(): void {
        // this.disabled ? this.monControle.disable() : this.monControle.enable();
        this.monControle.valueChanges.subscribe( v => {
            this.valueChange.emit(this.monControle.value);
            this.change.emit(this.monControle.value);
        })
    }

    ngOnInit() {
        this.valueComboText = this.value;
        if (this.placeholder) {
            this.model.placeholder = this.placeholder;
        }

        if (this.type === 'TextArea') {
            this.type = 'TextArea';
            this.multiline = true;
        }

        if (this.type === 'Numeric') {
            this.type = 'number';
        }

        if (this.type === 'DateTime') {
            if (this.value) {
                this.dateValue = new Date(this.value);
            } else {
                this.dateValue = new Date();
            }
            this.value = this.dateValue.toString();
            this.type = 'datetime-local';
            this.monControle.setValue(this.dateValue.toISOString());
        }

        if (this.type === 'ComboBox') {
            if (this.model && this.model.dataSource instanceof Array) {
                if (!this.model.fields) {
                    this.model.fields = { text: 'text', value: 'value' };
                }
                _.each(this.model.dataSource, i => {
                    if (i[this.model.fields.value] === this.value) {
                        this.valueComboText = i[this.model.fields.text];
                        this.value = i[this.model.fields.value];
                    }
                });
            }
        }

        if (this.type === 'Time') {
            // Valider si on recoit une date ou bien juste une string de l'heure
            if (this.value && this.value !== null) {
                if (moment(this.value).isValid()) {
                    this.value = moment(this.value).format('HH:mm');
                } else {
                    this.value = this.value;
                }

            } else {
                this.value = '';
            }
        }

        if (this.type !== 'datetime-local') {
            this.monControle.setValue(this.value);
        }
        if (this.required) {
            const t = this.monControle.validator;
            if (t) {
                console.log('test t', t(new FormControl()))
            }
            this.monControle.setValidators([Validators.required]);
            this.monControle.updateValueAndValidity()
        }
    }

    onChange(event: any) {
        this.value = this.monControle.value ? this.monControle.value : '';
        // this.valueChange.emit(this.monControle.value);
        // this.change.emit(this.monControle.value);
        event.preventDefault();
        event.stopPropagation();
    }

    onComboChange(event: any) {
        this.value = this.monControle.value ? this.monControle.value : '';
        // Aller chercher la valeur text du choix
        const val = _.find(this.model.dataSource, [
            this.model.fields.value,
            this.monControle.value,
        ]);
        this.valueComboText = val ? val[this.model.fields.text] : '';
    }

    onChangeTime(event: any) {
        this.value = event;
        this.monControle.setValue(this.value);
        this.change.emit(event);
        this.valueChange.emit(event);
    }

    clearValue(event: any) {
        this.monControle.setValue(null);
    }
    onTouched: Function = () => {
    };

    onblur() {
        this.blur.emit(this.monControle.value)
        this.onTouched();
    }

    writeValue(value: any): void {
        try {
            if (value !== undefined) {
                if (this.type === 'Time') {
                    // Valider si on recoit une date ou bien juste une string de l'heure
                    if (value && value !== null) {
                        if (moment.isDate(value)) {
                            this.value = moment(value).format('HH:mm');
                        } else {
                            this.value = value;
                        }

                    } else {
                        this.value = '';
                    }
                    this.monControle.setValue(this.value, { emitEvent: true });
                } else if (this.type === 'datetime-local') {
                    if (value) {
                        this.dateValue = new Date(value);
                    } else {
                        this.dateValue = new Date();
                    }
                    this.value = this.dateValue.toString();
                    this.monControle.setValue(this.dateValue.toISOString(), { emitEvent: true });
                } else {
                    this.value = value;
                    this.monControle.setValue(this.value, { emitEvent: true });
                }

                if (this.type === 'ComboBox') {
                    // Aller chercher la valeur text du choix
                    const val = _.find(this.model.dataSource, [
                        this.model.fields.value,
                        this.monControle.value,
                    ]);
                    this.valueComboText = val ? val[this.model.fields.text] : '';
                }
            }
        } catch(error) {
            console.error('writeValue', value, error);
        }

    }
    registerOnChange(fn: any): void {
        const sub = this.monControle.valueChanges.subscribe(fn);
        this.onChangeSubs.push(sub);
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.errors) {
            this.hasErrors = [];
            this.monControle.setErrors(null);
            _.each(control.errors, (err, type) => {
                switch (type.toLowerCase()) {
                    case 'minlength':
                        this.hasErrors.push(`Un minimum de ${err.requiredLength} caractères est requis`);
                        this.monControle.setErrors(err);
                        break;

                    case 'required':
                        this.hasErrors.push(`Le champ est obligatoire`);
                        this.monControle.setErrors(err);
                        break;
                
                    default:
                        break;
                }
            })
        } else {
            this.hasErrors = [];
            this.monControle.setErrors(null);
        }
        return this.monControle.errors;
    }

    setDisabledState(isDisable: boolean) {
        if (isDisable) {
            this.monControle.disable();
        } else {
            this.monControle.enable();
        }

        this.disabled = isDisable;
        this.ref.markForCheck();
    }

    // Clean up subscription
    ngOnDestroy(): void {
        for (const sub of this.onChangeSubs) {
            sub.unsubscribe();
        }
    }
}
