import { animate, AnimationBuilder, AnimationFactory, style } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, Params } from '@angular/router';
import { AppSettingsService, ToastService } from '@common/services';
import { AppMessageService } from '@common/services/messages.service';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { SimpleGridComponent } from 'ts-ng-lib';
import { ClickEventArg, ColumnDefinitionsModel, FilterDefinitionsModel, FilterEventArg } from 'ts-ng-lib';

@Component({
    selector: 'd1-reservations-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-list.component.html',
    styleUrls: ['reservations-list.component.scss'],
})
export class ReservationsListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() viewActualTimeOnly = true;
    @Input() viewAll = false;
    @Input() viewSchedule = false;

    public ListeReservations: ReservationModel[] = [];
    public ListeReservationsComplete: ReservationModel[] = [];
    public listes = [];
    private  myAnimation: AnimationFactory

    // private subs: Observable<Params>;
    @Input() date: moment.Moment = moment();
    @ViewChild('colStatutTemplate', {static : true}) colStatutTemplate!:TemplateRef<unknown>;
    @ViewChild('colPartiesTemplate', {static : true}) colPartiesTemplate!:TemplateRef<unknown>;
    @ViewChild('colOrganisateurTemplate', {static : true}) colOrganisateurTemplate!:TemplateRef<unknown>;
    @ViewChild('colInfosTemplate', {static : true}) colInfosTemplate!:TemplateRef<unknown>;
    @ViewChild('colDiversTemplate', {static : true}) colDiversTemplate!:TemplateRef<unknown>;
    @ViewChild('colPaymentTemplate', {static : true}) colPaymentTemplate!:TemplateRef<unknown>;
    @ViewChild('listeBooking', {static : true}) listeBooking!:SimpleGridComponent;
    @Output() selected:EventEmitter<ReservationModel> = new EventEmitter();
    
    public isCollapsed = true;
    public groupeTypes: any = [];
    public columnDefs: ColumnDefinitionsModel[] = [];   // Voir ngOnInit

    // quickFilters
    quickFilters: FilterDefinitionsModel[] = [
        { label: 'All', filters: [], active : true},
        { label: 'Frank', filters: [ {column: 'nomOrganisateur', value : 'François'} ], active : false},
        { label: 'Joueurs', filters: [ {column: 'nombreJoueurs', value : '3'} ], active : false},
        { label: 'Event!', filters: [], active : false, filter: this.onFlter},
    ];

    constructor(private modalService: NgbModal
        , private r$: ReservationsService
        , private ref: ChangeDetectorRef
        , private router: Router
        , private nav: ActivatedRoute
        , private settings$:AppSettingsService
        , private messages$:AppMessageService
        , private toastService: ToastService, private _builder: AnimationBuilder) {
            
            

            // Créer mon animation
            this.myAnimation = this._builder.build([
                // style({ height: 0 }),
                animate("1s ease-in", style({ height: '300px' })),
                animate("1s 1s ease-out", style({ height: 'auto' })),
              ]);
        
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.viewAll) {
            this.toggleView()
        }
        if (changes.viewActualTimeOnly) {
            this.toggleView()
        }
        if (changes.viewSchedule) {
            this.toggleViewSchedule()
        }
    }
    ngAfterViewInit(): void {
        // console.log('filterReservations()', this.filterReservations())
    }

    showSelected(group: ReservationModel) {
        this.selected.emit(group);
    }
    
    public get reservations() : ReservationModel[] {
        return this.filterReservations();
    }

    toggleView() {
        this.ListeReservations = this.filterReservations();
    }

    isSameDay() {
        return moment().diff(this.date, 'days') === 0;
    }

    toggleViewSchedule() {
        this.router.navigate(
            [], 
            {
              relativeTo: this.nav,
              queryParams: { viewSchedule: this.viewSchedule },
              queryParamsHandling: 'merge'
            });
        if (this.viewSchedule === false) {
            // Reset filter!
            this.ListeReservations = this.filterReservations();
        }

        // On va garder l'info dans
        let info = this.r$.getStorage();
        info.mainListView = (this.viewSchedule ? 'schedule' : 'list')
        this.r$.saveStorage(info);
        // this.viewSchedule = !this.viewSchedule;
    }


    // filterReservations():ReservationModel[] {
    //     let liste = this.ListeReservationsComplete; 
    //     if (this.viewActualTimeOnly === false || (this.viewActualTimeOnly === true && (this.date.format('YYYYMMDD') !== moment().format('YYYYMMDD')))) {
    //         return this.ListeReservationsComplete;
    //     } else {
    //         return _.reject(this.ListeReservationsComplete, (v:ReservationModel,i) => {
    //             const lastEvent = this.r$.getLastEvent(v);

    //             return (v.statutGroupe === 2 && lastEvent.isBefore(moment()));
    //         });
    //     }
    // }

    filterReservations():ReservationModel[] {
        let liste = this.ListeReservationsComplete; 
        if (this.viewActualTimeOnly === false || (this.viewActualTimeOnly === true && (this.date.format('YYYYMMDD') !== moment().format('YYYYMMDD')))) {
            // Rien à faire
            // return this.ListeReservationsComplete;
        } else {
            liste = _.reject(liste, (v:ReservationModel,i) => {
                const lastEvent = this.r$.getLastEvent(v);

                return (v.statutGroupe === 2 && lastEvent.isBefore(moment().subtract(30, 'm')));
            });
        }

        // Rejeter les annulation + effacés
        if (this.viewAll === false) {
            liste = _.reject(liste, (v:ReservationModel,i) => {
                return (v.estAnnule || v.estEfface);
            });
        }

        this.ref.markForCheck()
        return liste;
    }

    onFiltered(event: FilterEventArg | ReservationModel[]) {
        if (event instanceof Array){
            this.ListeReservations = event
        }
        // this.ListeReservations = _.filter(this.ListeReservations, {estAnnule: 1}) as ReservationModel[];
    }

    onFlter(filter:FilterDefinitionsModel, data: ReservationModel[]): [] {
        // this.ListeReservations = _.filter(this.ListeReservations, {estAnnule: 1}) as ReservationModel[];
        return _.filter(data, (r) => { return r.estAnnule } ) as [];
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }
    
    ngOnInit() {
        // On doit préparer les colonne ici parce qu'on a besoin d'un template de la view
        this.columnDefs = [
            { headerText: '#', visible: false, name: 'groupeId', sortable: true, arrowPosition: 'after' },
            { headerText: 'Heure arrivée', visible: true, name: "reservationDate", sortable: true, format: (val:any) => moment(val).format('HH:mm') , arrowPosition: 'after'},
            { headerText: 'Paiement', visible: false, name: "estAnnule", sortable: true, template: this.colPaymentTemplate , arrowPosition: 'after'},
            { headerText: '', visible: true, name: "divers", sortable: false, template: this.colDiversTemplate, arrowPosition: 'after'},
            { headerText: 'Organisateur', visible: true, name: 'nomOrganisateur', sortable: true, template: this.colOrganisateurTemplate, arrowPosition: 'after'},
            { headerText: 'Informations', visible: true, name: 'information', sortable: false, template: this.colInfosTemplate, arrowPosition: 'after'},
            { headerText: 'Fêté', visible: false, name: 'nomFete', sortable: true , arrowPosition: 'after'},
            { headerText: '# joueurs', visible: false, name: 'nombreJoueurs', sortable: true, arrowPosition: 'after' },
            { headerText: 'Parties', visible: true, name: 'parties', sortable: true, template: this.colPartiesTemplate, arrowPosition: 'after' },
            { headerText: 'Statut', visible: true, name: 'statutGroupe', sortable: true, template: this.colStatutTemplate, arrowPosition: 'after' },
            { headerText: 'Télephone', visible: false, name: 'telephone', sortable: true, arrowPosition: 'after' },
            { headerText: 'Courriel', visible: false, name: 'courriel', sortable: true, arrowPosition: 'after' },
            // { headerText: 'Notes', visible: true, name: 'notesAdmin', sortable: false, arrowPosition: 'after' }
        ];

        this.r$.ReservationsListe.subscribe( liste => {
            if (this.listeBooking) {
                this.listeBooking.loading = true;
            }
            this.ListeReservationsComplete = liste;
            this.ListeReservations = [... this.filterReservations()];
            if (this.listeBooking) {
                this.listeBooking.loading = false;
            }

            this.ref.markForCheck()

            // setTimeout(() => {
            //     console.log('trigger play');
            //     const el = document.querySelector('tbody .mat-column-reservationDate');
            //     if (el) {
            //         console.log('ReservationsListe element', el);
            //         const player = this.myAnimation.create(el);
            //         player.play();
            //     }
            // }, 125)

        })

        this.settings$.TypeGroupes.subscribe( l => {
            this.groupeTypes = l
        })

        // On va surveiller pour les querys
        // this.nav.queryParams.subscribe( (q) => {
        //     if (q.viewSchedule) {
        //         this.viewSchedule = (q.viewSchedule === 'true');
        //         this.ref.markForCheck()
        //     } else {
        //         let info = this.r$.getStorage();
        //         this.viewSchedule = (info.mainListView === 'schedule')
        //     }
        // })

        this.messages$.ReservationMessage.subscribe( r => {
            // console.log('Nouveaux messages pour les réservations', r);
        })
    }

    onChange() {
        
    }

    changeStatut(groupe:ReservationModel, statut: number) {
        this.r$.updateStatut(groupe.groupeId, statut)

    }

    getParties(groupe:ReservationModel) {
        return _.map(groupe.parties, (p) => { return p.nombreJoueurs + ' x ' + moment(p.partieTemps).format('HH:mm')})
    }

    getGroupeTypeCouleur(groupe:ReservationModel) {
        const gt = _.find(this.groupeTypes, {groupeTypeId : groupe.groupeTypeId})
        return (gt && gt.groupeTypeCouleur ? gt.groupeTypeCouleur : '#0061f2');
    }

    getGroupeTypeNom(groupe:ReservationModel) {
        const gt = _.find(this.groupeTypes, {groupeTypeId : groupe.groupeTypeId})
        return (gt ? gt.groupeTypeNom : 'TYPE INCONNU');
    }

    OnAdd() {
        this.router.navigate(['add'], { relativeTo: this.nav });
    }

    OnRowClick(event: ClickEventArg) {
        const r = event.rowData as ReservationModel;

        switch (event.event) {
            case 'edit':
                this.router.navigate([r.groupeId], { relativeTo: this.nav });
                // this.router.navigate([r.groupeId, 'edit'], { relativeTo: this.nav });
                break;
            case 'delete':
                // this.router.navigate([r.noteId, 'delete'], { relativeTo: this.nav });
                // this.notes$.deleteRecord(r).subscribe( (r) => {
                //     const defaultOptions = {
                //         autohide: true,
                //         delay: 3000,
                //         headerClasses : 'bg-success text-white'
                //     };
                //     this.toastService.show("Notes effacée", "La note a été effacée avec succès", defaultOptions);
                //     // this.router.navigate(['../'], {relativeTo: this.route} );
                //     this.notes$.getNotes$().subscribe( (r) => {
                //         this.ListeNotes = r;
                //         this.ref.markForCheck();
                //     });
                // })
                break;
            
            default:
                // this.router.navigate([r.groupeId], { relativeTo: this.nav, queryParamsHandling: "preserve" });
                this.selected.emit(r);
                break;
        }
    }
}
