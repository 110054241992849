<mat-label *ngIf="showLabel" for="statutGroupe">Statut du groupe : </mat-label
><ng-container *ngIf="showDropDown"
    ><button mat-stroked-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" [class.btn-outline-warning]="statutGroupe == 0 &amp;&amp; showColor" [class.btn-outline-success]="statutGroupe == 2 &amp;&amp; showColor" [class.btn-outline-danger]="statutGroupe == 3 &amp;&amp; showColor">{{ getLabel() }}<mat-icon matSuffix>arrow_drop_down</mat-icon></button
    ><mat-menu #menu="matMenu" (click)="$event.stopPropagation()"
        ><button mat-menu-item [class.text-warning]="showColor" (click)="setStatut(0)">Absent<mat-icon class="ml-1 text-warning">pending</mat-icon></button><button mat-menu-item [class.text-success]="showColor" (click)="setStatut(2)">Arrivé<mat-icon class="ml-1 text-success">verified_user</mat-icon></button><button mat-menu-item [class.text-danger]="showColor" (click)="setStatut(3)">No-Show<mat-icon class="ml-1 text-danger">thumb_down</mat-icon></button></mat-menu
    ></ng-container
><ng-container *ngIf="!showDropDown &amp;&amp; showToggle &amp;&amp; !showIcon"
    ><mat-button-toggle-group name="statutGroupe" [value]="statutGroupe" (change)="OnChange($event)" appearance="legacy"><mat-button-toggle value="0" [class.btn-warning]="statutGroupe == 0 &amp;&amp; showColor">Absent</mat-button-toggle><mat-button-toggle value="2" [class.btn-success]="statutGroupe == 2 &amp;&amp; showColor">Arrivé</mat-button-toggle><mat-button-toggle value="3" [class.btn-danger]="statutGroupe == 3 &amp;&amp; showColor">No-Show</mat-button-toggle></mat-button-toggle-group></ng-container
><ng-container *ngIf="!showDropDown &amp;&amp; !showToggle &amp;&amp; !showIcon"
    ><div class="btn btn-sm" name="statutGroupe" [class.btn-outline-warning]="statutGroupe == 0 &amp;&amp; !showColor" [class.btn-outline-success]="statutGroupe == 2 &amp;&amp; !showColor" [class.btn-outline-danger]="statutGroupe == 3 &amp;&amp; !showColor" [class.btn-warning]="statutGroupe == 0 &amp;&amp; showColor" [class.btn-success]="statutGroupe == 2 &amp;&amp; showColor" [class.btn-danger]="statutGroupe == 3 &amp;&amp; showColor">{{ getLabel() }}<mat-icon class="text-success ml-1" *ngIf="statutGroupe === 2">verified_user</mat-icon><mat-icon class="text-danger ml-1" *ngIf="statutGroupe === 3">thumb_down</mat-icon></div></ng-container
><ng-container *ngIf="!showDropDown &amp;&amp; showIcon"><mat-icon class="text-warning ml-1" *ngIf="statutGroupe === 0" [ngbTooltip]="getLabel()" container="body">pending</mat-icon><mat-icon class="text-success ml-1" *ngIf="statutGroupe === 2" [ngbTooltip]="getLabel()" container="body">verified_user</mat-icon><mat-icon class="text-danger ml-1" *ngIf="statutGroupe === 3" [ngbTooltip]="getLabel()" container="body">thumb_down</mat-icon></ng-container>
