import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReservationModel } from '@modules/reservations/models';
import { GameModel } from '@modules/schedule/models/game.model';
import moment from 'moment';

@Component({
    selector: 'd1-reservations-edit-detail',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-edit-detail.component.html',
    styleUrls: ['reservations-edit-detail.component.scss'],
})
export class ReservationsEditDetailComponent implements OnInit {
    @Input() booking!: ReservationModel;
    @Output() onSave: EventEmitter<ReservationModel> = new EventEmitter();

    public myForm: FormGroup;
    public games: GameModel[] = [];

    constructor(private fb: FormBuilder) {

        this.myForm = this.fb.group({
            groupeId: 0,
            groupeTypeId: 0,
            groupeTitre: '',
            groupe_tags: '',
            nomOrganisateur: '',
            nombreJoueurs: 0,
            reservationDate: moment([2022, 1, 1, 13, 0, 0]).toISOString(),
            courriel: '',
            telephone: '',
            depotFait: false,
            depotMontant: 0,
            depotReference: '',
            nomFete: '',
            salleReservee: 0,
            salleDebut: null,
            salleFin: null,
            notes: '',
            notesAdmin: '',
            initialesEmploye: '',
            statutGroupe: 0,
            statutConfirmation: 0,
            statutPaiement: 0,
            notesMarshall: '',
            responsable: '',
            notesMarshallCouleur: '',
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.booking) {
            console.log('OnChanges', changes.booking, this.booking.reservationDate);
            this.fetchData();
        }
    }

    ngOnInit() {
        this.myForm.valueChanges.subscribe(v => {
            console.log('Value changed', v);
        })
    }

    changeTimeDebut(newTime: any) {
        console.log('changeTimeDebut', newTime)
    }

    fetchData() {
        this.myForm.patchValue({
            groupeId: this.booking.groupeId,
            groupeTypeId: this.booking.groupeTypeId,
            groupeTitre: this.booking.groupeTitre,
            groupe_tags: this.booking.groupe_tags,
            nomOrganisateur: this.booking.nomOrganisateur,
            nombreJoueurs: this.booking.nombreJoueurs,
            reservationDate: moment.utc(this.booking.reservationDate).toISOString(),
            courriel: this.booking.courriel,
            telephone: this.booking.telephone,
            depotFait: this.booking.depotFait,
            depotMontant: this.booking.depotMontant,
            depotReference: this.booking.depotReference,
            nomFete: this.booking.nomFete,
            salleReservee: this.booking.salleReservee,
            salleDebut: this.booking.salleDebut,
            salleFin: this.booking.salleFin,
            notes: this.booking.notes,
            notesAdmin: this.booking.notesAdmin,
            initialesEmploye: this.booking.initialesEmploye,
            statutGroupe: this.booking.statutGroupe,
            statutConfirmation: this.booking.statutConfirmation,
            statutPaiement: this.booking.statutPaiement,
            notesMarshall: this.booking.notesMarshall,
            responsable: this.booking.responsable,
            notesMarshallCouleur: this.booking.notesMarshallCouleur,
        })
        if (this.booking.parties) {
            this.games = this.booking.parties;
        }
    }

    statutGroupeChange(statut: number) {
        this.myForm.controls.statutGroupe.setValue(statut);
    }

    statutConfirmationChange(statut: number) {
        this.myForm.controls.statutConfirmation.setValue(statut);
    }

    statutPaiementChange(statut: number) {
        this.myForm.controls.statutPaiement.setValue(statut);
    }

    changeTags(tags: string) {
        this.myForm.controls.groupe_tags.setValue(tags);
    }

    getMarshallColor() {
        return {
            'background-color': this.myForm.controls.notesMarshallCouleur.value,
            color: (this.myForm.controls.notesMarshallCouleur.value ? 'white' : undefined),
            'padding-left': (this.myForm.controls.notesMarshallCouleur.value ? '5px' : undefined)
        };
    }

    colorChanged(color: any) {
        console.log('colorChanged', color);
    }
}
