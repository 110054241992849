import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FullCalendarComponent} from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/core'
import { SlotEventModel } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import frLocale from '@fullcalendar/core/locales/fr';
import _ from 'lodash';
import { DateClickArg } from '@fullcalendar/interaction';

@Component({
    selector: 'd1-rentals-schedule-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-schedule-calendar.component.html',
    styleUrls: ['rentals-schedule-calendar.component.scss'],
})
export class RentalsScheduleCalendarComponent implements OnInit, AfterViewInit {
    @Input() initialDate: Date = new Date();
    @Output() selected:EventEmitter<SlotEventModel> = new EventEmitter();

    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
    calendarApi!: Calendar;

    public caloptions: CalendarOptions = {
        plugins: [

        ],
        timeZone: 'local',
        initialView: 'timeGridWeek',
        locale: frLocale,
        // aspectRatio: 1,
        slotMinTime : '10:00',
        slotMaxTime : '22:00',
        slotDuration : '01:00',
        /* @ts-ignore */
        allDaySlot : false,
        // businessHours: {
        //     // days of week. an array of zero-based day of week integers (0=Sunday)
        //     daysOfWeek: [ 1, 2, 3, 4 ], // Monday - Thursday

        //     startTime: '10:00', // a start time (10am in this example)
        //     endTime: '18:00', // an end time (6pm in this example)
        // },
        navLinks: true,
        dateClick: function(info:DateClickArg) {
            alert('clicked ' + info.dateStr);
        },
        select: function(info) {
            alert('selected ' + info.startStr + ' to ' + info.endStr);
        },
        headerToolbar: {
            left: 'prev next',
            center: '',
            right: 'timeGridWeek,timeGridDay',
        },
        contentHeight : "100vh",
        eventClick : this.eventClick.bind(this),
        selectable: true,
        eventSources: [

            // SCHEDULE SOURCE
            {
              id: 'horaire',
              url: '/api/bookings/ListSchedule',
              method: 'POST',
            //   backgroundColor : 'orange',
              failure: function() {
                alert('there was an error while fetching events!');
              },
              color: 'green',   // a non-ajax option
              textColor: 'white' // a non-ajax option
            }

            // any other sources...

          ]
    }

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private dialog$: MatDialog
    ) {
        this.initialDate = new Date();
        this.fetchData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('ngOnChanges', changes);
        if (changes.initialDate) {
            if (!changes.initialDate.currentValue) {
                this.initialDate = new Date();
            } else {
                this.initialDate = new Date(changes.initialDate.currentValue);
            }
            if (this.calendarApi) {
                this.calendarApi.gotoDate(this.initialDate)
            }
        }

    }

    ngAfterViewInit(): void {
        this.calendarApi = this.calendarComponent.getApi();
        if (!this.initialDate) {
            this.initialDate = new Date();
        }
        this.calendarApi.gotoDate(this.initialDate)
        console.log('render #1')
        this.calendarApi.render()
    }

    ngOnInit() { }

    eventClick(info: any) {
        console.log('event click', info);
        if (info.event.id) {
            this.selected.emit(info.event);
            // this.router.navigate([info.event.id], { relativeTo : this.nav})
        } else {
            console.log('NO ID');
        }
    }

    onChange() {}

    fetchData() {
        // this.rentals$.getSlots$().subscribe((r) => {
        //     this.ListeSlots = r as Slot[];
        //     this.ref.markForCheck();
        // });
    }

}
