import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-reservation-sheet-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservation-sheet-view.component.html',
    styleUrls: ['reservation-sheet-view.component.scss'],
})
export class ReservationSheetViewComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
