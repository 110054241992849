import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { ShopifyService } from './shopify.service';

@Injectable()
export class ShopifyInterceptorService implements HttpInterceptor {
    constructor(private shopifyService: ShopifyService, private route: Router, private s$:ShopifyService) {
        // console.log('this.s$.getShopify$()', this.s$.getShopify$())
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let myHeader = req.headers;
        // if (this.shopifyService.getToken() && req.url.startsWith('/api/v1/')) {
        //     myHeader = myHeader.set('Shopifyorization', this.shopifyService.getToken());
        //     const newReq = req.clone({
        //         headers: myHeader,
        //     });
        //     return next.handle(newReq).pipe(
        //         tap(
        //             () => {},
        //             (error: any) => {
        //                 if (error instanceof HttpErrorResponse) {
        //                     if (error.status !== 401) {
        //                         return;
        //                     }
        //                     return this.route.navigate(['shopify/login']);
        //                 }
        //             }
        //         )
        //     );
        // } else {
        //     const newReq = req.clone({ headers: myHeader });
        //     return next.handle(newReq);
        // }

        if (false) {
            // const s = this.s$.getShopify$();
            // myHeader = myHeader.set('x-shopify-admin-hmac', s.hmac);
            // myHeader = myHeader.set('x-shopify-admin-host', s.host);
            // myHeader = myHeader.set('x-shopify-admin-locale', s.locale);
            // myHeader = myHeader.set('x-shopify-admin-session', s.session);
            // myHeader = myHeader.set('x-shopify-admin-shop', s.shop);
            // myHeader = myHeader.set('x-shopify-admin-timestamp', s.timestamp);
            const newReq = req.clone({ headers: myHeader });
    
            console.log('le lai pogné!', myHeader);
            return next.handle(newReq);
        } else {
            const newReq = req.clone({ headers: myHeader });
            return next.handle(newReq);
        }
    }
}
