// Import modules
// Core
import * as angular from 'angular';
import 'angular-route';
import 'angular-cookies';
import 'angular-sanitize';
import 'angular-animate';
import '../../assets/i18n/angular-locale_ca-fr.js';


// Upgradable
import { $locationShim } from '@angular/common/upgrade';
import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';

// Packaged
import 'jquery';
import 'angular-ui-bootstrap';
import 'angular-ui-tinymce';
import 'chart.js';
import './jquery.timepicker'
import './timepickerdirective'
import './angular-vis'
import './angular-ui-notification'
import './ng-tags-input/ng-tags-input.min'
import 'ng-file-upload'
// import '@xenialab/angular-filemanager'
import './angular-filemanager/angular-filemanager.min'
import 'angular-barcode'
import './angular-barcode/angular-quagga-js'

import './angular-translate/angular-translate'
import './angular-translate/angular-translate-loader-static-files'
import './angular-translate/angular-translate-storage-cookie.min'
import './angular-translate/angular-translate-handler-log'

// Divers
import * as _ from 'underscore';
import * as moment from 'moment';

// Ressources
import '../ressources/breadcrumb';
import '../ressources/cedule';
// import '../ressources/communications';
import '../ressources/config';
import '../ressources/courriel';
import '../ressources/formulaires';
import '../ressources/login';
import '../ressources/prochaines-parties';
import '../ressources/rapports';
import '../ressources/reservation-liste';
import '../ressources/reservation-util';
import '../ressources/reservation';
import '../ressources/salles';
import '../ressources/user';
import '../ressources/utils';
import '../ressources/validation';
import '../ressources/vestes';
import '../ressources/virtual-reality';

// Injectables
import { LayoutDashboardComponent } from '../../modules/navigation/layouts';
import { RoomsService } from '../../modules/rooms/services/rooms.service';
import { Ng1MenuComponent } from '../../modules/app-common/components/ng1-menu/ng1-menu.component';
import { Ng1DateRangePickerComponent } from '../../modules/app-common/components/ng1-date-range-picker/ng1-date-range-picker.component';
import { DatePickerComponent } from '../../modules/app-common/components/date-picker/date-picker.component';
import { AppSettingsService } from '../../modules/app-common/services/appsettings.service';
import { ToastService } from '../../modules/app-common/services/toast.service';
import { AppMessageService } from '../../modules/app-common/services/messages.service';
import { NotesComponent } from '../../modules/app-common/components/notes/notes.component';
import { ShopifyService } from '../../modules/shopify/services/shopify.service';

moment.locale('fr')

/**
 * AngularJS App
 */
//, 'ui.timepicker'
var d1App = angular.module('d1App', ['ngRoute', 'ngCookies', 'ui.bootstrap', 'ui.tinymce', 'resources.breadcrumb', 'resources.reservation', 'resources.usagers', 'resources.reservationListe', 'resources.cedule', 'resources.salles', 'resources.vestes', 'resources.reservationUtil', 'ngTagsInput', 'ngVis', 'resources.rapports', 'ngSanitize', 'pascalprecht.translate', 'resources.config', 'ngFileUpload', 'FileManagerApp', 'resources.validation', 'resources.formulaires', 'resources.utils', 'resources.vr', 'ui.timepicker', 'ui-notification'])
    // .directive('matSlideToggle', downgradeComponent({component: MatSlideToggle}))
    .factory('$location', downgradeInjectable($locationShim))
    .factory('$roomService', downgradeInjectable(RoomsService))
    .factory('$messages', downgradeInjectable(AppMessageService))
    .factory('$configService', downgradeInjectable(AppSettingsService))
    .factory('$shopifyService', downgradeInjectable(ShopifyService))
    .factory('$toastService', downgradeInjectable(ToastService))
    .directive('sbproLayoutDashboard', downgradeComponent({ component: LayoutDashboardComponent }))
    .directive('ng1Menu', downgradeComponent({ component: Ng1MenuComponent }))
    .directive('d1Notes', downgradeComponent({ component: NotesComponent }))
    .directive('d1DatePicker', downgradeComponent({ component: DatePickerComponent }))
    .directive('ng1DateRangePicker', downgradeComponent({ component: Ng1DateRangePickerComponent }))
    ;

d1App.directive('myColorPicker', function ($compile) {
    return {
        restrict: 'E', //attribute or element
        scope: {
            myDirectiveVar: '=',
            options: '=',
            customColors: '=customcolors',
            bindModel: '=ngModel'
        },
        template: '<input class="d1-color-input" ng-model="bindModel">',
        replace: false,
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var elemHue = $('.d1-color-input')[0];
            var internalLoading = false;

            // Surveiller les changements du model
            scope.$watch('bindModel', () => {
                internalLoading = true;
                hueb.setColor(scope.bindModel && scope.bindModel !== null ? scope.bindModel : 'transparent')
            })

            var hueb = new Huebee(elemHue, {
                // options
                hues: 6,
                shades: 2,
                notation: 'hex',
                setText: false,
                customColors: scope[attr.customcolors]
            });
            hueb.setColor(ctrl.$viewValue)

            hueb.on('change', function (color, hue, sat, lum) {
                if (!internalLoading) {
                    if (color === 'transparent' || color === '') {
                        ctrl.$setViewValue(null, 'change');
                    } else {
                        ctrl.$setViewValue(color, 'change');
                    }

                } else {
                    // Ignore event
                    // on remet les event
                    internalLoading = false;
                }
                hueb.close();
            })
        }
    };
});

/**
 * SECTION DE L'INTERCEPTOR POUR LE JWT sur API
 * 
 */
d1App
    .factory('UserService', () => {
        // On va aller lire le token dans localStorage
        var lsData = localStorage.getItem('userData');
        var token = '';
        if (lsData) {
            try {
                var userData = JSON.parse(lsData);
                token = userData.auth_token;
            }
            catch (error) {
                console.error('UserService error parsing data', error)
            }
        }
        return {
            token: token,
            setToken: function (token) {
                this.token = token;
            },
            getToken: function () {
                return this.token;
            }
        }
    })
    .factory('apiInterceptor', ['UserService', (UserService) => {
        return {
            request: function (config) {
                if (config.url.startsWith('/api')) {
                    // Ajouter le token JWT dans le header.
                    config.headers['Authorization'] = UserService.getToken();
                }
                return config;
            },

            requestError: function (config) {
                console.error('REQUEST ERROR', config)
                return config;
            },

            response: function (res) {
                return res;
            },

            responseError: function (res) {
                console.error('RESPONSE ERROR', res)
                // if (res.status === 401) {
                //     $window.location.href = '/login'
                // }
                return res;
            }
        }
    }])
    .config(function ($httpProvider) {
        $httpProvider.interceptors.push('apiInterceptor');
    });


angular.module('ui.timepicker').value('uiTimepickerConfig', {
    step: 20,
    asMoment: false,
    'forceRoundTime': true,
    'timeFormat': 'H:i',
    'minTime': '08:00',
    'maxTime': '23:59',
    scrollDefault: 'now'
});
/**
 * Directive pour capter la fin du rendering
 */
d1App.directive('emitLastRepeaterElement', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit('LastRepeaterElement');
                });
            }
        }
    }
});

d1App.directive('selectedSalle', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {

            ctrl.$validators.selectedSalle = function (modelValue, viewValue) {
                if (viewValue[modelValue.salleId] == true) {
                    // consider non-empty models to be valid
                    return true;
                }

                // it is invalid
                return false;
            };
        }
    };
});


d1App.config(['$translateProvider', function ($translateProvider) {
    //$translateProvider.preferredLanguage('fr');

    $translateProvider
        .useStaticFilesLoader({ prefix: '/assets/lang/', suffix: '.json' })
        .registerAvailableLanguageKeys(['fr', 'en'], {
            'fr_*': 'fr',
            'en_*': 'en',
            '*': 'fr'
        })
        .determinePreferredLanguage();
    $translateProvider.useCookieStorage();

    $translateProvider.fallbackLanguage("fr");

    moment.locale('fr');
}]);

angular.module('FileManagerApp').config(['fileManagerConfigProvider', function (config) {
    var defaults = config.$get();

    config.set({
        appName: 'angular-filemanager',
        defaultLang: 'fr',
        listUrl: '/api/d1channel/list',
        uploadUrl: '/api/d1channel/upload',
        renameUrl: '/api/d1channel/rename',
        copyUrl: '/api/d1channel/copy',
        removeUrl: '/api/d1channel/remove',
        createFolderUrl: '/api/d1channel/createFolder',
        downloadFileUrl: '/api/d1channel/download',
        // Disabled
        getContentUrl: '/api/d1channel/disabled',
        editUrl: '/api/d1channel/disabled',
        downloadMultipleUrl: '/api/d1channel/disabled',
        moveUrl: '/api/d1channel/disabled',
        compressUrl: '/api/d1channel/disabled',
        extractUrl: '/api/d1channel/disabled',
        permissionsUrl: '/api/d1channel/disabled',
        basePath: '/',

        allowedActions: angular.extend(defaults.allowedActions, {
            pickFiles: true,
            pickFolders: true,
            upload: true,
            rename: true,
            move: false,
            copy: true,
            edit: false,
            changePermissions: false,
            compress: false,
            compressChooseName: false,
            extract: false,
            download: true,
            downloadMultiple: true,
            preview: true,
            remove: true,
            createFolder: true
        })
    });
}]);

d1App.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {

    $routeProvider
        .when('/ng1/cedules', {
            templateUrl: 'ng1/cedules/cedules-list.tpl.html',
            controller: 'D1CeduleCtrl',
            resolve: {
                listeCedules: ['Cedule', function (Cedule) {
                    return Cedule.getListe(moment(), 0);
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des cédules';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }]
            }
        })
        .when('/ng1/musique', {
            templateUrl: 'ng1/partials/musique.tpl.html',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Gestion de la musique';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '';
                }]
            }
        })
        .when('/ng1/vr', {
            templateUrl: 'ng1/partials/vr.tpl.html',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Gestion du VR';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '';
                }]
            }
        })
        .when('/ng1/vr/games', {
            templateUrl: 'ng1/partials/vr-games.tpl.html',
            controller: 'D1VRCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Catalogue de jeux de VR';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '';
                }],
                games: ['D1ServiceVR', function (D1ServiceVR) {
                    return D1ServiceVR.getGames();
                }]
            }
        })
        .when('/ng1/documents', {
            templateUrl: 'ng1/partials/documents.tpl.html',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Zone de documentation';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '';
                }]
            }
        })
        .when('/ng1/salles', {
            templateUrl: 'ng1/salles/salles-list.tpl.html',
            controller: 'D1SallesCtrl',
            resolve: {
                listeSalles: ['Salles', function (Salles) {
                    return Salles.getListe();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Utilisation des salles';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '';
                }],
                listeTypesSalles: ['Salles', function (Salles) {
                    return Salles.TypeSalles(true);
                }],
                $uibModalInstance: () => undefined,
                dateUtilisation: function () { return new Date(); }
            }
        })
        .when('/ng1/reservations/recherche', {
            templateUrl: 'ng1/reservations/reservations-recherche-avancee.tpl.html?v=1',
            controller: 'D1ReservationsRechercheCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Recherche avancée';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                sallesTypes: ['Salles', function (Salles) {
                    return Salles.TypeSalles(true);
                }]
            }
        })
        .when('/ng1/reservations/ajouter', {
            templateUrl: 'ng1/reservations/reservations-voir.tpl.html?v=1',
            controller: 'D1ReservationsCtrl',
            resolve: {
                listeReservations: ['ReservationListe', function (ReservationListe) {
                    return [];
                }],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                salleTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeSalles();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Ajout d\'une réservation';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }],
                listeVestes: ['Vestes', function (Vestes) {
                    return Vestes.getListe();
                }]
            }
        })
        .when('/ng1/reservations/nouvelles', {
            templateUrl: 'ng1/reservations/reservations-list.tpl.html?v=1',
            controller: 'D1ReservationsCtrl',
            resolve: {
                listeReservations: ['ReservationListe', function (ReservationListe) {
                    return ReservationListe.getListeNouvelles();
                }],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                salleTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeSalles();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Nouvelles réservations';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }],
                listeVestes: ['Vestes', function (Vestes) {
                    return Vestes.getListe();
                }],
                groupe: [function () { return {}; }],
                listeCedules: ['Cedule', function (Cedule) {
                    return Cedule.getListe();
                }],
                listeSalles: ['Salles', function (Salles) {
                    return Salles.getListe();
                }]
            }
        })
        .when('/ng1/reservations/:groupeId', {
            templateUrl: 'ng1/reservations/reservations-voir.tpl.html?v=1',
            controller: 'D1ReservationsCtrl',
            resolve: {
                listeReservations: () => [],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                salleTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeSalles();
                }],
                groupe: ['ReservationListe', '$route', function (ReservationListe, $route) {
                    return ReservationListe.getGroupe($route.current.params.groupeId);
                }],
                listeCedules: ['Cedule', function (Cedule) {
                    return Cedule.getListe();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Modifier une réservation';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }],
                listeSalles: ['Salles', function (Salles) {
                    return Salles.getListe();
                }]
            }
        })
        .when('/ng1/bonnefete/:groupeId', {
            //templateUrl:'partials/BonneFeteSigne.html?v=1',
            //controller:'D1FeteCtl',
            resolve: {
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                groupe: ['ReservationListe', '$route', function (ReservationListe, $route) {
                    return ReservationListe.getGroupe($route.current.params.groupeId);
                }]
            }
        })
        .when('/ng1/pochette/:groupeId', {
            resolve: {
                groupe: ['ReservationListe', '$route', function (ReservationListe, $route) {
                    return ReservationListe.getGroupe($route.current.params.groupeId);
                }]
            }
        })
        .when('/ng1/reservations/:groupeId/bonnefete', {
            templateUrl: 'ng1/partials/BonneFeteSigne.html?v=1',
            controller: 'D1FeteCtl',
            resolve: {
                groupe: ['ReservationListe', '$route', function (ReservationListe, $route) {
                    return ReservationListe.getGroupe($route.current.params.groupeId);
                }]
            }
        })
        .when('/ng1/reservations', {
            templateUrl: 'ng1/reservations/reservations-list.tpl.html?v=1',
            controller: 'D1ReservationsCtrl',
            // reloadOnSearch: false,
            resolve: {
                listeReservations: ['ReservationListe', '$location', function (ReservationListe, $location) {
                    var l = $location.search();
                    return ReservationListe.getListe(l.listedate, (l.afficherNonConfirmees ? l.afficherNonConfirmees == 1 : false));
                }],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                salleTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeSalles();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des réservations';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }],
                listeVestes: ['Vestes', function (Vestes) {
                    return Vestes.getListe();
                }]
            }
        })
        .when('/ng1/rapports/depots', {
            templateUrl: 'ng1/reservations/reservations-depots.tpl.html?v=1',
            controller: 'D1ReservationsCtrl',
            resolve: {
                listeReservations: ['ReservationListe', function (ReservationListe) {
                    return ReservationListe.getListeDepots();
                }],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                salleTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeSalles();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des réservations sans dépôts';
                    return Breadcrumb;
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                    return Breadcrumb;
                }],
                listeVestes: ['Vestes', function (Vestes) {
                    return Vestes.getListe();
                }]
            }
        })
        .when('/ng1/rapports/impayee', {
            templateUrl: 'ng1/reservations/reservations-depots.tpl.html?v=1',
            controller: 'D1ReservationsCtrl',
            resolve: {
                listeReservations: ['ReservationListe', function (ReservationListe) {
                    return ReservationListe.getListeImpayee();
                }],
                groupeTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeGroupes();
                }],
                salleTypes: ['D1Configurations', function (D1Configurations) {
                    return D1Configurations.getTypeSalles();
                }],
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des réservations impayées';
                }],
                pageManuel: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }],
                listeVestes: ['Vestes', function (Vestes) {
                    return Vestes.getListe();
                }]
            }
        })
        .otherwise({ controller: () => '' })

    // use the HTML5 History API
    // $locationProvider.html5Mode(true);
}]);

d1App.run(function ($rootScope, $location, Usagers, $translate, $window, Breadcrumb, Reservation, Notification) {
    $rootScope.$on('$routeChangeStart', function (ev, next, curr) {
        if (next.$$route) {
            // Rewrite rules
            var newPath = next.$$route.originalPath;
            if (newPath == "/ng1/reservations/:groupeId") {
                Reservation.chargerReservation(next.params.groupeId).then(function (resp) {
                    // Selon la confirmation, il faut aller à la bonne place.
                    if (resp.statutConfirmation == 0) {
                        Notification.warning({
                            message: "La réservation de " + resp.nomOrganisateur + " n'est pas confirmée. Redirection vers la page des réservations à confirmer ",
                            title: "Réservation #" + resp.groupeId,
                            positionX: 'right',
                            positionY: 'top',
                            delay: 2500,
                            replaceMessage: true,
                            onClose: function () {
                            }
                        });

                        $location.search("groupeId", resp.groupeId);
                        $location.path("/ng1/reservations/nouvelles");
                    } else {
                        $location.search("listedate", moment(resp.partiesDate).format("YYYY-MM-DD"));
                        $location.search("groupeId", resp.groupeId);
                        if (resp.statutConfirmation !== 1) {
                            $location.search("afficherNonConfirmees", 1);
                        }
                        if (resp.estAnnule || resp.estEffacee) {
                            $location.search("voirTout", 1);
                        }
                        $location.path("/ng1/reservations");
                    }
                })
            } else {
                if (next.$$route.resolve && next.$$route.resolve.routePrivee == true) {
                    if (!Usagers.voirSettings() || true) {
                        //ev.preventDefault();
                        if (!curr || !curr.$$route.originalPath) {
                            console.log("Pas le droit", Usagers);
                            $location.path('/ng1');
                        } else {
                            // Rewrite rules
                            var newPath = curr.$$route.originalPath;
                            $location.path(newPath);
                        }
                    }
                }
            }


        }
    });

    $rootScope.$on('$routeChangeSuccess', function (ev, next, curr) {
        // Changer le titre de la page
        $window.document.title = Breadcrumb.pageTitle;
    });

    $translate.refresh();
});

d1App.controller('D1QuickViewCeduleCtrl', ['$scope', '$http', '$sce', '$timeout', '$interval', 'Reservation', 'Cedule', function ($scope, $http, $sce, $timeout, $interval, Reservation, Cedule) {
    $scope.Cedule = Cedule;

    $scope.getProchainePartie = function () {
        return Reservation.nextGameTime(moment()).format('HH:mm');
    };

    $scope.getPartieCourante = function () {
        return Reservation.actualGameTime(moment()).format('HH:mm');
    };

    $scope.afficherCedule = function (arena_id) {
        Cedule.afficherCedule(moment().toDate(), arena_id);
    };

    function getCedule() {
        $http.get('/api/cedules/next').then(function (response) {
            $scope.lstCedules = response.data;

            // Pour me simplifier la vie
            $scope.lstCedules.forEach(function (ts) {
                ts.groupeNombre = ts[0].groupeNombre;
            });

        }, function errorCallback(response) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.
        });
    }

    getCedule();

}]);

d1App.controller('D1ReservationsRechercheCtrl', ['$scope', '$timeout', 'Reservation', 'groupeTypes', 'sallesTypes', function ($scope, $timeout, Reservation, groupeTypes, sallesTypes) {

    $scope.reservation = { estAnnule: 0, estEfface: 0, parDateInsertion: 0 };
    //$scope.lstSalleTypes = Reservation.TypeSalles;
    //$scope.lstGroupeTypes = Reservation.TypeGroupes;
    $scope.lstGroupeTypes = groupeTypes;
    $scope.lstSalleTypes = sallesTypes;
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;
    $scope.lstGroupes = [];

    // Calendrier
    $scope.options = {
        //customClass: getDayClass,
        //minDate: $scope.dateDebut,
        showWeeks: false
    };

    $scope.viewInfo = function (info) {
        console.log(info);
    };

    $scope.ouvrirDD = function () {
        $scope.dd_ouvert = true;
    };

    $scope.ouvrirDF = function () {
        $scope.df_ouvert = true;
    };

    $scope.$watch('dateDebut', function (i) { });

    $scope.$watch('dateFin', function (i) { });

    $scope.loadTags = function (query) {
        return Reservation.listeTags(query);
    };

    $scope.rechercher = function () {
        $scope.lstGroupes = [];
        Reservation.rechercher($scope.reservation).then(function (resultat) {
            $scope.lstGroupes = resultat;
        }, function (err) {

        })
    };

}]);

d1App.controller('D1ReservationsCtrl', [
    '$scope', '$sce', 'Reservation', 'ReservationListe', 'listeReservations', 'groupeTypes', 'salleTypes', 'Cedule', 'ReservationUtil', 'Salles', 'Vestes', '$uibModal', 'Usagers', '$location', 'Notification', 'VisDataSet', 'D1ServiceVR', 'D1Configurations', '$roomService', '$messages', '$shopifyService', '$configService', '$toastService',
    function ($scope, $sce, Reservation, ReservationListe, listeReservations, groupeTypes, salleTypes, Cedule, ReservationUtil, Salles, Vestes, $uibModal, Usagers, $location, Notification, VisDataSet, D1ServiceVR, D1Configurations, $roomService, $messages, $shopifyService, $configService, $toastService) {
        $scope.r = { import: '', importSP: '' };
        $scope.showMessage = '';
        $scope.affichage = $sce.trustAsHtml('');
        $scope.erreurMessage = $sce.trustAsHtml('');
        $scope.hasError = false;
        $scope.Usagers = Usagers;
        $scope.ajouterCoordonnees = false;
        $scope.veutUneSalle = false;

        $scope.Configurations = D1Configurations;
        $scope.Vestes = Vestes;
        $scope.Salles = Salles;
        $scope.Reservation = Reservation;
        $scope.lstGroupes = listeReservations;
        $scope.numGroupeTotal = $scope.lstGroupes.length;
        $scope.lstSalleTypes = salleTypes;
        $scope.m = {}; // Fake sub object!
        $scope.m.dateReservation = moment(ReservationListe.dateReservation).toDate();
        $scope.m.voirTout = ReservationListe.voirTout;
        $scope.filtered = [];
        $scope.isNouvelles = ($location.path().search('nouvelles') >= 0);
        $scope.isDepot = ($location.path().search('depots') >= 0);
        $scope.isImpayee = ($location.path().search('impayee') >= 0);
        $scope.isAjouter = ($location.path().search('ajouter') >= 0);

        $scope.lstGroupeTypes = groupeTypes;
        $scope.features = [];

        // Liste des features        
        $configService.features.subscribe( f => {
            $scope.features = f;
        })
        
        $scope.montrerDate = 0;
        $scope.salleOptions = {
            timeStripZeroSeconds: true
        };
        
        $messages.SettingsMessage.subscribe( r => {
            D1Configurations.getTypeGroupes().then( r => {
                $scope.lstGroupeTypes = r
                groupeTypes = r
            })
        })

        // $scope.customColors = [ '#C25', '#E62', '#EA0', '#19F', '#333' ];

        $scope.customColors = D1Configurations.app ? D1Configurations.app.presetColors.split(',') : "";
        $scope.getBraceletCouleur = function (groupe) {
            return { 'background-color': groupe.braceletCouleur + ' !important' }
        }

        const arena = _.findWhere(D1Configurations.getArenas(), { arena_id: 1 });
        var rotationTime = (arena && arena.rotation_time ? arena.rotation_time : 20);
        $scope.arena = arena;

        // Groupeid en parametre
        var l = $location.search();
        var defGroupeId = (l.groupeId >= 0 ? l.groupeId : 0);
        $scope.m.afficherNonConfirmees = (l.afficherNonConfirmees ? l.afficherNonConfirmees == 1 : false);

        // On cache des choses
        $scope.cacherDate = ($scope.isNouvelles || $scope.isDepot || $scope.isImpayee);

        $scope.voirClient = function(id) {
            if(id) {
                $shopifyService.voirClient(id);
            }
        }

        // Afficher les disponibilités
        $scope.afficherDisponibilites = function () {
            let selDate = moment($scope.m.dateReservation).format('YYYY-MM-DD');
            let arena = $scope.arena
            if (!arena) {
                const arenaC = _.findWhere(D1Configurations.getArenas(), { arena_id: 1 });
                var rotationTime = (arenaC && arenaC.rotation_time ? arenaC.rotation_time : 20);
                arena = arenaC;
            }
            $uibModal.open({
                animation: true,
                template: `
                <div class="m-2">
                    <div ng-repeat="ceduleListe in listePartiesDisponibles track by 'id' | orderBy : 'id'">
                        <!-- Ligne de disponibilité -->
                        <div class="x_title bg-success">
                            <div class="center-block h4 ">Disponibilité pour {{ceduleListe.arena_name}} ({{ceduleListe.nombreVestesActives}} <span translate>vestes</span>)</div>
                        </div>
                        <div ng-repeat="ced in ceduleListe.cedule" class="dispo-row">
                            <div class="">
                                <span uib-tooltip="Il y a un total de {{ced.nbJoueurs}} joueurs répartis dans {{ced.nbGroupes}} groupes">
                                <span class="label label-info" ng-class="{'label-info' : ced.numDisponible > 20, 'label-warning' : (ced.numDisponible > 8 && ced.numDisponible < 20), 'label-danger' : ced.numDisponible <= 8}">
                                    {{ced.partieHeures}}
                                </span> &nbsp;:&nbsp;{{(ced.numDisponible)}} places
                                </span>
                            </div>
                        </div>
                    </div>
                </div>`,
                controller: ['listePartiesDisponibles', '$scope',
                    function (listePartiesDisponibles, $scope) {
                        var info = arena;
                        info.cedule = listePartiesDisponibles;
                        $scope.listePartiesDisponibles = [];
                        $scope.listePartiesDisponibles.push(info);
                    }
                ],
                size1: 'lg',
                resolve: {
                    listePartiesDisponibles: ['Cedule', function (Cedule) {
                        return Cedule.disponibilites(selDate, arena.arena_id);
                    }],

                }
            });
        }
        /**
    
         */

        // Fonctions
        function verifierCedules() {
            if (arena && arena.disable_overbooked === undefined || (arena && arena.disable_overbooked !== undefined && arena.disable_overbooked === false)) {
                // On va vérifier seulement si ce n'est pas dans le passé
                if (moment($scope.m.dateReservation).isSameOrAfter(moment())) {
                    Cedule.getListe($scope.m.dateReservation, -1).then(function (data) {
                        const l = _.filter(data, (c) => {
                            return (c.nombreVestes - c.nombreJoueur < 0);
                        })

                        if (l.length) {
                            // Il y a des parties dans le négatif!
                            var msg = "Les cédules suivantes ont trop de joueurs!<br>" + _.map(l, (c) => { return moment(c.ceduleTemps).format('HH:mm') }).join('<br>');
                            $toastService.showError("Des cédules ont plus de joueurs que disponibles!", msg);
                        }
                    });
                }
            }
        }

        if (!$scope.cacherDate) verifierCedules();

        function verifierParties() {
            $scope.erreurMessage = '';
            $scope.hasError = false;
            if (arena && arena.disable_overbooked === undefined || (arena && arena.disable_overbooked !== undefined && arena.disable_overbooked === false)) {
                _.each($scope.reservation.lstParties, (p) => {
                    // Afficher une erreur si on dépasse le nombre!
                    const numMax = Vestes.nombreVestes(p.arena_id);
                    if (p.ceduleNombre > numMax) {
                        $scope.hasError = true;
                        $scope.erreurMessage += `La partie de ${p.partieHeures} a trop de joueurs : ${p.ceduleNombre} / ${numMax}<br>`;
                    }
                })
            }
        }

        $scope.nombreJoueursChange = async function (partie) {
            $scope.ajusterPartieTemps(partie)
        }

        $scope.administrationNotes = function (r) {
            return Reservation.administrationNotes(r);
        };

        // Init recherche
        $scope.s = {
            groupeTypeId: -1,
            nomOrganisateur: ''
        };

        // Calendrier
        $scope.s.dd_ouvert = false;
        $scope.s.dr_ouvert = false;
        $scope.cal_options = {
            showWeeks: false
        };
        $scope.ouvrirRD = function () {
            $scope.s.dr_ouvert = true;
        };
        $scope.ouvrirDD = function () {
            $scope.s.dd_ouvert = true;
        };
        $scope.aujourdhui = function () {
            $scope.m.dateReservation = new Date();
            $scope.changerDate();
        };

        $scope.$watch("m.voirTout", function (i) {
            ReservationListe.voirTout = i;
        });

        $scope.getNumFiltres = function (numFiltres) {
            return $scope.lstGroupes.length - numFiltres;
        };


        $scope.filterSalleDisponibles = function (salle) {
            if (salle.disponible == 0 || (salle.disponible == 1 && salle.estDisponible == 0)) {
                return true;
            }
            return false;
        };

        $scope.filterSalleDisponibles2 = function (salle) {

            if ($scope.reservation) {
                // Easiest, toujours afficher si c'est utilisée par la réservation
                if ($scope.reservation.salles && $scope.reservation.salles[salle.salleId] == true) {
                    return true;
                }

                if (salle.disponible == 0 || (salle.disponible == 1 && salle.estDisponible == 0)) {
                    return false;
                }
            }

            return true;
        };

        $scope.filtrerPartieArena = function (arena_id) {
            if ($scope.reservation && $scope.reservation.lstParties) {
                return _.where($scope.reservation.lstParties, { arena_id: arena_id });
            } else {
                return [];
            }
        };

        // Force r
        $scope.ForceUnlock = function (r) {
            if (confirm("Êtes-vous vraiment certain de vouloir débarrer cette réservation?\nIl pourrait y avoir des pertes de données!")) {
                r.loading++;
                Reservation.unlockRecord(r).then(function (ret) {
                    r.userLockTime = null;
                    r.userLockToken = null;
                    r.loading--;
                }, (error) => {
                    console.error('ForceUnlock error', error)
                    r.loading--;
                })
            }
        };


        $scope.getGroupeTypeNom = function (groupeTypeId) {
            var nom = _.find($scope.lstGroupeTypes, function (item) {
                return item.groupeTypeId == groupeTypeId;
            });

            if (nom == undefined) {
                nom = { groupeTypeNom: 'Forfaits' };
            }

            return nom.groupeTypeNom;
        };

        $scope.$watch('filtered', function (lst) {
            if (lst.length > 0 && $scope.reservation && !$scope.reservation.loading) {
                // Trouver un item déjà sélectionné
                var sel = _.findWhere(lst, { selected: true });
                if (sel == undefined) {
                    if (lst[0].groupeId != $scope.reservation.groupeId) {
                        $scope.selectReservation(lst[0]);
                    }
                } else {
                    if (sel.groupeId !== $scope.reservation.groupeId) {
                        $scope.selectReservation(sel);
                    }
                }
            }
        });

        $scope.loadTags = function (query) {
            return Reservation.listeTags(query);
        };

        $scope.rafraichirSalles = function () {
            rafraichirSalles();
        }
        function rafraichirSalles() {
            if ($scope.reservation) {
                if ($scope.timeline) {
                    $scope.visoptions.start = moment($scope.reservation.event_date).subtract(0, "d").subtract(3, 'h');
                    $scope.visoptions.end = moment($scope.reservation.event_date).add(0, "d").add(3, 'h');
                    $scope.timeline.moveTo($scope.reservation.event_date);
                }
                if ($scope.reservation.groupeType && $scope.reservation.groupeType.requiertSalle > 0) {
                    var sd = moment($scope.reservation.salleDebut);
                    var sf = moment($scope.reservation.salleFin);
                    if (sd.isValid() && sf.isValid()) {
                        var gId = $scope.reservation.groupeId;
                        Salles.verifierDisponibilite($scope.reservation.salleDebut, $scope.reservation.salleFin, $scope.reservation.groupeId).then(function (data) {
                            if (gId == $scope.reservation.groupeId) {
                                $scope.lstSalleTypes = data;

                                var s = _.find(data, function (i) {
                                    return (i.salleId == $scope.reservation.salleReservee);
                                });

                                if (s && $scope.reservation.salleReservee && s.disponible == 0) {
                                    // le choix était sur une salle non-disponible, on enleve le choix par force
                                    $scope.changerSalle(s);
                                }
                            }
                        });
                    }
                } else {
                    // Forcer un INIT
                    $scope.retirerSalle($scope.reservation, true);
                }
            }
        }


        function doitSauvegarder() {
            var lstModifies = _.filter($scope.lstGroupes, function (item) {
                return item.estModifiee == true;
            });

            return lstModifies.length > 0;
        }

        $scope.doitSauvegarder = doitSauvegarder;

        function CreateLocking() {
            if ($scope.reservation.groupeId > 0 && ($scope.reservation.userLockToken == undefined || $scope.reservation.userLockToken == null || $scope.reservation.userLockToken.length == 0)) {
                $scope.reservation.userLockTime = null;
                $scope.reservation.userLockToken = '';
                Reservation.lockRecord($scope.reservation, Usagers.user.uniqueToken).then(function (ret) {
                    if (ret.status == 200) {
                        $scope.reservation.estModifiee = true;
                        $scope.reservation.initialesEmploye = '';
                        $scope.reservation.userLockTime = moment();
                        $scope.reservation.userLockToken = Usagers.user.uniqueToken;
                        Notification.info({
                            title: "Cette fiche est barrée pour vos modifications!",
                            message: `Si vous êtes certain que tout est sauvegardé, vous pouvez <i class="fa fa-unlock"></i> débarrer cette réservation`,
                            positionX: 'right',
                            positionY: 'top',
                            delay: 2500,
                            replaceMessage: true,
                            onClose: function () {
                            }
                        });
                    } else {
                        // Impossible de LOCK quelqu'un d'autre l'a prit
                        alert('Modification impossible!!! Vos données ne seront pas sauvegardées!!!');
                    }
                });
            }
        }

        $scope.hasChanged = function (event) {
            if ($scope.reservation && !$scope.reservation.loading) {
                $scope.reservation.estModifiee = true;
            }
        }


        // $scope.$watchCollection('reservation', triggerChange);
        $scope.$watch('reservation.estModifiee', (n, o) => {
            // Si on passe de "non-modifie" à "modifie" on start un lock
            if (o === false && n === true) {
                $scope.reservation.initialesEmploye = '';
                CreateLocking();
            }
        });


        $scope.$on('$locationChangeStart', function (event) {
            if (doitSauvegarder()) {
                // Il y a des données non sauvegardées!
                if (!confirm("Attention, vous allez perdre des données qui ne sont pas sauvegardées.\nÊtes-vous vraiment certain de vouloir quitter la page?")) {
                    event.preventDefault();
                }
            }

        });


        /**
         * Filtrer la liste selon les critères
         */
        $scope.filtrerListe = function (item, index, array) {
            var bOk = true;
            //if ($scope.s.nomOrganisateur.length || $scope.s.groupeTypeId.length) {
            if ($scope.s.nomOrganisateur.length || $scope.s.groupeTypeId < 0) {
                if ($scope.s.nomOrganisateur.length) {
                    if (item.nomOrganisateur.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().indexOf($scope.s.nomOrganisateur.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()) === -1) {
                        bOk = false;
                    }

                    // **********************************************************************************
                    // Attention si le texte  n'est pas l'organisateur alors cherchons dans le fete qui
                    // pourrait renverser la valeur de bOk
                    // **********************************************************************************
                    // Parcontre, ne pas aller plus loin s'il existe dans l'organisateur
                    if (bOk == false && item.nomFete.length) {
                        if (item.nomFete.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().indexOf($scope.s.nomOrganisateur.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()) !== -1) {
                            bOk = true;
                        }
                    }
                }

                // Vérifier le type de groupe
                //if(bOk && $scope.s.groupeTypeId.length) {
                if (bOk && $scope.s.groupeTypeId < 0) {
                    if ($scope.s.groupeTypeId == -2) {
                        // Chercher une "non-fête"
                        bOk = !item.groupeType.estFete;
                    } else if ($scope.s.groupeTypeId == -3) {
                        // Chercher une "fête"
                        bOk = (item.groupeType.estFete == 1);
                    }
                    /*
                    else if (item.groupeTypeId != $scope.s.groupeTypeId){
                        bOk = false;
                    }
                    */
                    else {
                        // Tous (-1)
                        bOk = true;
                    }
                }

            }

            // Filtrer par type de groupe
            if (bOk && $scope.s.groupeTypeId >= 0) {
                bOk = (item.groupeTypeId == $scope.s.groupeTypeId);
            }

            if (bOk) {
                // Toujours cacher les annulés par défaut
                bOk = (item.estAnnule == 0 || (item.estAnnule == 1 && $scope.m.voirTout));
            }

            if (bOk) {
                // Toujours cacher les no-show (sauf si tout voir)
                bOk = (item.statutGroupe != 3 || $scope.m.voirTout);
            }

            if (bOk) {
                // Toujours cacher les status non-confirmes (sauf si tout voir)
                bOk = (item.statutConfirmation == 1 || $scope.m.voirTout || $scope.m.afficherNonConfirmees || $scope.isNouvelles);
            }

            if (bOk) {
                // Toujours cacher les non-confirmees (sauf si tout afficherNonConfirmees)
                // bOk = (item.statutConfirmation != 1 || $scope.m.afficherNonConfirmees);
            }

            if (bOk) {
                // Ne pas cacher si on arrive avec des parametres de recherche
                if (defGroupeId && item.groupeId == defGroupeId) {
                    bOk = true;
                }
            }

            // Maintenant filtrer si on ne veut pas tout voir.
            if (bOk && $scope.m.voirTout == false && item.statutGroupe == 2 && (defGroupeId == 0 || (defGroupeId && item.groupeId != defGroupeId))) {

                // est-ce que c'est annulé?
                if (item.estAnnule == 1 || item.statutGroupe > 2) {
                    bOk = false;
                } else {
                    if (item.partiesDate.diff(moment(), 'minutes') < -60) {
                        // Buffer dépassé!
                        bOk = false;
                    } else {
                        // Item dans le futur
                        bOk = true;
                    }
                }

            }

            return bOk;
        };

        $scope.filtrerGroupeType = function (item, index, array) {
            if ($scope.isNouvelles && !item.estDisponible) {
                return false;
            }

            return true;

        };

        $scope.ajusterPartieTemps = function (p) {
            p.partieHeures = moment(p.partieTemps).format("HH:mm");
            Reservation.modifierPartie($scope.reservation, p).then(function () {
                if (p.nombreJoueurs_Copy && (p.nombreJoueurs_Copy !== p.nombreJoueurs)) {
                    // On va manellement modifier le nombre de vestes selon la différence
                    p.ceduleNombre -= (p.nombreJoueurs_Copy - p.nombreJoueurs)
                }
                verifierParties()
            });
            $scope.reservation.estModifiee = true;
        };

        $scope.rafraichir = function () {
            var bOk = (!doitSauvegarder());
            if (!bOk) {
                // Attention, on va perdre des données
                bOk = confirm("Attention, vous allez perdre des données qui ne sont pas sauvegardées.\nÊtes-vous vraiment certain?")
                if (bOk) {
                    // On force le unlcok
                    var lstModifies = _.filter($scope.lstGroupes, function (item) {
                        return item.estModifiee == true;
                    });

                    let promises = [];
                    for (const r of lstModifies) {
                        r.loading++;
                        promises.push(new Promise((resolve, reject) => {
                            Reservation.unlockRecord(r).then(function (ret) {
                                r.userLockTime = null;
                                r.userLockToken = null;
                                r.loading--;
                                r.estModifiee = false;
                                resolve(true)
                            }, (error) => reject(error))
                        }));
                    }

                    Promise.all(promises).then(() => {
                        $scope.rafraichir();
                    })
                    // if ($scope.reservation.groupeId > 0) {
                    //     $scope.reservation.loading++;
                    //     Reservation.unlockRecord($scope.reservation).then(function(ret) {
                    //         $scope.reservation.userLockTime = null;
                    //         $scope.reservation.userLockToken = null;
                    //         $scope.reservation.loading--;
                    //     })
                    // }
                }
            } else if (bOk) {
                var bRefresh = false;
                var selectedId = ($scope.reservation ? $scope.reservation.groupeId : null);
                ReservationListe.getListe($scope.m.dateReservation).then(function (data) {
                    $scope.lstGroupes = data;
                    $scope.numGroupeTotal = data.length;

                    if ($scope.lstGroupes.length) {
                        if (selectedId) {
                            angular.forEach($scope.lstGroupes, function (item, index) {
                                if (item.groupeId == selectedId && !bRefresh) {
                                    $scope.selectReservation($scope.lstGroupes[index]);
                                    var el = document.getElementById("groupe-" + selectedId);
                                    if (el) {
                                        el.scrollIntoViewIfNeeded(true);
                                    }
                                    bRefresh = true;
                                }
                            });
                        }
                    } else {
                        $scope.reservation = undefined;
                    }

                    // Appeler la vérification pour les parties
                    verifierCedules();
                }),
                    function (err) {
                        console.error("Erreur", err);
                    };
            }
        };

        /**
         * On change la date actuelle
         * @param e
         */
        $scope.changerDate = function (e) {
            var bOk = (!doitSauvegarder());
            if (!bOk) {
                // Attention, on va perdre des données
                bOk = confirm("Attention, vous allez perdre des données qui ne sont pas sauvegardées.\nÊtes-vous vraiment certain?")
                if (bOk) {
                    if ($scope.reservation.groupeId > 0) {
                        $scope.reservation.loading++;
                        Reservation.unlockRecord($scope.reservation).then(function (ret) {
                            $scope.reservation.userLockTime = null;
                            $scope.reservation.userLockToken = null;
                            $scope.reservation.loading--;
                        })
                    }
                }
            }
            if (bOk) {
                //$scope.nouvelleReservation();
                $scope.m.dateReservation = e.toDate();
                $location.search('listedate', moment($scope.m.dateReservation).format("YYYY-MM-DD"));

                // On doit enlever les sous-critères indésirables.
                $location.search('groupeId', null);
                $location.search('afficherNonConfirmees', null);
            };
        }

        $scope.changerSalle = function (salle) {
            var mForm = this.myForm;

            $scope.reservation.estModifiee = true;
            if ($scope.reservation.salles[salle.salleId] == undefined) {
                $scope.reservation.salles[salle.salleId] = true;
            } else {
                $scope.reservation.salles[salle.salleId] = undefined;
            }

            // Petite validation
            var salleSelected = _.filter($scope.reservation.salles, function (s) { return s == true });
            if (salleSelected == undefined || salleSelected.length == 0) {
                mForm.$setValidity("required", false, $scope.reservation.salles);
            } else {
                mForm.$setValidity("required", true, $scope.reservation.salles);
            }

            var lstSalleNom = [];
            $scope.reservation.sallesSelectionnees = [];
            _.each($scope.reservation.salles, function (s, i) {
                if (s == true) {
                    var gt = _.findWhere(D1Configurations.TypeSalles, { salleId: i });
                    if (gt) {
                        lstSalleNom.push(gt.salleNom);
                        $scope.reservation.sallesSelectionnees.push(gt);
                    }
                }
            });
            $scope.reservation.salleNom = lstSalleNom.join(',');
            $scope.reservation.estModifiee = true;
        };

        $scope.multiSalles = function () {
            // Petite validation
            if ($scope.reservation) {
                var salleSelected = _.filter($scope.reservation.salles, function (s) {
                    return s == true
                });
                return (salleSelected.length > 1);
            }

            return 0;
        };

        $scope.retirerSalle = function (r, interne) {
            // On enleve les infos
            r.salleReservee = 0;
            r.salleNom = '';
            r.salle = {};
            r.salleDebut = null;
            r.salleFin = null;
            if (!interne) {
                r.estModifiee = true;
            }
        };

        $scope.timePickerOptions = {
            'disableTimeRanges1': [
                ['12am', '9:59am'],
                ['3am', '4:01am']
            ],
            //'scrollDefault': 'now',
            'step': rotationTime,
            'forceRoundTime': true,
            'timeFormat': 'H:i'
            // 'minTime' : '10:00',
            // 'maxTime' : '23:59'
        };

        $messages.ReservationMessage.subscribe(r => {
            var bRefresh = false;

            // Cas spécial lors d'un changement de journée de la réservation
            const eventGroupe = _.findWhere($scope.lstGroupes, { groupeId: r.groupeId })
            if (eventGroupe && eventGroupe.estModifiee === false && !$scope.isNouvelles && !$scope.isDepot && !$scope.isImpayee) {
                // C'est un groupe de notre liste
                if (moment($scope.m.dateReservation).format("DD-MM-YYYY") !== moment(eventGroupe.reservationDate).format("DD-MM-YYYY")) {
                    //if ($scope.reservation && $scope.reservation.groupeId === r.groupeId) 
                    //$scope.reservation = undefined;
                    $scope.rafraichir();
                    return;
                }
            }

            // Ne pas perdre de temps si c'est pour une date qu'on ne regarde pas.
            if (moment(r.reservationDate).format("DD-MM-YYYY") == moment($scope.m.dateReservation).format("DD-MM-YYYY") || $scope.isNouvelles || $scope.isDepot || $scope.isImpayee) {

                // Quitter drette la si c'est nous
                if (r.userLockToken != Usagers.user.uniqueToken) {

                    // Est-ce que le user est directement sur la fiche qu'un autre utilisateur modifie?
                    //if ($scope.reservation && r.groupeId == $scope.reservation.groupeId && r.userLockToken != Usagers.user.uniqueToken) {
                    if ($scope.reservation && (r.groupeId == $scope.reservation.groupeId)) {
                        // Faire un refresh automatique
                        Reservation.chargerReservation($scope.reservation.groupeId).then(function (item) {
                            // On undefined pour ne pas génrérer un "$watchCollection"
                            //$scope.reservation = undefined;
                            Reservation.parseFromDB(item, true).then(function (t) {
                                t.selected = true;
                                t.reloading = true;
                                //t.loading = 1;
                                $scope.reservation = t;
                                angular.forEach($scope.lstGroupes, function (item, index) {
                                    if (item.groupeId == r.groupeId && !bRefresh) {
                                        $scope.lstGroupes[index] = t;
                                        bRefresh = true;
                                    }
                                });
                                //$scope.reservation.loading = 0;
                            });
                        });
                    } else {
                        bRefresh = false;
                        angular.forEach($scope.lstGroupes, function (item, index) {
                            if (item.groupeId == r.groupeId && !bRefresh && r.userLockToken != Usagers.user.uniqueToken) {
                                Reservation.chargerReservation(item.groupeId).then(function (item) {
                                    Reservation.parseFromDB(item, true).then(function (t) {
                                        $scope.lstGroupes[index] = t;
                                        // En train de modifier on doit indiquer à l'usager de reloader
                                        Notification.info({
                                            message: "Réservation de " + r.nomOrganisateur + " modifiée. ",
                                            title: "Réservation #" + r.groupeId,
                                            positionX: 'right',
                                            positionY: 'top',
                                            delay: 2500,
                                            replaceMessage: true,
                                            onClose: function () {
                                            }
                                        });
                                    });

                                });
                                bRefresh = true;
                            }
                        });

                        // Ne pas reloader la liste si on est dans l'onglet /nouvelles
                        if (!bRefresh && $scope.isNouvelles == false && $scope.isDepot == false && $scope.isImpayee === false) {
                            // Soit que l'item est effacé ou encore que c'est une nouvelle réservation.
                            var bOk = (!doitSauvegarder());
                            var msg = "";
                            if (bOk) {
                                // Rafraichir si l'usager ne modifie pas.
                                $scope.rafraichir();
                                // En train de modifier on doit indiquer à l'usager de reloader
                                msg = "Nouvelle réservation insérée. " + r.nombreJoueurs + " joueurs pour " + r.nombreParties + " parties (" + r.lstPartiesSplit + ")";
                                msg += "<br>Passes : ";
                                _.each(r.lstParties, function (p) {
                                    var pc = Cedule.getPasseCouleur(p.partieHeures);
                                    var pn = Cedule.getPasseCouleurNom(p.partieHeures);
                                    msg += '<span class="label label-' + pc + '">' + (p.nombreJoueurs != undefined ? p.nombreJoueurs : r.nombreJoueurs) + ' ' + pn + '</span>';
                                });
                                Notification.info({
                                    message: msg,
                                    title: "Réservation à " + moment(r.event_date).format("H:mm") + " pour " + r.nomOrganisateur,
                                    positionX: 'right',
                                    positionY: 'top',
                                    delay: 10000,
                                    replaceMessage: true,
                                    onClose: function () {
                                    }
                                });
                            } else {
                                // En train de modifier on doit indiquer à l'usager de reloader
                                msg = "Des changements ont eu lieu par un autre utilisateur dans les réservations, veuillez rafraîchir vos données";
                                Notification.warning({
                                    message: msg,
                                    title: "Réservations pour le " + moment(r.event_date).format("DD MMM"),
                                    positionX: 'center',
                                    positionY: 'top',
                                    delay: 5000,
                                    replaceMessage: true,
                                    onClose: function () {
                                    }
                                });
                            }
                        }
                    }
                }
            } 
        });


        $scope.nombreJoueursModifie = function (myVar) {
            $scope.renommerGroupe();
            if (!isNaN($scope.reservation.nombreJoueurs)) {
                //var oldValue = $('#nombreJoueurs').data('old-value');
                var oldValue = $scope.reservation.nombreJoueurs_Copy;
                var delta = $scope.reservation.nombreJoueurs - oldValue;
                $scope.reservation.lstParties.forEach(function (p, i) {
                    // Ajuster le nombre de joueurs dans les parties
                    // ** SEULEMENT SI LE NOMBRE EST IDENTIQUE A L'ANCIENNE!!!
                    if (p.nombreJoueurs == oldValue || p.nombreJoueurs == undefined || isNaN(oldValue)) {
                        p.nombreJoueurs = $scope.reservation.nombreJoueurs;
                        p.warning = false;
                    } else {
                        $scope.hasError = true;
                        $scope.erreurMessage = $sce.trustAsHtml("ATTENTION! DES PARTIES ONT DES JOUEURS DIFFÉRENTS! RÉAJUSTEZ MANUELLEMENT!!! p.nombreJoueurs=" + p.nombreJoueurs + " oldValue=" + oldValue);
                        p.warning = true;
                    }
                    //$('#nombreJoueurs').data('old-value', $scope.reservation.nombreJoueurs);
                    $scope.reservation.nombreJoueurs_Copy = $scope.reservation.nombreJoueurs;
                });
                //$scope.reservation.initialesEmploye = Usagers.usager.initiales;
                $scope.reservation.estModifiee = true;
            } else {
                // Remettre l'ancienne "bonne" valeur
                //var oldValue = $('#nombreJoueurs').data('old-value');
                //$scope.reservation.nombreJoueurs = oldValue;
            }

        };


        $scope.nouvelleReservation = function () {
            $scope.selectReservation(Reservation.creer());
        };

        $scope.renommerGroupe = function () {
            if ($scope.reservation.nomOrganisateur) {
                if (!$scope.reservation.titreGroupeManuel && $scope.reservation.nomOrganisateur.trim().length) {
                    var words = $scope.reservation.nomOrganisateur.trim().split(" ");
                    if (words.length) {
                        $scope.reservation.groupeTitre = words[0];
                    } else {
                        $scope.reservation.groupeTitre = "";
                    }

                    $scope.reservation.groupeTitre += " (" + $scope.reservation.nombreJoueurs + ")";
                }
                $scope.reservation.estModifiee = true;
            }
        };

        $scope.groupeTitreManuel = function () {
            $scope.reservation.titreGroupeManuel = true;
        };

        $scope.timelineChange = function(noteList) {
            $scope.reservation.noteList = noteList;
            Reservation.patchReservation($scope.reservation.groupeId, {noteList : JSON.stringify(noteList)})
        }

        $scope.selectReservation = function (reservation) {
            $scope.showMessage = "";

            // Auto-find la bonne réservation
            if (!reservation) {
                // Validation
                if ($scope.lstGroupes.length == 0) {
                    return;
                }

                // Positionner sur la bonne réservation
                var gr = -1;
                _.each($scope.lstGroupes, function (g, i) {
                    if (defGroupeId > 0) {
                        if (g.groupeId == defGroupeId) {
                            gr = i;
                        }
                    } else if (g.estEfface == 0 && g.estAnnule == 0 && gr > -1) {
                        gr = i;
                    }
                });

                // Catch all
                if (gr == -1) gr = 0;

                reservation = $scope.lstGroupes[gr];
            }

            if ($scope.reservation && $scope.reservation.$dirty) {
                $scope.showMessage = "Sauvegardez avant de changer";
            } else {

                $scope.hasError = false;
                $scope.erreurMessage = $sce.trustAsHtml("");
                $scope.pending++;
                $scope.veutUneSalle = false;

                if ($scope.reservation) {
                    // Enlever la sélection précédente
                    //$scope.reservation.modifie = this.myForm.$dirty;
                    $scope.reservation.selected = false;
                }
                // Activer la sélection
                reservation.selected = true;
                if (!reservation.options) {
                    reservation.options = [];
                }
                if (!reservation.lstOptions) {
                    reservation.lstOptions = [];
                }

                // Timeline
                if (!reservation.noteList) {
                    reservation.noteList = []
                }

                $scope.reservation = reservation;
                var r = $scope.reservation;
                r.loading++;

                // Ajuster les types de forfaits
                $scope.lstGroupeTypes = [];
                _.each(groupeTypes, function (gt) {
                    if (gt.estDisponible) {
                        $scope.lstGroupeTypes.push(gt);
                    } else {
                        // Forfait désactivé, c'est plus spécial
                        if (reservation.groupeId != 0 && gt.groupeTypeId == reservation.groupeTypeId) {
                            $scope.lstGroupeTypes.push(gt);
                        }
                    }
                });

                if (r.groupeType) {
                    $scope.ajouterCoordonnees = ((r.groupeType.requiertCoordonnes == 1) || r.courriel.length > 0 || r.telephone.length > 0);

                    if (!$scope.reservation.salleDebut || moment($scope.reservation.salleDebut).format('YYYY-MM-DD') != $scope.reservation.partiesDate.format('YYYY-MM-DD')) {
                        $scope.reservation.salleDebut = Reservation.reajustTime($scope.reservation.partiesDate).toDate();
                        $scope.reservation.salleFin = moment($scope.reservation.salleDebut).add($scope.reservation.groupeType.minimumTemps, 'm').toDate();
                        var diffLen = $scope.reservation.lstParties.length - $scope.reservation.groupeType.nombreParties;
                        if (diffLen) {
                            // Ajouter du temps de salle
                            if (diffLen == 1) {
                                $scope.reservation.salleFin = moment($scope.reservation.salleFin).add(30, 'm').toDate();
                            } else if (diffLen == 2) {
                                $scope.reservation.salleFin = moment($scope.reservation.salleFin).add(60, 'm').toDate();
                            } else if (diffLen == 3) {
                                $scope.reservation.salleFin = moment($scope.reservation.salleFin).add(90, 'm').toDate();
                            }
                        }
                    }
                }

                // Salles
                rafraichirSalles();

                $scope.pending--;
                r.loading--;
            }
        };

        $scope.afficherReservation = function (e) {
            console.log(e);
        };

        $scope.changerGroupeType = function (gr) {
            Reservation.changerGroupeType(gr)
            // $scope.reservation.groupeTypeId = gr.groupeTypeId.toString();
            // $scope.reservation.nombreJoueurs = gr.minimumJoueurs;
            // $scope.reservation.nombreParties = gr.nombreParties;
            // $scope.calculateGames($scope.reservation);

            if (gr.requiertSalle == 0) {
                // S'assurer qu'on initialise les champs
                $scope.retirerSalle($scope.reservation, false)
            } else {
                rafraichirSalles();
            }
        };

        $scope.changerStatutGroupe = function (r) {
            Reservation.changerStatutGroupe(r);
        };

        $scope.effacerReservation = function (r) {
            var mForm = this.myForm;
            var myThis = this;
            if (!r.estEfface) {
                if (confirm('Êtes-vous certain de vouloir effacer cette réservation?')) {
                    Reservation.administrationNotes(r, 3).then(function (resp) {
                        mForm.$setPristine();
                        r = resp;
                        r.estModifiee = false;
                        r.initialesEmploye = Usagers.usager.initiales;
                        Reservation.effacerReservation(r);
                        $scope.showMessage = "Réservation de " + r.nomOrganisateur + " a été effacé avec succès";
                        $scope.changerDate(myThis);
                    });
                }
            } else {
                if (confirm('Êtes-vous certain de vouloir remettre cette réservation active?')) {
                    Reservation.administrationNotes(r, 4).then(function (resp) {
                        mForm.$setPristine();
                        r = resp;
                        r.estModifiee = false;
                        r.initialesEmploye = Usagers.usager.initiales;
                        Reservation.effacerReservation(r);
                        $scope.showMessage = "Réservation de " + r.nomOrganisateur + " a été effacée avec succès";
                        $scope.changerDate(myThis);
                    });
                }
            }
        };

        $scope.annulerReservation = function (r) {
            var mForm = this.myForm;
            var myThis = this;

            if (!r.estAnnule) {
                if (confirm('Êtes-vous certain de vouloir annuler cette réservation?')) {
                    Reservation.administrationNotes(r, 1).then(function (resp) {
                        mForm.$setPristine();
                        r = resp;
                        r.estModifiee = false;
                        r.initialesEmploye = Usagers.usager.initiales;
                        Reservation.annulerReservation(r);
                        $scope.showMessage = "Réservation de " + r.nomOrganisateur + " a été annulé avec succès";
                        $scope.changerDate(myThis);
                    });

                }
            } else {
                if (confirm('Êtes-vous certain de vouloir remettre cette réservation active?')) {
                    Reservation.administrationNotes(r, 2).then(function (resp) {
                        //this.myForm.$setPristine();
                        mForm.$setPristine();
                        r = resp;
                        r.estModifiee = false;
                        r.initialesEmploye = Usagers.usager.initiales;
                        Reservation.annulerReservation(r);
                        $scope.showMessage = "Réservation de " + r.nomOrganisateur + " remise active avec succès";
                        $scope.changerDate(myThis);
                    });
                }

            }
        };


        /**
         * Fonction qui calcule les heures selon le forfait
         *
         * @param reservation
         */
        $scope.ajusterParties = async function (r) {

            if (r.event_date) {
                r.estModifiee = true;
                r.event_date = moment(moment(r.event_date).format("YYYY-MM-DD " + moment(r.event_time).format("HH:mm"))).toDate();
                r.event_time = r.event_date;
                r.partiesDate = moment(r.event_date);
                r.reservationDate = moment(r.event_date).toDate();
                await Reservation.ajusterParties(r);

                await Reservation.calculerSalle(r);

                // Salles
                await rafraichirSalles();
                await Salles.getListe(r.event_date).then(function (data) {
                    //$scope.listeSalles = data;
                    preparerVisuel(data);
                });

            }
        };

        $scope.ajusterTempsParties = async function (r) {
            if (r.event_time) {
                r.estModifiee = true;
                var shiftGames = true, shiftSalles = true;
                if ($scope.reservation.lstParties.length > 0 || $scope.reservation.salleReservee) {
                    try {
                        var reponse = await Reservation.showReajustementDialog($scope.reservation);
                        shiftGames = (reponse.shiftGames !== true ? false : true)
                        shiftSalles = (reponse.shiftSalles !== true ? false : true)
                    } catch (error) {
                        // Ne rien faire
                    }
                }

                // Quel est "l'offset" en minutes de la nouvelle heure sélectionnée comparée à celle dans la bd
                var diffMinutes = moment(r.event_time).diff($scope.reservation.event_date, 'minutes');
                $scope.reservation.event_date = r.event_time;

                if (shiftGames) {
                    $scope.reservation.partiesDate = r.event_time;
                    $scope.reservation.lstParties.forEach(async (p) => {
                        p.partieTemps = moment(p.partieTemps).add(diffMinutes, 'm').toDate();
                        p.partieHeures = moment(p.partieTemps).format('HH:mm');

                        // Faire la logique des parties
                        await Reservation.modifierPartie($scope.reservation, p).then(() => verifierParties());
                    })

                    // Aller prendre la valeur de la première partie
                    if ($scope.reservation.lstParties.length) {
                        $scope.reservation.partiesDate = $scope.reservation.lstParties[0].partieTemps;
                    }
                }

                if (shiftSalles) {
                    // Rafraichir les salles de fête
                    $scope.reservation.salleDebut = moment($scope.reservation.salleDebut).add(diffMinutes, 'm').toDate();
                    $scope.reservation.salleFin = moment($scope.reservation.salleFin).add(diffMinutes, 'm').toDate();
                    rafraichirSalles();
                }

            }
        };

        /**
         * Fonction qui calcule les heures selon le forfait
         *
         * @param reservation
         */
        $scope.calculateGames = function (r) {

            r.partiesDate = moment(r.event_date);
            if (r.lstParties.length == 0 || (r.event_date != r.lstParties[0].partieTemps) || true) {
                if (confirm("Voulez-vous recalculer toutes les parties?\nSi vous avez ajouté des parties manuellement elles seront perdues")) {
                    // On ne veut pas changer le nombre de joueurs
                    var copieNbJoueurs = r.nombreJoueurs;
                    Reservation.calculateGames(r);
                    r.nombreJoueurs = copieNbJoueurs;
                } else {
                    Reservation.ajusterParties(r);
                }
                Reservation.calculerSalle(r);

            }
        };

        $scope.sauvegarderReservationSP = function () {
            angular.forEach($scope.lstGroupes, function (r) {
                Reservation.ajouterReservation(r).then(function (data) {
                    if (r.groupeId) {
                        $scope.showMessage = "Nouvelle réservation sauvegardée #" + data.groupeId;
                    } else {
                        $scope.showMessage = "Réservation sauvegardée avec succès #" + data.groupeId;
                    }
                });
            });
        };

        /**
         * Fonction qui insère une réservation
         *
         * @param reservation
         */
        $scope.ajouterReservation = function (r) {
            var myForm = this.myForm;
            if (r.groupeId) {
                Reservation.modifierReservation($scope.reservation).then(function (data) {
                    $toastService.showSuccess("<a href='/reservations/" + data.groupeId + "'>" + data.nomOrganisateur + "</a> pour le " + moment(data.partiesDate).format("DD MMM, YYYY à HH:mm"), "<i class='fa fa-2x fa-check-circle'></i> Réservation sauvegardée #" + data.groupeId)
                    $scope.reservation.userLockTime = null;
                    $scope.reservation.userLockToken = null;

                    // Make it last!
                    r.estModifiee = false;
                }, function (err) {
                    // Erreur!
                    $scope.hasError = true;
                    if (Array.isArray(err)) {
                        $scope.erreurMessage = $sce.trustAsHtml(err.join('<br />'));
                    } else {
                        $scope.erreurMessage = $sce.trustAsHtml(err);
                    }
                });
            } else {
                Reservation.ajouterReservation($scope.reservation).then(function (data) {
                    //$scope.showMessage = "Nouvelle réservation sauvegardée";
                    $scope.showMessage = "Nouvelle réservation sauvegardée #" + data.groupeId + " : <a href='/reservations/" + data.groupeId + "'>" + data.nomOrganisateur + "</a> pour le " + moment(data.partiesDate).format("DD MMM, YYYY à HH:mm");
                    myForm.$setPristine();
                    r.estModifiee = false;
                    $scope.reservation.userLockTime = null;
                    $scope.reservation.userLockToken = null;

                    if ($scope.isAjouter) {
                        $location.search('listedate', moment($scope.reservation.event_date).format("YYYY-MM-DD"));
                        $location.search('groupeId', data.groupeId);
                        $location.path("/ng1/reservations/");
                    }
                }, function (err) {
                    // Erreur!
                    $scope.hasError = true;
                    if (Array.isArray(err)) {
                        $scope.erreurMessage = $sce.trustAsHtml(err.join('<br />'));
                    } else {
                        $scope.erreurMessage = $sce.trustAsHtml(err);
                    }
                });
            }
        };

        /**
         * Ajuster le nombre de parties
         */
        $scope.ajusterNombreParties = function () {
            var r = $scope.reservation;
            if (r) {
                var a = r.lstParties.length;
                var l = r.nombreParties;
                if (l > a) {
                    // Ajouter des parties dans la liste
                    for (var i = a; i < l; i++) {
                        $scope.ajouterPartie(r);
                    }
                } else if (l < a) {
                    // Effacer des parties
                    for (var i = a; i > l; i--) {
                        $scope.effacerPartie(i - 1);
                    }
                }
            }
        };


        /**
         * Ajout d'une partie et accrocher un timepicker
         * @param e
         */
        $scope.ajouterPartie = function (e) {
            Reservation.ajouterPartie($scope.reservation).then(function (data) {
                if ($scope.reservation.groupeType.requiertSalle) {
                    var diffLen = $scope.reservation.lstParties.length - $scope.reservation.groupeType.nombreParties;
                    if (diffLen > 0) {
                        // Ajuster le temps de salle selon la dernière partie.
                        var i = $scope.reservation.lstParties.length;
                        $scope.reservation.salleFin = moment($scope.reservation.lstParties[i - 1].partieTemps).add(30, 'm').toDate();
                    }
                }
            });
        };

        /**
         * Soustraction d'une partie
         * @param i
         */
        $scope.effacerPartie = function (p) {
            Reservation.effacerPartie($scope.reservation, p);
        };

        $scope.copyToClipboard = function (copyText) {
            navigator.clipboard.writeText(copyText);
            $toastService.showSuccess("Copie", "<i class='fa fa-2x fa-check-circle'></i> Texte copié en mémoire")
        };

        $scope.cancelModifications = function () {
            var myForm = this.myForm;
            var gid = $scope.reservation.groupeId;
            var idx = 0;

            var r = _.find($scope.lstGroupes, function (gr, i) {
                idx = i;
                return (gr.groupeId == gid);
            });
            if (r) {
                if (confirm('Vous allez tout perdre, êtes-vous certain?')) {
                    myForm.$setPristine();
                    $scope.reservation.estModifiee = false;
                    $scope.veutUneSalle = false;

                    if (gid > 0) {
                        $scope.reservation.loading++;
                        Reservation.unlockRecord($scope.reservation).then(function (ret) {
                            $scope.reservation.userLockTime = null;
                            $scope.reservation.userLockToken = null;
                            ReservationListe.getGroupe(gid).then(function (data) {
                                $scope.lstGroupes[idx] = data;
                                $scope.reservation.loading--;
                                $scope.selectReservation(data);
                            });
                        }, (error) => {
                            console.error("cancelModifications()", error);
                            $scope.reservation.loading--;
                        })
                    }

                }
            }
        };

        $scope.envoyerCourriel = function () {
            Reservation.envoyerConfirmation($scope.reservation).then(r => {
                // Il est possible durant le courriel que les tags + confirmation changent. On les copies ici.
                $scope.reservation = { ...$scope.reservation, ...r }
            });
        };

        $scope.afficherCedule = function (arena_id) {
            Cedule.afficherCedule($scope.m.dateReservation, arena_id, true);
        };


        $scope.afficherCeduleSalles = function (curDate) {
            $roomService.voirSallesDisponibilites(false, $scope.isAjouter ? $scope.reservation.event_time : curDate ? curDate : $scope.m.dateReservation)
        };

        //$scope.nouvelleReservation();

        // Init première sélection
        $scope.selectReservation();
        /*
        if ($scope.lstGroupes.length) {
            var gr = 0;
            _.each($scope.lstGroupes, function(g, i) {
                if (defGroupeId > 0) {
                    if (g.groupeId == defGroupeId) {
                        gr = i;
                    }
                }
                else if (g.estEfface == 0 && g.estAnnule == 0 ) {
                    gr = i;
                }
            });
    
            $scope.selectReservation($scope.lstGroupes[gr]);
        }
        */

        $scope.changerDepot = function () {
            $scope.reservation.estModifiee = true;
            $scope.reservation.depotFait = !$scope.reservation.depotFait;

            if ($scope.reservation.depotFait && !$scope.reservation.depotMontant) {
                $scope.reservation.depotMontant = parseFloat($scope.reservation.groupeType.minimumDepot).toFixed(2);
            }
        };

        /**
         * Importer de shiftplanning (json)
         */
        $scope.importerReservationSP = function () {
            var t = ReservationUtil.parseEventFromSP($scope.r.importSP);
            if (Array.isArray(t)) {
                $scope.lstGroupes = t;
                $scope.selectReservation(t[0]);
            } else {
                if (t) {
                    $scope.selectReservation(t);
                }
            }
        };

        /**
         * Importer du web
         */
        $scope.importerReservation = function () {
            var t = ReservationUtil.parseEventFromWeb($scope.r.import);
            if (t) {
                $scope.selectReservation(t);
            }
        };

        /**
         * Section pour ajouter des features
         * @param type (1 == Lasertgag, 2 == VR)
         */
        $scope.ajouterPartieType = function (type) {

            if (type == 1) {
                $scope.ajouterPartie()
            } else if (type == 2) {
                // VR
                $scope.ajouterVR()
            }
        };

        $scope.ajouterVR = function () {
            var newPartie = {};

            if ($scope.reservation.nombreJoueurs == 0) {
                $scope.reservation.nombreJoueurs = 1;
            }
            newPartie.partieTemps = moment($scope.reservation.event_date).format("YYYY-MM-DD H:mm:00");
            newPartie.nombreJoueurs = $scope.reservation.nombreJoueurs;
            newPartie.partieDuree = 15;
            newPartie.lstVRStations = [];
            $scope.reservation.lstVR.push(newPartie);
            $scope.reservation.estModifiee = true;
        };

        $scope.effacerVR = function (i) {
            if ($scope.reservation.lstVR[i]) {
                //$scope.reservation.lstVR.splice(i,1);
                $scope.reservation.lstVR[i].estEffacee = true;
                $scope.reservation.estModifiee = true;
            }
        };

        $scope.DeseffacerVR = function (i) {
            if ($scope.reservation.lstVR[i]) {
                //$scope.reservation.lstVR.splice(i,1);
                $scope.reservation.lstVR[i].estEffacee = false;
                $scope.reservation.estModifiee = true;
            }
        };

        $scope.ajouterSalle = function () {
            $scope.veutUneSalle = true;
            $scope.reservation.salleReservee = 0;
            $scope.reservation.salleDebut = Reservation.reajustTime($scope.reservation.partiesDate).toDate();
            $scope.reservation.salleFin = moment($scope.reservation.salleDebut).add($scope.reservation.groupeType.minimumTemps, 'm').toDate();
            $scope.changerSalle();
        };

        $scope.updateVRAvailability = function (p) {
            p.lstVRStations = [];
            var synthOptions = {
                people: p.nombreJoueurs,
                date: moment($scope.reservation.event_date).format("YYYY-MM-DD"),
                duration: p.choixDuree,
                experience: 1
            };
            p.partieDuree = p.choixDuree;
            D1ServiceVR.getAvailability(synthOptions).then(function (response) {
                p.lstVRStations = response.times;
            });

        };

        $scope.voirHistorique1 = function (item) {
            var dlg = $uibModal.open({
                templateUrl: 'partials/historique.tpl.html',
                controller: 'ModalHistoriqueCtrl',
                size: 'lg', //'sm','lg'
                windowClass: 'validation-modal',
                resolve: {
                    reservation: function () {
                        return item;
                    }
                }
            });
            dlg.result.then(function () {
                // CLOSE FUNCTION
            }, function (selectedItem) {
                // DISMISS FUNCTION
            });
        };

        $scope.filtrerAttributs = function (item) {
            if (item) {
                return item.parentAttributId == null;
            } else {
                return false;
            }
        };

        /**
         * Salle Visual Timeline
         */
        $scope.visdata = { groups: [], items: [] };
        $scope.refreshing = 0;
        $scope.timeline = undefined;

        $scope.visevents = {
            'onload': function (timeline) {
                // Garder une référence au timeline pour passer des infos.
                $scope.timeline = timeline;
            },
            'rangechange': function (event) {
            },
            'rangechanged': function (event) {
                if (event.byUser && $scope.refreshing == 0) {
                    var diff = 0;
                    if ($scope.dateFin != moment(event.end).format('YYYY-MM-DD')) {
                        diff = moment(moment(event.end).format('YYYY-MM-DD')).diff($scope.dateFin, 'days');
                        if (diff > 0) {
                            var s = moment($scope.dateFin).add(1, 'd');
                            for (var i = 0; i < diff; i++) {
                                $scope.dateFin = s.format('YYYY-MM-DD');
                                $scope.refreshing++;
                                Salles.getListe(s).then(function (data) {
                                    $scope.refreshing--;
                                    preparerVisuel(data);
                                });
                                s.add(1, 'd');
                            }
                            // tous les events sont terminés.
                            //$scope.dateFin = moment(event.end);
                        }
                    }

                    if ($scope.dateDebut != moment(event.start).format('YYYY-MM-DD')) {
                        var s = moment($scope.dateDebut).subtract(1, 'd');
                        diff = moment($scope.dateDebut).diff(moment(event.start).format('YYYY-MM-DD'), 'days');
                        if (diff > 0) {
                            for (var i = 0; i < diff; i++) {
                                $scope.dateDebut = s.format('YYYY-MM-DD');
                                $scope.refreshing++;
                                Salles.getListe(s).then(function (data) {
                                    $scope.refreshing--;
                                    preparerVisuel(data);
                                });
                                s.subtract(1, 'd');
                            }
                            // tous les events sont terminés.
                            //$scope.dateDebut = moment(event.start);
                        }
                    }
                }
            },
            'click': function (event) {
            },
            'doubleClick': function (event) {
            },
            'select': function (event) {
            }
        };

        $scope.visoptions = {
            "align": "center",
            "autoResize": true,
            "editable": false,
            "selectable": true,
            "orientation": 'both',
            "showMajorLabels": true,
            "showMinorLabels": true,
            "maxMinorChars": 6,
            "hiddenDates": { start: '2016-10-20 00:01:00', end: '2016-10-20 09:00:00', repeat: 'daily' },
            "showCurrentTime": false
        };

        function preparerVisuel(liste) {
            // Faire les cédules de salles maintenant
            var lst = [];
            angular.forEach(liste, function (i) {
                /**
                 * Exemples de lignes
                 *
                 * Créer un background : {id: 'A', content: 'Passes noires', start: '2016-10-17: 08:20:00', end: '2016-10-17 08:40:00', type: 'background', group: 1, className: 'passes-noires'},
                 * Entrée style boite : {id: '7a', content: 'Entrée des joueurs', start: '2016-10-17 08:58', group: 1, type:'box'},
                 * Range : {id: 8, content: 'Règlements <i class="fa fa-phone"></i>', start: '2016-10-17 08:19:02', end: '2016-10-17 08:23:05', group: 1, className: 'bg-success', type : 'range'},
                 */
                lst.push({ id: i.groupeId + '_' + i.salleId, content: i.nomFete + ' (' + i.groupeTypeNom + ')<br>' + i.nomOrganisateur + '<br>' + i.groupeNombre + ' joueurs', start: i.salleDebut, end: i.salleFin, group: i.salleId, className: 'groupe-type-' + i.groupeTypeId, type: 'range' });
            });

            if ($scope.visdata.items.length) {
                // Append
                if (lst.length) {
                    var pureList = [];
                    angular.forEach(lst, function (i) {
                        if (_.find($scope.visdata.items._data, function (item) { return item.id == i.id; }) == undefined) {
                            pureList.push(i);
                        }
                    });
                    //$scope.visdata.items.add(lst);
                    $scope.visdata.items.add(pureList);
                }
            } else {
                // Init
                var items = [];
                if (lst.length) {
                    items = VisDataSet(lst)
                }
                $scope.visdata = { groups: $scope.groups, items: items };
            }

            //$scope.visdata = {groups: $scope.groups, items:items};
        }

        Salles.TypeSalles().then(function (data) {
            $scope.listeTypeSalles = [];
            $scope.listeTypeSalles = data;

            var lst = [];
            angular.forEach($scope.listeTypeSalles, function (i) {
                lst.push({
                    id: i.salleId,
                    content: i.salleNom
                });
            });
            $scope.groups = VisDataSet(lst);

            // Init
            preparerVisuel($scope.listeSalles);
        });

        if ($scope.isAjouter) {
            $scope.nouvelleReservation();
        }

    }]);

d1App.controller('D1FeteCtl', ['$scope', '$sce', '$location', 'ReservationListe', '$route', function ($scope, $sce, $location, ReservationListe, $route) {
    var URLarg = $location.search();

    if (URLarg.length) {
        $scope.FeteHeuresDebut = URLarg.FeteHeuresDebut;
        $scope.RoomName = URLarg.RoomName;
        $scope.FeteHeuresFin = URLarg.FeteHeuresFin;
        $scope.NomFete = URLarg.NomFete;
    }

    if ($route.current.params.groupeId) {
        ReservationListe.getGroupe($route.current.params.groupeId).then(function (r) {
            if (r.groupeId) {
                $scope.RoomName = r.salle.salleNom;
                $scope.FeteHeuresDebut = moment(r.salleDebut).format('H:mm');
                $scope.FeteHeuresFin = moment(r.salleFin).format('H:mm');
                $scope.NomFete = r.nomFete;
            }
        });
    }

}]);

d1App.controller('D1MenuCtrl', ['$scope', 'Usagers', 'Reservation', 'D1Configurations', '$messages', function ($scope, Usagers, Reservation, D1Configurations, $messages) {

    $scope.Usagers = Usagers;
    $scope.links = [];

    D1Configurations.getConfigurations().then(
        r => {
            $scope.links = D1Configurations.links;
        }
    );

    // D1Configurations.socket.on('RafraichirConfig', function(data) {
    $messages.SettingsMessage.subscribe(data => {
        // On doit reloader les infos
        if (data.type === 'links' && data.links !== undefined) {
            $scope.links = data.links;
        }
    });



    // Nouvelles réservations
    $scope.nombreReservationsNonConfirmee = function () {
        return Reservation.nombreReservationsNonConfirmee();
    };

}]);


d1App.controller('D1VRCtrl', ['$scope', '$timeout', '$interval', 'Usagers', 'games', function ($scope, $timeout, $interval, Usagers, games) {

    $scope.Usagers = Usagers;
    $scope.games = games;
}]);


d1App.controller('ModalHistoriqueCtrl', ['$scope', '$http', '$uibModalInstance', 'reservation',
    function ($scope, $http, $uibModalInstance, reservation) {

        $scope.r = reservation;

        $http.get('/api/reservations/historique/' + reservation.groupeId).then(function (response) {
            $scope.historique = response.data;
        });

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
]);