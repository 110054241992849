<form [formGroup]="myForm">
    <div class="form-group"><tsng-form-group-input class="w-50" label="Organisateur" type="Text" formControlName="nomOrganisateur"></tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="w-25" label="Courriel" type="email" formControlName="courriel"></tsng-form-group-input><tsng-form-group-input class="w-25" label="Téléphone" type="Text" formControlName="telephone"></tsng-form-group-input></div>
    <div class="form-group"><d1-reservations-status class="d-block" [statutGroupe]="booking.statutGroupe" (statutGroupeChange)="statutGroupeChange($event)"></d1-reservations-status><d1-reservations-confirmation-status class="d-block" [statutConfirmation]="booking.statutConfirmation" (statutConfirmationChange)="statutConfirmationChange($event)"></d1-reservations-confirmation-status><d1-form-chip-list class="d-block" header="Tags" [selectedItems]="booking.groupe_tags" (selectedItemsChange)="changeTags($event)"></d1-form-chip-list></div>
    <div class="form-group"><d1-reservations-payment-status class="d-block" [statutPaiement]="booking.statutPaiement" (change)="statutPaiementChange($event)"></d1-reservations-payment-status><tsng-form-group-input class="pr-2" label="Montant" type="number" suffix="$" formControlName="depotMontant"></tsng-form-group-input><tsng-form-group-input class="pr-2" label="Référence" type="text" formControlName="depotReference"></tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="pr-2" label="Date de réservation" type="Date" formControlName="reservationDate"></tsng-form-group-input><tsng-form-group-input label="Heure d'arrivée" type="Time" [hoursFormat]="24" [minuteSteps]="20" formControlName="reservationDate" (change)="changeTimeDebut($event)"></tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="w-50" label="Fêté" type="Text" formControlName="nomFete"></tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="w-100" label="Notes importantes" type="TextArea" formControlName="notesAdmin"></tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="w-100" label="Commentaires" type="TextArea" formControlName="notes"></tsng-form-group-input></div>
    <div class="form-group">
        <tsng-form-group-input class="w-50" [ngInputStyle]="getMarshallColor()" label="Note au marhall" type="Text" formControlName="notesMarshall"></tsng-form-group-input><button class="btn border border-light bg-white p-1" ngx-colors-trigger formControlName="notesMarshallCouleur" [hideTextInput]="true" [hideColorPicker]="true" (change)="colorChanged($event)"><mat-icon [style.color]="myForm.controls.notesMarshallCouleur.value">palette</mat-icon></button>
    </div>
    <div class="form-group">
        <sbpro-card
            ><div class="card-header">Parties</div>
            <div class="card-body"><d1-reservations-games [(games)]="games" [date]="booking.reservationDate" [groupSize]="booking.nombreJoueurs"></d1-reservations-games></div
        ></sbpro-card>
    </div>
</form>
<code>{{ myForm.value | json}}</code><code>{{ games | json}}</code>
