<div class="no-gutters">
    <div class="row">
        <div class="col-3 d-print-none pt-2"><tsng-form-group-input class="w-100" label="Fêté" [(value)]="NomFete"></tsng-form-group-input><tsng-form-group-input class="w-100" label="Salle" [(value)]="RoomName"></tsng-form-group-input><tsng-form-group-input class="w-100" #startHeure label="Heures" [(value)]="FeteHeuresDebut" prefix="De : "></tsng-form-group-input><tsng-form-group-input class="w-100" #endHeure label="Heures" [(value)]="FeteHeuresFin" prefix="À : "></tsng-form-group-input></div>
        <div class="row col-lg-9 center-block pagecontenu">
            <div class="contenu cadre">
                <div class="titre mx-auto">BONNE FÊTE</div>
                <div class="organisateur">{{NomFete}}</div>
                <div class="salle mx-auto">{{RoomName}}</div>
                <div class="heures mx-auto">{{FeteHeuresDebut}} à {{FeteHeuresFin}}</div>
                <div class="date mx-auto">{{DateFete}}</div>
            </div>
        </div>
    </div>
</div>
