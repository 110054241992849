/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
    faAngleDown,
    faAngleRight,
    faArrowCircleRight,
    faArrowCircleLeft,
    faArrowAltCircleLeft,
    faArrowLeft,
    faAt,
    faBars,
    faBirthdayCake,
    faBookOpen,
    faCalendar,
    faCalendarDay,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faChild,
    faCircle,
    faColumns,
    faDollarSign,
    faDoorOpen,
    faExclamation,
    faExclamationTriangle,
    faFlag,
    faGamepad,
    faHistory,
    faIdCardAlt,
    faLongArrowAltRight,
    faMobile,
    faMousePointer,
    faPercentage,
    faPhone,
    faPlus,
    faPlusCircle,
    faRunning,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTags,
    faTrash,
    faUser,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faAngleRight,
    faArrowCircleRight,
    faArrowCircleLeft,
    faArrowAltCircleLeft,
    faArrowLeft,
    faAt,
    faBars,
    faBirthdayCake,
    faBookOpen,
    faCalendar,
    faCalendarDay,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faChild,
    faCircle,
    faColumns,
    faDollarSign,
    faDoorOpen,
    faExclamation,
    faExclamationTriangle,
    faFlag,
    faGamepad,
    faHistory,
    faIdCardAlt,
    faLongArrowAltRight,
    faMobile,
    faMousePointer,
    faPercentage,
    faPhone,
    faPlus,
    faPlusCircle,
    faRunning,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTags,
    faTrash,
    faUser,
};
