import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ClickEventArg, ColumnDefinitionsModel } from '@common/components/simple-grid/simple-grid.component';
import { RentalsAvailabilityEditComponent, RentalsBookingEditComponent } from '@modules/rentals/components';
import { Rental, Slot } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';

@Component({
    selector: 'd1-rentails-availability-detail-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentails-availability-detail-view.component.html',
    styleUrls: ['rentails-availability-detail-view.component.scss'],
})
export class RentailsAvailabilityDetailViewComponent implements OnInit {
    slot!:Slot;
    slot_id = 0;

    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: 'Booking #', visible: true, name: 'booking_id', sortable: true },
        { headerText: 'Groupe', visible: true, name: 'nomOrganisateur', sortable: true },
        { headerText: 'Nombre', visible: true, name: 'nombreJoueurs', sortable: true },
        { headerText: 'Tel', visible: true, name: 'telephone', sortable: true },
        { headerText: 'Courriel', visible: true, name: 'courriel', sortable: true },
        { headerText: 'status', visible: true, name: 'status', sortable: true },
    ];

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private dialog$: MatDialog
    ) {
        this.slot_id = this.nav.snapshot.params.id;
        console.log('this.nav.snapshot.queryParams', this.nav.snapshot.queryParams);
        if (+this.slot_id) {
            this.slot = {
                slot_id : this.slot_id,
                availability_count : 0,
                availability_tag : '',
                confirmation_mode : 1,
                duration_sec : 0,
                status : 1,
                inventory_count : 0,
                service_id : '1',
                start_sec : new Date(),
                rentals : []
            }
            this.fetchData();
        } else {
            if (this.nav.snapshot.queryParams) {
                const qp = this.nav.snapshot.queryParams;
                this.slot = {
                    slot_id : this.slot_id,
                    availability_count : qp.availability_count,
                    availability_tag : qp.availability_tag,
                    confirmation_mode : qp.confirmation_mode,
                    duration_sec : qp.duration_sec,
                    status : qp.status,
                    inventory_count : qp.inventory_count,
                    service_id : qp.service_id,
                    start_sec : qp.start_sec,
                    rentals : []
                }
            } else {
                console.log('No query params!!!');
            }

        }

    }

    fetchData() {
        this.rentals$.getSlot$(this.slot_id).subscribe((r) => {
            this.slot = r as Slot;
            this.ref.markForCheck();
        });
    }

    convertMinuteToHours(minutes: number) {
        if (minutes) {
            return `${Math.floor(minutes/60)}h${(minutes % 60).toString().length<2 ? '0' :''}${(minutes % 60)}`;
        } else {
            return '';
        }
    }

    OnEdit() {
        const dialogRef = this.dialog$.open(RentalsAvailabilityEditComponent, {data : { slot : this.slot} });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== 'annuler') {
                console.log(`Dialog result: ${result.action}`, result.data);
                if (this.slot.slot_id) {
                    this.rentals$.updateSlot$(result.data).subscribe( r => {
                        console.log('Après le post', r);
                        this.fetchData();
                    })
                } else {
                    this.rentals$.createSlot$(result.data).subscribe( r => {
                        console.log('Après le post', r);
                        this.fetchData();
                    })
                }

            }
        });
    }

    OnRowClick(event: ClickEventArg) {
        console.log("OnRowClick", event);
        const booking = event.rowData as Rental;
        if (event.event === 'edit') {
            this.router.navigate(['rentals', 'bookings', booking.booking_id, 'edit']);
        } else {
            this.router.navigate(['rentals', 'bookings', booking.booking_id]);
        }
        // this.router.navigate(['bookings', booking.booking_id], { relativeTo: this.nav });
    }

    OnAdd() {
        this.router.navigate(['rentals', 'bookings', 'add'], {queryParams: { slot_id : this.slot_id } });
    }

    ngOnInit() {}
}
