import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'd1-rooms-filter',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-filter.component.html',
    styleUrls: ['rooms-filter.component.scss'],
})
export class RoomsFilterComponent implements OnInit {
    @Input('date') selectedDate: moment.Moment = moment();
    @Output() change: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(moment())
    
    constructor() {

    }
    
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }

    ngOnInit() {}

    dateChange(date: moment.Moment) {
        this.change.next(date)
    }
}
