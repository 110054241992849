import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { UsersService } from './users.service';
import { UsersResolve } from './users.resolve';

export const services = [AuthService, UserService, UsersService, UsersResolve];

export * from './auth.service';
export * from './user.service';
export * from './users.service';
export * from './users.resolve';
