<div mat-dialog-title>Ajout rapide d'un walk-in</div>
<div mat-dialog-content>
    <form [formGroup]="myForm">
        <div class="form-group"><tsng-form-group-input class="w-100" label="Notes importantes" type="textarea" formControlName="notesAdmin"></tsng-form-group-input></div>
        <div class="form-group"><tsng-form-group-input class="w-50" label="Nom du groupe" type="Text" formControlName="nomOrganisateur"></tsng-form-group-input><tsng-form-group-input class="ml-2" label="Nombre de joueurs" type="number" formControlName="nombreJoueurs"></tsng-form-group-input></div>
        <div class="form-group">
            <mat-form-field class="w-50"><mat-label>Courriel</mat-label><input matInput formControlName="courriel" type="email" autocomplete="off"/></mat-form-field><mat-form-field class="ml-2"><mat-label>Téléphone</mat-label><input matInput formControlName="telephone" type="text" autocomplete="off"/></mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-50"
                ><mat-label>Forfait</mat-label
                ><mat-select formControlName="groupeTypeId"
                    ><mat-optgroup *ngFor="let group of forfaitsGroupes" [label]="group.groupe"><mat-option *ngFor="let groupeType of group.forfaits" [value]="groupeType.groupeTypeId">{{groupeType.groupeTypeNom}}</mat-option></mat-optgroup></mat-select
                ></mat-form-field
            >
        </div>
        <div class="form-group mt-2"><mat-label>Parties</mat-label><d1-schedule-pick-schedule #schedulePick *ngIf="myForm.controls.nombreJoueurs.value &gt; 0" [date]="dateNow" (onSelected)="OnChange($event)" [groupSize]="myForm.controls.nombreJoueurs.value" removePast="true"></d1-schedule-pick-schedule></div>
        <div class="form-group"><d1-reservations-payment-status [statutPaiement]="myForm.get('statutPaiement')?.value" (change)="statutPaiementChange($event)"></d1-reservations-payment-status></div>
        <div class="form-group"><d1-reservations-status [statutGroupe]="myForm.get('statutGroupe')?.value" (change)="statutChange($event)"></d1-reservations-status></div>
        <div class="form-group">
            <mat-form-field class="w-25"><mat-label>Initiales employé</mat-label><input matInput formControlName="initialesEmploye" type="text" autocomplete="off"/></mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions><button class="btn btn-success" (click)="save()" [disabled]="!myForm.valid">Enregistrer</button><button class="btn btn-default" mat-dialog-close="annuler">Annuler</button></div>
