import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '@common/services';
import { Rental } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import { MapGeocoder } from '@angular/google-maps';

@Component({
    selector: 'd1-rentals-booking-detail-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-booking-detail-edit.component.html',
    styleUrls: ['rentals-booking-detail-edit.component.scss'],
})
export class RentalsBookingDetailEditComponent implements OnInit {
    rental!: Rental;
    private _lastAdresse = '';

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private geocoder: MapGeocoder,
        private t$:ToastService
    ) {

        if (this.nav.snapshot.data.booking) {
            this.rental = this.nav.snapshot.data.booking;
            this._lastAdresse = `${this.rental.nocivique} ${this.rental.rue}, ${this.rental.ville}, ${this.rental.province}, ${this.rental.codepostal}`;
        } else {
            var id = parseInt(this.nav.snapshot.params.id)
            if (id.toString() === 'NaN') {
                this.rental = {
                    booking_id : 0,
                    slot_id : 0,
                    groupeId : 0,
                    status : 1
                } as Rental

            } else {
                this.rentals$.getBooking$(id).subscribe((r) => {
                    this.rental = r as Rental;
                    this.ref.markForCheck();
                });
            }
        }

        if (this.nav.snapshot.queryParams) {
            const slot_id = (this.nav.snapshot.queryParams.slot_id ? this.nav.snapshot.queryParams.slot_id : 0)
            if (slot_id) {
                this.rentals$.getSlot$(slot_id).subscribe( s => {
                    console.log('Slot loaded', s);
                    delete s.rentals;
                    this.rental = { ...this.rental, ...s}
                    this.rental.reservationDate = new Date(s.start_sec);
                    this.ref.markForCheck();
                })
            }
        }
    }

    ngOnInit() {}

    onChange(event:any) {
        // console.log('edit change event', event, this.rental);
        // let adresse = `${this.rental.nocivique} ${this.rental.rue}, ${this.rental.ville}, ${this.rental.province}, ${this.rental.codepostal}`
        // if (this._lastAdresse !== adresse) {
        //     this.geocoder.geocode({ address: adresse }).subscribe(({results}) => {
        //         console.log("GeoCoder", results);
        //         this.rental.geo_lat = results[0].geometry.location.lat()
        //         this.rental.geo_long = results[0].geometry.location.lng()
        //         this._lastAdresse = adresse;
        //         console.log('this.rental.geo_lat', this.rental.geo_lat, this.rental.geo_long)
        //         console.log('results[0].geometry.location.lng()', results[0].geometry.location.lat(), results[0].geometry.location.lng())
        //     });
        // }
    }

    onSave() {
        if (this.rental.booking_id) {
            this.rentals$.updateBooking$(this.rental).subscribe( r => {
                console.log('Après update', r);
                this.t$.showSuccess('Sauvegardé', 'Location sauvegardée avec succès');
                this.router.navigate(['..'], {relativeTo: this.nav});
            }, error => {
                console.error('ERREUR', error)
            })
        } else {
            this.rentals$.createBooking$(this.rental).subscribe( r => {
                console.log('Après insert', r);
                this.t$.showSuccess('Sauvegardé', 'Location ajoutée avec succès');
                this.router.navigate(['..', r.booking_id], {relativeTo: this.nav});
            }, error => {
                console.error('ERREUR', error)
            })
        }
    }

    onDelete() {}
    onCancel() {
        this.router.navigate(['..'], {relativeTo: this.nav});
    }

}
