<div class="form">
    <div class="d-flex d-flex-row w-100">
        <mat-form-field class="w-25 mr-2"><mat-label>Initiales</mat-label><input matInput placeholder="Initiales" #inputNoteInitiales autocomplete="off" [ngModel]="initiales"/></mat-form-field><mat-form-field class="w-100 mr-2"><mat-label>Ajouter une note</mat-label><input matInput placeholder="Indiquez une note" #inputNote autocomplete="off" (keyup)="checkKey($event)"/></mat-form-field>
        <div>
            <button type="submit" mat-stroked-button (click)="ajouterNote(inputNote)" matSuffix [disabled]="(inputNote.value.length == 0 || inputNoteInitiales.value.length == 0)" [class.btn-success]="!(inputNote.value.length == 0 || inputNoteInitiales.value.length == 0)"><fa-icon class="mr-1" [icon]="['fas', 'plus-circle']"></fa-icon></button>
        </div>
    </div>
    <div class="d-flex d-flex-column mb-2 justify-content-between align-items-center" *ngFor="let item of timeline; let index = index" role="listitem">
        <div class="badge badge-info p-2 mr-2">{{ item.auteur }}<br />{{ item.created | date : 'd MMM YYYY à HH:mm:ss'}}</div>
        <div class="col-content mr-2 flex-fill"><div class="align-middle">{{ item.description }}</div></div>
        <div><fa-icon class="mr-1" [icon]="['fas', 'trash']" (click)="deleteNote(index)"></fa-icon></div>
    </div>
</div>
