import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ScheduleModel } from '@modules/schedule/models';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'd1-schedule-pick-schedule',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-pick-schedule.component.html',
    styleUrls: ['schedule-pick-schedule.component.scss'],
})
export class SchedulePickScheduleComponent implements OnInit, OnChanges {
    @Input() games: GameModel[] = []
    @Input() date!: Date;
    @Input() groupSize: number = 0;
    @Input() arenaid: number = 1;
    @Input() removePast: boolean = false;
    @Output() onSelected: EventEmitter<moment.Moment[]> = new EventEmitter();

    public selectedCedules: moment.Moment[] = [];

    public ceduleListe: ScheduleModel[] = [];

    constructor(public s$: ScheduleService, private ref: ChangeDetectorRef) {

        if (this.date) {
            this.fetchData()
        }
        if (this.games) {
            this.selectedCedules = _.map(this.games, (g) => {
                return moment(g.partieTemps);
            })
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date && changes.date.currentValue) {
            this.fetchData()
        }


        if (changes.groupSize && changes.groupSize.currentValue && this.selectedCedules.length > 0) {
            let diff = 0
            diff = (parseInt(changes.groupSize.currentValue) - parseInt(changes.groupSize.previousValue)) * -1
            this.selectedCedules.forEach(c => {
                const ret = _.findIndex(this.ceduleListe, (cedule) => {
                    return c.isSame(cedule.journeeTemps);
                });
                this.ceduleListe[ret].numDisponible += diff
            })
        }

        if (changes.games && changes.games.currentValue) {
            this.selectedCedules = _.map(this.games, (g) => {
                return moment(g.partieTemps);
            })
            this.ref.markForCheck()
        }
    }

    fetchData() {
        this.s$.disponibilites(this.date, this.arenaid).subscribe(c => {
            if (this.removePast) {
                _.forEach(c, (s: any) => {
                    if (moment(this.date) < moment(s.journeeTemps) || this.isSelected(s)) {
                        this.ceduleListe.push(s);
                    }
                })
            } else {
                this.ceduleListe = c;
            }
            this.ref.markForCheck()
        });
    }

    ngOnInit() { }

    getPasseCouleur(partieTemps: string) {
        return this.s$.getPasseCouleur(partieTemps)
    }
    getClasses(partieTemps: string) {
        return ['border', `border-${this.getPasseCouleur(partieTemps)}`]
    }

    toggleCedule(cedule: ScheduleModel) {
        const ret = _.findIndex(this.selectedCedules, (journeeTemps) => {
            return journeeTemps.isSame(cedule.journeeTemps);
        });
        const numJoueurs = this.getNumJoueursCedule(cedule);
        if (ret !== -1) {
            // Retirer
            this.selectedCedules.splice(ret, 1)
            cedule.numDisponible += parseInt(numJoueurs.toString());
        } else {
            // Ajouter
            this.selectedCedules.push(moment(cedule.journeeTemps))
            cedule.numDisponible -= parseInt(numJoueurs.toString());
        }

        this.onSelected.emit(this.selectedCedules);
    }

    isSelected(cedule: ScheduleModel) {
        const ret = _.find(this.selectedCedules, moment(cedule.journeeTemps));
        return (ret !== undefined)
    }

    getNumJoueursCedule(cedule: ScheduleModel) {
        const ret = _.find(this.games, { partieTemps: cedule.journeeTemps }) as GameModel;
        return (ret !== undefined ? ret.nombreJoueurs : this.groupSize)
    }

}
