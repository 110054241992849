/* tslint:disable: ordered-imports*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { DragDropModule } from '@angular/cdk/drag-drop';


/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as scheduleComponents from './components';

/* Containers */
import * as scheduleContainers from './containers';

/* Guards */
import * as scheduleGuards from './guards';

/* Services */
import * as scheduleServices from './services';

import { DragScrollModule } from 'ngx-drag-scroll';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        DragScrollModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppCommonModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatTooltipModule,
        NavigationModule,
    ],
    providers: [...scheduleServices.services, ...scheduleGuards.guards],
    declarations: [...scheduleContainers.containers, ...scheduleComponents.components],
    exports: [...scheduleContainers.containers, ...scheduleComponents.components],
})
export class ScheduleModule {}
