<form [formGroup]="myForm">
    <sbpro-card
        ><div class="card-header"><span *ngIf="resource.ressourceId">Ressource #{{ resource.ressourceId }}</span><span *ngIf="!resource.ressourceId">Nouvelle ressource</span></div>
        <div class="card-body">
            <div class="form-group"><tsng-form-group-input class="w-50" label="Type" type="ComboBox" formControlName="ressourceTypeId" [required]="true" [model]="{ dataSource: typeListe }"> </tsng-form-group-input></div>
            <div class="form-group"><tsng-form-group-input class="w-100" label="Nom" type="text" [required]="true" formControlName="ressourceName"></tsng-form-group-input></div>
            <div class="form-group"><tsng-form-group-input class="w-100" label="SKU" type="text" formControlName="ressourceSKU"></tsng-form-group-input></div>
            <div class="form-group">
                <mat-label>Tags<d1-form-chip-list header="" [selectedItems]="resource.ressourceTags" (change)="changeTags($event)"></d1-form-chip-list></mat-label>
            </div></div
    ></sbpro-card>
</form>
