<sb-breadcrumbs slim="true"></sb-breadcrumbs
><sbpro-card
    ><div class="card-body">
        <sbpro-card
            ><div class="card-header">Locations pour aujourd'hui</div>
            <div class="card-body"><d1-rentals-booking-list-today></d1-rentals-booking-list-today></div></sbpro-card
        ><sbpro-card
            ><div class="card-header">Retours de locations</div>
            <div class="card-body"><d1-rentals-booking-return-future></d1-rentals-booking-return-future></div></sbpro-card
        ><sbpro-card
            ><div class="card-header">Locations à venir</div>
            <div class="card-body"><d1-rentals-booking-list-future></d1-rentals-booking-list-future></div
        ></sbpro-card></div
></sbpro-card>
