import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import curPackage from '../../../../package.json'
import { ToastService } from './toast.service';

@Injectable()
export class AppVersionInterceptorService implements HttpInterceptor {

    constructor(private _snackBar: MatSnackBar) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.startsWith('/api/')) {
            return next.handle(req).pipe(
                filter( (r) => r instanceof HttpResponse),
                tap(
                    (httpEvent:HttpEvent<any>) => { 
                        if (httpEvent instanceof HttpResponse) {
                            // TODO: Ne pas si fier à la valeur numérique car 2.5.10 (2510) devient plus grand que 2.6.0 (260)
                            if (httpEvent.headers.has('x-reload-app-min-version')) {
                                let packVersion = curPackage.version.split('.')
                                let headerVersion = httpEvent.headers.get('x-reload-app-min-version')?.split('.')
                                if ((headerVersion![0] > packVersion[0]) || (headerVersion![1] > packVersion[1]) || (headerVersion![2] > packVersion[2])) {
                                    const nextAppVersion = (httpEvent.headers.get('x-reload-app-min-version') || "")
                                    const ref = this._snackBar.open(`Une nouvelle version est disponible${nextAppVersion ? ` (v${nextAppVersion})`: ''}, veuillez rafraîchir la page`, `Rafraîchir`);
                                    ref.onAction().subscribe( (v) => {
                                        window.location.reload();
                                    });
                                }
                            }
                        }
                    }
                )
            );
        } else {
            return next.handle(req);
        }
    }
}
