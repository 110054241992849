import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { AuthGuard } from '@modules/auth/guards';

// Match any URL that starts with `users`
export function isAngularJSUrl(url: UrlSegment[]) {
    return url.length > 0 && url[0].path.startsWith('ng1') ? ({consumed: url}) : null;
}

@Component({
    template: ''
})
export class EmptyComponent {
    
}


const routes: Routes = [
    { 
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard',
        canActivateChild: [AuthGuard] 
    },
    // AngularJS routes
    // { matcher: isAngularJSUrl, component: Ng1ViewComponent },
    // { matcher: isAngularJSUrl, loadChildren: () =>
    //     import('modules/ng1/ng1.module').then(
    //         m => m.Ng1Module
    //     ), },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'shopify',
        loadChildren: () =>
            import('modules/shopify/shopify-routing.module').then(m => m.ShopifyRoutingModule),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'schedule',
        loadChildren: () =>
            import('modules/schedule/schedule-routing.module').then(m => m.ScheduleRoutingModule),
    },
    {
        path: 'booking',
        loadChildren: () =>
            import('modules/reservations/reservations-routing.module').then(m => m.ReservationsRoutingModule),
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('modules/settings/settings-routing.module').then(m => m.SettingsRoutingModule),
    },
    {
        path: 'rapports',
        loadChildren: () =>
            import('modules/rapports/rapports-routing.module').then(m => m.RapportsRoutingModule),
    },
    {
        path: 'resource',
        loadChildren: () =>
            import('modules/resource/resource-routing.module').then(m => m.ResourceRoutingModule),
    },
    {
        path: 'rooms',
        loadChildren: () =>
            import('modules/rooms/rooms-routing.module').then(m => m.RoomsRoutingModule),
    },
    {
        path: 'rentals',
        loadChildren: () =>
            import('modules/rentals/rentals-routing.module').then(m => m.RentalsRoutingModule),
    },
    {
        path: 'tickets',
        loadChildren: () =>
            import('modules/worktickets/worktickets-routing.module').then(m => m.WorkticketsRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    {
        path: 'version',
        loadChildren: () =>
            import('modules/app-common/app-common-routing.module').then(
                m => m.AppCommonRoutingModule
            ),
    },
    {
        path: 'membres',
        loadChildren: () =>
            import('modules/membres/membres-routing.module').then(
                m => m.MembresRoutingModule
            ),
    },
    {
        matcher: isAngularJSUrl, component: EmptyComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
