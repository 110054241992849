import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'd1-shopify-main-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-main-view.component.html',
    styleUrls: ['shopify-main-view.component.scss'],
})
export class ShopifyMainViewComponent implements OnInit {
    constructor(private router:Router) {}
    ngOnInit() {}
 

}
