import { SalleModel } from "@modules/rooms/models";
import { GameModel } from "@modules/schedule/models/game.model";
import _ from "lodash";
import moment from "moment";

// export class SalleModel {
//     disponible: number = 0;
//     estDisponible: number = 0;
//     salleId: number = 0;
//     salleIdExterne: number = 0;
//     salleNom = ''

//     constructor(s: SalleModel | undefined) {
//         if (s) {
//             this.disponible = s.disponible
//             this.estDisponible = s.estDisponible
//             this.salleId = s.salleId
//             this.salleIdExterne = s.salleIdExterne
//             this.salleNom = s.salleNom
//         }
//     }
// }

export class TimelineModel {
    action: string = '';
    detail: string = '';
    groupeHistoriqueId: number = 0;
    groupeId: number = 0;
    historiqueDate: Date | undefined = undefined
    user: string = '';

    constructor(s?: TimelineModel) {
        if (s !== undefined) {
            this.action = s.action
            this.detail = s.detail
            this.groupeHistoriqueId = s.groupeHistoriqueId
            this.groupeId = s.groupeId
            this.historiqueDate = s.historiqueDate
            this.user = s.user
        } else {
            this.historiqueDate = new Date()
        }
    }
}

export class ReservationModel {
    groupeId: number = 0;
    groupeTypeId: number = 0;
    groupeTitre: string = '';
    groupe_tags: string = '';
    nomOrganisateur: string = '';
    nombreJoueurs: number = 0;
    reservationDate: Date | null = null;
    insertionDate: Date | null = null;
    courriel: string = '';
    telephone: string = '';
    partiesDate: Date | null = null;
    depotFait: boolean = false;
    depotMontant: number = 0;
    depotReference: string = '';
    nomFete: string = '';
    salleReservee: number = 0;
    salleDebut: Date | null = null;
    salleFin: Date | null = null;
    notes: string = '';
    notesAdmin: string = '';
    initialesEmploye: string = '';
    statutGroupe: number = 0;
    statutConfirmation: number = 0;
    statutPaiement: number = 0;
    estImporte: boolean = false;
    estEfface: boolean = false;
    estAnnule: boolean = false;
    notesMarshall: string = '';
    responsable: string = '';
    userLockToken: string = '';
    userLockTime: Date | null = null;
    notesMarshallCouleur: string = '';
    lstSalles: string = '';
    parties?: GameModel[] = [];
    salles?: SalleModel[] = [];
    timeline?: TimelineModel[] = [];
    vr?: GameModel[] = [];
    valomotionCode: string = '';
    valomotionBirthdayCode: string = '';
    groupeTypeNom: string = '';
    groupeType?: any;
    braceletCouleur?: string = '';


    constructor(r?: ReservationModel) {
        if (r) {
            let groupe = this;
            groupe.groupeId = r.groupeId;
            groupe.groupeTypeId = r.groupeTypeId;
            groupe.groupeTitre = r.groupeTitre;
            groupe.groupeTypeNom = r.groupeTypeNom;
            groupe.groupe_tags = r.groupe_tags;
            groupe.nomOrganisateur = r.nomOrganisateur;
            groupe.nombreJoueurs = r.nombreJoueurs;
            groupe.reservationDate = (moment(r.reservationDate).toDate());
            groupe.insertionDate = (r.insertionDate ? moment(r.insertionDate).toDate() : moment().toDate());
            groupe.courriel = r.courriel;
            groupe.telephone = (r.telephone || '');
            groupe.partiesDate = moment(r.partiesDate).toDate();
            groupe.depotFait = r.depotFait;
            groupe.depotMontant = r.depotMontant;
            groupe.depotReference = r.depotReference;
            groupe.nomFete = r.nomFete;
            groupe.salleReservee = (r.salleReservee ? r.salleReservee : 0);
            groupe.salleDebut = (r.salleDebut ? moment(r.salleDebut).toDate() : null);
            groupe.salleFin = (r.salleFin ? moment(r.salleFin).toDate() : null);
            groupe.notes = r.notes;
            groupe.notesAdmin = (r.notesAdmin ? r.notesAdmin : '');
            groupe.initialesEmploye = r.initialesEmploye;
            groupe.statutGroupe = r.statutGroupe;
            groupe.statutConfirmation = (r.statutConfirmation == null ? 0 : r.statutConfirmation);
            groupe.statutPaiement = (r.statutPaiement == undefined ? 0 : r.statutPaiement);
            groupe.estImporte = (r.estImporte == null ? false : r.estImporte);
            groupe.estEfface = (r.estEfface !== undefined ? r.estEfface : false);
            groupe.estAnnule = (r.estAnnule !== undefined ? r.estAnnule : false);
            groupe.notesMarshall = r.notesMarshall;
            groupe.responsable = r.responsable;
            groupe.userLockToken = r.userLockToken;
            groupe.notesMarshallCouleur = r.notesMarshallCouleur;
            groupe.lstSalles = r.lstSalles;
            groupe.valomotionCode = r.valomotionCode;
            groupe.valomotionBirthdayCode = r.valomotionBirthdayCode;
            groupe.groupeType = r.groupeType;
            groupe.braceletCouleur = r.braceletCouleur;

            groupe.parties = _.map(r.parties, (s) => new GameModel(s));
            groupe.salles = _.map(r.salles, (s) => new SalleModel(s));
            groupe.timeline = _.map(r.timeline, (t) => new TimelineModel(t))
            groupe.vr = _.map(r.vr, (g) => new GameModel(g))
        }
    }

};

export interface SalleInfo {
    salleId: number;
    salleDebut: Date;
    salleFin: Date;
    ancienneSalleId?: number;
}

/**
 * On va utiliser cette classe pour le "LocalStorage"
 */
export class ReservationStorage {
    public mainListView: string = ''
    public utilSallesView: string = ''
    public modeCalendar: string = ''
}