<div class="d-inline-flex flex-wrap">
    <div class="card cedule-wrapper mr-2 mt-2 shadow rounded pointer" *ngFor="let cedule of ceduleListe" [class.disabled]="cedule.numDisponible &lt;= 0" [class]="getClasses(cedule.partieHeures)" (click)="toggleCedule(cedule)">
        <div class="p-2 font-weight-bold text-center align-middle header border-bottom" [class.text-danger]="cedule.numDisponible &lt;= 0" [class.text-warning]="cedule.numDisponible &lt; getNumJoueursCedule(cedule)">
            {{ cedule.numDisponible }} places
            <div *ngIf="isSelected(cedule)">{{ getNumJoueursCedule(cedule) }} joueurs</div>
        </div>
        <div class="m-2 pr-2 pl-2">
            <div class="text-center align-middle font-weight-bolder">{{ cedule.partieHeures }}<mat-icon class="text-success ml-1" *ngIf="isSelected(cedule)">task_alt</mat-icon></div>
        </div>
        <div class="pb-2" [class.bg-success]="isSelected(cedule)" [class.bg-warning]="cedule.numDisponible &lt; getNumJoueursCedule(cedule)" [class.bg-danger]="cedule.numDisponible &lt;= 0"></div>
    </div>
</div>
