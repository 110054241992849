import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { SalleModel } from '@modules/rooms/models';
import { RoomsService } from '@modules/rooms/services';
import _ from 'lodash';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-rooms-pick-available',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-pick-available.component.html',
    styleUrls: ['rooms-pick-available.component.scss'],
})
export class RoomsPickAvailableComponent implements OnInit, OnDestroy, OnChanges {
    @Input('date') dateSelected: Date = moment().hours(12).minutes(0).seconds(0).toDate();
    @Input() showDate: boolean = false;
    @Input() multiple: boolean = false;
    @Input('duration') bookingMinutes = 60;

    public _subs: Subscription[] = [];
    public latest: any = [];
    public schedules = [];
    public selectedTimeSlots: any = [];
    public roomList: SalleModel[] = [];
    public activeRooms: SalleModel[] = [];
    public roomUsage: any = [];
    public bookingBufferMinutes = 20;
    public currentTimeSlot: any = undefined;
    public montrerSallesInterne = false

    @Output('selected') selected: EventEmitter<any> = new EventEmitter();
    @Output('datechange') datechange: EventEmitter<any> = new EventEmitter();

    constructor(private route: ActivatedRoute, private r$: RoomsService, private ref: ChangeDetectorRef) {
        if (route.snapshot.data.rooms) {
            this.roomList = route.snapshot.data.rooms;
            if (this.roomList) {
                this.activeRooms = this.roomList.filter((s) => (s.estDisponible && (this.montrerSallesInterne || (this.montrerSallesInterne === false && s.visiblePublic === true))))
            }
        }
    }

    getSchedule() {
        const mDate = moment(this.dateSelected).endOf('day')

        this.r$.getTimeSlots$(mDate, this.bookingMinutes).subscribe(t => {
            // Prenre la liste complète des salles
            this.roomList = t;
            this.activeRooms = t.filter((s: SalleModel) => (s.estDisponible && (this.montrerSallesInterne || (this.montrerSallesInterne === false && s.visiblePublic))))
            this.ref.markForCheck();
        })
    }
    toggleSalles(toggle: MatSlideToggleChange) {
        this.montrerSallesInterne = toggle.checked;
        this.activeRooms = this.roomList.filter((s: SalleModel) => (s.estDisponible && (this.montrerSallesInterne || (this.montrerSallesInterne === false && s.visiblePublic))))
    }

    ngOnDestroy(): void {
        this._subs.forEach((s) => s.unsubscribe())
    }

    ngOnInit() {
        this.getSchedule();
    }

    getEndTime(curDate: moment.Moment) {
        return moment(curDate).add(this.bookingMinutes, 'minutes').toDate();
    }

    onDateChange(date: moment.Moment) {
        this.dateSelected = date.toDate();
        this.getSchedule();
        this.datechange.emit(this.dateSelected)
    }

    onSelected(item: any) {
        item.selected = item.selected ? false : true;

        if (!this.multiple) {
            this.selected.emit(item)
        } else {
            // Batir une liste de selected
            const selectedItems: any[] = [];
            this.activeRooms.forEach((r: any) => {
                r.timeslots.forEach((t: any) => {
                    if (t.selected) {
                        selectedItems.push(t);
                    }
                })
            });

            this.selected.emit(selectedItems)
        }
    }

    setBookingMinutes(minutes: number) {
        if (this.bookingMinutes != minutes) {
            this.bookingMinutes = minutes;
            this.getSchedule();
        }
    }

    showInfo(t: any) {
        this.currentTimeSlot = t;
    }

    onClose() {
        const selectedItems: any[] = [];
        this.activeRooms.forEach((r: any) => {
            r.timeslots.forEach((t: any) => {
                if (t.selected) {
                    selectedItems.push(t);
                }
            })
        });

        this.selected.emit(selectedItems)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dateSelected) {
            this.dateSelected = changes.dateSelected.currentValue;
            this.getSchedule();
        }
    }
}
