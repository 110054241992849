/* tslint:disable: ordered-imports*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as reservationsComponents from './components';

/* Containers */
import * as reservationsContainers from './containers';

/* Guards */
import * as reservationsGuards from './guards';

/* Services */
import * as reservationsServices from './services';

/* Schedules */
import { ScheduleModule } from '@modules/schedule/schedule.module';
import { ScheduleService } from '@modules/schedule/services';

/** Rooms */
import { RoomsModule } from '@modules/rooms/rooms.module';
import { RoomsService } from '@modules/rooms/services/rooms.service';

// Divers
import { ChartsModule } from '@modules/charts/charts.module';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { NgxColorsModule } from 'ngx-colors';
import { NgChartsModule } from 'ng2-charts';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppCommonModule,
        NavigationModule,
        ChartsModule,
        RoomsModule,
        ScheduleModule,
        MatBottomSheetModule,
        NgxMatColorPickerModule,
        NgxColorsModule,
        NgChartsModule
    ],
    providers: [...reservationsServices.services, ...reservationsGuards.guards, ScheduleService, RoomsService
        , { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
    ],
    declarations: [...reservationsContainers.containers, ...reservationsComponents.components],
    exports: [...reservationsContainers.containers, ...reservationsComponents.components],
})
export class ReservationsModule { }
