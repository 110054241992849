<div>Réservation</div>
<div>
    <form [formGroup]="myForm">
        <sbpro-card
            ><div class="card-header"><span *ngIf="rental.groupeId">Client #{{ rental.groupeId }}</span><span *ngIf="!rental.groupeId">Nouveau client</span><button class="ml-2 btn btn-primary" *ngIf="rental.groupeId" type="button" (click)="retirerGroupe()">Retirer</button></div>
            <div class="card-body"><d1-groupe-edit [groupe]="groupe" (change)="groupeChange($event)"></d1-groupe-edit></div></sbpro-card
        ><sbpro-card
            ><div class="card-header">Location</div>
            <div class="card-body">
                <ng-container *ngIf="rental.duration_sec &gt; 0"
                    ><div>Date : {{ rental.start_sec | date : 'd MMM, YYYY @ HH:mm' }}</div>
                    <div>Durée : {{ rental.duration_sec }} minutes</div>
                    <div>Nb équipements : {{ myForm.controls.nombreJoueurs.value }}</div>
                    <button class="btn btn-info toggleBtn" *ngIf="rental.status &lt; 2" type="button" (click)="changeSlot()">Modifier</button></ng-container
                ><d1-rentals-availability-calendar *ngIf="rental.duration_sec === 0 || showSlot" [initialDate]="myForm.controls.reservationDate.value" (selected)="selectedSlotEvent($event)" [minimumQuantity]="myForm.controls.nombreJoueurs.value"></d1-rentals-availability-calendar></div></sbpro-card
        ><sbpro-card
            ><div class="card-header">Adresse</div>
            <div class="card-body">
                <div class="form-group"><tsng-form-group-input class="w-25" label="No. civique" type="Text" formControlName="nocivique"></tsng-form-group-input><tsng-form-group-input class="w-75" label="Rue" type="Text" formControlName="rue"></tsng-form-group-input></div>
                <div class="form-group"><tsng-form-group-input class="w-50 mr-2" label="Ville" type="Text" formControlName="ville"></tsng-form-group-input><tsng-form-group-input class="w-25" label="Province" type="Text" formControlName="province"></tsng-form-group-input></div>
                <div class="form-group"><tsng-form-group-input class="w-25 pr-2" label="Pays" type="Text" formControlName="pays"></tsng-form-group-input><tsng-form-group-input class="w-25" label="Code Postal" type="Text" formControlName="codepostal"></tsng-form-group-input></div></div
        ></sbpro-card>
    </form>
</div>
