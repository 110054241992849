<div mat-dialog-title>Disponibilité</div>
<div mat-dialog-content>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <sbpro-alert *ngIf="myForm.invalid &amp;&amp; myForm.touched" classes="alert-warning" [dismissable]="true" [icon]="true">Il y a des erreurs, vérifiez vos données!</sbpro-alert>
        <div class="form-group"><tsng-form-group-input class="w-75" id="start_sec" label="Date" type="Date" required="true" formControlName="start_sec"></tsng-form-group-input></div>
        <div class="form-group"><tsng-form-group-input id="start-time-clock" label="Début" type="Time" [hoursFormat]="24" formControlName="start_time" (change)="changeTimeDebut($event)"> </tsng-form-group-input></div>
        <div class="form-group"><tsng-form-group-input class="pr-2" id="availability_count" label="Disponible" type="Numeric" required="true" formControlName="availability_count"></tsng-form-group-input></div>
        <div class="form-group"><tsng-form-group-input class="pr-2" id="inventory_count" label="Inventaire" type="Numeric" required="true" formControlName="inventory_count"></tsng-form-group-input></div>
        <div class="form-group"><tsng-form-group-input class="pr-2" id="duration_sec" label="Durée" suffix="minutes" type="Numeric" required="true" formControlName="duration_sec"></tsng-form-group-input></div>
    </form>
</div>
<div mat-dialog-actions><button class="btn btn-success mr-2" (click)="onAction('save')" [disabled]="!myForm.valid">Enregistrer</button><button class="btn btn-danger mr-2" *ngIf="slot.slot_id" (click)="onAction('delete')" mat-dialog-close="delete">Supprimer</button><button class="btn btn-warning mr-2" (click)="onCancel()" mat-dialog-close="annuler">Annuler</button></div>
