import * as _ from 'underscore';
import * as moment from 'moment';

//angular.module('resources.rapports', ['resources.cedule', "googlechart", 'ui.grid', 'ui.grid.edit', 'ui.grid.cellNav']);
angular.module('resources.rapports', ['resources.cedule', 'angular-barcode', 'angular-quagga-js', 'ui.bootstrap']);
var D1Rapports = angular.module('resources.rapports').factory('Rapports', ['$http', '$rootScope', 'Reservation', '$q', function ($http, $rootScope, Reservation, $q) {

    var Rapports = {
        init: function () {
        },
        listeRapports: []
    };

    Rapports.ListeCommandes = function (dateDebut, dateFin) {
        return $http.get('/api/rapports/commandes/' + moment(dateDebut).format('YYYY-MM-DD') + '/' + moment(dateFin).format('YYYY-MM-DD')).then(function (response) {
            return response;
        });
    };

    Rapports.ListeGateaux = function (dateDebut, dateFin) {
        return $http.get('/api/rapports/gateaux/' + moment(dateDebut).format('YYYY-MM-DD') + '/' + moment(dateFin).format('YYYY-MM-DD')).then(function (response) {
            return response;
        });
    };


    Rapports.ListeTypeGroupesParJournee = function (dateDebut, dateFin) {
        return $http.get('/api/rapports/typegroupejournee/' + moment(dateDebut).format('YYYY-MM-DD') + '/' + moment(dateFin).format('YYYY-MM-DD')).then(function (response) {
            return response;
        });
    };

    Rapports.ListeFetes = function (dateDebut, dateFin) {
        return $http.get('/api/rapports/fetes/' + moment(dateDebut).format('YYYY-MM-DD') + '/' + moment(dateFin).format('YYYY-MM-DD')).then(function (response) {
            var maListe = [];
            var maListePromises = [];
            angular.forEach(response.data, function (item) {
                //maListe.push(Reservation.parseFromDB(item));
                maListePromises.push(Reservation.parseFromDB(item, true));
            });
            return $q.all(maListePromises).then(function (data) {
                angular.forEach(data, function (item) {
                    maListe.push(item);
                });
                return maListe;
            });
        });
    };


    Rapports.ListeReservations = function (dateDebut, dateFin) {
        return $http.get('/api/rapports/reservations/' + moment(dateDebut).format('YYYY-MM-DD') + '/' + moment(dateFin).format('YYYY-MM-DD')).then(function (response) {
            return response;
        });
    };

    Rapports.ListeVentes = function (dateDebut, dateFin) {
        return $http.get('/api/lightspeed/reports/sales?bycategory=1&startTime=' + moment(dateDebut).format('YYYY-MM-DD') + '&endTime=' + moment(dateFin).format('YYYY-MM-DD')).then(function (response) {
            return response;
        });
    };

    Rapports.TypeGroupes = Reservation.TypeGroupes;

    // Init
    Rapports.ìnit;

    return Rapports;
}]);

D1Rapports.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {

    $routeProvider
        .when('/ng1/rapports/fetes', {
            templateUrl: 'ng1/rapports/fetes-list.tpl.html',
            controller: 'D1RapportsFeteCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Rapports des commandes';
                }]
            }
        })
        .when('/ng1/rapports/animateurs', {
            templateUrl: 'ng1/rapports/animateur-list.tpl.html',
            controller: 'D1RapportsAnimateurCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Rapports des fêtes';
                }]
            }
        })
        .when('/ng1/rapports/statistiques', {
            templateUrl: 'ng1/rapports/statistiques.tpl.html',
            controller: 'D1RapportsStatistiquesCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Rapports statistiques';
                }]
            }
        })
        .when('/ng1/rapports/codebarres', {
            templateUrl: 'ng1/rapports/codebarre.tpl.html',
            controller: 'D1RapportsCodebarreCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Codes barres';
                }]
            }
        })
        .when('/ng1/rapports/ventes', {
            templateUrl: 'ng1/rapports/ventes.tpl.html',
            controller: 'D1RapportsVentesCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Rapports de ventes';
                }]
            }
        })
        .when('/ng1/rapports/marketing', {
            templateUrl: 'ng1/rapports/marketing-list.tpl.html',
            controller: 'D1RapportsMarketingCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function (Breadcrumb) {
                    Breadcrumb.pageTitle = 'Marketing';
                }],
                gabarits: ['D1Courriel', function (D1Courriel) {
                    return D1Courriel.getGabarits();
                }]
            }
        })
        ;

    // use the HTML5 History API
    $locationProvider.html5Mode(true);
}]);


D1Rapports.controller('D1RapportsFeteCtrl', ['$scope', 'Rapports', function ($scope, Rapports) {
    $scope.Rapports = Rapports;
    $scope.showMessage = '';
    $scope.dateDebut = moment(moment().format('YYYY-MM-DD 00:00:00')).day(1).toDate();
    $scope.dateFin = moment(moment().format('YYYY-MM-DD 00:00:00')).day(14).toDate();
    $scope.ListeGateaux = [];
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;
    //$scope.TypeGroupes = Rapports.TypeGroupes;

    // Batir la liste des forfaits de fetes
    $scope.TypeGroupes = _.where(Rapports.TypeGroupes, { estFete: 1 });

    // Calendrier
    $scope.options = {
        //customClass: getDayClass,
        //minDate: $scope.dateDebut,
        showWeeks: true
    };

    $scope.ouvrirDD = function () {
        $scope.dd_ouvert = true;
    };

    $scope.ouvrirDF = function () {
        $scope.df_ouvert = true;
    };

    $scope.rafraichir = function () {
        getGateaux();
        getFetes();
    };

    $scope.$watch('dateDebut', function (i) {
        //getGateaux();
        //getFetes();
    });

    $scope.$watch('dateFin', function (i) {
        //getGateaux();
        //getFetes();
    });

    $scope.getDateDebut = function () {
        return moment($scope.dateDebut).format('DD MMM');
    };

    $scope.getDateFin = function () {
        return moment($scope.dateFin).format('DD MMM');
    };

    function getGateaux() {
        //console.log("$scope.dateDebut, $scope.dateFin", $scope.dateDebut, $scope.dateFin);
        Rapports.ListeCommandes($scope.dateDebut, $scope.dateFin).then(function (response) {
            $scope.ListeGateaux = response.data;
            _.each($scope.ListeGateaux, function (g) {
                if (g.options && g.options.length) {
                    var opt = JSON.parse(g.options);
                    g.attributsOptions = {
                        attribut: g.listeOptions,
                        attributId: g.attributId,
                        choix: (_.findWhere(opt, { id: g.choix })).name,
                        choixTemps: moment(g.choixTemps)
                    };
                }
            })
        });
    }

    function getFetes() {
        //console.log("$scope.dateDebut, $scope.dateFin", $scope.dateDebut, $scope.dateFin);
        Rapports.ListeFetes($scope.dateDebut, $scope.dateFin).then(function (response) {
            $scope.ListeFetes = response;

            angular.forEach($scope.ListeFetes, function (v, k) {
                $scope.ListeFetes[k].pagebreakbefore = (k % 5 == 0 && k != 0);
            });
        });
    }

    // Filtrage
    $scope.gateauFiltrer = function (groupe) {

        if (groupe.gateauChoix.length > 0 || groupe.listeOptions.length > 0) {
            return true;
        }
        return false;
    };

    $scope.filtreForfait = function (groupe) {
        if ($scope.groupeType && $scope.groupeType.groupeTypeId) {
            if (groupe.groupeTypeId == $scope.groupeType.groupeTypeId) {
                return true;
            }
            return false;
        }
        return true;
    };

    // Init
    $scope.rafraichir();

}]);


D1Rapports.controller('D1RapportsAnimateurCtrl', ['$scope', 'Rapports', 'Cedule', '$filter', 'D1Configurations', 'Reservation', function ($scope, Rapports, Cedule, $filter, D1Configurations, Reservation) {
    $scope.Rapports = Rapports;
    $scope.Cedule = Cedule;
    $scope.Reservation = Reservation;
    $scope.showMessage = '';
    $scope.dateDebut = moment(moment().format('YYYY-MM-DD 00:00:00')).day(1).toDate();
    $scope.ListeFetes = [];
    $scope.ListeTodos = [];
    $scope.feteParSalle = [];
    $scope.ifCombo = false;
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;
    $scope.viewMode = 'coordonateur';
    $scope.ifChrono = false
    console.log(' $scope.ifChrono :',  $scope.ifChrono);

    var $translate = $filter('translate');

    // Calendrier
    $scope.options = {
        //customClass: getDayClass,
        //minDate: $scope.dateDebut,
        showWeeks: true
    };

    $scope.ouvrirDD = function () {
        $scope.dd_ouvert = true;
    };

    $scope.ouvrirDF = function () {
        $scope.df_ouvert = true;
    };


    $scope.$watch('dateDebut', function (i) {
        getFetes();
    });

    $scope.$watch('dateFin', function (i) {
        getFetes();
    });

    $scope.changerVue = function (vueMode) {
        $scope.viewMode = vueMode;
        $('.right_col').css('min-height', 'auto');
    };

    $scope.getDateDebut = function () {
        return moment($scope.dateDebut).format('DD MMM');
    };

    $scope.getDateFin = function () {
        return moment($scope.dateFin).format('DD MMM');
    };

    $scope.getArrivee = function (groupe) {
        // Trouver la date la plus petite
        //console.log(groupe);
        var pdate = moment(groupe.partiesDate);
        var sdate = moment(groupe.salleDebut);

        if (groupe.lstVR.length) {
            pdate = moment.min(pdate, moment(groupe.lstVR[0].partieTemps))
        }
        //var vrdate = moment(groupe.salleDebut);
        //console.log(moment.min(pdate,sdate));
        return moment.min(pdate, sdate).toDate();
    };

    function getFetes() {
        //console.log("$scope.dateDebut, $scope.dateFin", $scope.dateDebut, $scope.dateFin);
        Rapports.ListeFetes($scope.dateDebut, $scope.dateDebut).then(function (response) {
            //$scope.ListeFetes = response;

            // Filtrer seulement celles qui ont des fetes
            $scope.ListeFetes = _.where(response, function (r) {
                return r.salleReservee > 0;
            });

            $scope.ListeFetes.forEach(f => {
                if (f.lstOptions.length > 0 || f.gateauChoix.length > 0) {$scope.ifCombo = true}
                f.chronologie = Reservation.chronologie(f, true).map(c =>{
                    return c.description + ': ' + moment(c.timestamp).format('HH:mm')
                })
            })
            /*
            console.log(response);
            var parJour = _.groupBy(response, function (r) { return moment(r.event_date).format("YYYY-MM-DD")});
            console.log("parJour", parJour);
            _.each(parJour, function(day) {
                console.log("day", day);
                _.each(day, function(g) {
                    console.log("g", g);
                    var parHeure = _.groupBy(g.lstParties, 'partieHeures');
                    console.log("parHeure", parHeure);
                });
            });
            */

            // Regrouper par salle de fete pour pouvoir trouver la prochaine utilisation de la salle
            var feteParSalle = _.groupBy($scope.ListeFetes, 'salleReservee');
            angular.forEach($scope.ListeFetes, function (v, k) {
                $scope.ListeFetes[k].pagebreakbefore = (k % 3 == 0 && k != 0);
                $scope.ListeFetes[k].prochaineHeureSalle = '';
                $scope.ListeFetes[k].prochainNomFete = '';

                // Aller chercher la prochaine utilisation de salle
                var curIndex = _.findIndex(feteParSalle[v.salleReservee], { id: v.id });
                if (curIndex != -1) {
                    // On s'est trouvé, allons voir s'il y a une prochaine location
                    var nextFete = feteParSalle[v.salleReservee][curIndex + 1];
                    if (nextFete !== undefined) {
                        // All good, on a une prochaine heure de salle dans la même journée
                        if (moment(v.salleDebut).format("YYYY-MM-DD") == moment(nextFete.salleDebut).format("YYYY-MM-DD")) {
                            $scope.ListeFetes[k].prochaineHeureSalle = nextFete.salleDebut;
                            $scope.ListeFetes[k].tempEntreSalle = moment(nextFete.salleDebut).diff(v.salleFin, 'm');
                            $scope.ListeFetes[k].prochainNomFete = (nextFete.nomFete ? nextFete.nomFete : nextFete.nomOrganisateur);
                        }
                    }
                }
            });
            $scope.feteParSalle = feteParSalle;

            // Faire les todo
            var todoList = response;
            var todoParJour = {};

            // Faire une liste par jour par :
            //  - Début de salle
            //  - Par temps de parties
            //  - Par slush/popcorn
            //  - Par gâteau (préparation -1h)
            //  - Par sortie de salle
            angular.forEach(todoList, function (journee) {
                // Préparer la structure
                var todo;
                journee.event_time = moment(journee.event_time).format('HH:mm');
                if (!todoParJour[journee.partiesDate.format('YYYY-MM-DD')]) {
                    // Nouvelle journée, nouvel array
                    todo = {};
                    todoParJour[journee.partiesDate.format('YYYY-MM-DD')] = todo;
                }
                else {
                    // Prendre la référence de la journée
                    todo = todoParJour[journee.partiesDate.format('YYYY-MM-DD')];
                }
                var gDate = moment(moment(journee.event_date).format('YYYY-MM-DD') + " " + journee.gateauDate + ":00");
                gDate.subtract(1, 'hour');

                if (!todo[journee.event_time]) {
                    todo[journee.event_time.toString()] = [];
                }
                if (journee.groupeType.requiertSlush && !todo[journee.slushDate] && journee.slushDate) {
                    todo[journee.slushDate.toString()] = [];
                }
                if (journee.groupeType.requiertGateau && !todo[journee.gateauDate] && journee.gateauDate) {
                    todo[journee.gateauDate.toString()] = [];
                    todo[gDate.format('HH:mm')] = [];
                }

                // Nouvelle liste d'options (attributs)
                if (journee.lstOptions.length > 0) {
                    _.each(journee.lstOptions, function (attr) {
                        if (attr.selectedOption.temps) {
                            // On doit servir à une heure précise
                            var sf = attr.selectedOption.temps.format('HH:mm');
                            if (!todo[sf]) {
                                todo[sf] = [];
                            }
                            todo[sf].push($translate("Servir") + " " + attr.nom + " pour " + journee.nomFete);
                        }
                    });
                }

                try {
                    todo[journee.event_time].push($translate("Arrivée de") + " " + (journee.nomFete ? journee.nomFete : journee.nomOrganisateur) + (journee.salle ? " (" + journee.salle.salleNom + ")" : ""));
                    var sf = moment(journee.salleFin).format('HH:mm').toString();
                    if (!todo[sf]) {
                        todo[sf] = [];
                    }
                    todo[sf].push($translate("Départ de ") + " " + (journee.nomFete ? journee.nomFete : journee.nomOrganisateur) + (journee.salle ? " (" + journee.salle.salleNom + ")" : ""));

                    if (journee.groupeType.requiertSlush && todo[journee.slushDate] && journee.slushDate) {
                        todo[journee.slushDate].push($translate("Slush/popcorn pour ") + " " + journee.nomFete + (journee.salle ? " (" + journee.salle.salleNom + ")" : ""));
                    }
                    if (journee.groupeType.requiertGateau && todo[journee.gateauDate] && journee.gateauDate) {
                        todo[journee.gateauDate].push($translate("Servir gâteau pour ") + " " + journee.nomFete + (journee.salle ? " (" + journee.salle.salleNom + ")" : ""));
                        if (!todo[gDate.format('HH:mm')]) {
                            todo[gDate.format('HH:mm')] = [];
                        }
                        todo[gDate.format('HH:mm')].push($translate("Sortir le gâteau du réfrigérateur pour ") + " " + journee.nomFete + (journee.salle ? " (" + journee.salle.salleNom + ")" : ""));
                    }
                }
                catch (e) {
                    console.log("Erreur", e);
                    //console.log(gDate.format('HH:mm'));
                    //console.log(todo, gDate, journee);
                }


            });

            $scope.ListeTodos = {};
            $scope.ListeOrganisateur = {};
            //trierParHeure(todoParJour);


            angular.forEach($scope.ListeFetes, function (f) {
                //console.log("fete nbparties", f, f.lstParties, f.lstParties.length);
                var l = todoParJour[f.partiesDate.format('YYYY-MM-DD')];
                angular.forEach(f.lstParties, function (p) {
                    if (!l[p.partieHeures]) {
                        l[p.partieHeures] = [$translate("Partie pour ") + " " + (f.nomFete ? f.nomFete : f.nomOrganisateur) + " " + (f.salle ? "(" + f.salle.salleNom + ") " : "") + $translate(" groupe de ") + " " + p.nombreJoueurs + $translate(" joueurs")];
                    }
                    else {
                        l[p.partieHeures].push($translate("Partie pour ") + " " + (f.nomFete ? f.nomFete : f.nomOrganisateur) + " " + (f.salle ? "(" + f.salle.salleNom + ") " : "") + $translate(" groupe de ") + " " + p.nombreJoueurs + $translate(" joueurs"));
                    }
                });
            });

            trierParHeure(todoParJour);
            trierFeteParHeure(todoList);

            //console.log("todoList", todoList);


            /*
            _.each(todoParJour, function(journee,i) {
                var sortedKeys = _.sortBy(_.keys(journee), function(key){
                    return key;
                });
                var newList = {};
                _.each(sortedKeys, function(k) {
                    newList[k] = journee[k];
                });
                $scope.ListeTodos[i] = angular.copy(newList);
            });
            */

            $('.right_col').css('min-height', 'auto');

            // Doit rajouter les parties qui s'en viennent (async)
            /*
            $scope.$watchCollection('ListeFetes[' + (response.length-1) + '].lstParties', function (i) {
                console.log("$watchCollection('ListeFetes[' + (response.length-1) + '].lstParties'");
                console.log("i", i);
                console.log("response", response);
                //if (i && i.length) {
                if (response && response.length) {
                    angular.forEach($scope.ListeFetes, function(f) {
                        var l = todoParJour[f.partiesDate.format('YYYY-MM-DD')];
                        angular.forEach(f.lstParties, function(p) {
                            if (!l[p.partieHeures]) {
                                l[p.partieHeures] = [$translate("Partie pour ") + f.nomFete + " (" + f.salle.salleNom + $translate(") groupe de ") + p.nombreJoueurs + $translate(" joueurs")];
                            }
                            else {
                                l[p.partieHeures].push($translate("Partie pour ") + f.nomFete + " (" + f.salle.salleNom + $translate(") groupe de ") + p.nombreJoueurs + $translate(" joueurs"));
                            }
                        });
                    });

                    trierParHeure(todoParJour);
                    trierFeteParHeure(todoList);
                }
            });
            */

        });
    }

    function trierFeteParHeure(todoList) {
        var fete = _.filter(todoList, function (ev) { return (ev.groupeType.estFete == 1 || ev.salleReservee > 0); });
        //var fete = _.filter(todoList, function(ev) { return (ev.groupeType.estFete == 1);} );
        var lstFinale = [];

        _.each(fete, function (ev, i) {
            ev.todoOrganisateur = [];

            // Passer au travers de tous les temps (parties, gateaux, slush, etc)
            // Batir une liste d'object {temps:'', description:''}
            _.each(ev.lstPartiesSplit.split(','), function (p, i) {
                if (ev.lstParties[i] !== undefined) {
                    ev.lstParties[i].PasseNom = Cedule.getPasseCouleurNom(p)
                    ev.todoOrganisateur.push({ 'temps': p, 'description': 'Partie #' + (i + 1), 'partie': ev.lstParties[i] });
                }
            });

            // Slush
            if (ev.slushDate) {
                ev.todoOrganisateur.push({ 'temps': ev.slushDate, 'description': 'Dégustation slush/popcorn', 'partie': null });
            }
            // Gateau
            if (ev.gateauDate) {
                ev.todoOrganisateur.push({ 'temps': ev.gateauDate, 'description': 'Dégustation du gâteau ou des bonbons', 'partie': null });
            }

            // Nouvelle liste d'options (attributs)
            if (ev.lstOptions.length > 0) {
                _.each(ev.lstOptions, function (attr) {
                    if (attr.selectedOption.temps) {
                        var sf = attr.selectedOption.temps.format('HH:mm');
                        ev.todoOrganisateur.push({ 'temps': sf, 'description': 'Dégustation de ' + attr.nom, 'partie': null });
                    }
                });
            }

            //console.log("ev.todoOrganisateur = ", ev.todoOrganisateur);
            //console.log("ev.todoOrganisateur (sorted) = ", _.sortBy(ev.todoOrganisateur, 'temps'));
            ev.todoOrganisateur = _.sortBy(ev.todoOrganisateur, 'temps');

            /*
            var sortedKeys = _.sortBy(_.keys(journee), function(key){
                return key;
            });
            _.each(sortedKeys, function(k) {
                newList[k] = journee[k];
            });

             lstFinale.push(angular.copy(newList));
            */

        });
    }

    function trierParHeure(todoParJour) {
        _.each(todoParJour, function (journee, i) {
            var sortedKeys = _.sortBy(_.keys(journee), function (key) {
                return key;
            });
            var newList = {};
            _.each(sortedKeys, function (k) {
                newList[k] = journee[k];
            });
            $scope.ListeTodos[i] = angular.copy(newList);
        });
    }

    $scope.laJournee = function (n) {
        return moment(n).toDate();
    };

    $scope.rafraichir = function () {
        getFetes();
    };

    // Init
    //getFetes();

}]);

D1Rapports.controller('D1RapportsMarketingCtrl', ['$scope', 'Rapports', 'gabarits', '$interpolate', 'Reservation', 'D1Configurations', 'D1Courriel', 'Notification', '$q', '$toastService', function ($scope, Rapports, gabarits, $interpolate, Reservation, D1Configurations, D1Courriel, Notification, $q, $toastService) {
    $scope.Rapports = Rapports;
    $scope.showMessage = '';
    $scope.dateDebut = moment(moment().format('YYYY-MM-DD 00:00:00')).day(1).toDate();
    $scope.dateFin = moment(moment().format('YYYY-MM-DD 00:00:00')).day(7).toDate();
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;
    $scope.liste = [];
    $scope.statutGroupe = "2";
    $scope.statutPaiement = "1"; // Payé par défaut
    $scope.lstGabarits = gabarits;
    $scope.groupSelected = undefined;
    $scope.gabarit = undefined;
    $scope.lstGroupeTypes = D1Configurations.TypeGroupes
    $scope.groupeType = undefined;
    $scope.Reservation = Reservation;
    $scope.groupe_tags = [];

    // var $translate = $filter('translate');
    var lstMois = 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_');

    // Calendrier
    $scope.options = {
        //customClass: getDayClass,
        //minDate: $scope.dateDebut,
        showWeeks: true
    };

    $scope.ouvrirDD = function () {
        $scope.dd_ouvert = true;
    };

    $scope.ouvrirDF = function () {
        $scope.df_ouvert = true;
    };

    $scope.$watch('dateDebut', function (i) {
        // getFetes();
    });

    $scope.$watch('dateFin', function (i) {
        // getFetes();
    });

    $scope.changerVue = function (vueMode) {
        $scope.viewMode = vueMode;
        $('.right_col').css('min-height', 'auto');
    };

    $scope.getDateDebut = function () {
        return moment($scope.dateDebut).format('DD MMM');
    };

    $scope.getDateFin = function () {
        return moment($scope.dateFin).format('DD MMM');
    };

    $scope.laJournee = function (n) {
        return moment(n).toDate();
    };

    $scope.Rechercher = function () {
        getReservations();
    };

    $scope.selectReservation = function (groupe) {
        if ($scope.groupSelected) $scope.groupSelected.selected = false
        if (groupe) groupe.selected = true;
        $scope.groupSelected = groupe;
    }

    function getReservations() {
        $scope.groupSelected = undefined;
        Rapports.ListeReservations($scope.dateDebut, $scope.dateFin).then((r) => {
            // On va passer la liste sous plusieurs filtres
            // 1 - Un courriel doit être présent
            var liste = r.data;
            liste = _.filter(liste, (g) => {
                return g.courriel && g.courriel.length;
            });

            // 2 - Le groupe statut
            liste = _.where(liste, { statutGroupe: parseInt($scope.statutGroupe) });

            // 3 - Type de groupe
            if ($scope.groupeType) {
                liste = _.where(liste, { groupeTypeId: parseInt($scope.groupeType.groupeTypeId) });
            }

            // Final - On ajoute le champ de sélection + tokens
            liste = _.map(liste, l => {
                // Tokens ajoutés
                var ha = moment(l.reservationDate);
                l.partiesDate = moment(l.partiesDate);
                l.event_date = moment(l.reservationDate);
                l.reservationDate = new Date(l.reservationDate);
                l.token = {
                    jour: l.partiesDate.format('D'),
                    mois_nom: l.partiesDate.format('MMMM'),
                    mois: lstMois[l.partiesDate.format('M') - 1],
                    parties: '',
                    heures: 'De ' + moment(l.salleDebut).format('H:mm') + ' à ' + moment(l.salleFin).format('H:mm'),
                    extra: '',
                    heure_arrivee: ha.subtract(15, 'm').format('H:mm'),
                    chronologie: Reservation.chronologie(l)
                };

                angular.forEach(l.lstParties, function (k, i) {
                    l.token.parties += "Parties #" + (i + 1) + ': ' + k.partieHeures + "<br>";
                });

                // Slush + Popcorn
                if (l.slushDate) {
                    l.token.extra = "Votre slush et popcorn seront servi à " + l.slushDate;
                }
                if (l.gateauDate) {
                    l.token.extra += "<br>Votre gâteau sera servi à " + l.gateauDate;
                }

                l.event_time = moment(l.event_time).format('HH:mm');

                // Automatiquement sélectionner le groupe pour l'envoi
                l.selectionne = true;
                return l;
            })

            if (liste.length) {
                $scope.selectReservation(liste[0]);
            }
            $scope.liste = liste;
        });
    }

    $scope.toutSelectionner = function () {
        _.each($scope.liste, g => g.selectionne = true);
    }
    $scope.toutDeselectionner = function () {
        _.each($scope.liste, g => g.selectionne = false);
    }


    /**
     * Section pour les courriels
     */
    Handlebars.registerHelper('inc', function (value, options) {
        if (options == undefined || typeof options !== "number") {
            options = 1;
        }
        return (value + options);
    });


    Handlebars.registerHelper('formatDate', function (value, format) {
        var curDate = moment(value);
        return (curDate.format(format));
    });

    Handlebars.registerHelper('AdjustFormatDate', function (value, format, adjust, adjustType) {
        var curDate = moment(value);
        if (adjust !== undefined && adjustType !== undefined) {
            curDate.add(adjust, adjustType)
        }
        return (curDate.format(format));
    });

    $scope.tinymceOptions = {
        onChange: function (e) {
            // put logic here for keypress and cut/paste changes
        },
        inline: false,
        plugins: 'advlist autolink link image lists charmap print preview, code',
        skin: 'lightgray',
        theme: 'modern',
        resize: true,
        height: 400
    };

    $scope.changerGabarit = function () {
        if ($scope.gabarit) {
            gabarits.forEach(function (g) {
                if (g.id == $scope.gabarit.id) {
                    $scope.sujet = g.versions[0].subject;
                    $scope.courriel_body = g.versions[0].html_content;
                }
            });
        }
    };

    $scope.nbSelectionne = function () {
        return _.filter($scope.liste, { selectionne: true }).length;
    }

    $scope.ok = async function () {
        var listeEnvoi = _.filter($scope.liste, { selectionne: true });
        if (listeEnvoi.length === 0) {
            alert('Veuillez sélectionner au minimum un groupe à qui envoyer un courriel')
        } else {
            if (confirm(`Vous allez envoyer un courriel à ${listeEnvoi.length} personnes. Êtes-vous certain?`)) {
                try {
                    var promises = [];
                    var g = $scope.gabarit;
                    var template = Handlebars.compile($scope.courriel_body);
                    if (g && g.generation === 'legacy') {
                        template = $interpolate($scope.courriel_body, false, null, false);
                    }
                    // console.log('template', template);
                    _.each(listeEnvoi, (groupe, i) => {
                        D1Courriel.Email.to = groupe.courriel;
                        D1Courriel.Email.to_name = groupe.nomOrganisateur;
                        D1Courriel.Email.sujet = $scope.sujet;

                        // Contenu
                        if (g) {
                            D1Courriel.Email.contenu = template(groupe);
                        } else {
                            D1Courriel.Email.contenu = $scope.courriel_body
                        }

                        // Ajouter ce nouveau promise
                        var deferred = $q.defer();
                        promises.push(deferred.promise);

                        D1Courriel.sendmail().then(
                            function (response) {
                                // Tags
                                if ($scope.groupe_tags) {
                                    // Ajouter les tags pour cette réservation
                                    promises.push(new Promise((resolve, reject) => {
                                        const groupe_tags2 = _.union(groupe.groupe_tags, $scope.groupe_tags);
                                        Reservation.updateTags(groupe.groupeId, groupe_tags2).then(
                                            (r) => {
                                                groupe.groupe_tags = groupe_tags2;
                                                resolve(r);
                                            }, (error) => {
                                                console.error(error);
                                                reject(error);
                                            }
                                        )
                                    }));
                                }

                                // Tags
                                if ($scope.notesAdmin) {
                                    // Ajouter les tags pour cette réservation
                                    promises.push(new Promise((resolve, reject) => {
                                        const notesAdmin2 = $scope.notesAdmin + (groupe.notesAdmin.length ? `\r\n${groupe.notesAdmin}` : '');
                                        Reservation.updateNotesAdmin(groupe.groupeId, notesAdmin2).then(
                                            (r) => {
                                                groupe.notesAdmin = notesAdmin2;
                                                resolve(r);
                                            }, (error) => {
                                                console.error(error);
                                                reject(error);
                                            }
                                        )
                                    }));
                                }

                                if ($scope.notes) {
                                    // Ajouter un historique pour garder une trace;
                                    var histo = {
                                        groupeId: groupe.groupeId,
                                        action: 'email',
                                        detail: `Courriel envoyé à [${groupe.courriel}] avec le sujet [${$scope.sujet}] et le gabarit [${($scope.gabarit ? $scope.gabarit.name : 'Aucun')}] : ${$scope.notes}`
                                    };

                                    if ($scope.groupe_tags) {
                                        histo.detail += ` | Tags : ${_.pluck($scope.groupe_tags, 'text').join(', ')}`
                                    }

                                    if ($scope.notesAdmin) {
                                        histo.detail += ` | Notes admin : ${$scope.notesAdmin}`
                                    }
                                    Reservation.ajouterHistorique(histo).then((rh) => {
                                        deferred.resolve(`Courriel envoyé à : ${groupe.courriel}`);
                                    });
                                } else {
                                    deferred.resolve(`Courriel envoyé à : ${groupe.courriel}`);
                                }
                            },
                            function (err) {
                                deferred.reject(`Erreur de courriel pour : ${groupe.courriel}. ${err}`);
                            });
                    })

                    // Message
                    $toastService.showSuccess("Envoi des courriels", "<i class='fa fa-2x fa-check-circle'></i> Envoi massif démarré avec succès.<br>Veuillez patienter jusqu'à la fin")

                    $q.all(promises)
                        .then((r) => {
                            $toastService.showSuccess("Envoi terminé", "<i class='fa fa-2x fa-check-circle'></i> Envoi massif terminé avec succès.")
                        }, (error) => {
                            console.error('promise all error', error);
                            $toastService.showError("Erreur de courriel", "<i class='fa fa-2x fa-bomb'></i> Échec de l'envoi du courriel : " + error.toString())
                        })

                } catch (error) {
                    $toastService.showError("Erreur de l'envoi massif!", "<i class='fa fa-2x fa-bomb'></i> Échec de l'envoi du courriel : ")
                }

            }
        }
    }

    // Init
    getReservations();

}]);


D1Rapports.controller('D1RapportsStatistiquesCtrl', ['$scope', 'Rapports', 'D1Configurations', function ($scope, Rapports, D1Configurations) {
    $scope.Rapports = Rapports;
    $scope.showMessage = '';
    $scope.dateDebut = moment(moment().format('YYYY-MM-DD 00:00:00')).day(1).toDate();
    $scope.dateFin = moment(moment().format('YYYY-MM-DD 00:00:00')).day(7).toDate();
    $scope.ListeReservations = [];
    $scope.ListeTypesGroupe = [];
    $scope.ListeTypesAssembly = [];
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;

    // Calendrier
    $scope.options = {
        //customClass: getDayClass,
        //minDate: $scope.dateDebut,
        showWeeks: false
    };

    $scope.viewInfo = function () {
        console.log($scope.ListeReservations);
    };

    $scope.ouvrirDD = function () {
        $scope.dd_ouvert = true;
    };

    $scope.ouvrirDF = function () {
        $scope.df_ouvert = true;
    };

    $scope.$watch('dateDebut', function (i) {
    });

    $scope.$watch('dateFin', function (i) {
    });

    $scope.getDateDebut = function () {
        return moment($scope.dateDebut).format('DD MMM');
    };

    $scope.getDateFin = function () {
        return moment($scope.dateFin).format('DD MMM');
    };

    $scope.rafraichir = function () {
        getData();
    };

    function getData() {
        $scope.ListeReservations = [];
        $scope.ListeTypesGroupe = [];
        $scope.ListeTypesGroupeJournee = [];
        $scope.ListeTypesAssembly = [];

        Rapports.ListeTypeGroupesParJournee($scope.dateDebut, $scope.dateFin).then(function (response) {
            $scope.ListeTypesGroupeJournee = _.groupBy(response.data, 'Journee');

        });

        Rapports.ListeReservations($scope.dateDebut, $scope.dateFin).then(function (response) {
            $scope.ListeReservations = response.data;

            var t = _.countBy(_.sortBy($scope.ListeReservations, function (i) { return i.groupeTypeNom }), function (item) {
                return item.groupeTypeNom;
            });
            $scope.myChartObject.data.rows = [];
            angular.forEach(t, function (r, k) {
                $scope.myChartObject.data.rows.push({
                    c: [
                        { v: k },
                        { v: r }
                    ]
                });
            });


            //console.log(response.data);

            // Section des types de groupes
            $scope.ListeTypesGroupe = _.groupBy(response.data, function (r) { return r.groupeTypeId; });

            if ($scope.ListeTypesGroupe[0]) {
                // Regrouper les groupe par nombre de joueurs
                // De 1 à 6
                // De 7 à 15
                // Plus de 15

                var temp = []

                temp = _.groupBy($scope.ListeTypesGroupe[0], function (g) {
                    if (g.nombreJoueurs <= 6) {
                        return 1;
                    }
                    if (g.nombreJoueurs <= 15) {
                        return 2;
                    }
                    if (g.nombreJoueurs > 15) {
                        return 3;
                    }
                    // Catch-all
                    return 0;
                });


                var temp2 = [];
                _.each(temp, function (g, typeId) {
                    //g[0].
                    var t = {};
                    t.groupeTypeId = g[0].groupeTypeId;
                    t.groupeTypeNom = g[0].groupeTypeNom;
                    t.partiesDate = g[0].partiesDate;
                    t.nomFete = '';
                    t.nomOrganisateur = (typeId == 1 ? 'Moins de 7 joueurs' : (typeId == 2 ? 'De 7 à 15 joueurs' : 'Plus de 15 joueurs'));
                    t.nombreJoueurs = 0;
                    t.notesAdmin = g.length.toString() + " groupes";

                    _.each(g, function (g2) { t.nombreJoueurs += g2.nombreJoueurs });

                    temp2.push(t);
                });

                $scope.ListeTypesGroupe[0] = temp2;
            }
        });
    }

    // Init
    getData();

    /**
     * Chart sruff
     * @type {{}}
     */
    $scope.myChartObject = {};
    $scope.myChartObject.type = "BarChart";
    $scope.myChartObject.data = {
        "cols": [
            { id: "t", label: "Type de groupe", type: "string" },
            { id: "s", label: "Réservations", type: "number" }
        ]
        , "rows": []
    };
    $scope.myChartObject.options = {
        'title': 'Réservations par type de groupe'
    };


    /**
     * TEST
     */
    $scope.myChartObject2 = {};
    $scope.myChartObject2.type = "ColumnChart";
    $scope.myChartObject2.data = {
        "cols": [
            { id: "t", label: "Type de groupe", type: "string" }
        ]
        , "rows": []
    };
    $scope.myChartObject22 = {
        "type": "ColumnChart",
        "displayed": false,
        "data": {
            "cols": [
                {
                    "id": "month",
                    "label": "Month",
                    "type": "string",
                    "p": {}
                },
                {
                    "id": "laptop-id",
                    "label": "Laptop",
                    "type": "number",
                    "p": {}
                },
                {
                    "id": "desktop-id",
                    "label": "Desktop",
                    "type": "number",
                    "p": {}
                },
                {
                    "id": "server-id",
                    "label": "Server",
                    "type": "number",
                    "p": {}
                },
                {
                    "id": "cost-id",
                    "label": "Shipping",
                    "type": "number"
                }
            ],
            "rows": [
                {
                    "c": [
                        {
                            "v": "January",
                            "p": {}
                        },
                        {
                            "v": 19,
                            "f": "42 items",
                            "p": {}
                        },
                        {
                            "v": 12,
                            "f": "Ony 12 items",
                            "p": {}
                        },
                        {
                            "v": 7,
                            "f": "7 servers",
                            "p": {}
                        },
                        {
                            "v": 4,
                            "p": {}
                        }
                    ]
                },
                {
                    "c": [
                        {
                            "v": "February",
                            "p": {}
                        },
                        {
                            "v": 13,
                            "p": {}
                        },
                        {
                            "v": 1,
                            "f": "1 unit (Out of stock this month)",
                            "p": {}
                        },
                        {
                            "v": 55,
                            "p": {}
                        },
                        {
                            "v": 2,
                            "p": {}
                        }
                    ]
                },
                {
                    "c": [
                        {
                            "v": "January",
                            "p": {}
                        },
                        {
                            "v": 19,
                            "f": "42 items",
                            "p": {}
                        },
                        {
                            "v": 12,
                            "f": "Ony 12 items",
                            "p": {}
                        },
                        {
                            "v": 7,
                            "f": "7 servers",
                            "p": {}
                        },
                        {
                            "v": 4,
                            "p": {}
                        }
                    ]
                },
                {
                    "c": [
                        {
                            "v": "February",
                            "p": {}
                        },
                        {
                            "v": 13,
                            "p": {}
                        },
                        {
                            "v": 1,
                            "f": "1 unit (Out of stock this month)",
                            "p": {}
                        },
                        {
                            "v": 55,
                            "p": {}
                        },
                        {
                            "v": 2,
                            "p": {}
                        }
                    ]
                },
                {
                    "c": [
                        {
                            "v": "March",
                            "p": {}
                        },
                        {
                            "v": 24,
                            "p": {}
                        },
                        {
                            "v": 5,
                            "p": {}
                        },
                        {
                            "v": 11,
                            "p": {}
                        },
                        {
                            "v": 6,
                            "p": {}
                        }
                    ]
                }
            ]
        },
        "options": {
            "title": "Sales per month",
            "isStacked": "true",
            "fill": 20,
            "displayExactValues": true,
            "vAxis": {
                "title": "Sales unit",
                "gridlines": {
                    "count": 10
                }
            },
            "hAxis": {
                "title": "Date"
            }
        },
        "formatters": {},
        "view": {}
    };


    /**
     * Grid stuff
     * @type {{enableSorting: boolean, columnDefs: *[], onRegisterApi: $scope.gridOptions1.onRegisterApi}}
    $scope.gridOptions1_old = {
        enableSorting: true,
        columnDefs1: [
            { field: 'groupeId' },
            { field: 'nomOrganisateur' },
            { field: 'nombreJoueur', enableSorting: false }
        ],
        onRegisterApi1: function( gridApi ) {
            console.log(gridApi);
            $scope.grid1Api = gridApi;
        },
        data : $scope.ListeReservations
    };

    $scope.gridOptions1 = {
        enableSorting: true,
        enableCellEditOnFocus : true,
        columnDefs: [
            { field: 'groupeId' },
            { field: 'nomOrganisateur', enableCellEdit: true },
            { field: 'nombreJoueur', enableSorting: false }
        ],
        onRegisterApi: function( gridApi ) {
            console.log(gridApi);
            $scope.grid1Api = gridApi;
        },
        data : []
    };
     */


}]);

D1Rapports.controller('D1RapportsVentesCtrl', ['$scope', 'Rapports', '$filter', function ($scope, Rapports, $filter) {
    $scope.Rapports = Rapports;
    $scope.showMessage = '';
    $scope.dateDebut = moment(moment().format('YYYY-MM-DD 00:00:00')).toDate();
    $scope.dateFin = moment(moment().format('YYYY-MM-DD 00:00:00')).toDate();
    $scope.ListeVentes = [];
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;

    // BArcode TEST
    $scope.myBarCode = "231035171287";
    $scope.bc = {
        format1: 'CODE128',
        format: 'CODE39',
        lineColor: '#000000',
        width: 2,
        height: 100,
        displayValue: true,
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 20,
        background: '#ffffff',
        margin: 0,
        marginTop: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
        marginRight: undefined,
        flat: true,
        valid: function (valid) {
        }
    };

    $scope.onProcessed = function (result) {
        console.log('yes', result);
    };
    $scope.onDetected = function (result) {
        console.log('onDet', result);
    };

    // Calendrier
    $scope.options = {
        //customClass: getDayClass,
        //minDate: $scope.dateDebut,
        showWeeks: false
    };

    $scope.viewInfo = function () {
        console.log($scope.ListeVentes);
    };

    $scope.ouvrirDD = function () {
        $scope.dd_ouvert = true;
    };

    $scope.ouvrirDF = function () {
        $scope.df_ouvert = true;
    };

    $scope.$watch('dateDebut', function (i) {
    });

    $scope.$watch('dateFin', function (i) {
    });

    $scope.getDateDebut = function () {
        return moment($scope.dateDebut).format('DD MMM');
    };

    $scope.getDateFin = function () {
        return moment($scope.dateFin).format('DD MMM');
    };

    $scope.rafraichir = function () {
        getData();
    };

    function getData() {
        $scope.ListeVentes = [];
        Rapports.ListeVentes($scope.dateDebut, $scope.dateFin).then(function (response) {
            $scope.ListeVentes = response.data;

            if (response.data["@attributes"].count != '0') {
                $scope.myChartObject.data.rows = [];
                $scope.myChartObject.data.cols = [];

                var l = $scope.ListeVentes.salesByCategory;
                _.each(l, function (item) {
                    if (item.calcSubtotal >= 0) {
                        var cVal = $filter('currency')(item.calcSubtotal);
                        $scope.myChartObject.data.cols.push({
                            id: item.categoryName,
                            label: item.categoryName + ' (' + cVal + ')',
                            type: "string"
                        });
                        $scope.myChartObject.data.cols.push({
                            id: "v",
                            label: "ventes",
                            type: "number"
                        });
                        $scope.myChartObject.data.rows.push({
                            c: [
                                { v: item.categoryName + ' (' + cVal + ')' },
                                { v: item.calcSubtotal }
                            ]
                        });
                    }
                });
                $scope.myChartObject.options.title = 'Ventes par catégories (' + $filter('currency')($scope.ListeVentes.stats.calcWithoutDiscount) + ")";
            }
        });
    }

    // Init
    getData();

    /**
     * Chart sruff
     * @type {{}}
     */
    $scope.myChartObject = {};
    $scope.myChartObject.type = "PieChart";
    $scope.myChartObject.data = {
        "cols": []
        , "rows": []
    };
    $scope.myChartObject.options = {
        'title': 'Ventes par catégories',
        pieHole: 0.4
    };
    var formatters = {
        number: [{
            prefix: '$',
            columnNum: 1,
            fractionDigits: 2
        }]
    };
    $scope.myChartObject.formatters = formatters;



}]);

D1Rapports.controller('D1RapportsCodebarreCtrl', ['$scope', 'Rapports', function ($scope, Rapports) {
    // Init
    $scope.codebarres = [];

    for (i = 0; i < 80; i++) {
        $scope.codebarres.push(Math.floor((Math.random() * 1000000000) + 1));
    }

    $scope.bc = {
        format: 'CODE128',
        lineColor: '#000000',
        width: 2,
        height: 20,
        displayValue: true,
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 12,
        background: '#ffffff',
        margin: 0,
        marginTop: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
        marginRight: undefined,
        flat: false,
        valid: function (valid) {
        }
    };

    $scope.doPrint = function () {
        if ($scope.readyToPrint) {
            window.print();
            window.close();
        }
    };

}]);