import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleService } from '@modules/schedule/services';
import moment from 'moment';

@Component({
    selector: 'd1-reservations-game-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-game-card.component.html',
    styleUrls: ['reservations-game-card.component.scss'],
})
export class ReservationsGameCardComponent implements OnInit {
    @Input() game: GameModel = new GameModel();
    @Input() includePlayerNumber:boolean = false;
    @Input() allowEdit:boolean = false;
    @Input() allowDelete:boolean = false;
    @Output() changeGame:EventEmitter<GameModel> = new EventEmitter();

    constructor(private ref: ChangeDetectorRef, private s$:ScheduleService) {

    }

    getPasseCouleur(g:GameModel) {
        return this.s$.getPasseCouleur(moment(g.partieTemps).format('HH:mm'))
    }
    
    getClasses(g:GameModel) {
        return [`bg-${this.getPasseCouleur(g)}`]
    }

    deleteGame(g:GameModel) {
        console.log('should delete game', g)
    }

    increasePlayers(g:GameModel) {
        g.nombreJoueurs = Math.max(0, g.nombreJoueurs+1)
        console.log('++', g);
    }

    decreasePlayers(g:GameModel) {
        g.nombreJoueurs = Math.max(0, g.nombreJoueurs-1)
        console.log('--', g);
    }

    ngOnInit() {
        
    }

    onChange(event:any) {
        console.log('game card change', event);
        this.game.nombreJoueurs = +event;
        this.changeGame.emit(this.game);
    }
}
