<div class="container-fluid">
    <div class="d-flex flex-row justify-content-between">
        <mat-form-field class="w-50" [class.d-none]="!showFilter"><mat-label>Filtre</mat-label><input matInput (keyup)="applyFilter($event)" placeholder="Ex. Flolan" #input autocomplete="off"/></mat-form-field>
        <div><button class="btn btn-primary btn-sm" *ngIf="disabled === false &amp;&amp; allowAdd === true" (click)="onAddClick()">Ajouter</button></div>
    </div>
    <table class="mat-elevation-z1" mat-table [dataSource]="dataSource" [width]="width" matSort>
        <ng-container matColumnDef="_select_"
            ><th class="selectCol" mat-header-cell *matHeaderCellDef><mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() &amp;&amp; isAllSelected()" [indeterminate]="selection.hasValue() &amp;&amp; !isAllSelected()"></mat-checkbox></th>
            <td class="selectCol" mat-cell *matCellDef="let element"><mat-checkbox (click)="$event.stopPropagation()" (change)="onSelectionChange($event, element)" [checked]="selection.isSelected(element)"></mat-checkbox></td></ng-container
        ><ng-container *ngFor="let cd of getSortable()" [matColumnDef]="cd.name"
            ><th mat-header-cell *matHeaderCellDef mat-sort-header>{{ cd.headerText}}</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!cd.showAsPills">{{ RenderElement(element[cd.name], cd) }}</div>
                <d1-display-info *ngIf="cd.showAsPills" [list]="element[cd.name]"></d1-display-info></td></ng-container
        ><ng-container *ngFor="let cd of getNotSortable()" [matColumnDef]="cd.name"
            ><th mat-header-cell *matHeaderCellDef>{{ cd.headerText}}</th>
            <td mat-cell *matCellDef="let element" [class.action]="cd.name === '_action_'">
                <span *ngIf="cd.name !== '_action_'"
                    ><div *ngIf="!cd.showAsPills">{{ RenderElement(element[cd.name], cd) }}</div>
                    <d1-display-info *ngIf="cd.showAsPills" [list]="element[cd.name]"></d1-display-info></span
                ><span *ngIf="cd.name === '_action_' &amp;&amp; disabled === false &amp;&amp; showAction"
                    ><button class="mr-2" *ngIf="allowEdit" mat-mini-fab color="primary" (click)="action(element, 'edit', $event)"><i-feather name="edit-3"></i-feather></button><button *ngIf="allowDelete" mat-mini-fab color="warn" (click)="action(element, 'delete', $event)"><i-feather name="trash-2"></i-feather></button
                ></span></td
        ></ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{selectable: disabled === false}" *matRowDef="let row; columns: displayedColumns;" (click)="onClick(row, $event)"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Aucune donnée disponible</td>
        </tr>
    </table>
    <mat-paginator [class.d-none]="(data.length &lt; pageSize)" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
</div>
