import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ColumnDefinitionsModel, ClickEventArg } from '@common/components';
import { FullCalendarComponent} from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/core'
import { RentalsAvailabilityEditComponent } from '@modules/rentals/components';
import { EventModel, Slot, SlotEventModel } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import moment from 'moment';
import frLocale from '@fullcalendar/core/locales/fr';
import _ from 'lodash';

// Full calendar plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timePlugin from '@fullcalendar/timegrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin

@Component({
    selector: 'd1-rentals-availability-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-availability-calendar.component.html',
    styleUrls: ['rentals-availability-calendar.component.scss'],
})
export class RentalsAvailabilityCalendarComponent implements OnInit, OnChanges {
    // public ListeSlots: Slot[] = [];
    // public listes = [];
    // public curDate = new Date('2021-05-29 12:00:00');
    // public numEquipement = 0;

    @Input() initialDate: Date = new Date();
    @Input() minimumQuantity: number = 1;
    @Output() selected:EventEmitter<SlotEventModel> = new EventEmitter();

    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
    calendarApi!: Calendar;

    public caloptions: CalendarOptions = {
        plugins: [
            dayGridPlugin,
            timePlugin,
            interactionPlugin,
            // adaptivePlugin 
        ],
        timeZone: 'local',
        initialView: 'dayGridMonth',
        locale: frLocale,
        aspectRatio: 3,
        fixedWeekCount : false,
        showNonCurrentDates : false,
        navLinks: true,
        customButtons: {
            addNewSlot: {
              text: '(+) dispo',
              click: this.OnAdd.bind(this)
            }
          },
        headerToolbar: {
            left: 'prev,next today addNewSlot',
            center: 'title',
            right: 'dayGridDay,dayGridWeek,dayGridMonth',
        },
        contentHeight : "75vh",
        eventClick : this.eventClick.bind(this),
        editable: true,
        eventSourceSuccess: (content, xhr) => {
            return _.map(content, r => {
                if (r.slot_id !== undefined) {
                    if (r.availability_count >= this.minimumQuantity) {
                        return {
                            start:  moment(r.start_sec).toISOString(),
                            end : moment(r.start_sec).add(r.duration_sec, 'm').toISOString(),
                            title : `${r.availability_count} / ${r.inventory_count} ${(r.numRentals ? '(' + r.numRentals + ' locations)' : '')}`,
                            rentals : r.rentals,
                            slot : r,
                            id : r.slot_id,
                        } as SlotEventModel
                    } else {
                        return [];
                    }
                } else {
                    return r;
                }
            });
        },
        eventSources: [

            // your event source
            {
              id: 'slot',
              url: '/api/bookings/ListSlots',
              method: 'POST',
              backgroundColor : 'orange',
              extraParams: {
                num: this.minimumQuantity
              },
              display : 'auto',
              failure: function() {
                alert('there was an error while fetching events!');
              },
              color: 'blue',   // a non-ajax option
              textColor: 'black' // a non-ajax option
            },

            // SCHEDULE SOURCE
            {
              id: 'horaire',
              url: '/api/bookings/ListSchedule',
              method: 'POST',
            //   backgroundColor : 'orange',
              failure: function() {
                alert('there was an error while fetching events!');
              },
              color: 'green',   // a non-ajax option
              textColor: 'white' // a non-ajax option
            }

            // any other sources...

          ]
    }

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private dialog$: MatDialog
    ) {
        this.initialDate = new Date();
        this.minimumQuantity = 1;
        this.fetchData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialDate) {
            if (!changes.initialDate.currentValue) {
                this.initialDate = new Date();
            } else {
                this.initialDate = new Date(changes.initialDate.currentValue);
            }
            if (this.calendarApi) {
                this.calendarApi.gotoDate(this.initialDate)
            }
    }
        if (changes.minimumQuantity && !changes.minimumQuantity.currentValue) {
            this.minimumQuantity = 1;
        }
    }

    ngAfterViewInit(): void {
        if (this.calendarComponent) {
            this.calendarApi = this.calendarComponent.getApi();
            if (!this.initialDate) {
                this.initialDate = new Date();
            }
            this.calendarApi.gotoDate(this.initialDate)
        }
        // this.calendarApi.render()
    }

    ngOnInit() {
    }

    OnAdd() {
        // this.router.navigate(['add'], { relativeTo: this.nav });
        const slot: Slot = {
            slot_id : 0,
            availability_count : 0,
            availability_tag : '',
            confirmation_mode : 1,
            duration_sec : 0,
            status : 1,
            inventory_count : 0,
            service_id : '1',
            start_sec : new Date()
        }
        const dialogRef = this.dialog$.open(RentalsAvailabilityEditComponent, {data : { slot : slot} });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== 'annuler') {
                this.rentals$.createSlot$(result.data).subscribe( r => {
                    this.fetchData();
                })

            }
        });
    }

    eventClick(info: any) {
        if (info.event.id) {
            info.event.extendedProps.slot.numRentals = this.minimumQuantity;
            this.selected.emit(info.event);
            // this.router.navigate([info.event.id], { relativeTo : this.nav})
        } else {
            console.log('NO ID');
        }
    }

    changeDate(newDate: any) {
        this.initialDate = moment(newDate).toDate();
        this.calendarApi.gotoDate(this.initialDate)
    }

    changeQuantity(num: any) {
        if (typeof num === 'string' &&  this.minimumQuantity !== +num) {
            // this.numEquipement = num;
            _.each(this.caloptions.eventSources, (src:any) => {
                if (src.id === 'slot') {
                    src.extraParams.num = num;
                }
            })

            this.minimumQuantity = +num;
            this.calendarApi.refetchEvents();
        }
    }

    onChange() {}

    fetchData() {
        // this.rentals$.getSlots$().subscribe((r) => {
        //     this.ListeSlots = r as Slot[];
        //     this.ref.markForCheck();
        // });
    }

}
