import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from '@common/components/dialog-confirmation/dialog-confirmation.component';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})

export class MessageBoxService {

    constructor(private dialog$: MatDialog) {}

    /**
     * Ouvrir un message box
     * @param header Message entete
     * @param message Contenu
     * @param button1 Texte du bouton #1
     * @param button2 (optionel) Texte du bouton #2
     * @returns Observable<string> Le texte du bouton cliqué
     */
    public open(header:string, message:string, button1: string = 'Ok', button2: string = ''): Observable<string> {
        const modalRef = this.dialog$.open(DialogConfirmationComponent, {
            data: { header, message, button1, button2 }
        });
        return modalRef.afterClosed();
    }

    /**
     * Ouvrir un message box pour confirmer un "delete"
     * @param header (optionel) Message entete : Défaut : Confirmer
     * @param message (optionel) Contenu : Défaut : Êtes-vous certain de vouloir effacer cet item?
     * @param button1 (optionel) Texte du bouton #1 : Défaut : Effacer
     * @param button2 (optionel) Texte du bouton #2 : Défaut : Annuler
     * @returns Observable<string> Le texte du bouton cliqué
     */
    public Confirm(header:string = 'Confirmer', message:string = '', button1: string = 'Oui', button2: string = 'Non'): Observable<string> {
        const modalRef = this.dialog$.open(DialogConfirmationComponent, {
            data: { header, message, button1, button2 }
        });
        return modalRef.afterClosed();
    }

    /**
     * Ouvrir un message box pour confirmer un "delete"
     * @param header (optionel) Message entete : Défaut : Confirmer
     * @param message (optionel) Contenu : Défaut : Êtes-vous certain de vouloir effacer cet item?
     * @param button1 (optionel) Texte du bouton #1 : Défaut : Effacer
     * @param button2 (optionel) Texte du bouton #2 : Défaut : Annuler
     * @returns Observable<string> Le texte du bouton cliqué
     */
    public ConfirmDelete(header:string = '', message:string = '', button1: string = '', button2: string = ''): Observable<string> {
        const modalRef = this.dialog$.open(DialogConfirmationComponent, {
            data: { header, message, button1, button2 }
        });
        return modalRef.afterClosed();
    }
}
