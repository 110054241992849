import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ReservationsService } from '../../services/reservations.service';
import { BaseChartDirective } from 'ng2-charts';
import moment from 'moment';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
    selector: 'd1-reservations-stats-periode',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-stats-periode.component.html',
    styleUrls: ['reservations-stats-periode.component.scss'],
})
export class ReservationsStatsPeriodeComponent implements OnInit {
    @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective | undefined;

    public barChartLegend = true;
    @Input() width = undefined;
    @Input() height = undefined;
    public startDate = moment().day(1).toDate();
    public endDate = moment().day(7).toDate();
    public startDate2: Date | null = null;
    public endDate2: Date | null = null;
    public fetching = false;
    public fetching2 = false;
    public type: any = 'weekday';
    
    public barChartData: ChartConfiguration<'bar' | 'line'>['data'] = {
        labels: [],
        // labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [],
    };
    public barChartOptions: ChartConfiguration<'bar'>['options'] = {
        responsive: true,
        maintainAspectRatio : false,
        // aspectRatio : 1.6
        layout: {
            padding : {
                top: 30
            }
        },
        scales: {
            'y-axis-1':  {axis: 'y', title: { text: '# Groupes', display: true} },
            'y-axis-2':  {axis: 'y', title: { text: '# Joueurs', display: true}, position: 'right' },
            x : {
                ticks: {
                    source: 'labels'
                }
            }
        },
        plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            datalabels: {
              anchor: 'end',
              align: 'end'
            }
          }
    };
    public barChartPlugins = [
        DataLabelsPlugin
    ];

    constructor(private r$:ReservationsService, private ref:ChangeDetectorRef) {

    }

    fetchData1() {
        // Ne pas faire de fecth plusieurs fois
        if (this.startDate && this.endDate && !this.fetching) {
            // Get data
            this.barChartData.datasets = [];
            this.barChartData.labels = this.type !== 'weekday' ? [] : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            this.fetching = true;
            this.r$.getStatistiques(moment(this.startDate).toDate(), moment(this.endDate).toDate(), this.type).subscribe( (r:any[]) => {
                const data  = r.map( (v) => {
                    return {x: v.Journee, y: v.NombreGroupes, label: v.NombreGroupes}
                });
                this.barChartData.datasets.push (
                    { data: data, label: `Groupes P1: ${moment(this.startDate).year()}`, yAxisID: 'y-axis-1'},
                    { data: r.map( (v) => {
                        return {x: v.Journee, y: v.NumJoueurs, label: v.NumJoueurs};
                    }), label: `Joueurs P1: ${moment(this.startDate).year()}`, type: this.type !== 'typegroupe' ? 'line' : 'bar', yAxisID: 'y-axis-2', datalabels: {color: 'blue'}}
                )
                this.fetching = false;
                
                // Aller chercher le 2e data (pas super pour le moment de refaire encore le data)
                this.fetchData2();
                if (!this.fetching2) {
                    this.chart?.render()
                    this.ref.markForCheck();
                }
            })

        }
    }

    fetchData2() {
        
        // Ne pas faire de fecth plusieurs fois
        if (this.startDate2 && this.endDate2 && !this.fetching2) {
            // Init
            this.fetching2 = true;
            
            // Aller chercher l'année précédente
            this.r$.getStatistiques(moment(this.startDate2).toDate(), moment(this.endDate2).toDate(), this.type).subscribe( (r:any[]) => {
                const info = [...this.barChartData.datasets];
                if (this.barChartData.datasets.length > 2) {
                    this.barChartData.datasets = this.barChartData.datasets.slice(0, 2);
                }
                const data2 = r.map( (v) => {
                    return {x: v.Journee, y: v.NombreGroupes, label: v.NombreGroupes}
                });
                this.barChartData.datasets.push(
                    { data: data2, label: `Groupes P2: ${moment(this.startDate2).year()}`, yAxisID: 'y-axis-1'},
                    { data: r.map( (v) => {
                        return {x: v.Journee, y: v.NumJoueurs, label: v.NumJoueurs};
                    }), label: `Joueurs P2: ${moment(this.startDate2).year()}`, type: this.type !== 'typegroupe' ? 'line' : 'bar', yAxisID: 'y-axis-2', datalabels: {color: 'red'}}
                )
                this.chart?.render()
                this.ref.markForCheck();
                this.fetching2 = false;
            })
        }
    }

    ngOnInit(): void {
        // On va mettre nos dates à la semaine courante
        this.startDate = moment().day(1).toDate();
        this.endDate = moment().day(7).toDate();
    }

    rangeChange(dates: any) {
        if (dates.start && dates.end) {
            this.fetchData1();
        }
    }

    rangeChange2(dates: any) {
        if (dates.start && dates.end) {
            this.fetchData2();
        }
    }

    typeChange(event: MatButtonToggleChange) {
        this.type = event.value;
        this.fetching = false;
        this.fetching2 = false;
        this.fetchData1();
    }
}
