import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'd1-shopify-customer-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-customer-dialog.component.html',
    styleUrls: ['shopify-customer-dialog.component.scss'],
})
export class ShopifyCustomerDialogComponent implements OnInit {
    public customer: Customer = {
        id: 0,
        email: '',
        accepts_marketing: false,
        created_at: new Date(),
        updated_at: new Date(),
        first_name: '',
        last_name: '',
        orders_count: 0,
        state: false,
        total_spent: '',
        last_order_id: 0,
        note: '',
        verified_email: false,
        multipass_identifier: '',
        tax_exempt: false,
        tags: '',
        last_order_name: '',
        currency: '',
        phone: '',
        addresses: [],
        accepts_marketing_updated_at: new Date(),
        marketing_opt_in_level: '',
        tax_exemptions: [],
        email_marketing_consent: {},
        sms_marketing_consent: null,
        admin_graphql_api_id: '',
        default_address: {
            id: '',
            customer_id: '',
            first_name: '',
            last_name: '',
            company: '',
            address1: '',
            address2: '',
            city: '',
            province: '',
            country: '',
            zip: '',
            phone: '',
            name: '',
            province_code: '',
            country_code: '',
            country_name: '',
            default: ''
        },
        orders: [],
    };

    constructor(@Inject(MAT_DIALOG_DATA) data: DialogInfo, public dialogRef: MatDialogRef<ShopifyCustomerDialogComponent>, private ref: ChangeDetectorRef) {
        console.log('Data', data);
        if (data.customer) {
            this.customer = data.customer
        }
    }

    ngOnInit() {

    }

    filterProperties(list:any[]) {
        return list.filter( (i:any) => i.name[0] !== '_' );
    }
}

export type DialogInfo = {
    customer: Customer
}

export type CustomerAddress = {
    id: string,
    customer_id: string,
    first_name: string,
    last_name: string,
    company: string,
    address1: string,
    address2: string,
    city: string,
    province: string,
    country: string,
    zip: string,
    phone: string,
    name: string,
    province_code: string,
    country_code: string,
    country_name: string,
    default: string
}

export type Customer = {
        id: number,
        email: string,
        accepts_marketing: boolean,
        created_at: Date,
        updated_at: Date,
        first_name: string,
        last_name: string,
        orders_count: number,
        state: boolean,
        total_spent: string,
        last_order_id: number,
        note: string,
        verified_email: boolean,
        multipass_identifier: string,
        tax_exempt: boolean,
        tags: string,
        last_order_name: string,
        currency: string,
        phone: string,
        addresses:CustomerAddress[],
        accepts_marketing_updated_at: Date,
        marketing_opt_in_level: string,
        tax_exemptions: [],
        email_marketing_consent: any,
        sms_marketing_consent: null,
        admin_graphql_api_id: string,
        default_address: CustomerAddress
        orders?:[]
}