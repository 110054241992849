import { X } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    selector: 'ng1-date-range-picker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './ng1-date-range-picker.component.html',
    styleUrls: ['ng1-date-range-picker.component.scss'],
})
export class Ng1DateRangePickerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() startDate:Date | null = null;
    @Input() endDate:Date | null = null;
    @Input() label:string | null = null;
    @Output() rangeChange:EventEmitter<any>= new EventEmitter();
    @Output() startDateChange:EventEmitter<Date | null>= new EventEmitter();
    @Output() endDateChange:EventEmitter<Date | null>= new EventEmitter();
    private _subs?:Subscription[] = []

    public range: FormGroup;
    
    constructor() {
        this.range = new FormGroup({
            start: new FormControl(this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null),
            end: new FormControl(this.endDate ? new Date(this.endDate) : null),
        });
    }

    ngOnInit() {
        this._subs?.push(this.range.valueChanges.pipe(
            distinctUntilChanged( (p,c) => {
                return p.start === c.start && p.end === c.end;
            })
        ).subscribe( (v) => {
            this.rangeChange.emit({
                start: v.start ? moment(v.start).toISOString() : v.start, 
                end: v.end ? moment(v.end).toISOString() : v.end
            })
        }))

        this.range.patchValue( {
            start: this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
            end: this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
        })
    }

    onDateStartChange(event: MatDatepickerInputEvent<Moment, String>) {
        this.startDateChange.emit(event.value?.endOf('D').toDate())
    }
    onDateEndChange(event: MatDatepickerInputEvent<Moment, String>) {
        this.endDateChange.emit(event.value?.endOf('D').toDate())
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.startDate) {
                this.range.get('start')?.setValue(changes.startDate.currentValue ? moment(changes.startDate.currentValue).format('YYYY-MM-DD') : null)
            }
            if (changes.endDate) {
                this.range.get('end')?.setValue(changes.endDate.currentValue ? moment(changes.endDate.currentValue).format('YYYY-MM-DD') : null)
            }
        }
    }

    ngOnDestroy(): void {
        this._subs?.forEach(s => s.unsubscribe());
    }
}
