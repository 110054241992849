import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../models';
import { UsersService } from './users.service';

@Injectable({
    providedIn: 'root'
  })
  export class UsersResolve implements Resolve<User[]> {
  
    constructor(private r$: UsersService ) { }
  
    resolve(): User[] | Observable<User[]> | Promise<User[]> {
        return this.r$.users$();
    }
  
  }
  