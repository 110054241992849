<form #addNote>
    <div class="d-flex d-flex-row">
        <mat-form-field [attr.style.width]="100"><mat-label>Initiales</mat-label><input matInput placeholder="Initiales" #inputNoteInitiales autocomplete="off" required/></mat-form-field><mat-form-field class="fluid" [attr.style.width]="100"><mat-label>Ajouter une note</mat-label><input matInput placeholder="Ajouter une note" #inputNote autocomplete="off" required /><fa-icon class="align-middle" [icon]="['fas', 'plus-circle']" matSuffix></fa-icon><button type="submit" mat-stroked-button (click)="ajouterNote(inputNote)" matSuffix [disabled]="(inputNote.value.length == 0 || inputNoteInitiales.value.length == 0)" [class.btn-success]="!(inputNote.value.length == 0 || inputNoteInitiales.value.length == 0)">Ajouter</button></mat-form-field>
    </div>
</form>
<mat-form-field><mat-label>Filtre</mat-label><input matInput (keyup)="applyFilter($event)" placeholder="Indiquez un filtre" #inputTimeline autocomplete="off"/></mat-form-field>
<div class="row mb-4" *ngFor="let item of _timeline" role="listitem">
    <div class="col-1 col-icon align-middle"><fa-icon class="align-middle" [icon]="['fas', ( item.action == 'Insertion' ? 'plus-circle' : 'circle')]"></fa-icon></div>
    <div class="col-1 col-icon align-middle">{{ item.user }}</div>
    <div class="col-2 col-date"><span class="align-middle">{{ item.historiqueDate | date}} {{ item.historiqueDate | date : 'HH:mm:ss'}}</span></div>
    <div class="col-8 col-content"><mat-card class="align-middle">{{ item.detail }}</mat-card></div>
</div>
