import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-rentals-schedules-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-schedules-view.component.html',
    styleUrls: ['rentals-schedules-view.component.scss'],
})
export class RentalsSchedulesViewComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
