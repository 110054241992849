import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ClickEventArg, ColumnDefinitionsModel } from '@common/components/simple-grid/simple-grid.component';
import { ResourceModel } from '@modules/rentals/models';
import { ResourcesService } from '@modules/rentals/services/resources.service';
import moment from 'moment';

@Component({
    selector: 'd1-rentals-resources-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-resources-view.component.html',
    styleUrls: ['rentals-resources-view.component.scss'],
})
export class RentalsResourcesViewComponent implements OnInit {
    liste!:ResourceModel[];

    ngOnInit() {}

    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: '#', visible: true, name: 'ressourceId', sortable: false },
        { headerText: 'Type', visible: true, name: 'ressourceTypeName', sortable: true },
        { headerText: 'Nom', visible: true, name: 'ressourceName', sortable: true },
        { headerText: 'tags', visible: true, name: 'ressourceTags', showAsPills: true, sortable: false },
        { headerText: 'SKU', visible: true, name: 'ressourceSKU', sortable: true },
    ];

    constructor(
        private r$: ResourcesService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private dialog$: MatDialog
    ) {
        this.fetchData();
    }

    fetchData() {
        this.r$.getResources$().subscribe((r) => {
            this.liste = r as ResourceModel[];
            this.ref.markForCheck();
        });
    }

    OnRowClick(event: ClickEventArg) {
        const booking = event.rowData as ResourceModel;
        if (event.event === 'edit') {
            this.router.navigate(['rentals', 'resources', booking.ressourceId, 'edit']);
        } else {
            this.router.navigate(['rentals', 'resources', booking.ressourceId]);
        }
    }

    OnAdd() {
        this.router.navigate(['rentals', 'resources', 'add']);
    }


}
