import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ScheduleModel } from '@modules/schedule/models';

@Component({
    selector: 'd1-schedule-group-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-group-item.component.html',
    styleUrls: ['schedule-group-item.component.scss'],
})
export class ScheduleGroupItemComponent implements OnInit {
    @Input() group: ScheduleModel = new ScheduleModel()
    @Input() viewCompact: boolean = true
    
    constructor() {}

    ngOnInit() {}

}
