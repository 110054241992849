import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'd1-reservations-filter',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-filter.component.html',
    styleUrls: ['reservations-filter.component.scss'],
})
export class ReservationsFilterComponent implements OnInit, OnDestroy {
    @Input('date') selectedDate: moment.Moment = moment();
    // @Output() dateChange: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(moment())
    @Output() dateChange: Subject<moment.Moment> = new Subject<moment.Moment>()
    
    constructor() {

    }
    
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }

    ngOnInit() {}

    onDateChange(date: moment.Moment) {
        this.dateChange.next(date)
    }

}
