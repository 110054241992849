/* tslint:disable: ordered-imports*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as shopifyComponents from './components';

/* Containers */
import * as shopifyContainers from './containers';

/* Guards */
import * as shopifyGuards from './guards';

/* Services */
import * as shopifyServices from './services';
import { RentalsModule } from '@modules/rentals/rentals.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ShopifyInterceptorService, ShopifyService } from './services';
import { ChartsModule } from '@modules/charts/charts.module';
import { RoomsModule } from '@modules/rooms/rooms.module';
import { ScheduleModule } from '@modules/schedule/schedule.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppCommonModule,
        NavigationModule,
        ChartsModule,
        RoomsModule,
        ScheduleModule,
        RentalsModule
    ],
    providers: [...shopifyServices.services, ...shopifyGuards.guards, { provide: HTTP_INTERCEPTORS, useClass: ShopifyInterceptorService, multi: true }],
    declarations: [...shopifyContainers.containers, ...shopifyComponents.components],
    exports: [...shopifyContainers.containers, ...shopifyComponents.components],
})
export class ShopifyModule {}
