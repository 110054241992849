export class ScheduleModel {
    arena_id: number = 0;
    journeeTemps: Date | undefined = undefined;
    nbGroupes: number = 0;
    nbJoueurs: number = 0;
    nombreVestes: number = 0;
    numDisponible: number = 0;
    partieDate: string = '';
    partieHeures: string = '';
    groupeTypeNom: string = '';
    nomOrganisateur: string = '';
    nombreJoueurs: number = 0;
    statutGroupe: number = 0;
    ceduleNombreJoueurs: number = 0;
    selected: boolean = false;
    groupeTypeCouleur: string = '';
    notesMarshall: string = '';
    notesMarshallCouleur: string = '';
    nomFete: string = '';
    groupeTypeId: string = '';
    groupeId: number = 0;
    presence: number = 0;
    id: number = 0;
    braceletCouleur?: string = '';

    constructor(schedule?: ScheduleModel) {
        if (schedule !== undefined) {
            this.arena_id = schedule.arena_id;
            this.journeeTemps = schedule.journeeTemps;
            this.nbGroupes = schedule.nbGroupes;
            this.nbJoueurs = schedule.nbJoueurs;
            this.nombreVestes = schedule.nombreVestes;
            this.numDisponible = schedule.numDisponible;
            this.partieDate = schedule.partieDate;
            this.partieHeures = schedule.partieHeures;  
            this.statutGroupe = schedule.statutGroupe;
            this.presence = schedule.presence !== undefined ? schedule.presence : 0;
            this.ceduleNombreJoueurs = schedule.ceduleNombreJoueurs;
            this.id = schedule.id;
        }
    }
};

export interface ScheduleInformation {
    ceduleInformation : string
    ceduleTemps : Date
}