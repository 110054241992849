import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
    name : 'phone'
})
export class phonePipe implements PipeTransform {
  transform(phoneValue: number | string, country?: string): any {
    try {
      if (country == undefined) country = 'CA';
      if (typeof(phoneValue) == 'string' && phoneValue.length == 0) return '';
      const phoneNumber = parsePhoneNumber((phoneValue || '').toString().replace('+', '') + '', country as CountryCode);
      return phoneNumber.formatNational();
    } catch (error:any) {
      if (error.name !== "ParseError") {
        console.error("phonePipe ERROR", error, phoneValue)
      }
      return phoneValue;
    }
  }
}

@Pipe({
  name : 'phoneUri'
})
export class phoneUriPipe implements PipeTransform {
  transform(phoneValue: number | string, country?: string): any {
    try {
      if (country == undefined) country = 'CA';
      if (typeof(phoneValue) == 'string' && phoneValue.length == 0) return '';
      const phoneNumber = parsePhoneNumber((phoneValue || '').toString().replace('+', '') + '', country as CountryCode);
      return phoneNumber.getURI();
    } catch (error:any) {
      if (error.name !== "ParseError") {
        console.error("phoneUriPipe ERROR", error, phoneValue)
      }
      return phoneValue;
    }
  }
}

@Pipe({
  name : 'phoneUrl'
})
export class phoneUrlPipe implements PipeTransform {
  transform(phoneValue: number | string, country?: string): any {
    try {
      if (country == undefined) country = 'CA';
      if (typeof(phoneValue) == 'string' && phoneValue.length == 0) return '';
      const phoneNumber = parsePhoneNumber((phoneValue || '').toString().replace('+', '') + '', country as CountryCode);
      return `<a href='${phoneNumber.getURI()}>${phoneNumber.formatNational()}</a>`;
    } catch (error) {
      console.error("phoneUrlPipe ERROR", error, phoneValue)
      return phoneValue;
    }
  }
}