import * as _ from 'underscore';

angular.module('resources.courriel', []);
angular.module('resources.courriel').factory('D1Courriel', ['$http', function ($http) {

    var monCourriel = {
        Email : {
            to : '',
            to_name : '',
            sujet : '',
            contenu : '',
            tags: '',
            notes: '',
            notesAdmin: '',
            isConfirmation: false
        },
        templates : [],

        init : function() {
            this.templates = [];
        },
        sendmail : function() {
            var myEmail = angular.copy(this.Email);
            return $http.post('/api/courriel/envoyer', myEmail)
        },
        getGabarits : function() {
            return $http.get('/api/courriel/gabarits').then( (response) => {
                this.templates = _.sortBy(response.data, 'name');
                return this.templates;
            });
        }
    };

    return monCourriel;
}]);
