<a class="nav-link" *ngIf="sideNavItem.isExternal !== true &amp;&amp; validateUserRealm() &amp;&amp; (sideNavItem.feature === undefined || (sideNavItem.feature &amp;&amp; features.includes(sideNavItem.feature)))" (click)="toggle()" [ngClass]="{active: isActive, collapsed: collapsed}" [routerLink]="sideNavItem.link"
    ><div class="nav-link-icon" *ngIf="sideNavItem.icon"><i-feather [name]="sideNavItem.icon"></i-feather></div>
    {{sideNavItem.text}}
    <div class="badge badge-primary ml-2" *ngIf="sideNavItem.new">New!</div>
    <div class="sidenav-collapse-arrow"><fa-icon class="ml-auto" *ngIf="sideNavItem.submenu" [icon]="['fas', 'angle-down']"></fa-icon></div
></a>
<nav class="sidenav-menu-nested nav" #collapsibleSection [ngClass]="{collapsed: collapsed}">
    <ng-container *ngIf="sideNavItem.feature === undefined || (sideNavItem.feature &amp;&amp; features.includes(sideNavItem.feature))"><sbpro-side-nav-item class="submenu" *ngFor="let submenuItem of sideNavItem.submenu" [sideNavItem]="submenuItem" [ngClass]="{collapsed: collapsed}" [hierarchy]="hierarchyExtension"></sbpro-side-nav-item></ng-container>
</nav>
<a class="nav-link" *ngIf="sideNavItem.isExternal === true" (click)="toggle()" [ngClass]="{active: isActive, collapsed: collapsed}"
    ><div class="nav-link-icon" *ngIf="sideNavItem.icon"><i-feather [name]="sideNavItem.icon"></i-feather></div>
    {{sideNavItem.text}}
    <div class="badge badge-primary ml-2" *ngIf="sideNavItem.new">New!</div>
    <div class="sidenav-collapse-arrow"><fa-icon class="ml-auto" *ngIf="sideNavItem.submenu" [icon]="['fas', 'angle-down']"></fa-icon></div>
    <!-- [routerLink]='sideNavItem.link', --></a
>
