import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';

@Injectable()
export class ChartsService {
    constructor() {
        this.Chart.defaults.font.family =
            'Metropolis,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
        this.Chart.defaults.color = '#858796';
    }

    get Chart() {
        return Chart;
    }
}
