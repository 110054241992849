import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettingsService } from '@common/services';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-reservations-print-receipt',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-print-receipt.component.html',
    styleUrls: ['reservations-print-receipt.component.scss'],
})
export class ReservationsPrintReceiptComponent implements OnInit, OnDestroy,AfterViewInit {
    public reservation: ReservationModel = new ReservationModel()
    private mySubs: Subscription[] = [];
    public groupeTypes: any = [];

    constructor(private route: ActivatedRoute, private r$: ReservationsService, private ref: ChangeDetectorRef, private s$:ScheduleService, private settings$:AppSettingsService) {
        if (route.snapshot.data.reservation) {
            this.reservation = route.snapshot.data.reservation;
        }
        
        this.settings$.TypeGroupes.subscribe( l => {
            this.groupeTypes = l
        })
    }

    ngAfterViewInit(): void {
        // window.print();
        // window.close();
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        _.each(this.mySubs, (s) => s.unsubscribe());
    }

    ngOnInit() {
        this.mySubs.push(this.route.params.subscribe( (params) => {
            if (this.route.snapshot.data.reservation) {
                this.reservation = this.route.snapshot.data.reservation;
            }
            this.ref.markForCheck();
        }));

    }

    getPartieClasse(p: GameModel) {
        return 'badge-' + this.s$.getPasseCouleur(moment(p.partieTemps).format('HH:mm'));
    }

    getPasseCouleurNom(p: GameModel) {
        return this.s$.getPasseCouleurNom(moment(p.partieTemps).format('HH:mm'));
    }

    getCodeBarre(p: GameModel):string {
        return this.r$.getCodeBarre(this.reservation, p);
    }

    
    getGroupeTypeNom(groupe:ReservationModel) {
        const gt = _.find(this.groupeTypes, {groupeTypeId : groupe.groupeTypeId})
        return (gt ? gt.groupeTypeNom : 'Groupe');
    }
}
