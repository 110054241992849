<div>Nombre de réservations : {{ ListeReservations.length }}<span *ngIf="ListeReservations.length !== ListeReservationsComplete.length">&nbsp;({{ ListeReservationsComplete.length - ListeReservations.length }} sont flitrées)</span></div>
<tsng-simple-grid class="w-100" *ngIf="!viewSchedule" #listeBooking [data]="ListeReservations" [columnDefinitions]="columnDefs" [showAction]="false" [allowEdit]="true" pageSize="100" [loading]="true" [allowDelete]="false" [allowAdd]="true" [disabled]="false" (onFiltered)="onFiltered($event)" (rowclick)="OnRowClick($event)" (onEdit)="OnRowClick($event)" (onAdd)="OnAdd()" (onDelete)="OnRowClick($event)"></tsng-simple-grid><d1-reservations-list-cedule *ngIf="viewSchedule" [viewActualTimeOnly]="viewActualTimeOnly" [date]="date" (selected)="showSelected($event)" (filtered)="onFiltered($event)"></d1-reservations-list-cedule><ng-template #colStatutTemplate let-groupe="data"><d1-reservations-status class="pr-2 pl-2" [statutGroupe]="groupe.statutGroupe" [showLabel]="false" [showDropDown]="true" [showToggle]="false" [showColor]="true" (change)="changeStatut(groupe, $event)"></d1-reservations-status></ng-template
><ng-template #colPaymentTemplate let-groupe="data"><d1-reservations-payment-status class="pr-2 pl-2" [statutPaiement]="groupe.statutPaiement" [showLabel]="false" [showToggle]="false" [showColor]="false" [showIcon]="true"></d1-reservations-payment-status></ng-template><ng-template #colPartiesTemplate let-groupe="data"><d1-reservations-list-games [games]="groupe.parties" [includePlayerNumber]="true"></d1-reservations-list-games></ng-template
><ng-template #colDiversTemplate let-groupe="data"
    ><d1-reservations-payment-status class="mr-1" *ngIf="groupe.statutPaiement !== 1" [statutPaiement]="groupe.statutPaiement" [showLabel]="false" [showToggle]="false" [showColor]="false" [showIcon]="true"></d1-reservations-payment-status><d1-reservations-confirmation-status class="mr-1" *ngIf="groupe.statutConfirmation !== 1" [statutConfirmation]="groupe.statutConfirmation" [showLabel]="false" [showToggle]="false" [showColor]="false" [showIcon]="true"></d1-reservations-confirmation-status><mat-icon class="mr-1 text-danger" ngbTooltip="Réservation annulée" *ngIf="groupe.estAnnule">cancel</mat-icon><mat-icon class="mr-1 text-danger" ngbTooltip="Réservation effacée" *ngIf="groupe.estEfface">folder_delete</mat-icon><mat-icon class="mr-1" [ngbTooltip]="groupe.notesAdmin" placement="right" *ngIf="groupe.notesAdmin">speaker_notes</mat-icon
    ><mat-icon class="mr-1" [ngbTooltip]="groupe.notesMarshall" placement="right" *ngIf="groupe.notesMarshall" [style.color]="(groupe.notesMarshallCouleur ? groupe.notesMarshallCouleur : '#5bc0de')">3p</mat-icon></ng-template
><ng-template #colInfosTemplate let-groupe="data"
    ><div class="mat-cell p-2 mb-2">
        <div class="badge p-2 text-white mr-2" [style.background-color]="getGroupeTypeCouleur(groupe)" [class]="['groupe-type-'+groupe.groupeTypeId]">{{ getGroupeTypeNom(groupe) }}</div>
        <d1-display-info class="mr-2" [label]="" [list]="groupe.groupe_tags"></d1-display-info></div></ng-template
><ng-template #colOrganisateurTemplate let-groupe="data"
    ><div class="font-weight-bold">{{ groupe.nomOrganisateur | titlecase }}</div>
    <div>{{ groupe.telephone | phone }}</div>
    <div *ngIf="groupe.nomFete">Fêté : {{ groupe.nomFete }}</div></ng-template
>
