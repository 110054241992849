// Divers
import * as _ from 'underscore';
import * as moment from 'moment';
import { io } from 'socket.io-client';
moment.locale('fr')

var d1Reservation = angular.module('resources.reservation', ['resources.cedule', 'resources.courriel']);
angular.module('resources.reservation').factory('Reservation', ['$http', 'Cedule', '$uibModal', '$rootScope', '$interval', '$q', '$timeout', '$cookies', 'Notification', 'D1Configurations', '$configService', '$messages', function ($http, Cedule, $uibModal, $rootScope, $interval, $q, $timeout, $cookies, Notification, D1Configurations, $configService, $messages) {

    var reservation = {};
    var typesGroupes = [];
    reservation.socket = io();
    reservation.nouvelle = undefined;
    reservation.nonConfirmees = 0;
    reservation.listeNouvelle = [];
    reservation.DesactiverNotification = false;

    $configService.TypeGroupes.subscribe((d) => {
        typesGroupes = d;
    })

    $messages.SettingsMessage.subscribe( r => { 
        $configService.TypeGroupes.subscribe((d) => {
            typesGroupes = d;
        })
    })

    reservation.creerPartie = function (r) {
        var PartieModel = Cedule.creerPartieFromReservation(r);
        return PartieModel;
    };

    reservation.creer = function () {
        var stime = moment();
        stime.seconds(0);
        stime.milliseconds(0);

        stime = reservation.nextGameTime(stime);

        var ReservationModel = {
            id: 0,
            arena_id: 1,
            event_date: stime.toDate(),
            //event_time : stime.format('HH:mm'),
            event_time: stime.toDate(),
            groupeTypeNom: '',
            envoiConfirmation: true,
            titreGroupeManuel: false,
            lstParties: [],
            lstOptions: [],
            lstVR: [],
            // tags: [],

            // DATABASE MODELE
            groupeId: 0,
            groupeTypeId: 0,
            groupeTitre: '',
            nomOrganisateur: '',
            nombreJoueurs: 2,
            reservationDate: null,
            insertionDate: stime.toDate(),
            courriel: '',
            telephone: '',
            partiesDate: stime,
            partiesJournee: stime.format('DD-MM-YYYY'),
            depotFait: false,
            depotMontant: null,
            depotReference: '',
            nomFete: '',
            gateauDate: null,
            gateauChoix: '',
            slushDate: null,
            salleReservee: 0,
            salles: [],
            salleDebut: '',
            salleFin: '',
            notes: '',
            notesAdmin: '',
            initialesEmploye: '',
            nombreParties: 0,
            statutGroupe: 0,
            statutConfirmation: 1,
            statutPaiement: 0,
            estImporte: 0,
            shiftPlanningId: null,
            estEfface: 0,
            estAnnule: 0,
            partieOffset: 0,
            salleOffset: 0,
            userLockTime: null,
            userLockToken: null,
            responsable: null,
            notesMarshall: '',
            notesMarshallCouleur: '',
            loading: 0,
            confirmationEnvoyee: false,
            valomotionCode: '',
            valomotionBirthdayCode: '',
            braceletCouleur: '',
            estModifiee: false,
            noteList: [],
            customerId: undefined,
            cart_token: undefined,
        };

        // Prendre le type de groupe associé
        var gt = _.find(typesGroupes, function (gr) {
            return (gr.groupeTypeId == ReservationModel.groupeTypeId);
        });
        if (!gt) {
            // Default
            gt = _.find(typesGroupes, function (gr) {
                return (gr.groupeTypeId == 0);
            });
        }
        if (gt) {
            ReservationModel.groupeType = gt;
            ReservationModel.nombreJoueurs = (gt.minimumJoueurs ? gt.minimumJoueurs : 0);
        } else {
            console.error("PAS DE GROUPE AVEC ID = 0", typesGroupes, ReservationModel.groupeTypeId)
        }

        return ReservationModel;
    };

    /**
     * Fonction qui permet de prendre une valeur de la BD vers notre objet
     * @param r
     */
    reservation.parseFromDB = function (r, usePromise) {
        try {
            var newReservation = reservation.creer();
            var deferred = 0;
            var promises = []

            if (usePromise == true) {
                deferred = $q.defer();
            }

            newReservation.loading = 1;
            newReservation.id = r.groupeId;
            // Pourquoi prendre les dates d'un autre champ???
            newReservation.event_date = moment(r.reservationDate).toDate();
            newReservation.event_time = moment(r.reservationDate).toDate();

            newReservation.groupeId = r.groupeId;
            newReservation.groupeTypeId = r.groupeTypeId.toString();
            newReservation.groupeTypeNom = r.groupeTypeNom;
            newReservation.groupeTitre = r.groupeTitre;
            newReservation.nomOrganisateur = r.nomOrganisateur;
            newReservation.nombreJoueurs = r.nombreJoueurs;
            newReservation.nombreJoueurs_Copy = r.nombreJoueurs;
            newReservation.reservationDate = r.reservationDate;
            newReservation.insertionDate = r.insertionDate;
            newReservation.courriel = (r.courriel ? r.courriel : '');
            newReservation.telephone = r.telephone;
            newReservation.partiesDate = moment(r.partiesDate);
            newReservation.partiesJournee = moment(r.partiesDate).format('DD-MM-YYYY'); // Pour la recherche simple
            newReservation.depotFait = r.depotFait;
            newReservation.depotMontant = r.depotMontant;
            newReservation.depotReference = r.depotReference;
            newReservation.nomFete = r.nomFete;
            newReservation.gateauDate = (r.gateauDate ? moment(r.gateauDate).format("HH:mm") : null);
            newReservation.gateauChoix = r.gateauChoix;
            newReservation.slushDate = (r.slushDate ? moment(r.slushDate).format("HH:mm") : null);
            newReservation.salleReservee = r.salleReservee;
            newReservation.salleDebut = (r.salleDebut ? moment(r.salleDebut).toDate() : null);
            newReservation.salleFin = (r.salleFin ? moment(r.salleFin).toDate() : null);
            newReservation.notes = r.notes;
            newReservation.salles = [];
            newReservation.sallesSelectionnees = [];
            newReservation.salleNom = '';
            newReservation.notesAdmin = r.notesAdmin;
            newReservation.initialesEmploye = (r.initialesEmploye == null ? '' : r.initialesEmploye);
            newReservation.titreGroupeManuel = true;
            newReservation.statutGroupe = r.statutGroupe;
            newReservation.statutConfirmation = r.statutConfirmation;
            newReservation.statutPaiement = r.statutPaiement;
            newReservation.estImporte = r.estImporte;
            newReservation.estEfface = r.estEfface;
            newReservation.estAnnule = r.estAnnule;
            newReservation.notesMarshall = r.notesMarshall;
            newReservation.notesMarshallCouleur = (r.notesMarshallCouleur ? r.notesMarshallCouleur : '');
            newReservation.userLockTime = r.userLockTime;
            newReservation.userLockToken = r.userLockToken;
            newReservation.responsable = r.responsable;
            newReservation.groupe_tags = r.groupe_tags;
            newReservation.confirmationEnvoyee = r.confirmationEnvoyee
            newReservation.valomotionCode = r.valomotionCode
            newReservation.valomotionBirthdayCode = r.valomotionBirthdayCode
            newReservation.braceletCouleur = r.braceletCouleur
            newReservation.noteList = r.noteList ? JSON.parse(r.noteList) : []
            newReservation.customerId = r.customerId
            newReservation.cart_token = r.cart_token

            // Les parties
            newReservation.lstPartiesSplit = '';
            newReservation.lstParties = [];
            if(r.lstParties) {
                var lasertag = _.where(r.lstParties, { partieType: 1 });
                var l = [];
                if (lasertag !== undefined && lasertag.length > 0) {
                    l = [];
                    newReservation.arena_id = (lasertag[0].arena_id ? lasertag[0].arena_id : 1);
                    lasertag.forEach(function (p) {
                        p.partieTemps = moment(p.partieTemps).toDate();
                        p.groupeNombreJoueur_Copy = p.groupeNombreJoueur;
                        p.nombreJoueurs_Copy = p.nombreJoueurs;
                        l.push(p.partieHeures);
                        p.passeCouleur = Cedule.getPasseCouleur(p.partieHeures, p.arena_id);
                        newReservation.lstParties.push(p);
                    });
                    newReservation.nombreParties = newReservation.lstParties.length;
                    newReservation.lstPartiesSplit = l.join(',');
                }
            } else {
                newReservation.lstParties = r.parties.split(',').map( (p) => {
                    return {
                        partieHeures: p,
                        // TODO: Trouver la façon d'avoir un arenaid non hardcodé
                        passeCouleur: Cedule.getPasseCouleur(p, 1),
                        partieTemps: moment(moment(r.reservationDate).format('MM-DD-YYYY') + ' ' + p, 'MM-DD-YYYY hh:mm')
                    };
                })
            }

            var vr = _.where(r.lstParties, { partieType: 2 });
            newReservation.lstVR = [];
            if (vr) {
                l = [];
                for (let p of vr) {
                    l.push(p.partieHeures);
                    newReservation.lstVR.push(p);
                }
                newReservation.lstPartiesVRSplit = l.join(',');
            }

            // Logiques additionnelles
            if (!r.groupeTitre) {
                newReservation.groupeTitre = r.groupeTypeNom + ' (' + r.nombreJoueurs + ') ' + r.nomOrganisateur;
            }

            // Salle
            let gt;
            newReservation.salle = null;
            newReservation.sallesSelectionnees = [];
            if (r.salleReservee > 0) {
                gt = _.find(D1Configurations.TypeSalles, function (gr) {
                    return (gr.salleId == r.salleReservee);
                });
                if (gt) {
                    newReservation.salleNom = gt.salleNom;
                    newReservation.salle = gt;

                    // Multi-salles
                    newReservation.salles[gt.salleId] = true;
                    newReservation.sallesSelectionnees.push(gt);
                }
            }

            // Multi salles?
            if (r.lstSalles) {
                var lstSalles = r.lstSalles.split(',');
                var lstSalleNom = [];
                newReservation.salle = [];
                newReservation.sallesSelectionnees = [];
                for (let s of lstSalles) {
                    gt = _.findWhere(D1Configurations.TypeSalles, { salleId: parseInt(s) });
                    if (gt) {
                        lstSalleNom.push(gt.salleNom);
                        newReservation.salle.push(gt);

                        // Multi-salles
                        newReservation.salles[gt.salleId] = true;
                        newReservation.sallesSelectionnees.push(gt);
                    }
                }
                newReservation.salleNom = lstSalleNom.join(',');
            }

            // Prendre le type de groupe associé
            gt = _.find(typesGroupes, function (gr) {
                return (gr.groupeTypeId == r.groupeTypeId);
            });
            if (!gt) {
                // Default
                gt = typesGroupes[0];
            }
            newReservation.groupeType = gt;

            // Options
            newReservation.options = [];
            newReservation.lstOptions = [];
            for (let item of newReservation.options) {
                newReservation.options[item.attributId] = {};
                newReservation.options[item.attributId].id = item.choix;
                newReservation.options[item.attributId].temps = (item.choixTemps ? moment(item.choixTemps) : null);

                var curItem = _.findWhere(D1Configurations.Attributs, { attributId: item.attributId });
                if (curItem) {
                    var newItem = _.clone(curItem);
                    var curChoix = _.findWhere(curItem.options, { id: item.choix });
                    newItem.selectedOption = {
                        id: item.choix,
                        nom: (curChoix ? curChoix.name : ''),
                        temps: (item.choixTemps ? moment(item.choixTemps) : null),
                        choixExplication: (item.choixExplication ? item.choixExplication : null),
                        tempsDate: (item.choixTemps ? moment(item.choixTemps).toDate() : null)
                    };
                    newReservation.lstOptions.push(newItem);
                } else {
                    console.log("Incapable de trouver " + item.attributId, D1Configurations.Attributs);
                }
            }

            if (usePromise) {
                $q.all(promises).then(function () {
                    newReservation.loading = 0;
                    deferred.resolve(newReservation);
                });
                return deferred.promise;
            } else {
                newReservation.loading = 0;
                return newReservation;
            }
        } catch (error) {
            console.error("reservation.parseFromDB ERROR", error)
        }

    };

    /**
     * Fonction minimaliste pour changer le statut, doit être rapide.
     * @param r
     * @returns {*}
     */
    reservation.changerStatutGroupe = function (r) {
        if (r.groupeId) {
            r.loading++;
            return $http.post('/api/reservations/' + r.groupeId + '/statutgroupe/' + parseInt(r.statutGroupe)).then(function (response) {
                r.loading--;
                // reservation.socket.emit('RafraichirReservations', r);
                $messages.sendMessageReservation(r)
                return response;
            });
        }
    };

    /**
     * Fonction minimaliste pour changer le statut, doit être rapide.
     * @param r
     * @returns {*}
     */
    reservation.updateTags = function (groupeId, groupe_tags) {
        if (groupeId) {
            return $http.put('/api/reservations/tags', { groupeId, groupe_tags }).then(function (response) {
                return response;
            });
        }
    };
    /**
     * Fonction minimaliste pour changer le notesAdmin, doit être rapide.
     * @param r
     * @returns {*}
     */
    reservation.updateNotesAdmin = function (groupeId, notesAdmin) {
        if (groupeId) {
            return $http.put('/api/reservations/notesadmin', { groupeId, notesAdmin }).then(function (response) {
                return response;
            });
        }
    };

    /**
     * Fonction qui "lock" un record.
     * @param r
     * @returns {*}
     */
    reservation.lockRecord = function (r, userToken) {
        var deferred = $q.defer();
        if (r.groupeId && userToken && r.userLockToken.length == 0) {
            r.loading++;
            return $http.post('/api/reservations/' + r.groupeId + '/lock/' + userToken).then(function (response) {
                r.userLockTime = moment();
                r.userLockToken = userToken;
                // reservation.socket.emit('RafraichirReservations', r);
                $messages.sendMessageReservation(r)
                r.loading--;
                return response;
            },
                function (err) {
                    r.loading--;
                    console.error("Error", err);
                }
            );
        } else if (r.groupeId > 0) {
            deferred.reject("Invalid groupeId");
            return deferred.promise;
        } else {
            r.userLockTime = moment();
            r.userLockToken = userToken;
            deferred.resolve({ status: 200 });
            return deferred.promise;
        }
    };

    /**
     * Fonction qui "unlock" un record.
     * @param r
     * @returns {*}
     */
    reservation.unlockRecord = function (r) {
        var deferred = $q.defer();
        if (r.groupeId) {
            r.loading++;
            $http.post('/api/reservations/' + r.groupeId + '/unlock')
                .then(function (response) {
                    // reservation.socket.emit('RafraichirReservations', r);
                    $messages.sendMessageReservation(r)
                    r.userLockTime = null;
                    r.userLockToken = null;
                    r.loading--;
                    deferred.resolve(response);
                },
                    function (err) {
                        console.error("unlockRecord() Error", err);
                        r.loading--;
                        deferred.reject(err);
                    }
                );
        } else {
            deferred.reject({ status: 500, message: 'Invalid group id : ' + r.groupeId });
        }
        return deferred.promise;
    };


    /**
     * Fonction qui "laod" un record.
     * @param r
     * @returns {*}
     */
    reservation.loadGroupe = function (groupeId, usePromise) {
        if (groupeId) {
            return $http.get('/api/reservations/' + groupeId).then(function (response) {
                return reservation.parseFromDB(response.data, usePromise);
            },
                function (err) {
                    console.log("Error", err);
                    return err;
                });
        }
    };

    /**
     * Fonction pour changer les informations quand prend un autre type de groupe
     * Certaines logiques à appliquer!!!
     *
     * @param r     Rservation
     * @param gt    GroupeType
     *
     * @returns reservation modifiée
     */
    reservation.changerGroupeType = function (r) {

        try {
            var gt = r.groupeType;
            r.estModifiee = true;
            r.groupeTypeId = gt.groupeTypeId.toString();
            var bOk = (r.groupeId == 0 || r.groupeId == undefined);
            if (r.groupeId) {
                bOk = confirm('Voulez-vous recacluler toutes les parties et les heures de salles selon le forfait sélectionné?');
            }
            if (bOk) {
                // Ne pas jouer avec le nombre de joueurs si c'est une réservation
                // existante ou encore qu'on a déjà touché le nombre de joueurs dans une nouvelle réservation
                if ((r.groupeId == 0 || r.groupeId == undefined) && (r.nombreJoueurs == undefined || r.nombreJoueurs_Copy == undefined)) {
                    r.nombreJoueurs = (gt.minimumJoueurs !== undefined ? gt.minimumJoueurs : r.nombreJoueurs);
                }
                r.nombreParties = gt.nombreParties;
                reservation.calculateGames(r);
            }

            if (gt.requiertSalle == 0) {
                // S'assurer qu'on initialise les champs
                r.salleReservee = 0;
                r.salleNom = '';
                r.salle = {};
                r.salleDebut = null;
                r.salleFin = null;
                r.estModifiee = true;
            } else {
                // Ajuster la salle
                if (bOk) {
                    reservation.calculerSalle(r);
                }
            }
        } catch (error) {
            console.error(error);
        }
        return r;
    };

    /**
     * Fonction qui ajuste les heures de salles selon le type de groupe
     *
     * @param r
     *
     * @returns {*}
     */
    reservation.calculerSalle = function (r) {
        if (r.groupeType.requiertSalle) {
            var st = reservation.reajustTime(moment(r.event_date));
            if (r.groupeType.salleOffset > 0) {
                // On a un offset des heures de la salle
                st.add(r.groupeType.salleOffset, 'm');
            }

            // Effacer les micros secondes
            st.seconds(0);
            st.milliseconds(0);

            // Affecter les heures.
            r.salleDebut = st.toDate();
            r.salleFin = moment(r.salleDebut).add(r.groupeType.minimumTemps, 'm').toDate();
            r.estModifiee = true;
        }

        return r;
    };


    /**
     *
     * Va réajuster l'heure pour nos 20 minutes.
     * @param t Passer un objet "moment"
     *
     * @returns moment() ajusté
     */
    reservation.reajustTime = function (t, arena_id = 1) {
        // Effacer les micros secondes
        t.seconds(0);
        t.milliseconds(0);

        const nextTime = reservation.nextGameTime(t, arena_id);
        const diffMinutes = nextTime.diff(t, 'minutes');

        if (diffMinutes > 0 && diffMinutes <= 5) {
            // Arrondir au prochain
            t = reservation.nextGameTime(t, arena_id);
        } else {
            // Prendre le temps actuel
            t = reservation.actualGameTime(t, arena_id);
        }

        // switch (t.minute()) {
        //     case 15:
        //         // Ajuster pour "20"
        //         t.add(5, 'm');
        //         break;

        //     case 30:
        //         // Ajuster pour "40"
        //         t.add(10, 'm');
        //         break;

        //     case 45:
        //         // Ajuster pour "40"
        //         t.subtract(5, 'm');
        //         break;
        // }

        return t;
    };

    /**
     *
     * Va réajuster l'heure pour nos 20 minutes mais pour la prochaine partie (futur).
     * @param t Passer un objet "moment"
     *
     * @returns moment() ajusté
     */
    reservation.nextGameTime = function (t, arena_id = 1) {
        let rotationTime = 20;
        let tc = moment(t);
        let arena = _.findWhere(D1Configurations.getArenas(), { arena_id: arena_id });
        if (arena) {
            rotationTime = (arena.rotation_time ? arena.rotation_time : 20);
        }
        // else {
        //     // Catch all
        //     if (t.minute() >= 0 && t.minute() < 20) {
        //         t.minute(20);
        //     } else if (t.minute() >= 20 && t.minute() < 40) {
        //         t.minute(40);
        //     } else if (t.minute() >= 40) {
        //         // On change pour la prochaine heure
        //         t.add(1, 'h');
        //         t.minute(0);
        //     } else {
        //         // catch all
        //         t.minute(0);
        //     }
        // }

        const numToursHeure = Math.floor(60 / rotationTime) - 1;
        const curTour = Math.floor(tc.minute() / rotationTime);

        // Arrondir au prochain
        if ((curTour + 1) < numToursHeure) {
            tc.minute((rotationTime * (curTour + 1)));
        } else {
            tc.minute(0);
        }

        return tc;
    };

    /**
     *
     * Va réajuster l'heure pour afficher la partie actuelle.
     * @param t Passer un objet "moment"
     *
     * @returns moment() ajusté
     */
    reservation.actualGameTime = function (t, arena_id = 1) {

        let rotationTime = 20;
        let arena = _.findWhere(D1Configurations.getArenas(), { arena_id: arena_id });
        if (arena) {
            rotationTime = (arena.rotation_time ? arena.rotation_time : 20);
        }
        // if (t.minute() >= 0 && t.minute() < 20) {
        //     t.minute(0);
        // } else if (t.minute() >= 20 && t.minute() < 40) {
        //     t.minute(20);
        // } else if (t.minute() >= 40) {
        //     t.minute(40);
        // } else {
        //     t.minute(0);
        // }

        const curTour = Math.floor(t.minute() / rotationTime);

        // Arrondir à l'actuel
        t.minute(rotationTime * curTour);

        return t;
    };

    reservation.nombreReservationsNonConfirmee = function () {
        return (reservation.nonConfirmees > 0 ? reservation.nonConfirmees : '');
    };

    function getNonConfirmees() {
        if (!reservation.DesactiverNotification) {
            // Aller chercher le nombre de réservations non-confirmees à chaque minute
            $http.get('/api/reservations/nombreNonConfirmees').then(function (response) {
                reservation.nonConfirmees = response.data.num;

                if (reservation.nonConfirmees > 0) {
                    // On va aller chercher la liste des groupeId
                    var newListe = [];
                    $http.get('/api/reservations/nouvelles').then(function (response) {
                        _.each(response.data, function (r) {
                            // filtrer les "en attente", elles ont déjà été vues et gérées
                            if (r.statutConfirmation != 2) {
                                newListe.push(r.groupeId);
                            }
                        });

                        // Comparer si on a du nouveau
                        let diff = _.difference(newListe, reservation.listeNouvelle);
                        if (diff.length > 0) {
                            // Il y a du nouveau, on fait la notification!
                            // En train de modifier on doit indiquer à l'usager de reloader
                            Notification.info({
                                message: "Aller voir les <a href='/reservations/nouvelles'>réservations non-confirmées</a>",
                                title: "Il y a " + diff.length + " demandes de réservation non-confirmées!",
                                positionX: 'right',
                                positionY: 'top',
                                onClose: function () {
                                    //msg.nePlusAfficher = true;
                                }
                            });
                        }
                        reservation.listeNouvelle = newListe;
                    });

                }
            });
        }
    }
    // On commence par aller chercher la valeur une fois, ensuite on part une intervale
    getNonConfirmees();
    $interval(getNonConfirmees, 60000);

    function scheduleGames(r) {
        for (let i = 0; i < r.groupeType.nombreParties; i++) {
            reservation.ajouterPartie(r);
        }
    }

    function scheduleExtra(start_timestamp, myEvent) {
        // Fonctiopn rendue inutile.
        return;

        // Calcul automatique des heures pour la slush et gateau
        // var stime = moment(start_timestamp);
        // var gt = myEvent.groupeType;
        // if (!gt) {
        //     gt = _.find(D1Configurations.TypeGroupes, function(gr) {
        //         return (gr.groupeTypeId == myEvent.groupeTypeId);
        //     });
        // }
        // if (!gt) {
        //     // Default
        //     gt = D1Configurations.TypeGroupes[0];
        // }

        // if (gt.requiertSlush == 1) {
        //     // Ajoute 20 minutes
        //     stime.add(20 + gt.partieOffset, 'm');
        //     myEvent.slushDate = stime.format("HH:mm");
        //     myEvent.extra = "Votre slush et popcorn seront servi à " + stime.format("H:mm");
        // } else {
        //     myEvent.slushDate = '';
        // }

        // if (gt.requiertGateau == 1) {
        //     if (gt.requiertSlush == 1) {
        //         // Ajouter 40 minutes ** A CAUSE DE SLUSH **
        //         stime.add(40, 'm');
        //     } else {
        //         stime.add(20, 'm');
        //     }
        //     myEvent.gateauDate = stime.format("HH:mm");
        //     myEvent.extra += "<br>Votre gâteau sera servi à " + stime.format("H:mm");
        // } else {
        //     myEvent.gateauDate = '';
        // }
    }

    /**
     * Fonction qui calcule les heures selon le forfait
     *
     * @param reservation
     */
    reservation.calculateGames = function (reservation) {
        // Faire la syncho....
        // Todo.... se débarrasser de event_date???
        reservation.estModifiee = true;
        reservation.partiesDate = moment(reservation.event_date);
        if (reservation.partiesDate) {
            reservation.lstParties = [];
            scheduleGames(reservation);
            scheduleExtra(reservation.partiesDate, reservation);
        }
        return reservation;
    };

    /**
     * Fonction qui valide les points importants d'une réservation
     *
     * @param r Objet réservation
     *
     * @returns {*} True si tout est beau, sinon un texte pour donner l'erreur
     */
    async function validerReservation(r) {
        // On valide les points les plus importants!
        var promise = $q.defer();
        var lstPromises = [];
        var salleSelected = [];

        if (r.groupeType.requiertSalle == 1 && r.statutConfirmation == 1) {

            // Vérifier les salles
            if (r.salles.length == 0 || r.salles == null || r.salles == undefined) {
                lstPromises.push("Veuillez sélectionner une salle");
            } else {
                salleSelected = _.filter(r.salles, function (s) { return s == true });
                if (salleSelected && salleSelected.length > 0) {
                    lstPromises.push(true);
                } else {
                    lstPromises.push("Veuillez sélectionner une salle");
                }
            }

            // Vérifier les dates
            if (!moment(r.salleDebut).isValid() || !moment(r.salleFin).isValid()) {
                lstPromises.push("Dates de la salle invalides");
            } else {
                lstPromises.push(true);
            }

            if (moment(r.event_date).format("YYYY-MM-DD") !== moment(r.salleDebut).format("YYYY-MM-DD")) {
                lstPromises.push(`La date des salles [${moment(r.salleDebut).format("YYYY-MM-DD")}] ne correspond pas avec la date de l'événement [${moment(r.event_date).format("YYYY-MM-DD")}]!`);
            }

            // Vérifier que c'est toujours disponible.
            if (salleSelected && salleSelected.length > 0) {
                var promiseDispo = $http.get('/api/salles/disponibilites/' + moment(r.salleDebut).format("YYYY-MM-DD HH:mm:00") + "/" + moment(r.salleFin).format("YYYY-MM-DD HH:mm:00") + "/" + r.groupeId).then(function (response) {
                    var numS = 0,
                        numOK = 0;
                    _.each(r.salles, function (s, salleId) {
                        if (s == true) {
                            numS++;
                            // Trouver si la salle est disponible
                            var curS = _.findWhere(response.data, { salleId: salleId, estDisponible: 1 });
                            if (curS != undefined) {
                                numOK++
                            }
                        }
                    });

                    // Valider que toutes les salles sont OK
                    if (numS == numOK) {
                        return true;
                    } else {
                        return "La salle n'est pas disponible";
                    }
                });

                // Ajouter aux promises et attendre la réponse
                lstPromises.push(promiseDispo);
            }
        }

        if (!r.initialesEmploye || r.initialesEmploye.trim().length == 0) {
            lstPromises.push("Vos initiales sont obligatoires");
        } else {
            lstPromises.push(true);
        }

        // Vérifier les dates
        if (!moment(r.event_date).isValid() || !moment(r.partiesDate).isValid()) {
            lstPromises.push("Vos dates sont invalides");
        } else {
            lstPromises.push(true);
        }

        // Vérifier que les journées entre la réservation, les parties et les salles sont conformes
        if (r.partieTemps) {
            if (moment(r.event_date).format("YYYY-MM-DD") !== moment(r.partiesDate).format("YYYY-MM-DD")) {
                lstPromises.push(`Les dates des parties [${moment(r.partiesDate).format("YYYY-MM-DD")}] ne correspondent pas avec la date de l'événement [${moment(r.event_date).format("YYYY-MM-DD")}]!`);
            }

            // Vérifier que l'heure d'arrivée n'est pas plus grande que le plus petit moment des parties ou de la salle
            var curMinPartie = _.min(r.lstParties, (p) => moment(p.partieTemps).unix())
            var curMin = Math.min(moment(r.event_date).unix(), moment(curMinPartie.partieTemps).unix())
            if (r.salleReservee) {
                curMin = Math.min(curMin, moment(r.salleDebut).unix())
            }
            if (curMin !== moment(r.event_date).unix()) {
                // Probleme!
                lstPromises.push(`L'heure d'arrivée de ${moment(r.event_date).format('HH:mm')} est plus tard que le premier événement à ${moment.unix(curMin).format('HH:mm')}. Vérifiez vos heures!`);
            }
        }


        // Tout est beau à ce point-ci.
        $q.all(lstPromises).then(function (ret) {
            var error = [];
            _.each(ret, function (r) {
                if (r !== true) {
                    error.push(r);
                }
            });
            if (error.length > 0) {
                promise.reject(error);
            } else {
                promise.resolve(true);
            }

        }, function (error) {
            promise.reject(error);

        });

        return promise.promise;
    }

    /**
     * Aller chercher les types de groupes possible pour une réservation
     *
     * @returns {*}
     */
    reservation.ajouterReservation = function (r) {
        if (r.groupeId == 0) {
            var ret = validerReservation(r);

            return ret.then(function () {
                // All good
                r.loading++;
                return $http.post('/api/reservations/ajouter', r).then(function (response) {
                    r.loading--;
                    r.groupeId = response.data.groupeId;
                    r.estModifiee = false;
                    // reservation.socket.emit('RafraichirReservations', r);
                    $messages.sendMessageReservation(r)
                    return response.data;
                });
            }, function (err) {
                // Erreur!
                var deferred = $q.defer();
                deferred.reject(err);
                return deferred.promise;
            });
        } else {
            // Mettre un peu logique!!!
            return reservation.modifierReservation(r);
        }
    };

    /**
     * Aller chercher les types de groupes possible pour une réservation
     *
     * @returns {*}
     */
    reservation.modifierReservation = function (r) {
        if (r.groupeId > 0) {
            return validerReservation(r).then(function () {
                // All good
                r.loading++;
                return $http.post('/api/reservations/modifier/' + r.groupeId, r).then(function (response) {
                    r.loading--;
                    r.estModifiee = false;
                    $messages.sendMessageReservation(r)
                    // reservation.socket.emit('RafraichirReservations', r);
                    return response.data;
                });
            }, function (err) {
                // Erreur!
                var deferred = $q.defer();
                deferred.reject(err);
                return deferred.promise;
            });
        } else {
            var deferred = $q.defer();
            deferred.reject("No groupeid");
            return deferred.promise;
        }
    };

    /**
 * Aller chercher les types de groupes possible pour une réservation
 *
 * @returns {*}
 */
    reservation.patchReservation = function (groupeId, fields) {
        if (groupeId > 0) {
            return $http.patch('/api/reservations/' + groupeId, fields).then(function (response) {
                return response.data;
            });
        } else {
            var deferred = $q.defer();
            deferred.reject("No groupeid");
            return deferred.promise;
        }
    };

    reservation.effacerReservation = function (r) {
        if (r.groupeId > 0) {
            r.loading++;
            var url = '/api/reservations/effacer/' + r.groupeId;
            if (r.estEfface) {
                // On remet actif!
                url = '/api/reservations/deseffacer/' + r.groupeId;
            }
            return $http.post(url, r).then(function (response) {
                r.loading--;
                r.estEfface = !r.estEfface;
                // reservation.socket.emit('RafraichirReservations', r);
                $messages.sendMessageReservation(r)
                return response.data;
            });
        } else {
            var deferred = $q.defer();
            deferred.reject("No groupeid");
            return deferred.promise;
        }
    };

    reservation.annulerReservation = function (r) {
        if (r.groupeId > 0) {
            r.loading++;
            var url = '/api/reservations/annuler/' + r.groupeId;
            if (r.estAnnule) {
                // On remet actif!
                url = '/api/reservations/desannuler/' + r.groupeId;
            }
            return $http.post(url, r).then(function (response) {
                r.estAnnule = !r.estAnnule;
                r.loading--;
                // reservation.socket.emit('RafraichirReservations', r);
                $messages.sendMessageReservation(r)
                return response.data;
            });
        } else {
            var deferred = $q.defer();
            deferred.reject("No groupeid");
            return deferred.promise;
        }
    };

    /**
     * Aller chercher un groupe en particulier
     * @returns {*}
     */
    reservation.chargerReservation = function (groupeId) {
        if (groupeId > 0) {
            return $http.get('/api/reservations/' + groupeId).then(function (response) {
                return response.data;
            });
        } else {
            return [];
        }
    };

    /**
     * Aller chercher un groupe en particulier
     * @returns {*}
     */
    reservation.chargerReservationDeShiftPlanning = function (spId) {
        if (spId > 0) {
            return $http.get('/api/reservations/sp/' + spId).then(function (response) {
                return response.data;
                //return reservation.parseFromDB(response.data);
            });
        } else {
            return false;
        }
    };

    /**
     * Faire la logique pour rajouter une partie à une réservation
     *
     * @param r
     */
    reservation.ajouterPartie = function (r, stime) {

        if (!stime) {
            stime = reservation.reajustTime(moment(r.event_date));
        }
        var newPartie = reservation.creerPartie(r);
        var gt = r.groupeType;
        var lp = r.lstParties.length;

        // console.log("r", r);
        const arena = _.findWhere(D1Configurations.getArenas(), { arena_id: r.arena_id });
        // console.log("arena", arena);
        var rotationTime = (arena && arena.rotation_time ? arena.rotation_time : 20);
        // console.log("rotationTime", rotationTime);

        if (lp) {
            var last = r.lstParties[lp - 1];

            // Selon le type de groupe, on doit ajuster le nombre de minutes
            if (gt.joueConsecutif) {
                // Back to back
                stime = moment(last.partieTemps).add(rotationTime, 'm');
            } else {
                // Tout a fait normal, on skip une partie (+40 minutes de la précédente)
                stime = moment(last.partieTemps).add((rotationTime * 2), 'm');
            }
        } else {
            // 1ère partiue de cédulée, doit-on faire un décalage?
            if (gt.partieOffset > 0) {
                stime = stime.add(gt.partieOffset, 'm');
            }
        }

        // Ajout d'une pause entre les parties?
        if (gt.pauseEntreParties > 0 && gt.pauseApresPartie == lp) {
            // On doit rajouter une pause
            stime = stime.add(gt.pauseEntreParties, 'm');
        }

        // On rajoute la partie et ensuite on va trouve le nombre de joueurs dans la cédule
        newPartie.partieHeures = stime.format('HH:mm');
        newPartie.partieTemps = stime.toDate();
        newPartie.passeCouleur = Cedule.getPasseCouleur(newPartie.partieHeures, r.arena_id);
        r.lstParties.push(newPartie);

        r.nombreParties = r.lstParties.length;
        r.estModifiee = true;

        //r.loading++;
        return Cedule.partieNombreJoueurs(stime.toDate()).then(function (data) {
            //r.loading--;
            newPartie.ceduleNombre = data.nbJoueurs;

            var l = [];
            r.lstParties.forEach(function (p) {
                l.push(p.partieHeures);
            });
            r.lstPartiesSplit = l.join(',');
            return r;
        });
    };

    /**
     * Faire la logique pour modifier l'heure d'une partie spécifique
     *
     * @param r
     */
    reservation.modifierPartie = function (r, partie) {
        var t = moment(moment(partie.partieTemps).format("YYYY-MM-DD") + ' ' + partie.partieHeures);
        partie.partieTemps = t.toDate();
        partie.passeCouleur = Cedule.getPasseCouleur(partie.partieHeures, r.arena_id);
        r.loading++;
        return Cedule.partieNombreJoueurs(t.toDate()).then(function (data) {
            r.loading--;
            partie.ceduleNombre = data.nbJoueurs;

            var l = [];
            r.lstParties.forEach(function (p) {
                l.push(p.partieHeures);
            });
            r.lstPartiesSplit = l.join(',');
            return r;
        });
    };

    /**
     * Faire la logique pour rajouter une partie à une réservation
     *
     * @param r
     */
    reservation.ajusterParties = function (r, stime) {
        // Init
        if (!stime) {
            stime = reservation.reajustTime(moment(r.event_date));
        }

        // Déplacer les parties à la bonne date
        _.each(r.lstParties, (p) => {
            p.partieDate = stime.format("YYYY-MM-DD");
            p.partieTemps = moment(p.partieDate + " " + p.partieHeures).toDate();
            reservation.modifierPartie(r, p);
        });

        r.estModifiee = true;

    };

    reservation.effacerPartie = function (r, p) {
        var l = [];

        var index = -1;

        if (p.id !== undefined) {
            _.each(r.lstParties, function (item, i) {
                if (item.id == p.id) {
                    index = i;
                }
            });
        } else if (p.partieHeures) {
            index = _.findIndex(r.lstParties, { partieHeures: p.partieHeures })
        } else {
            index = p;
        }

        if (index > -1) {
            r.estModifiee = true;
            r.lstParties.splice(index, 1);
            r.nombreParties = r.lstParties.length;
            r.lstParties.forEach(function (partie) {
                l.push(partie.partieHeures);
            });
            r.lstPartiesSplit = l.join(',');
            r.partiesDate = moment(r.lstParties[0].partieTemps)
        }

        return r;
    };

    reservation.envoyerConfirmation = function (r) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/partials/sendConfirmation.html',
            controller: 'ConfirmationInstanceCtrl',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                groupe: function () {
                    return _.clone(r);
                },
                gabarits: ['D1Courriel', function (D1Courriel) {
                    return D1Courriel.getGabarits();
                }]
            }
        });

        return modalInstance.result;
    };

    reservation.administrationNotes = function (r, action) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/partials/adminNotes.tpl.html',
            controller: 'AdminNotesInstanceCtrl',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                groupe: function () {
                    return r;
                },
                action: function () {
                    return action;
                }
            }
        });

        return modalInstance.result;
        /*
                modalInstance.result.then(function () {
                }, function () {
                    // Rien à faire
                });
        */
    };

    reservation.ajouterWalkin = function () {

        var newR = reservation.creer();

        // On met les valeurs par défaut
        newR.statutConfirmation = 1; // Confirmé
        newR.statutGroupe = 2; // Arrivé
        newR.statutPaiement = 1; // Payé

        // Parties
        newR.nombreJoueurs = 2;
        newR.nombreParties = 0;
        //reservation.calculateGames(newR);

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/reservations/reservations-walkin.tpl.html',
            controller: 'D1ReservationWalkinCtrl',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                reservation: function () {
                    return newR;
                }
            }
        });

        return modalInstance.result;
        /*
         modalInstance.result.then(function () {
         }, function () {
         // Rien à faire
         });
         */
    };


    reservation.ajouterVR = function (newR) {

        //var newR = reservation.creer();

        // On met les valeurs par défaut
        //newR.statutConfirmation = 1;    // Confirmé
        //newR.statutGroupe = 2;          // Payé

        // Parties
        //newR.nombreJoueurs = 2;
        //newR.nombreParties = 0;
        //reservation.calculateGames(newR);

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/reservations/reservations-pick-vr.tpl.html',
            controller: 'D1ReservationPickVRCtrl',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                reservation: function () {
                    return newR;
                }
            }
        });

        return modalInstance.result;
        /*
         modalInstance.result.then(function () {
         }, function () {
         // Rien à faire
         });
         */
    };


    reservation.ajouterLasertag = function (newR, arena_id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/reservations/reservations-pick-lasertag.tpl.html',
            controller: 'D1ReservationPickLasertagCtrl',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                reservation: function () {
                    return newR;
                },
                arena_id: arena_id
            }
        });

        modalInstance.result.then((r) => {
        }, () => {
            // console.log('dismiss', dismiss)
        });


        return modalInstance.result;
    };

    /**
     * Fonction minimaliste pour changer le statut, doit être rapide.
     * @param r
     * @returns {*}
     */
    reservation.rechercher = function (r) {
        if (r) {
            return $http.post('/api/reservations/rechercher/', r).then(function (response) {
                var l = [];
                response.data.forEach(function (item) {
                    var t = reservation.parseFromDB(item);
                    l.push(t);
                });

                return l;
            });
        }
    };

    /**
     * Aller chercher un groupe en particulier
     * @returns {*}
     */
    reservation.listeTags = function (query) {
        return $http.get('/api/reservations/tags/' + query).then((l) => {
            const liste = _.pluck(l.data, 'tag');
            return liste;
        });
        /*
        return $http.get('/api/reservations/tags').then(function (response) {
            return response.data;
        });
        */
    };

    // /**
    //  * Aller chercher les tags d'un groupe
    //  * @returns {*}
    //  */
    // reservation.chargerTags = function(groupeId) {
    //     if (groupeId > 0) {
    //         return $http.get('/api/reservations/' + groupeId + '/tags').then(function(response) {
    //             return response.data;
    //         });
    //     } else {
    //         return [];
    //     }
    // };

    /**
     * Aller chercher les options d'un groupe
     * @returns {*}
     */
    reservation.chargerOptions = function (groupeId) {
        if (groupeId > 0) {
            return $http.get('/api/reservations/' + groupeId + '/options').then(function (response) {
                return response.data;
            });
        } else {
            return [];
        }
    };


    reservation.ShowScanCodePopup = function (temps) {

        var newR = reservation.creer();

        // On met les valeurs par défaut
        newR.statutConfirmation = 1; // Confirmé
        newR.statutGroupe = 2; // Arrivé
        newR.statutPaiement = 2; // Payé

        // Parties
        newR.nombreJoueurs = 2;
        newR.nombreParties = 0;

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/reservations/reservations-scan.tpl.html',
            controller: 'D1ReservationScanCodeCtrl',
            size: 'auto',
            resolve: {
                temps: function () {
                    return temps;
                }
            }
        });

        return modalInstance.result;
        /*
         modalInstance.result.then(function () {
         }, function () {
         // Rien à faire
         });
         */
    };

    reservation.scanCode = function (codeBarre) {
        if (codeBarre.length > 0) {
            var code = codeBarre.split("-");
            return reservation.loadGroupe(code[0], true).then(function (groupe) {

                if (groupe.groupeId > 0) {
                    var p = _.findWhere(groupe.lstParties, { id: parseInt(code[1]) });
                    if (p !== undefined) {
                        // Garder une copie de la partie trouvée
                        groupe.codeScanne = p;
                        return groupe;
                    } else {
                        return { error: 'ERREUR #100', data: { error: 'Code barre invalide' } };
                    }
                } else {
                    return { error: 'ERREUR #102', data: { error: groupe.data.error } };
                }
            },
                function (error) {
                    return { error: 'ERREUR #101', data: error.data };
                });
        } else {
            // On doit retourner un promise
            var q = $q.defer();

            q.reject({ error: 'ERREUR #103', data: { error: 'Code barre invalide' } });

            return q.promise;
        }
    };

    reservation.voirHistorique = function (item) {
        var dlg = $uibModal.open({
            templateUrl: 'ng1/partials/historique.tpl.html',
            controller: 'ModalHistoriqueCtrl',
            size: 'lg', //'sm','lg'
            windowClass: 'validation-modal',
            resolve: {
                reservation: function () {
                    return item;
                }
            }
        });
        dlg.result.then(function () {
            // CLOSE FUNCTION
        }, function () {
            // DISMISS FUNCTION
        });
    };

    reservation.ajouterHistorique = (historique) => {
        // Ajouter un historique
        return $http.post('/api/historique', historique)
    };


    /**
     * Section des attributs
     *
     */
    reservation.ajouterAttribut = function (r, attr) {
        var newItem = _.clone(attr);
        r.estModifiee = true;
        r.lstOptions.push(newItem);

        // Add all related attributes to this parent
        var subList = _.where(r.Attributs, { parentAttributId: newItem.attributId });
        _.each(subList, function (item) {
            reservation.ajouterAttribut(r, item);
        })
    };

    reservation.effacerAttribut = function (r, i) {
        if (r.lstOptions[i]) {
            r.lstOptions[i].estEffacee = true;
            r.estModifiee = true;
        }
    };

    reservation.DeseffacerAttribut = function (r, i) {
        if (r.lstOptions[i]) {
            r.lstOptions[i].estEffacee = false;
            r.estModifiee = true;
        }
    };

    reservation.afficherReservation = function (e) {
        console.log(e);
    };

    /**
     * Utilitaires
     */

    /**
     * Permet de comparer 2 réservations pour les différences
     * @param r1
     * @param r2
     */
    reservation.comparer = function (r1, r2) {
        var k1 = _.allKeys(r1);
        //var k2 = _.allKeys(r2);
        var subList = "lstParties".split('_');
        var excludedList = "$$hashKey_lstPartiesSplit_selected_lstOptions_lstVR_tags_options".split('_');
        var diff = [];

        _.each(k1, function (item) {
            //console.log(item, _.indexOf(subList, item));
            if (_.indexOf(excludedList, item) === -1) {
                if (_.indexOf(subList, item) >= 0) {
                    //console.log(item + " dans la liste");
                    var d = reservation.comparer(r1[item], r2[item]);
                    if (d.length) {
                        diff.push(d);
                    }
                } else {
                    if (r1[item] instanceof Date) {
                        if (moment(r1[item]).isSame(r2[item]) === false) {
                            diff.push({ item: item, r1: r1[item], r2: r2[item] });
                        }
                    } else {
                        if (r1[item] !== r2[item]) {
                            //console.log("Item " + item + " est différent", r1[item], r2[item]);
                            diff.push({ item: item, r1: r1[item], r2: r2[item] });
                        } else {
                            //console.log("Item " + item + " identiques");
                        }
                    }
                }
            }
        });

        //console.log("diff", diff);
        return diff;
    };

    /**
     * Fonction qui retourne le déroulement des événements d'une réservation en ordre chronologique
     *
     * @param groupe
     * @returns array d'évéenements
     */
    reservation.chronologie = function (groupe, ifShort) {
        var chrono = [];

        // Batir une liste d'événements
        // type : 1 = partie, 2 = vr, 3 = salle
        // event = { id: int, timestamp : moment, description : string, type : int, index : int }

        // La salle
        if (groupe.salleReservee > 0) {
            var duree = moment(groupe.salleFin).diff(moment(groupe.salleDebut), 'minutes');
            chrono.push({
                id: groupe.salleReservee,
                timestamp: moment(groupe.salleDebut),
                description: ifShort ? "Salle début" : "Début de votre salle. Durée de " + duree + " minutes",
                type: 3,
                index: 1
            });

            chrono.push({
                id: groupe.salleReservee,
                timestamp: moment(groupe.salleFin),
                description: ifShort ? "Salle sortie" : "Sortie de votre salle",
                type: 3,
                index: 2
            })
        }

        // Les parties
        _.each(groupe.lstParties, function (item, i) {
            chrono.push({ id: item.id, timestamp: moment(item.partieTemps), description: "Partie #" + (i + 1) + (item.nombreJoueurs ? " : " + item.nombreJoueurs + " joueurs" : ''), type: 1, index: i })
        });

        // Les VR
        _.each(groupe.lstVR, function (item, i) {
            chrono.push({ id: item.id, timestamp: moment(item.partieTemps), description: "Réalité virtuelle sur " + item.nombreJoueurs + " station(s). Durée de " + item.partieDuree + " minutes", type: 2, index: i })
        });

        // On place en ordre
        chrono = _.sortBy(chrono, 'timestamp');
        return chrono;
    };


    /**
     * 
     */
    reservation.showReajustementDialog = function (groupe) {

        var modalInstance = $uibModal.open({
            templateUrl: 'ng1/reservations/reservations-dialog-adjust.tpl.html',
            controller: ($scope, $uibModalInstance) => {
                $scope.options = { shiftGames: true, shiftSalles: true };
                $scope.diffMinutes = moment(groupe.event_time).diff(groupe.event_date, 'minutes');
                $scope.groupe = groupe;

                // bouton "cancel"
                $scope.closeConfirm = function () {
                    // set init values
                    // close function
                    $scope.options.shiftGames = false;
                    $scope.options.shiftSalles = false;
                    $uibModalInstance.close($scope.options);
                };

                // bouton "save"
                $scope.saveDialog = function () {
                    // Retourner les choix sélectionnés
                    $uibModalInstance.close($scope.options);
                };
            },
            size: 'md', //'sm','lg'
            resolve: {}
        });
        return modalInstance.result;

        // return modalInstance.result.then(function(reponse) {
        //     // CLOSE FUNCTION
        //     console.log('Closing', reponse);
        //     modalInstance.close(reponse);

        // }, function() {
        //     // DISMISS FUNCTION
        //     console.log('DISMISS');
        //     modalInstance.dismiss();
        // });
    };


    return reservation;
}]);

d1Reservation.controller('ConfirmationInstanceCtrl', function ($scope, $uibModalInstance, groupe, $sce, $interpolate, D1Courriel, gabarits, Reservation, Notification, $q, D1Configurations, $toastService) {
    // Init
    $scope.courriel_body = '';
    $scope.groupe = groupe;
    $scope.sujet = '';
    $scope.gabarit = {};
    $scope.lstGabarits = [];
    $scope.Reservation = Reservation;
    $scope.checkConfirmation = true;
    $scope.groupe_tags = ''
    var lstMois = 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_');
    const curGroupeTypeId = _.findWhere(D1Configurations.TypeGroupes, { groupeTypeId: +groupe.groupeTypeId })

    // Tokens ajoutés
    var ha = moment(groupe.event_date);
    $scope.tokens = {
        jour: ha.format('D'),
        mois_nom: ha.format('MMMM'),
        mois: lstMois[ha.format('M') - 1],
        parties: '',
        heures: 'De ' + moment(groupe.salleDebut).format('H:mm') + ' à ' + moment(groupe.salleFin).format('H:mm'),
        extra: '',
        heure_arrivee: ha.subtract(15, 'm').format('H:mm'),
        chronologie: Reservation.chronologie(groupe)
    };

    angular.forEach(groupe.lstParties, function (k, i) {
        $scope.tokens.parties += "Parties #" + (i + 1) + ': ' + k.partieHeures + "<br>";
    });

    // Slush + Popcorn
    if (groupe.slushDate) {
        $scope.tokens.extra = "Votre slush et popcorn seront servi à " + groupe.slushDate;
    }
    if (groupe.gateauDate) {
        $scope.tokens.extra += "<br>Votre gâteau sera servi à " + groupe.gateauDate;
    }

    groupe.event_time = moment(groupe.event_time).format('HH:mm');
    groupe.token = $scope.tokens;
    //console.log(groupe);

    $scope.tinymceOptions = {
        inline: false,
        plugins: 'advlist autolink link image lists charmap print preview, code',
        skin: 'lightgray',
        theme: 'modern',
        resize: true,
        height: 400
    };

    // Le call API peut prendre du temps à revenir, attendre!!!
    $scope.$watch('gabarits', function () {
        if ($scope.courriel_body.length == 0) {
            _.sortBy(gabarits, 'name');
            gabarits.forEach(function (g) {
                $scope.lstGabarits.push({ 'id': g.id, 'name': g.name, 'sujet': g.versions[0].subject });
                //if (g.id == 'b6364807-7f5b-4ad2-8440-e5233c373c0a') {
                //    $scope.exp = $interpolate(g.versions[0].html_content, false, null, false);
                //}
            });

            $scope.gabarit = _.findWhere($scope.lstGabarits, { id: curGroupeTypeId.confirmationTemplate });
            $scope.changerGabarit()
            $scope.checkConfirmation = true
        }
        //$scope.courriel_body = $scope.exp(groupe);
    });

    Handlebars.registerHelper('inc', function (value, options) {
        if (options == undefined || typeof options !== "number") {
            options = 1;
        }
        return (value + options);
    });


    Handlebars.registerHelper('formatDate', function (value, format) {
        var curDate = moment(value);
        return (curDate.format(format));
    });

    Handlebars.registerHelper('AdjustFormatDate', function (value, format, adjust, adjustType) {
        var curDate = moment(value);
        if (adjust !== undefined && adjustType !== undefined) {
            curDate.add(adjust, adjustType)
        }
        return (curDate.format(format));
    });

    $scope.changerGabarit = function () {
        $scope.checkConfirmation = false;
        gabarits.forEach(function (g) {
            if (g.id == $scope.gabarit.id) {
                $scope.sujet = g.versions[0].subject;
                if (g.generation && g.generation == 'dynamic') {
                    //$scope.exp = $interpolate(g.versions[0].html_content, false, null, false);
                    //var htmlcontent = $scope.exp(groupe);
                    //var template = Handlebars.compile(htmlcontent);
                    var template = Handlebars.compile(g.versions[0].html_content);
                    $scope.courriel_body = template(groupe);
                } else {
                    $scope.exp = $interpolate(g.versions[0].html_content, false, null, false);
                    $scope.courriel_body = $scope.exp(groupe);
                }
            }
        });
    };



    $scope.ok = function () {

        // On confirme lorsqu'on envoit par courriel!
        D1Courriel.Email.to = groupe.courriel;
        D1Courriel.Email.to_name = groupe.nomOrganisateur;
        //D1Courriel.Email.to = 'benoit@typhon.ca';
        D1Courriel.Email.sujet = $scope.sujet;
        D1Courriel.Email.contenu = $scope.courriel_body;
        D1Courriel.Email.isConfirmation = $scope.checkConfirmation
        D1Courriel.Email.tags = $scope.groupe_tags
        //console.log("On envoit ce texte : ", $scope.courriel_body);
        D1Courriel.sendmail().then(function () {
            let promises = [];

            // Ajouter un historique pour garder une trace;
            var histo = {
                groupeId: groupe.groupeId,
                action: 'email',
                detail: `Courriel [${$scope.checkConfirmation ? "de confirmation " : ""}]envoyé à [${groupe.courriel}] avec le sujet [${$scope.sujet}] et le gabarit [${$scope.gabarit.name}]`
            };
            promises.push(Reservation.ajouterHistorique(histo));

            // Tags
            if ($scope.groupe_tags) {
                // Ajouter les tags pour cette réservation
                promises.push(new Promise((resolve, reject) => {
                    const groupe_tags2 = _.union(groupe.groupe_tags, $scope.groupe_tags);
                    Reservation.updateTags(groupe.groupeId, groupe_tags2).then(
                        (r) => {
                            groupe.groupe_tags = groupe_tags2;
                            resolve(r);
                        }, (error) => {
                            console.error(error);
                            reject(error);
                        }
                    )
                }));
            }


            if ($scope.checkConfirmation) {
                groupe.confirmationEnvoyee = true
                promises.push(Reservation.patchReservation(groupe.groupeId, { confirmationEnvoyee: true }))
            }

            if ($scope.notes) {
                // Ajouter un historique pour garder une trace;
                histo = {
                    groupeId: groupe.groupeId,
                    action: 'email',
                    detail: `Courriel [${$scope.checkConfirmation ? "de confirmation " : ""}]envoyé à [${groupe.courriel}] avec le sujet [${$scope.sujet}] et le gabarit [${($scope.gabarit ? $scope.gabarit.name : 'Aucun')}] : ${$scope.notes}`
                };

                if ($scope.groupe_tags) {
                    histo.detail += ` | Tags : ${_.pluck($scope.groupe_tags, 'text').join(', ')}`
                }

                promises.push(Reservation.ajouterHistorique(histo));
            }

            Promise.all(promises).then(() => {
                // Retourner une liste de champs modifiés
                $uibModalInstance.close({
                    confirmationEnvoyee: groupe.confirmationEnvoyee,
                    groupe_tags: groupe.groupe_tags,
                });
                $toastService.showSuccess("Courriel envoyé", "<i class='fa fa-2x fa-check-circle'></i> Message envoyé avec succès")
            })

        },
            function (err) {
                console.error(err)
                $toastService.showError("Erreur de courriel", "<i class='fa fa-2x fa-bomb'></i> Échec de l'envoi du courriel : ")
                //$uibModalInstance.close();
            });

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

d1Reservation.controller('AdminNotesInstanceCtrl', function ($scope, $uibModalInstance, groupe, action) {
    // Init
    $scope.groupe = groupe;
    $scope.action = action;
    $scope.title = '';
    $scope.explication = '';

    switch (action) {
        case 1:
            $scope.title = "l'annulation d'une réservation";
            break;

        case 2:
            $scope.title = "remettre la réservation active";
            break;

        case 3:
            $scope.title = "effacer une réservation";
            break;

        case 4:
            $scope.title = "la restoration d'une réservation";
            break;

    }

    console.log(groupe);

    $scope.ok = function () {
        console.log("ok", $scope);
        switch (action) {
            case 1:
                $scope.groupe.notesAdmin += "\r\nANNULATION D'UNE RÉSERVATION";
                break;

            case 2:
                $scope.groupe.notesAdmin += "\r\nREMISE D'UNE RÉSERVATION ACTIVE";
                break;

            case 3:
                $scope.groupe.notesAdmin += "\r\nEFFACER UNE RÉSERVATION";
                break;

            case 4:
                $scope.groupe.notesAdmin += "\r\nRESTAURER UNE RÉSERVATION";
                break;

        }
        $scope.groupe.notesAdmin += " (" + moment().format("D MMM YYYY @ HH:mm:ss") + ") : " + $scope.explication;

        $uibModalInstance.close($scope.groupe);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

//
d1Reservation.controller('D1ReservationWalkinCtrl', function ($scope, $uibModalInstance, reservation, Usagers, $sce, $interpolate, Cedule, Reservation, D1ServiceVR, D1Configurations, $messages) {
    // Init
    $scope.reservation = reservation;
    $scope.title = '';
    $scope.explication = '';
    $scope.Usagers = Usagers;
    $scope.listePartiesDisponibles = [];
    $scope.lstVRNouvelle = [];
    $scope.Configurations = D1Configurations;
    $scope.lstExperiences = [];
    $scope.lstVRDurees = [];

    if (Usagers.voirModule('vr')) {
        D1ServiceVR.getExperiences().then(function (resp) {
            $scope.lstExperiences = resp.stations;
        }, function (err) {
            // error
            console.log("D1ReservationPickVRCtrl ERROR", err);
            throw err;
        });
    }

    // On va prendre des groupes qui ne requierent pas de dépots
    $scope.lstGroupeTypes = _.filter(D1Configurations.TypeGroupes, (g) => {
        return (g.estDisponible === 1 && g.estFete === 0 && g.requiertCoordonnes === 0 && g.minimumDepot === 0) || g.groupeTypeId === 0
    });

    $messages.SettingsMessage.subscribe( r => { 
        $scope.lstGroupeTypes = _.filter(D1Configurations.TypeGroupes, (g) => {
            return (g.estDisponible === 1 && g.estFete === 0 && g.requiertCoordonnes === 0 && g.minimumDepot === 0) || g.groupeTypeId === 0
        });
    })

    // Disponibilités
    _.each(D1Configurations.getArenas(), function (arena) {
        $scope.listePartiesDisponibles[arena.arena_id] = [];
        Cedule.disponibilites(reservation.event_date, arena.arena_id).then(function (data) {
            // Ne pas prendre les disponibilités dans le passé, on veut toujours ce qui s'en vient à partir de maintenant.
            var n = moment().format('HH:mm');
            _.each(data, function (item) {
                if (item.partieHeures >= n) {
                    let sel = _.findWhere(reservation.lstParties, { partieHeures: item.partieHeures, arena_id: item.arena_id });
                    item.selected = (sel != undefined ? 1 : 0);
                    item.arena_id = arena.arena_id;
                    item.passeCouleur = Cedule.getPasseCouleur(item.partieHeures, arena.arena_id);
                    $scope.listePartiesDisponibles[arena.arena_id].push(item);
                }
            });
        });
    });

    // Init variables
    $scope.reservation.initialesEmploye = Usagers.usager.initiales;
    $scope.reservation.nomOrganisateur = '';

    $scope.validate = function () {
        var r = $scope.reservation;
        var bOk = false;

        // Basic
        bOk = (r.initialesEmploye && r.initialesEmploye.length > 0 && r.nomOrganisateur && r.nomOrganisateur.length > 0);

        // Valider pour des parties sélectionnées
        if (bOk) {
            _.each($scope.listePartiesDisponibles, function (arena) {
                bOk |= (_.findWhere(arena, { selected: 1 }) != undefined);
            })

            // Check VR
            _.each($scope.lstVRNouvelle, function (newVR) {
                bOk |= (_.findWhere(newVR.lstVRStations, { selected: 1 }) != undefined);
            });
        }

        return bOk;
    };

    $scope.afficherReservation = function () {
        console.log($scope.reservation);
        console.log($scope.listePartiesDisponibles);
    };

    /**
     * Fonctions pour le VR
     */
    $scope.ajouterVR = function () {
        var newPartie = {};

        if ($scope.reservation.nombreJoueurs == 0) {
            $scope.reservation.nombreJoueurs = 1;
        }
        //newPartie.partieTemps = moment($scope.reservation.event_date).format("YYYY-MM-DD H:mm:00");
        newPartie.partieTemps = moment($scope.reservation.event_date).toDate();
        newPartie.nombreJoueurs = $scope.reservation.nombreJoueurs;
        newPartie.partieDuree = 15;
        newPartie.lstVRStations = [];
        $scope.lstVRNouvelle.push(newPartie);
        $scope.reservation.estModifiee = true;
    };

    $scope.effacerVR = function (i) {
        if ($scope.lstVRNouvelle[i]) {
            //$scope.reservation.lstVR.splice(i,1);
            $scope.lstVRNouvelle[i].estEffacee = true;
            $scope.reservation.estModifiee = true;
        }
    };

    $scope.DeseffacerVR = function (i) {
        if ($scope.lstVRNouvelle[i]) {
            //$scope.reservation.lstVR.splice(i,1);
            $scope.lstVRNouvelle[i].estEffacee = false;
            $scope.reservation.estModifiee = true;
        }
    };

    $scope.updateVRAvailability = function (p) {
        p.lstVRStations = [];
        p.partieDuree = p.choixDuree;
        D1ServiceVR.getAvailability($scope.reservation.event_date, p.choixExperience, p.choixDuree, p.nombreJoueurs).then(function (response) {
            p.lstVRStations = response.times;
        });

    };

    $scope.ok = function () {
        // Vars
        var r = $scope.reservation;
        //var lstParties = _.where($scope.listePartiesDisponibles, {selected : 1});
        var lstParties = [];

        _.each($scope.listePartiesDisponibles, function (parties) {
            var t = _.where(parties, { selected: 1 });
            lstParties = lstParties.concat(t);
        });

        // Init
        r.nombreParties = 0;
        r.lstParties = [];
        r.groupeTypeId = r.groupeType.groupeTypeId;

        // S'assurer de matcher l'heure de départ selon les parties ou le VR
        if (lstParties.length) {
            var item = lstParties[0];
            r.event_date = moment(r.event_date).format("YYYY-MM-DD " + item.partieHeures + ":00");
        }

        // Préparer les parties sélectionnées
        _.each(lstParties, function (item) {
            var p = {
                partiesDate: item.partiesDate,
                partieHeures: item.partieHeures,
                arena_id: item.arena_id
            };
            r.lstParties.push(p);
        });
        r.nombreParties = r.lstParties.length;
        r.lstPartiesSplit = _.map(r.lstParties, 'partieHeures');

        // Préparer les stations de VR
        r.lstVR = [];
        _.each($scope.lstVRNouvelle, function (newVR) {
            var lstStations = _.where(newVR.lstVRStations, { selected: 1 });
            _.each(lstStations, function (selStation) {
                // Une demande de sations VR
                var newStation = _.clone(newVR);
                newStation.id = Math.random();
                newStation.nouvelle = true;
                newStation.stationVR = selStation;
                newStation.partieTemps = moment(selStation.startTimePre).toDate();
                r.lstVR.push(newStation);
            });

            // Comparer pour prendre le plus petit entre le lasertag et le VR.
            var firstVR = lstStations[0];
            if (lstParties.length) {
                var vrDate = moment(moment(r.event_date).format("YYYY-MM-DD " + firstVR.value + ":00"));
                if (vrDate.isBefore(r.event_date)) {
                    // Le VR est AVANT le lasertag
                    r.event_date = vrDate.format("YYYY-MM-DD H:mm:00");
                }
            } else {
                r.event_date = moment(r.event_date).format("YYYY-MM-DD " + firstVR.value + ":00");
            }
        });

        r.event_time = moment(r.event_date).toDate();
        r.partiesDate = moment(r.event_time);
        //console.log(r);
        console.log(r.lstVR);

        Reservation.ajouterReservation(r);
        $uibModalInstance.close(r);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

d1Reservation.controller('D1ReservationPickVRCtrl', function ($scope, $uibModalInstance, reservation, Usagers, $sce, $interpolate, Cedule, Reservation, D1ServiceVR) {
    // Init
    $scope.reservation = reservation;
    $scope.title = '';
    $scope.explication = '';
    $scope.Usagers = Usagers;
    $scope.listePartiesDisponibles = [];
    $scope.lstVRNouvelle = [];
    $scope.lstExperiences = [];
    $scope.lstVRDurees = [];

    D1ServiceVR.getExperiences().then(function (resp) {
        $scope.lstExperiences = resp.stations;
    }, function (err) {
        // error
        console.log("D1ReservationPickVRCtrl ERROR", err);
        throw err;
    });

    $scope.validate = function () {
        var bOk = false;

        // Check VR
        _.each($scope.lstVRNouvelle, function (newVR) {
            bOk |= (_.findWhere(newVR.lstVRStations, { selected: 1 }) != undefined);
        });

        return bOk;
    };

    /**
     * Fonctions pour le VR
     */
    $scope.ajouterVR = function () {
        var newPartie = {};

        if ($scope.reservation.nombreJoueurs == 0) {
            $scope.reservation.nombreJoueurs = 1;
        }
        newPartie.partieTemps = moment($scope.reservation.event_date).format("YYYY-MM-DD H:mm:00");
        newPartie.nombreJoueurs = Math.min(2, $scope.reservation.nombreJoueurs);
        newPartie.partieDuree = 15;
        newPartie.lstVRStations = [];
        $scope.lstVRNouvelle.push(newPartie);
        $scope.reservation.estModifiee = true;
    };

    $scope.effacerVR = function (i) {
        if ($scope.lstVRNouvelle[i]) {
            //$scope.reservation.lstVR.splice(i,1);
            $scope.lstVRNouvelle[i].estEffacee = true;
            $scope.reservation.estModifiee = true;
        }
    };

    $scope.DeseffacerVR = function (i) {
        if ($scope.lstVRNouvelle[i]) {
            //$scope.reservation.lstVR.splice(i,1);
            $scope.lstVRNouvelle[i].estEffacee = false;
            $scope.reservation.estModifiee = true;
        }
    };

    $scope.updateVRAvailability = function (p) {
        p.lstVRStations = [];
        p.partieDuree = p.choixDuree;
        D1ServiceVR.getAvailability($scope.reservation.event_date, p.choixExperience, p.choixDuree, p.nombreJoueurs).then(function (response) {
            p.lstVRStations = response.times;
        });

    };

    $scope.ok = function () {
        // Vars
        var r = $scope.reservation;

        // Init
        //r.lstVR = [];

        // Préparer les stations de VR
        _.each($scope.lstVRNouvelle, function (newVR) {
            var lstStations = _.where(newVR.lstVRStations, { selected: 1 });
            _.each(lstStations, function (selStation) {
                // Une demande de sations VR
                var newStation = _.clone(newVR);
                newStation.id = Math.random();
                newStation.nouvelle = true;
                newStation.stationVR = selStation;
                newStation.partieTemps = moment(selStation.startTimePre).toDate();
                r.lstVR.push(newStation);
            });
        });

        console.log(r.lstVR);

        $uibModalInstance.close(r);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };


    $scope.ajouterVR();
});

d1Reservation.controller('D1ReservationPickLasertagCtrl', function ($scope, $uibModalInstance, reservation, Usagers, Cedule, D1Configurations,) {
    // Init
    $scope.reservation = reservation;
    $scope.Usagers = Usagers;
    $scope.listePartiesDisponibles = [];
    $scope.Configurations = D1Configurations;
    let oldlstParties = [...reservation.lstParties]

    // Init

    // Disponibilités
    _.each(D1Configurations.getArenas(), function (arena) {
        $scope.listePartiesDisponibles[arena.arena_id] = [];
        $scope.listePartiesDisponibles[arena.arena_id].bypass = 0;
        Cedule.disponibilites(reservation.event_date, arena.arena_id).then(function (data) {
            // Ne pas prendre les disponibilités dans le passé, on veut toujours ce qui s'en vient à partir de maintenant.
            var n = moment().format('HH:mm');

            _.each(data, function (item) {
                if (item.partieHeures >= n || true) {
                    let sel = _.findWhere(reservation.lstParties, { partieHeures: item.partieHeures, arena_id: item.arena_id });

                    item.selected = (sel != undefined ? 1 : 0);
                    item.arena_id = arena.arena_id;
                    item.nombreJoueurs = (sel != undefined ? sel.nombreJoueurs : $scope.nombreJoueurs);
                    item.passeCouleur = (sel != undefined ? sel.passeCouleur : Cedule.getPasseCouleur(item.partieHeures, arena.arena_id));
                    $scope.listePartiesDisponibles[arena.arena_id].push(item);
                }
            });
        });

    });

    $scope.validate = function () {
        var bOk = false;

        // Basic
        _.each($scope.listePartiesDisponibles, function (arena) {
            bOk |= (_.findWhere(arena, { selected: 1 }) != undefined);
        });

        return bOk;
    };

    $scope.afficherReservation = function () {
        console.log($scope.reservation);
        console.log($scope.listePartiesDisponibles);
    };


    $scope.showDisponibilites = function (arena_id) {
        // On inverse le bypass
        var bypass = $scope.listePartiesDisponibles[arena_id].bypass;
        if (bypass == 0) {
            bypass = 1;
        } else {
            bypass = 0;
        }

        // Disponibilités
        $scope.listePartiesDisponibles[arena_id] = [];
        $scope.listePartiesDisponibles[arena_id].bypass = bypass;
        Cedule.disponibilites(reservation.event_date, arena_id, bypass).then(function (data) {
            _.each(data, function (item) {
                let sel = _.findWhere(reservation.lstParties, { partieHeures: item.partieHeures, arena_id: item.arena_id });

                item.selected = (sel != undefined ? 1 : 0);
                item.arena_id = arena_id;
                item.nombreJoueurs = (sel != undefined ? sel.nombreJoueurs : $scope.nombreJoueurs);
                item.passeCouleur = (sel != undefined ? sel.passeCouleur : Cedule.getPasseCouleur(item.partieHeures, arena_id));
                $scope.listePartiesDisponibles[arena_id].push(item);
            });
        });

    };


    $scope.ok = function () {
        // Vars
        var r = $scope.reservation;
        var lstParties = [];

        _.each($scope.listePartiesDisponibles, function (parties) {
            var t = _.where(parties, { selected: 1 });
            lstParties = lstParties.concat(t);
        });

        // Init
        r.nombreParties = 0;
        r.lstParties = [];

        // Préparer les parties sélectionnées
        var pd = moment(r.event_date).format("YYYY-MM-DD");
        _.each(lstParties, function (item) {
            var p = {
                partiesDate: pd,
                nombreJoueurs: (!item.nombreJoueurs ? r.nombreJoueurs : item.nombreJoueurs),
                partieHeures: item.partieHeures,
                passeCouleur: item.passeCouleur,
                arena_id: item.arena_id,
                partieTemps: moment(pd + " " + item.partieHeures).toDate()
            };
            r.lstParties.push(p);
        });
        r.nombreParties = r.lstParties.length;
        r.lstPartiesSplit = _.map(r.lstParties, 'partieHeures');

        let a = _.pluck(r.lstParties, 'partieHeures');
        let b = _.pluck(oldlstParties, 'partieHeures');
        let diff = _.difference(b, a)

        r.estModifiee = (diff.length || (r.lstParties.length !== oldlstParties.length)) > 0
        if (r.estModifiee) {
            r.partiesDate = moment(r.lstParties[0].partieTemps)
        }

        $uibModalInstance.close(r);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});


d1Reservation.controller('D1ReservationScanCodeCtrl', function ($scope, $uibModalInstance, Usagers, $sce, $interpolate, Cedule, Reservation, temps, Notification, $toastService) {
    // Init
    $scope.codeBarre = '';
    $scope.Usagers = Usagers;
    $scope.codeScanne = false;
    $scope.codeLoading = false;
    $scope.Cedule = Cedule;

    var sound = new Howl({
        src: ['video/alarm.mp3']
    });

    $scope.validate = function () {
        var bOk = true;

        // N'est pas valide s'il y a des erreurs
        bOk = !$scope.hasError;

        return bOk;
    };

    $scope.ok = function () {
        // Vars
        var el = $("#codeBarre");
        el.focus();
        el.select();

        // Init
        $scope.hasError = false;
        $scope.codeScanne = false;
        $scope.codeLoading = true;

        Reservation.scanCode($scope.codeBarre).then(function (groupe) {
            $scope.codeLoading = false;
            console.log("scanCode() groupe", groupe);
            if (groupe.error) {
                $scope.hasError = true;
                $scope.erreurMessage = groupe.data.error;
            } else {
                // codeScanne contient la partie désirée
                if (temps == undefined) {
                    $scope.codeScanne = groupe.codeScanne;
                } else {
                    // On doit valider qu'on est dans le bon timeslot
                    var pt = moment(groupe.codeScanne.partieTemps);
                    if (pt.isSame(temps)) {
                        // Auto-confirmé, all good!
                        Notification.info({
                            message: "<i class='fa fa-3x fa-check-circle'></i> Code barre [" + $scope.codeBarre + "] validé",
                            title: "Auto-confirmé",
                            positionX: 'right',
                            positionY: 'top',
                            delay: 2000,
                            onClose: function () { }
                        });

                        // On ferme automatiquement
                        el.val('');
                        $scope.codeScanne = false;

                    } else {
                        $scope.codeScanne = groupe.codeScanne;
                        $scope.hasError = true;
                        $scope.erreurMessage = "Le code n'est pas valide pour cette cédule";
                        sound.play();
                        // Auto-confirmé, all good!
                        $toastService.showError("<i class='fa fa-3x fa-exclamation-triangle'></i> Code barre [" + $scope.codeBarre + "] invalide!", " Groupe de " + groupe.nomOrganisateur + " n'est pas dans la bonne partie.<br>Passes cédulées pour : " + pt.format("D MMM à HH:mm"))

                    }
                }
            }
        }, function (error) {
            $scope.hasError = true;
            $scope.erreurMessage = error.data.error;
            $scope.codeLoading = false;
        });


        //$uibModalInstance.close(r);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});
/**
 * Created by benoit on 04/10/2016.
 */