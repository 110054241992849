<mat-card>
    <mat-card-title>
        <a class="mr-2" href="https://district-1-lasertag.myshopify.com/admin/customers/{{customer.id}}" target="_blank">{{ customer.first_name | titlecase }} {{ customer.last_name | titlecase }}</a>
        <div class="badge">Total commandé : {{ +customer.total_spent | currency}}</div></mat-card-title
    ><mat-card-content
        ><div class="d-flex-column">
            <div>{{ customer.default_address.address1}}</div>
            <div>{{ customer.default_address.city}} {{ customer.default_address.province_code}} {{ customer.default_address.zip}}</div>
            <div>{{ customer.default_address.phone}}</div>
            <div>{{ customer.email}}</div>
            <h3><span class="pr-2" [matBadge]="customer.orders_count">Commandes </span></h3>
            <div class="d-flex flex-wrap">
                <mat-card class="flex-fill m-1" *ngFor="let o of customer.orders"
                    ><mat-card-title> <a class="mr-2" href="https://district-1-lasertag.myshopify.com/admin/orders/{{o.id}}" target="_blank">{{ o.name }}</a></mat-card-title
                    ><mat-card-subtitle>Commandé le : {{ o.created_at | date }}</mat-card-subtitle><mat-card-subtitle>{{ o.total_price | currency }}</mat-card-subtitle
                    ><mat-card-content
                        ><ul>
                            <li *ngFor="let item of o.line_items">
                                {{ item.quantity }} x {{ item.name}} : {{ item.price*item.quantity | currency }}
                                <ul *ngIf="filterProperties(item.properties).length &gt; 0">
                                    <li *ngFor="let prop of filterProperties(item.properties)">{{ prop.name }} : {{ prop.value }}</li>
                                </ul>
                            </li>
                        </ul></mat-card-content
                    ></mat-card
                >
            </div>
        </div></mat-card-content
    ></mat-card
>
