import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-reservations-print-unfolding',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-print-unfolding.component.html',
    styleUrls: ['reservations-print-unfolding.component.scss'],
})
export class ReservationsPrintUnfoldingComponent implements OnInit {
    public reservation: ReservationModel = new ReservationModel()
    private mySubs: Subscription[] = [];

    constructor(private route: ActivatedRoute, private r$: ReservationsService, private ref: ChangeDetectorRef, private s$:ScheduleService) {
        if (route.snapshot.data.reservation) {
            this.reservation = route.snapshot.data.reservation;
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        _.each(this.mySubs, (s) => s.unsubscribe());
    }

    ngOnInit() {
        this.mySubs.push(this.route.params.subscribe( (params) => {
            if (this.route.snapshot.data.reservation) {
                this.reservation = this.route.snapshot.data.reservation;
            }
            this.ref.markForCheck();
        }));
    }

    getPartieClasse(p: GameModel) {
        return 'badge-' + this.s$.getPasseCouleur(p.partieTemps);
    }

    // get getCodeBarre() {
    //     return this.r$.getCodeBarre(this.reservation, ;
    // }

    getCodeBarre(p: GameModel):string {
        return this.r$.getCodeBarre(this.reservation, p);
    }
}
