import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShopifyService } from '@modules/shopify/services';
import { ColumnDefinitionsModel, SimpleGridComponent } from 'ts-ng-lib';

@Component({
    selector: 'd1-shopify-admin-clients',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-admin-clients.component.html',
    styleUrls: ['shopify-admin-clients.component.scss'],
})
export class ShopifyAdminClientsComponent implements OnInit {
    @ViewChild('grille', { static: true}) grille!:SimpleGridComponent;
    public lstClients!:[];
    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: '#', visible: true, name: 'id', sortable: true, arrowPosition: 'after' },
        { headerText: 'Prénom', visible: true, name: 'first_name', sortable: true, arrowPosition: 'after' },
        { headerText: 'Nom', visible: true, name: 'last_name', sortable: true, arrowPosition: 'after' },
        { headerText: 'Courriel', visible: true, name: 'email', sortable: true, arrowPosition: 'after' },
        { headerText: 'Téléphone', visible: true, name: 'default_address.phone', sortable: true, arrowPosition: 'after' },
        { headerText: 'Adresse', visible: true, name: 'default_address', sortable: true, arrowPosition: 'after', format: (v) => {
            return v ? `${v.address1}, ${v.city}` : '';
        } },
        { headerText: 'Tags', visible: true, name: 'tags', showAsPills:true, sortable: false, arrowPosition: 'after' },
        // { headerText: 'Image', visible: true, name: 'image', sortable: false, arrowPosition: 'after', format: (v) => {
        //     if (v) {
        //         // const test = this.dom.bypassSecurityTrustHtml(`<img src="${this.dom.bypassSecurityTrustResourceUrl(v.src)}" />`)
        //         return `<img class="img-thumbnail img-fluid" src="${v.src}" />`;
        //     } else {
        //         return v;
        //     }
        // } },

    ];

    constructor(private s$:ShopifyService, private ref:ChangeDetectorRef, private dom:DomSanitizer) {
        // this.s$.getClients().subscribe( lst => {
        //     console.log('lst', lst);
        //     this.lstClients = lst ;
        //     ref.markForCheck()
        // })
    }
    ngOnInit() {}

    ngAfterViewInit(): void {
        this.grille.loading = true;
        this.s$.getClients().subscribe( lst => {
            this.lstClients = lst ;
            this.grille.loading = false;
            this.ref.markForCheck()
        })
    }

}
