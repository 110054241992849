import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClickEventArg, ColumnDefinitionsModel } from '@common/components';
import { ToastService } from '@common/services';
import { FullCalendarComponent} from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/core'
import { Rental } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import frLocale from '@fullcalendar/core/locales/fr';
import _ from 'lodash';

// Full calendar plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timePlugin from '@fullcalendar/timegrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin


class EventModel {
    start: Date | string | undefined = undefined;
    end: Date | string | undefined = undefined;
    title: string = '';
}

@Component({
    selector: 'd1-rentals-bookings-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-bookings-view.component.html',
    styleUrls: ['rentals-bookings-view.component.scss'],
})
export class RentalsBookingsViewComponent implements OnInit, AfterViewInit {
    public ListeRentals: Rental[] = [];
    // public listes:EventModel[] = [];
    public curDate = new Date();
    public moment = moment;
    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
    calendarApi!: Calendar;

    public caloptions: CalendarOptions = {
        plugins: [
            dayGridPlugin,
            timePlugin,
            interactionPlugin,
            // adaptivePlugin 
        ],
        timeZone: 'local',
        /* @ts-ignore */
        schedulerLicenseKey: '1234567890-fcs-1834567890',
        initialView: 'dayGridWeek',
        locale: frLocale,
        aspectRatio: 3,
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridDay,dayGridWeek,dayGridMonth',
        },
        contentHeight : "50vh",
        eventClick : this.eventClick.bind(this),
        // eventContent : (item) => {
        //     console.log('eventContent', item);
        // },
        // eventMouseEnter : mf => {
        //     console.log('mousenter', mf);
        // },
        editable: true,
        eventSources: [

            // your event source
            {
              id: 'slot',
              url: '/api/bookings/ListBookings',
              method: 'POST',
              backgroundColor : 'orange',
            //   extraParams: {
            //     num: this.minimumQuantity
            //   },
              display : 'auto',
              failure: function() {
                alert('there was an error while fetching events!');
              },
              color: 'blue',   // a non-ajax option
              textColor: 'black' // a non-ajax option
            },
        ],
        eventSourceSuccess: (content: any, xhr: any) => {
            return _.map(content, r => {
                return {
                    start:  moment(r.start_sec).toISOString(),
                    end : moment(r.start_sec).add(r.duration_sec, 'm').toISOString(),
                    title : `${r.nomOrganisateur} ( ${r.nombreJoueurs} équipements)`,
                    booking : r,
                    id : r.booking_id,
                }
            });
        },
          
    }

    public isCollapsed = true;
    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: 'Réservation #', visible: true, name: 'booking_id', sortable: true },
        { headerText: 'Groupe', visible: true, name: 'groupe', sortable: true },
        { headerText: 'status', visible: true, name: 'status', sortable: true },
        { headerText: 'Date de création', visible: true, name: 'rentalCreatedDate', sortable: true, format: f => moment(f).format('DD-MM-YYYY @ HH:mm') },
        { headerText: 'Catégories', visible: true, name: 'categories', sortable: true, showAsPills: true },
    ];

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
    ) {
        this.rentals$.getBookings$().subscribe((r) => {
            this.ListeRentals = r as Rental[];
            const listes = _.map(this.ListeRentals, r => {
                return {
                    start:  moment(r.start_sec).toISOString(),
                    end : moment(r.start_sec).add(r.slot?.duration_sec, 'm').toISOString(),
                    title : r.nomOrganisateur,
                    rental : r,
                    id : r.booking_id,
                } as EventModel;
            });
            // this.calendarApi.addEventSource(listes)
            // this.calendarApi.render()
            this.ref.markForCheck();
        });
    }
    ngAfterViewInit(): void {
        this.calendarApi = this.calendarComponent.getApi();
        this.calendarApi.gotoDate(this.curDate)
        this.calendarApi.render()
    }

    ngOnInit() {

    }

    onChange() {}

    eventClick(info: any) {
        console.log('event click', info);
        this.router.navigate([info.event.id], { relativeTo : this.nav})
    }

    changeDate(event:any) {
        console.log("change Date", event);
        // let calendarApi = this.calendarComponent.getApi();
        console.log('calendarApi', this.calendarApi)

        this.curDate = event
        if (this.calendarApi) {

            this.calendarApi.gotoDate(moment(event).toDate())
            this.calendarApi.render()
        }
    }

    OnAdd() {
        this.router.navigate(['add'], { relativeTo: this.nav });
    }

    OnRowClick(event: ClickEventArg) {
        // console.log("OnRowClick", event);

        const r = event.rowData as Rental;

        switch (event.event) {
            case 'edit':
                this.router.navigate([r.booking_id, 'edit'], { relativeTo: this.nav });
                break;
            case 'delete':
                // this.router.navigate([r.rentalId, 'delete'], { relativeTo: this.nav });
                // this.rentals$.deleteBooking$(r).subscribe(r => {
                //     const defaultOptions = {
                //         autohide: true,
                //         delay: 3000,
                //         headerClasses: 'bg-success text-white',
                //     };
                //     this.toastService.show(
                //         'Rentals effacée',
                //         'La rental a été effacée avec succès',
                //         defaultOptions
                //     );
                //     // this.router.navigate(['../'], {relativeTo: this.route} );
                //     this.rentals$.getRentals$(undefined).subscribe(r => {
                //         this.ListeRentals = r as Rental[];
                //         this.ref.markForCheck();
                //     });
                // });
                break;

            default:
                this.router.navigate([r.booking_id], { relativeTo: this.nav });
                break;
        }
    }
}
