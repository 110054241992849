// Divers
import * as _ from 'underscore';
import * as moment from 'moment';

/**
 * Created by benoit on 10/10/2016.
 */
 angular.module('resources.reservationUtil', ['resources.reservation']);
 angular.module('resources.reservationUtil').factory('ReservationUtil', ['$http', 'Reservation', '$interval', 'D1Configurations', function ($http, Reservation, $interval, D1Configurations) {
 
     var ReservationUtil = {};
 
     var lstSchedules = [998761,998762,998767,998768,998769,999968];
     //var lstMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
 
     function findInNotes(match,lstNotes) {
         var sPattern = "";
 
         angular.forEach(lstNotes, function(v, k) {
             if (v.trim().length) {
                 var pos = v.trim().search(match);
                 if (pos >= 0) {
                     // Found, take next line
                     sPattern = lstNotes[k+1].toString().trim();
                     //sPattern = v.trim().substr(pos + match.length).toString();
                 }
             }
         });
         return sPattern;
     }
 
     function findInNotes_OLD(match,lstNotes) {
         var sPattern = "";
 
         angular.forEach(lstNotes, function(v, k) {
             if (v.trim().length) {
                 var pos = v.trim().search(match);
                 if (pos >= 0) {
                     // Found
                     sPattern = v.trim().substr(pos + match.length).toString();
                 }
             }
         });
         return sPattern;
     }
 
 
     function findConfirmedSchedule(reservation, notes) {
         var internalPattern = /\[\*.*\*\]/g;
         var pat = notes.match(internalPattern);
         if (pat) {
             // Parties
             var parties = pat[0].match(/P\d+/g);
             if (parties) {
                 var pt = '';
                 reservation.lstParties = [];
                 angular.forEach(parties, function(k, i){
                     pt = moment(reservation.partiesDate.format('YYYY-MM-DD ') + k.substr(1,2) + ":" + k.substr(3,4));
                     Reservation.ajouterPartie(reservation, pt);
                 });
             }
 
             // Gateau
             var gateaux = pat[0].match(/G\d+/g);
             angular.forEach(gateaux, function(k){
                 reservation.gateauDate = k.substr(1,2) + ":" + k.substr(3,4);
             });
 
             // Slush
             var slush = pat[0].match(/S\d+/g);
             angular.forEach(slush, function(k){
                 reservation.slushDate = k.substr(1,2) + ":" + k.substr(3,4);
             });
 
             // Depot Fait
             var df = pat[0].match(/DF+/g);
             if (df) {
                 reservation.depotFait = true;
                 reservation.depotReference = "Importé de Shiftplanning";
             }
             else {
                 // On essait par le titre!!!!
                 var df = reservation.groupeTitre.match(/DF+/g);
                 if (df) {
                     reservation.depotFait = true;
                     reservation.depotReference = "Importé du titre de Shiftplanning";
                 }
             }
 
             return true;
         }
 
         return false;
     }
 
     /**
      * Importation de shiftplanning
      *
      * @param myEvent
      * @returns {*}
      */
     ReservationUtil.parseEventFromSP = function(mySP) {
 
         /**
          * SAMPLE SHIFTPLANNING OBJECT
          *
 
          {
                "id": "334480745",
                "created": "1475701417",
                "published": "1476057220",
                "edited": "1475701437",
                "deleted": "0",
                "schedule": "999968",
                "type": "0",
                "needed": "0",
                "working": "0",
                "user": "1918015",
                "start_date": {
                  "id": 2721,
                  "month": 10,
                  "day": 10,
                  "wday": 1,
                  "year": 2016,
                  "weekday": "Mon",
                  "mname": "Oct",
                  "week": 41,
                  "dayid": 2,
                  "timeid": 45,
                  "timeid_exact": 45,
                  "formatted": "Oct 10, 2016",
                  "time": "11:00",
                  "timestamp": 1476111600,
                  "hours": 11,
                  "minutes": 0,
                  "seconds": 0,
                  "date": "2016-10-10 11:00:00",
                  "day_of_year": "283",
                  "week_adjusted": 41,
                  "sp_wday": 2
                },
                "end_date": {
                  "id": 2721,
                  "month": 10,
                  "day": 10,
                  "wday": 1,
                  "year": 2016,
                  "weekday": "Mon",
                  "mname": "Oct",
                  "week": 41,
                  "dayid": 2,
                  "timeid": 49,
                  "timeid_exact": 49,
                  "formatted": "Oct 10, 2016",
                  "time": "12:00",
                  "timestamp": 1476115200,
                  "hours": 12,
                  "minutes": 0,
                  "seconds": 0,
                  "date": "2016-10-10 12:00:00",
                  "day_of_year": "283",
                  "week_adjusted": 41,
                  "sp_wday": 2
                },
                "length": 1,
                "title": "ECHO (12) Julie Racette",
                "location": "0",
                "notes": "Titre : ECHO (12) Julie Racette\n==Coordonn\u00e9es==\nVotre nom au complet: Julie Racette\nT\u00e9l\u00e9phone #: 438-274-8466\nNombre de personnes: 12\n\n==Forfait==\nMissions de groupes: ECHO\n\n==ADMIN==\nNotes : \nInsertion le : 5 Oct 2016 17:03:17\nInitiales : AR\nParties : \nParties #1 : 11:00\nParties #2 : 11:40\n\n** NE PAS EFFACER **\n[*[P1100][P1140]*]",
                "confirmed": "0",
                "ref": "0",
                "start_timestamp": "2016-10-10 11:00:00",
                "end_timestamp": "2016-10-10 12:00:00",
                "traded": null,
                "confirmed_start_timestamp": null,
                "confirmed_end_timestamp": null,
                "absent": null,
                "perms": "2",
                "schedule_name": "R2 Groupe",
                "schedule_color": "1",
                "schedule_location_id": "0",
                "cost": {
                  "staff": 0,
                  "hours": 0,
                  "dollars": 0
                },
                "start_time": {
                  "id": 45,
                  "time": "11:00"
                },
                "end_time": {
                  "id": 49,
                  "time": "12:00"
                },
                "paidtime": 1
              }
 
          **************************
          * SAMPLE "notes" FIELD
          **************************
          Titre : ECHO (11) Annie Claveau
          ==Coordonnées==
          Votre nom au complet: Annie Claveau
          Téléphone #: 514-916-8302
          Courriel: annie5987@hotmail.com
          Nombre de personnes: 11
 
          ==Forfait==
          Missions de groupes: ECHO
 
          ==ADMIN==
          Notes :
          Insertion le : 1 Oct 2016 18:07:16
          Initiales : GR
          Parties :
          Parties #1 : 16:00
          Parties #2 : 16:40
 
          ** NE PAS EFFACER **
          [*[P1600][P1640]*]
          */
 
         // On commence par faire le parsing via les notes de SP
         var tsp;
         if (_.isString(mySP)) {
             tsp = JSON.parse(mySP);
         }
         else {
             tsp = mySP;
         }
         //console.log(tsp);
         if (tsp.data ) {
             var lst = [];
             tsp.data.forEach(function(item){
                 var t1 = ReservationUtil.parseEventFromSP(item);
                 if (t1) {
                     lst.push(t1);
                 }
                 //console.log(t1);
             });
             return lst;
         }
         else {
             // Ne pas se bâdrer avec une réservation sans notes ou sans schedule
             var sc = _.indexOf(lstSchedules, parseInt(tsp.schedule));
             if (tsp.notes.length == 0 || sc == -1 && tsp.schedule != 1373243) {
                 return false;
             }
             var newR = ReservationUtil.parseEventFromWeb(tsp.notes);
 
             // Allons voir si la réservation est déjà insérée
             var wait_sync = true;
             Reservation.chargerReservationDeShiftPlanning(tsp.id).then(function(spReservation){
                 if (spReservation) {
                     // Le groupe existe, on copie son groupeId
                     newR.groupeId = spReservation.groupeId;
                 }
                 // Ne plus attendre on a la réponse
                 wait_sync = false;
             });
 
             var int_wait = $interval(function() {
                 if (wait_sync == false) {
                     $interval.cancel(int_wait)
                 }
             }, 10, 30, false);
 
             // On termine avec l'ajustement de l'objet de SP
             var insertDate = moment.unix(tsp.created);
             newR.insertionDate = moment(insertDate).toDate();
             newR.partiesDate = moment(tsp.start_timestamp);
             newR.event_date = newR.partiesDate.toDate();
             //newR.event_time = newR.partiesDate.format("HH:mm");
             newR.event_time = newR.event_date;
             newR.groupeTitre = tsp.title;
             newR.statutConfirmation = 0;    // Confirmé par défaut!
             newR.estAnnule = (tsp.schedule == 1373243);    // indiqué si annulé ou non!
 
             var salle = _.find(newR.TypeSalles, function (s) {
                 return (s.salleIdExterne == tsp.schedule);
             });
 
             if (salle) {
                 // On a une salle de réservée
                 newR.salleReservee = salle.salleId;
                 newR.salleDebut = moment(tsp.start_timestamp).toDate();
                 newR.salleFin = moment(tsp.end_timestamp).toDate();
             }
 
             if (newR.partiesDate.isValid()) {
                 if (!findConfirmedSchedule(newR, tsp.notes)) {
                     Reservation.calculateGames(newR);
                 }
             }
 
             newR.shiftPlanningId = tsp.id;
 
             return newR;
         }
     };
 
     /**
      * Importation du web, ou encore des notes de Shiftplanning que j'ai créé
      *
      * @param myEvent
      */
     ReservationUtil.parseEventFromWeb = function(myEvent) {
 
         /**
          * EXEMPLES DE NOTES DE SHIFTPLANNING OU DU WEB
          *
 
          Forfait de fête
          FÊTE DELTA - LA PLUS POPULAIRE
          Nom du fêté
          Anthony Moïse
          Nombre de joueurs
          8
          Ajoutez un petit EXTRA à votre forfait...
 
 
          le gâteau de DISTRICT 1 (8 choix de saveurs) 35$ (8-10 joueurs)
 
          Saveur de gâteau
          Chocolat
          Votre nom complet
          Marie-Noëlle Robichaud
          Courriel
          marienoelle8@hotmail.com
          Téléphone
          (514) 267-5202
          Date
          22/03/2019
          Heure
          06:00 pm
          Champ masqué
          OVpOwC7k5nJ5q0IM
 
          */
 
         var newR = Reservation.creer();
 
         if (!myEvent || myEvent.trim().length == 0) {
             return newR;
         }
 
         // Parse l'event et insère l'info
         var lstNotes = myEvent.split("\n");
         if ( !Array.isArray(lstNotes)) {
             lstNotes = myEvent.split("\\r");
         }
 
         var token = findInNotes("Champ masqué", lstNotes);
 
         // Init
         newR.nombreParties = 2;
         newR.nomOrganisateur = findInNotes("Votre nom complet", lstNotes);
         newR.courriel = findInNotes("Courriel", lstNotes);
         newR.telephone = findInNotes("Téléphone", lstNotes);
         newR.nomFete = findInNotes("Nom du fêté", lstNotes);
         newR.nombreJoueurs = parseInt(findInNotes("Nombre de joueurs", lstNotes));
         if (isNaN(newR.nombreJoueurs)) newR.nombreJoueurs = 0;
         var ed = findInNotes("Date", lstNotes);
         var eh = findInNotes("Heure", lstNotes);
 
         // Faire un format ISO-8601 (YYYY-MM-DD)
         var ted = ed.substring(6,10)  + "-" + ed.substring(3,5) + "-" + ed.substring(0,2);
         console.log("date et heure + ted", ed, eh, ted);
         newR.event_date = moment(ted + " " +  eh).toDate();
         //newR.event_time = moment(newR.event_date).format('H:mm');
         newR.event_time = newR.event_date;
         newR.partiesDate = moment(newR.event_date);
 
         // Type de groupe default
         var gt = _.findWhere(D1Configurations.TypeGroupes, {groupeTypeId : 0 });
         newR.groupeTypeId = 0;
         newR.groupeType = gt;
         console.log("gt", gt);
 
         // Choix de forfait
         var forfait  = findInNotes("Forfait de fête", lstNotes);
         console.log("forfait", forfait);
         if (forfait.length) {
             var groupeTypeId = 33;
 
             switch (forfait) {
                 case 'FÊTE DELTA - LA PLUS POPULAIRE2' :
                     break;
 
                 case 'FÊTE DELTA - LA PLUS POPULAIRE' :
                     newR.nombreParties = 3;
                     console.log("FETE DELTA ON CHANGE POUR 3 PARTIES");
                     break;
             }
 
             gt = _.findWhere(D1Configurations.TypeGroupes, {groupeTypeId : groupeTypeId });
             if (gt == undefined) {
                 // Catch all
                 gt = _.findWhere(D1Configurations.TypeGroupes, {groupeTypeId : 0 });
                 newR.groupeType = gt;
             }
 
             // Change it
             newR.groupeType = gt;
             newR.groupeTypeId = gt.groupeTypeId;
             console.log("FORFAIT gt + nb parties", gt, newR.nombreParties);
 
         }
         else {
             //var m = findInNotes("Missions de groupes: ", lstNotes);
             var m = findInNotes("Moins de 6 joueurs: ", lstNotes);
             if (m.length == 0) {
                 m = findInNotes("Au moins 8 joueurs: ", lstNotes);
             }
             if (m.length == 0) {
                 m = findInNotes("Au moins 15 joueurs: ", lstNotes);
             }
             if (m.length) {
                 var p = m.toString().toUpperCase().indexOf("MISSION ");
                 if (p < 0) {
                     p = m.toString().toUpperCase().indexOf("ESCOUADE");
                     //p += 9;
                 }
                 else {
                     p += 8;
                 }
                 if (p < 0) {
                     p = m.toString().toUpperCase().indexOf("MOBILISATION");
                     //p += 13;
                 }
 
                 if (p >= 0) {
                     // On split par mission
                     var l = m.substring(p).toUpperCase().replace("'", " ").split(" ");
                     var found = false;
                     angular.forEach(l, function (w) {
                         if (!found) {
                             D1Configurations.TypeGroupes.forEach(function (gr, i) {
                                 //if (gr.groupeTypeNom.toString().toUpperCase().indexOf(m.toString().toUpperCase()) >= 0) {
                                 if (!found && gr.groupeTypeNom.toString().toUpperCase().indexOf(w + " ") >= 0) {
                                     if (gr.estDisponible == 1) {
                                         console.log("Trouvé " + w + " dans " + gr.groupeTypeNom + " typeid = " + gr.groupeTypeId);
                                         newR.groupeTypeId = gr.groupeTypeId;
                                         newR.groupeType = gr;
                                     }
                                     found = true;
                                 }
                             });
                         }
                     });
                 }
             }
         }
         //newR.groupeTitre = newR.groupeType.groupeTypeNom + ' (' + newR.nombreJoueurs + ') ' + newR.nomOrganisateur;
 
         newR.gateauChoix = findInNotes("Saveur de gâteau", lstNotes);
         console.log("newR", newR);
 
         if (newR.partiesDate.isValid()) {
             //Reservation.calculateGames(newR);
             var numparties = newR.nombreParties;
 
             for(i=0; i< numparties;i++) {
                 console.log("On ajouter une partie #" + (i+1));
                 Reservation.ajouterPartie(newR);
             }
         }
 
         newR.notes = myEvent;
         newR.estImporte = 1;
         newR.statutConfirmation = 1;
 
         console.log("newR", newR);
         return newR;
     };
 
     ReservationUtil.parseEventFromWeb_OLD = function(myEvent) {
 
         /**
          * EXEMPLES DE NOTES DE SHIFTPLANNING OU DU WEB
          *
 
 
          Titre : ECHO (11) Annie Claveau
          ==Coordonnées==
          Votre nom au complet: Annie Claveau
          Téléphone #: 514-916-8302
          Courriel: annie5987@hotmail.com
          Nombre de personnes: 11
 
          ==Forfait==
          Missions de groupes: ECHO
 
          ==ADMIN==
          Notes :
          Insertion le : 1 Oct 2016 18:07:16
          Initiales : GR
          Parties :
          Parties #1 : 16:00
          Parties #2 : 16:40
 
          ** NE PAS EFFACER **
          [*[P1600][P1640]*]
 
 
 
          ==Coordonnées==
          Votre nom au complet: Hanane Azouz
          Téléphone #: 5145922674
          Courriel: azouzhanane@gmail.com
          Date désirée: sam, 10/22/2016
          Heure désirée: 13:00
          Nombre de personnes: 6 personnes
 
          ==Forfait==
          Choix de forfait: FORFAIT DE FÊTE #3 (29$ par joueur, 3 parties et 120 minutes de salle, gâteau "District 1" et slush + popcorn)
          Nom de la personne fêtée: Wassim Zaouche
          Saveurs de gâteau (Forfait #3 seulement): Marbré
          Garniture: Garniture de fraises
 
          */
 
         var newR = Reservation.creer();
 
         if (!myEvent || myEvent.trim().length == 0) {
             return newR;
         }
 
         // Parse l'event et insère l'info
         var lstNotes = myEvent.split("\n");
         if ( !Array.isArray(lstNotes)) {
             lstNotes = myEvent.split("\\r");
         }
 
         // Init
         newR.nomOrganisateur = findInNotes("Votre nom au complet: ", lstNotes);
         newR.courriel = findInNotes("Courriel: ", lstNotes);
         newR.telephone = findInNotes("Téléphone #: ", lstNotes);
         newR.nomFete = findInNotes("Nom de la personne fêtée: ", lstNotes);
         if (newR.nomFete.length == 0) {
             newR.nomFete = findInNotes("Fêté : ", lstNotes);
         }
         newR.notes = findInNotes("Notes : ", lstNotes);
         newR.initialesEmploye = findInNotes("Initiales : ", lstNotes);
         //newR.nombreJoueurs = parseInt(findInNotes("Nombre de personnes: ", lstNotes));
         newR.nombreJoueurs = parseInt(findInNotes("Nombre de joueurs: ", lstNotes));
         if (isNaN(newR.nombreJoueurs)) newR.nombreJoueurs = 0;
         var ed = findInNotes("Date désirée: ", lstNotes);
         var eh = findInNotes("Heure désirée: ", lstNotes);
         ed = ed.substring(5);
 
         // Faire un format ISO-8601 (YYYY-MM-DD)
         var ted = ed.substring(6,10)  + "-" + ed.substring(0,2) + "-" + ed.substring(3,5);
         newR.event_date = moment(ted + " " +  eh).toDate();
         //newR.event_time = moment(newR.event_date).format('H:mm');
         newR.event_time = newR.event_date;
         newR.partiesDate = moment(newR.event_date);
 
         // Choix de forfait
         //var forfait  = findInNotes("Choix de forfait: FORFAIT DE FÊTE #", lstNotes);
         var forfait  = findInNotes("Forfaits de fête \\(au moins 8 joueurs\\): Forfait", lstNotes);
         if (forfait.length) {
             var fnum = parseInt(forfait);
             if (fnum) {
                 var gt = _.find(newR.TypeGroupes, function (gr) {
                     return (gr.groupeTypeId == (fnum + 2));
                 });
                 if (!gt) {
                     // Default
                     gt = _.find(newR.TypeGroupes, function (gr) {
                         return (gr.groupeTypeId == 0);
                     });
                 }
                 newR.groupeTypeId = gt.groupeTypeId;
                 newR.groupeType = gt;
             }
         }
         else {
             //var m = findInNotes("Missions de groupes: ", lstNotes);
             var m = findInNotes("Moins de 6 joueurs: ", lstNotes);
             if (m.length == 0) {
                 m = findInNotes("Au moins 8 joueurs: ", lstNotes);
             }
             if (m.length == 0) {
                 m = findInNotes("Au moins 15 joueurs: ", lstNotes);
             }
             if (m.length) {
                 var p = m.toString().toUpperCase().indexOf("MISSION ");
                 if (p < 0) {
                     p = m.toString().toUpperCase().indexOf("ESCOUADE");
                     //p += 9;
                 }
                 else {
                     p += 8;
                 }
                 if (p < 0) {
                     p = m.toString().toUpperCase().indexOf("MOBILISATION");
                     //p += 13;
                 }
 
                 if (p >= 0) {
                     // On split par mission
                     var l = m.substring(p).toUpperCase().replace("'", " ").split(" ");
                     var found = false;
                     angular.forEach(l, function (w) {
                         if (!found) {
                             newR.TypeGroupes.forEach(function (gr, i) {
                                 //if (gr.groupeTypeNom.toString().toUpperCase().indexOf(m.toString().toUpperCase()) >= 0) {
                                 if (!found && gr.groupeTypeNom.toString().toUpperCase().indexOf(w + " ") >= 0) {
                                     if (gr.estDisponible == 1) {
                                         console.log("Trouvé " + w + " dans " + gr.groupeTypeNom + " typeid = " + gr.groupeTypeId);
                                         newR.groupeTypeId = gr.groupeTypeId;
                                         newR.groupeType = gr;
                                     }
                                     found = true;
                                 }
                             });
                         }
                     });
                 }
             }
         }
         newR.groupeTitre = newR.groupeType.groupeTypeNom + ' (' + newR.nombreJoueurs + ') ' + newR.nomOrganisateur;
 
         //newR.gateauChoix = findInNotes("Saveurs de gâteau (Forfait #3 seulement): ", lstNotes);
         newR.gateauChoix = findInNotes("Saveurs de gâteau \\(Forfait #3 seulement\\)", lstNotes);
         if (newR.gateauChoix.length) {
             newR.gateauChoix += " + " + findInNotes("Garniture: ", lstNotes);
         }
         else {
             newR.gateauChoix = findInNotes("Gâteau: ", lstNotes);
         }
 
         if (newR.partiesDate.isValid()) {
             Reservation.calculateGames(newR);
         }
 
         newR.notes = myEvent;
         newR.estImporte = 1;
         newR.statutConfirmation = 1;
         return newR;
     };
 
     return ReservationUtil;
 }]); 