import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIparams } from '@modules/groupe/models';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rental, RentalResource, Slot } from '../models';

@Injectable()
export class RentalsService {
    private apiUrl = '/api/bookings'; // URL to web api

    constructor(private http: HttpClient) {}

    getAvailabilities$(): Observable<{}> {
        return of({});
    }

    getAvailability$(): Observable<{}> {
        return of({});
    }

    updateAvailability$(): Observable<{}> {
        return of({});
    }

    createAvailability$(): Observable<{}> {
        return of({});
    }

    deleteAvailability$(): Observable<{}> {
        return of({});
    }

    /**
     * Bookings
     * @returns
     */
    getBookings$(params?:any): Observable<Rental[]> {
        return this.http.post<Rental[]>(this.apiUrl + '/ListBookings', params).pipe(
            map((response: Rental[]) => {
                return response;
            })
        );
    }

    getBooking$(id:number): Observable<Rental> {
        return this.http.get<Rental>(this.apiUrl + `/booking/${id}`);
    }

    updateBooking$(rental:Rental): Observable<Rental> {
        return this.http.post<Rental>(this.apiUrl + `/UpdateBooking`, rental);
    }

    updateBookingResource$(rental:RentalResource): Observable<Rental> {
        return this.http.put<Rental>(this.apiUrl + `/UpdateBookingResource/${rental.booking_id}`, rental);
    }

    createBooking$(rental:Rental): Observable<Rental> {
        return this.http.post<Rental>(this.apiUrl + `/CreateBooking`, rental);
    }

    deleteBooking$(): Observable<Rental> {
        return of({} as Rental);
    }

    /**
     * Slots
     * @returns
     */
    getSlots$(start?: Date, end?:Date): Observable<Slot[]> {
        const startDate:Date = (start ? moment(start).startOf('day').toDate() : moment().startOf('day').toDate())
        const endDate:Date = (end ? moment(end).startOf('day').toDate() : moment(startDate).endOf('month').toDate())
        return this.http.post<Slot[]>(this.apiUrl + '/ListSlots', {start: startDate, end: endDate}).pipe(
            map((response: Slot[]) => {
                return response;
            })
        );
    }

    getSlot$(id: number): Observable<Slot> {
        return this.http.get<Slot>(this.apiUrl + `/slots/${id}`);
    }

    updateSlot$(slot: Slot): Observable<Slot> {
        return this.http.put<Slot>(this.apiUrl + '/slots', slot);
    }

    createSlot$(slot: Slot): Observable<Slot> {
        return this.http.post<Slot>(this.apiUrl + '/slots', slot);
    }

    deleteSlot$(): Observable<Slot> {
        return of({} as Slot);
    }

}
