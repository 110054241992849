import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { url } from 'inspector';
import { Observable, of } from 'rxjs';

@Injectable()
export class ExtUrlResolverService implements Resolve<any> {
    constructor() {}

    resolve(route: ActivatedRouteSnapshot,state:RouterStateSnapshot):Observable<any>
    {
        // Doit rajouter le + '' pour éviter un NULL
        window.location.href= (route.queryParamMap.get('url') && route.queryParamMap.get('url') !== null ? route.queryParamMap.get('url') : '') + '';
        return of(null);
    }
}
