<li class="nav-item dropdown no-caret mr-3 dropdown-user" *ngIf="userService.user$ | async as user" ngbDropdown [placement]="placement" display="dynamic">
    <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false"><mat-icon class="text-light">account_circle</mat-icon></a>
    <div class="dropdown-menu border-0 shadow animated--fade-in-up" ngbDropdownMenu aria-labelledby="dropdownUser">
        <h6 class="dropdown-header d-flex align-items-center">
            <mat-icon class="text-dark">account_circle</mat-icon>
            <div class="dropdown-user-details">
                <div class="dropdown-user-details-name">{{ user.nom }}</div>
                <div class="dropdown-user-details-email">{{ user.email }}</div>
            </div>
        </h6>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" routerLink="/dashboard"
            ><div class="dropdown-item-icon"><i-feather name="settings"></i-feather></div>
            Account</a
        ><a class="dropdown-item" (click)="confirmLogout()"
            ><div class="dropdown-item-icon"><i-feather name="log-out"></i-feather></div>
            Logout</a
        >
    </div>
</li>
