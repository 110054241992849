import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '@modules/navigation/services';
import { ReservationsService } from '@modules/reservations/services';
import { ScheduleService } from '@modules/schedule/services';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'sb-top-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
    @Input() rtl = false;
    public nextColor: string = "white";
    private _sub! : Subscription;
    private _sub2! : Subscription;
    private passeTime = timer(0, 60000);

    constructor(private navigationService: NavigationService, private r$: ReservationsService, private s$: ScheduleService, private ref: ChangeDetectorRef) {
        this._sub2 = this.passeTime.subscribe(x => {
            if (this._sub)  this._sub.unsubscribe();
            this._sub = this.s$.getPassesStatus(0).subscribe(r => {
                this.nextColor = r.nextColor
                this.ref.markForCheck()
            })
        });
    }

    ngOnDestroy(): void {
        if (this._sub) this._sub.unsubscribe();
        if (this._sub2) this._sub2.unsubscribe();
    }

    ngOnInit() {
    }
    
    toggleSideNav() {
        this.navigationService.toggleSideNav();
    }

    onWalkin() {
        this.r$.showWalkin()
    }
}
