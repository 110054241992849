import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app-component.html',
})
export class AppComponent {
    public showNg1View: boolean = false;

    constructor(public router: Router, private titleService: Title, public auth$:AuthService) {
        this.router.events
            .pipe(
                filter(event => event instanceof ChildActivationEnd || event instanceof NavigationStart)
            )
            .subscribe(event => {
                if (event instanceof ChildActivationEnd) {
                    let snapshot = (event as ChildActivationEnd).snapshot;
    
                    while (snapshot.firstChild !== null) {
                        snapshot = snapshot.firstChild;
                    }
                    this.titleService.setTitle(snapshot.data.title || 'RELOAD v2');
                } else if (event instanceof NavigationStart) {
                    this.showNg1View = event.url.startsWith('/ng1')
                }
            });

    }
}
