import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// FullCalendar stuff
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timePlugin from '@fullcalendar/timegrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import adaptivePlugin from '@fullcalendar/adaptive'

import frLocale from '@fullcalendar/core/locales/fr';

import { RoomsService } from '@modules/rooms/services';
import { SalleCeduleModel, SalleModel } from '@modules/rooms/models';

import moment from 'moment';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MessageBoxService } from 'ts-ng-lib';
import { SalleInfo } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { AppMessageService, AppSettingsService } from '@common/services';
import { ConfigService } from '@common/services/config.service';
import { UserService } from '@modules/auth/services';
import { User } from '@modules/auth/models';

const NUM_DAYS = 3;

@Component({
    selector: 'd1-rooms-schedules',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-schedules.component.html',
    styleUrls: ['rooms-schedules.component.scss'],
})
export class RoomsSchedulesComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    public dateSelected: Date = moment().hours(12).minutes(0).seconds(0).toDate();
    public data: any;
    public groups: any;
    public roomList?: SalleModel[];
    public ceduleList?: SalleCeduleModel[] = [];
    public montrerSallesInterne = false
    public currentUser!: User
    public slotDuration: number = 20;

    @ViewChild('timeline', { static: true }) timelineContainer!: ElementRef;
    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

    calendarApi!: Calendar;

    public caloptions: CalendarOptions = {
        plugins: [
            dayGridPlugin,
            timePlugin,
            interactionPlugin,
            resourceTimeGridPlugin,
            resourceTimelinePlugin,
            // adaptivePlugin 
        ],
        timeZone: 'local',
        schedulerLicenseKey: '1234567890-fcs-20034567890',
        initialView: 'resourceTimeGridDay',
        datesAboveResources: true,
        slotDuration: {minutes : this.slotDuration},
        allDaySlot: false,
        slotMinTime: '08:00:00',
        headerToolbar: {
            // left: 'prev,next',
            left: '',
            center: 'title',
            right: 'resourceTimeGridDay,resourceTimelineDay,resourceTimeGridWeekend'
        },
        views: {
            resourceTimeGridWeekend: {
                type: 'resourceTimeGrid',
                duration: { days: NUM_DAYS },
                buttonText: `${NUM_DAYS} jours`
            },
            resourceTimelineDay: {
                slotDuration: '00:20:00',
                buttonText: 'Timeline'
            }
        },
        locale: frLocale,
        height: "auto",
        // contentHeight : "50vh",
        // aspectRatio : 1.8,
        editable: true,
        eventDurationEditable: true,
        snapDuration: '01:20:00',
        eventOverlap: function (stillEvent, movingEvent) {
            return stillEvent.display == "background";
        },
        eventSources: [],
        eventResize: (info: any) => this.changerReservation(info),
        eventDrop: (info: any) => this.changerReservation(info),
        eventClick: (info) => {
            console.log('event selected', info);
        },
        resourceAreaHeaderContent: 'Salles',
        resources: [],
        events: [],
        selectable: true,
        selectOverlap: false,
        // select: (info) => {
        //     console.log('selection, info', info)
        //     const modalRef = this.dialog$.open(DialogEmailComponent, {
        //         data: { reservation: undefined },
        //         minWidth: '60vw'
        //     });
        //     modalRef.afterClosed().subscribe(r => {
        //         console.log('afterClose', r);
        //     });
        // }
        // events: 'https://fullcalendar.io/api/demo-feeds/events.json?with-resources=4&single-day'
    }

    constructor(private r$: RoomsService, private res$: ReservationsService, private route: ActivatedRoute
        , private ref: ChangeDetectorRef, private dialog$: MatDialog, private mbox: MessageBoxService
        , private msg$: AppMessageService, private d1$: AppSettingsService, private c$: ConfigService
        , private u$: UserService) {
        if (route.snapshot.data.rooms) {
            this.roomList = route.snapshot.data.rooms;
            if (this.roomList) {
                const activeRooms = this.roomList.filter((s) => s.estDisponible && (this.montrerSallesInterne ? true : s.visiblePublic))
                this.caloptions.resources = _.map(activeRooms, (s) => {
                    return { id: s.salleId.toString(), title: s.salleNom }
                });
            } else {
                this.caloptions.resources = [];
            }

            u$.user$.subscribe(u => {
                this.currentUser = u
            })
        }
    }

    onDateChange() {
        this.dateSelected = moment(this.dateSelected).hour(12).toDate()
        this.fetchData();
        this.calendarApi.gotoDate(moment(this.dateSelected).toDate())
        this.calendarApi.render()
    }

    ngOnChanges(changes: SimpleChanges): void {
        // this.fetchData();
    }

    ngAfterViewInit(): void {
        this.calendarApi = this.calendarComponent.getApi();
        this.fetchData()
        this.calendarApi.updateSize();
    }

    changerReservation(eventArg: any) {
        const event = eventArg.event;
        const resource = eventArg.event.getResources();
        const r: SalleCeduleModel = event.extendedProps as SalleCeduleModel;
        this.mbox.Confirm('Changement des heures', `Êtes-vous certain de vouloir modifier les heures de salles pour le groupe ${r.nomOrganisateur}?`, 'Oui')
            .subscribe((reponse) => {
                if (reponse === 'Oui') {
                    if (event.start && event.end) {
                        const salleInfo: SalleInfo = {
                            salleId: +resource[0].id,
                            salleDebut: event.start,
                            salleFin: event.end,
                            ancienneSalleId: r.salleId
                        }
                        this.res$.updateSalle(r.groupeId, salleInfo).subscribe(ret => {
                            this.res$.get(r.groupeId, false).subscribe(booking => {
                                this.msg$.sendMessageReservation(booking)
                            });
                            this.fetchData()
                        })
                    }

                } else {
                    eventArg.revert();
                }
            })
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {

    }

    formatTime(v: number) {
        return v + 'm';
    }

    fetchData() {
        this.ceduleList = []

        let date = moment(this.dateSelected)
        let endDate = moment(this.dateSelected).add((NUM_DAYS - 1), 'days')
        if (this.calendarApi.view.type !== 'resourceTimeGridWeekend') {
            this.d1$.Horaires.subscribe(h => {
                var horairesLatest = this.c$.findLatestSchedule(h, date);
                var heuresLatest = this.c$.findLatestScheduleHours(horairesLatest, date);
                this.caloptions.slotMinTime = moment(heuresLatest.start_date).format('HH:mm:ss').toString()
                this.caloptions.slotMaxTime = moment(heuresLatest.end_date).format('HH:mm:ss').toString()
            })
        } else {
            let minTime: string;
            let maxTime: string;
            for (let i = 0; i < NUM_DAYS; i++) {
                let date = moment(this.dateSelected).add(i, 'days')
                this.d1$.Horaires.subscribe(h => {
                    var horairesLatest = this.c$.findLatestSchedule(h, date);
                    var heuresLatest = this.c$.findLatestScheduleHours(horairesLatest, date);

                    if (!minTime || minTime > moment(heuresLatest.start_date).format('HH:mm:ss')) {
                        minTime = moment(heuresLatest.start_date).format('HH:mm:ss')
                    }
                    if (!maxTime || maxTime < moment(heuresLatest.end_date).format('HH:mm:ss')) {
                        maxTime = moment(heuresLatest.end_date).format('HH:mm:ss')
                    }

                    // Modifier les min/max
                    this.caloptions.slotMinTime = minTime
                    this.caloptions.slotMaxTime = maxTime
                })
            }
        }

        this.r$.getRoomsUsage$(date, endDate).subscribe((r) => {
            this.ceduleList = r;

            this.caloptions.events = _.map(this.ceduleList, (s) => {
                return {
                    resourceId: s.salleId.toString(),
                    title: (s.nomFete ? 'Fête : ' + s.nomFete : s.nomOrganisateur) + ' - (' + s.groupeNombre + ')',
                    url: `/ng1/reservations/${s.groupeId}`,
                    extendedProps: s,
                    start: moment(s.salleDebut).toISOString(),
                    end: moment(s.salleFin).toISOString(),
                }
            })
            this.calendarApi.updateSize();
            this.calendarApi.render()
            this.ref.markForCheck();
        })

        for (let i = 0; i < NUM_DAYS; i++) {
            let date = moment(this.dateSelected).add(i, 'days')
            this.r$.getTimeSlots$(date, 60).subscribe(t => {
                // Prenre la liste complète des salles
                this.roomList = t;
                let activeRooms = t.filter((s: SalleModel) => (s.estDisponible && s.visiblePublic))
                activeRooms.forEach((r: SalleModel) => {
                    this.caloptions.events = [
                        ...(this.caloptions.events as any),
                        ..._.map(r.timeslots?.filter(ts => (ts.available)), (s) => {
                            return {
                                resourceId: s.salleId.toString(),
                                title: "LIBRE",
                                extendedProps: s,
                                start: moment(s.moment).toISOString(),
                                end: moment(s.moment).add(s.duration, 'minutes').toISOString(),
                                color: 'green',
                                borderColor: 'green',
                                textColor: 'green',
                                display: 'background'
                            }
                        })
                    ]
                });

                this.calendarApi.updateSize();
                this.calendarApi.render()
                this.ref.markForCheck();
            })
        }
    }

    toggleSalles(toggle: MatSlideToggleChange) {
        this.montrerSallesInterne = toggle.checked;
        const activeRooms = this.roomList?.filter((s) => s.estDisponible && (this.montrerSallesInterne ? true : s.visiblePublic))
        /* @ts-ignore */
        this.caloptions.resources = _.map(activeRooms, (s) => {
            return { id: s.salleId.toString(), title: s.salleNom }
        });
    }

    zoom(minutes: number) {
        this.slotDuration = minutes;
        this.calendarApi.setOption('slotDuration', { minutes: minutes })
    }

}
