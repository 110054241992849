<div class="row"><d1-schedule-list-navigator [(date)]="dateSelected" (change)="onDateChange($event)"></d1-schedule-list-navigator></div>
<div class="x_title bg-success">
    <div class="center-block h4 "><span translate>Cédule du</span> {{dateSelected | date: 'd MMMM yyyy'}} ({{nombreVestes}}) <span translate>vestes</span>)</div>
</div>
<table class="table cedule-vue cedule-vue-{{arena_id}}">
    <thead>
        <tr>
            <th class="heure"><span translate>Heure</span></th>
            <th class="nombre"><span translate>Nombre</span></th>
            <th class="groupe"><span translate>Groupes</span></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let cedule of lstCedules2 | async; index as i;" id="ts-{{i.toString().replace(':','-')}}">
            <td class="heure">
                {{ceduleTemps(cedule)}} <button type="button" class="btn btn-sm btn-primary" ng-click="ShowScanCodePopup(cedule.ceduleTemps)">Scan code</button><br />
                <input type="text" ng-model="cedule.ceduleInformation" class="form-control" ng-change="changerCeduleInfos(cedule)" ng-blur="modifierCeduleInfos(cedule)" ng-show="cedule.partieType != 2" />
            </td>
            <td class="nombre bg-partieType-{{partieType}}" [ngClass]="['bg-' + cedule.passeCouleur]">
                <div class="x_title">{{nombreJoueursFiltres(cedule)}}&nbsp;({{cedule.nombreJoueur}}&nbsp;poss.)</div>
                <div class="x_content" ng-show="partieType == 1">{{nombreVestes - cedule.nombreJoueur}} <span translate>disp.</span></div>
            </td>
            <td class="groupe">
                <div id="cedule-ts-{{i}}" class="cedule-drop-zone">
                    <div *ngFor="let groupe of cedule" class="x_panel cedule groupe-type-{{groupe.groupeTypeId}} groupe-groupeid groupe-groupeid-{{groupe.groupeId}} groupe-partietype-{{partieType}}" ng-style="{background: (groupe.groupeTypeCouleur ? groupe.groupeTypeCouleur : 'auto')}" ng-mouseover="highlight(groupe.groupeId)" ng-mouseleave="unhighlight(groupe.groupeId)">
                        <div ng-show="groupe.groupeId > 0" matTooltip="{{groupe.nomOrganisateur}} ({{groupe.groupeTypeNom}}) " id="groupe-groupeid-{{groupe.groupeId}}" class="">
                            <div class="x_title" ng-class="{'groupe-paye': (groupe.statutGroupe == 1 || groupe.statutGroupe == 2)}">{{groupe.nombreJoueurs}}</div>
                            <div class="x_content">
                                <div ng-show="groupe.notesMarshall.length" class="label label-info" ng-style="{background: (groupe.notesMarshallCouleur ? groupe.notesMarshallCouleur : 'auto')}">{{groupe.notesMarshall}}</div>
                                <span ng-show="groupe.nomFete.length"><span translate>Fêté</span> : {{groupe.nomFete}}</span>
                                <span ng-hide="groupe.nomFete.length">{{groupe.nomOrganisateur}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>
