import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, EventEmitter, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith} from 'rxjs/operators';

import * as _ from 'lodash';
import { Input } from '@angular/core';

@Component({
    selector: 'd1-form-chip-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-chip-list.component.html',
    styleUrls: ['form-chip-list.component.scss'],
})
export class FormChipListComponent implements OnInit, OnChanges {
    @ViewChild('itemInput') itemInput?: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete?: MatAutocomplete;

    @Input() header = '';
    @Input() hint = undefined;
    @Input() placeholder = 'Écrire un item ...';
    @Input() allItems: string[] = [];
    @Input() selectedItems: string[] | string = [];
    @Output() selectedItemsChange = new EventEmitter<string[] | string>();
    @Output() change = new EventEmitter<string[] | string>();
    
    // Init
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    itemCtrl = new FormControl();
    filteredItems: Observable<string[]>;
    itemList: string[] = [];
    
    constructor(private ref: ChangeDetectorRef) {
        // Surveiller ce que l'usager écrit et trouver parmis la liste
        this.filteredItems = this.itemCtrl.valueChanges.pipe(
            startWith(null),
            map((item: string | '') => {
                const cat = (item || '').toLowerCase();
                return item ? _.filter(this.allItems, (c) => { return c.toLowerCase().match(cat); }) as string[] : this.allItems
            }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedItems) {
            this.selectedItems = changes.selectedItems.currentValue
            this.ngOnInit()
        }
    }

    ngOnInit() {
        this.itemList = [];
        if (typeof this.selectedItems === 'string') {
            if (this.selectedItems) {
                this.itemList = this.selectedItems.split('|').slice();
            }
        } else {
            if (this.selectedItems) {
                this.itemList = this.selectedItems.slice()
            }
        }
    }
    
    /**
     * Fonction pour retirer les items déjà sélectionnés
     */
    excludeFromList(r:any) {
        this.allItems = _.differenceWith(r, this.itemList, (v:any, f) => {
            return v.toLowerCase().match(f.toLowerCase());
        });
    }

    onFocus(event: any) {
        this.itemCtrl.setValue('');
    }

    syncItems() {
        if (typeof this.selectedItems === 'string') {
            this.selectedItems = this.itemList.join('|');
        } else {
            this.selectedItems = this.itemList
        }

        this.selectedItemsChange.emit(this.selectedItems);
        this.change.emit(this.selectedItems);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our item
        if ((value || '').trim()) {
            this.itemList.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.syncItems();
        this.itemCtrl.setValue('');
    }

    remove(item: string): void {
        const index = this.itemList.indexOf(item);

        if (index >= 0) {
            this.itemList.splice(index, 1);
        }

        this.syncItems();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.itemList.push(event.option.viewValue);
        if (this.itemInput !== undefined) {
            this.itemInput.nativeElement.value = '';
            this.itemInput.nativeElement.blur();
        }
        this.itemCtrl.setValue('');
        this.syncItems();
        
    }

}
