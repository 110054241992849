import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'd1-reservations-payment-status',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-payment-status.component.html',
    styleUrls: ['reservations-payment-status.component.scss'],
})
export class ReservationsPaymentStatusComponent implements OnInit {
    @Input() statutPaiement: number = 0;
    @Input() showLabel = true;
    @Input() showColor = true;
    @Input() showToggle = true;
    @Input() showIcon = false;
    @Output() statutPaiementChange: EventEmitter<number> = new EventEmitter<number>()
    @Output() change: EventEmitter<number> = new EventEmitter<number>()

    constructor() {}

    getLabel() {
        if (!this.showLabel) {
            return '';
        }

        switch (this.statutPaiement) {
            case 0:
                return 'Impayé'
            case 1:
                return 'Payé'
            case 2:
                return 'Partiellement payé'
        
            default:
                return '';
        }
    }

    ngOnInit() {
        
    }

    OnChange(event: any) {

        this.statutPaiement = parseInt(event.value)
        this.statutPaiementChange.emit(this.statutPaiement)
        this.change.emit(this.statutPaiement)
    }
}
