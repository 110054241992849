import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

export enum GroupeStatutListe {
    Impayé = 0,
    Payé,
    Arrivé,
    NoShow
}


@Component({
    selector: 'd1-reservations-status',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-status.component.html',
    styleUrls: ['reservations-status.component.scss'],
})
export class ReservationsStatusComponent implements OnInit, OnChanges {
    @Input() statutGroupe: number = 0;
    @Input() showLabel = true;
    @Input() showDropDown = false;
    @Input() showColor = true;
    @Input() showIcon = false;
    @Input() showToggle = true;
    @Output() statutGroupeChange: EventEmitter<number> = new EventEmitter<number>()
    @Output() change: EventEmitter<number> = new EventEmitter<number>()

    constructor() {}
    ngOnChanges(changes: SimpleChanges): void {
        // console.log('d1-reservations-status changes', changes);
    }


    ngOnInit() {

    }

    getLabel() {
        switch (this.statutGroupe) {
            case 0:
                return 'Absent'
            case 1:
                return ''
            case 2:
                return 'Arrivé'
            case 3:
                return 'No-Show'

            default:
                return '';
        }
    }

    setStatut(statut:number) {
        this.statutGroupe = statut
        this.statutGroupeChange.emit(this.statutGroupe)
        this.change.emit(this.statutGroupe)
    }

    OnChange(event: MatButtonToggleChange) {
        this.setStatut(event.value)
        // this.statutGroupe = parseInt(event.value)
        // this.statutGroupeChange.emit(this.statutGroupe)
        // this.change.emit(this.statutGroupe)
    }
}
