import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@common/services';
import { AppMessageService } from '@common/services/messages.service';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ScheduleInformation, ScheduleModel } from '../models';

@Injectable()
export class ScheduleService {
    private apiUrl = '/api/cedules';
    private _selectedDate: moment.Moment = moment();
    public SelectedDate: BehaviorSubject<moment.Moment> = new BehaviorSubject(this._selectedDate);
    private _init = true;
    private listeCedules: ScheduleModel[] = [];
    public CeduleListe: BehaviorSubject<ScheduleModel[]> = new BehaviorSubject(this.listeCedules);
    private _problemsListe: any = []
    private _arenas: any = []
    public warnings: BehaviorSubject<any> = new BehaviorSubject(this._problemsListe);
    private configs: any = {};
    private pc_en = 'blue_purple_black_orange_yellow_green_white'.split('_');
    private pc_fr = 'bleu_mauve_noire_orange_jaune_verte_blanche'.split('_');


    constructor(private http: HttpClient, private appMessages: AppMessageService, private appSettings: AppSettingsService) {
        // On va écouter les messages des dates voir s'il y a des changements
        this.SelectedDate.subscribe(newDate => {
            // if (newDate.format("YYYYMMDD") !== this._selectedDate.format("YYYYMMDD") || this._init) {
            this._selectedDate = newDate;
            this.getSchedule$().subscribe();
            // }
        });

        // On va écouter les messages des réservations pour voir s'il y a des changements
        // On se calme le ponpon sur les messages trop rapides,, debounce de 500 ms;
        // TODO : Trouver pourquoi les messages sont multipliés!
        this.appMessages.ReservationMessage.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(r => {
            if (moment(r.partiesDate).format("YYYY-MM-DD") === this._selectedDate.format("YYYY-MM-DD")) {
                this.getSchedule$().subscribe();
                this._init = false;
            }
        })

        // On va écouter les messages des réservations pour voir s'il y a des changements
        // On se calme le ponpon sur les messages trop rapides,, debounce de 500 ms;
        // TODO : Trouver pourquoi les messages sont multipliés!
        this.appMessages.CeduleMessage.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(r => {
            if (moment(r.journeeTemps).format("YYYY-MM-DD") === this._selectedDate.format("YYYY-MM-DD")) {
                this.getSchedule$().subscribe();
            }
        })

        this.appMessages.CeduleGameMessage.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(r => {
            if (moment(r.ceduleTemps).startOf('day').format("YYYY-MM-DD") === this._selectedDate.startOf('day').format("YYYY-MM-DD")) {
                // this.getSchedule$().subscribe();
            }
        })

        // Les couleurs de passes sont dans les configs
        this.appSettings.configurations.subscribe(c => {
            this.configs = c.cedules;
            if (this.configs.passes) {
                this.pc_fr = this.configs.passes.split('_')
            }
        })

        // Les couleurs de passes sont dans les configs
        this.appSettings.Arenas.subscribe(c => {
            this._arenas = c;
        })
    }

    getGameSchedule(partieTemps: moment.Moment, ceduleInfo: string): Observable<ScheduleModel[] | any> {
        return this.http.get<ScheduleModel[]>(this.apiUrl + `/groupes/${partieTemps.format('YYYY-MM-DD HH:mm:ss')}/1/1`).pipe(
            map((response: ScheduleModel[] | any) => {
                response.forEach((element: any) => {
                    if(ceduleInfo && ceduleInfo != "") {
                        element.ceduleInformation = ceduleInfo
                    }
                    element.partieHeures = moment(element.partieTemps).format('HH:mm')
                    element.partieTemps ? element.journeeTemps = element.partieTemps : null
                });

                // Vérifions si la cédule contient des problèmes
                // const timeSlot = _.map(_.groupBy(_.reject(this.listeCedules, (c:any) => ( c.groupeId === null || c.nombreVestes >= c.ceduleNombreJoueurs)), 'partieTemps'), (ts:any) => {
                //     return {id: ts[0].partieHeures, description : `${ts[0].partieHeures} : Trop de joueurs (${ts[0].ceduleNombreJoueurs})`};
                // });

                // // Préparer la liste des problèmes
                // this._problemsListe = [];
                // if (timeSlot) {
                //     this._problemsListe = timeSlot;
                // }

                // this.warnings.next(this._problemsListe)
                return response;
            })
        );
    }

    /**
     * Aller chercher la cédule complète d'une journée
     * @param state 
     * @returns 
     */
    getSchedule$(state?: any): Observable<ScheduleModel[] | any> {
        const params: any[] = [];
        let paramsQuery = '';
        let cd = this._selectedDate.format("YYYY-MM-DD")

        if (state && state instanceof Object) {
            // { skip: 0, take: 30 }
            if (state.skip || state.take || state.filter) {
                if (state.skip) params.push(`skip=${state.skip}`);
                if (state.take) params.push(`take=${state.take}`);
                if (state.filter) params.push(`filter=${state.filter}`);

                paramsQuery = '?' + params.join('&');
            }
            if (moment.isMoment(state) || moment.isDate(state)) {
                paramsQuery = '?bypass=1&date=' + moment(state).format("YYYY-MM-DD")
                cd = moment(state).format("YYYY-MM-DD")
            } else {
            }
        }

        return this.http.get<ScheduleModel[]>(this.apiUrl + `/list/${cd}/1/1?bypass=0` + paramsQuery).pipe(
            map((response: ScheduleModel[]) => {
                this.listeCedules = response;
                this.CeduleListe.next(this.listeCedules);

                // Vérifions si la cédule contient des problèmes
                const timeSlot = _.map(_.groupBy(_.reject(this.listeCedules, (c: any) => (c.groupeId === null || c.nombreVestes >= c.ceduleNombreJoueurs)), 'journeeTemps'), (ts: any) => {
                    return { id: ts[0].partieHeures, description: `${ts[0].partieHeures} : Trop de joueurs (${ts[0].ceduleNombreJoueurs})` };
                });

                // Préparer la liste des problèmes
                this._problemsListe = [];
                if (timeSlot) {
                    this._problemsListe = timeSlot;
                }

                this.warnings.next(this._problemsListe)
                return response;
            })
        );
    }


    getPasseCouleur(tTime: string, arena_id = 1) {
        let arena = _.find(this._arenas, { arena_id: arena_id });
        if (arena && arena.passes) {
            // On calcule le temps depuis minuit jusqu'au moment demandé (ex: 810 minutes)
            // Ensuite on va diviser ça par le temps de nos rotations  (ex : 810 / 30 minutes = 27)
            // On prend ce nombre de rotations et on modulo selon le nombre de couleurs de passes (ex : 27 % 6 = 3)
            // Le résultat donne l'index à retourner.
            try {
                const rotationTime = (arena.rotation_time ? arena.rotation_time : 20)
                const lstPasses = arena.passes.split('_');
                const diffMinutes = moment("2020-06-15 " + tTime).diff("2020-06-15 00:00:00", 'minutes');
                const sequence = (diffMinutes / rotationTime) % lstPasses.length;
                return lstPasses[sequence];
            } catch (e) {
                // Default
                return "white";
            }

        } else {
            switch (tTime) {
                case '00:00':
                case '02:00':
                case '04:00':
                case '06:00':
                case '08:00':
                case '10:00':
                case '12:00':
                case '14:00':
                case '16:00':
                case '18:00':
                case '20:00':
                case '22:00':
                    return 'blue';

                case '00:20':
                case '02:20':
                case '04:20':
                case '06:20':
                case '08:20':
                case '10:20':
                case '12:20':
                case '14:20':
                case '16:20':
                case '18:20':
                case '20:20':
                case '22:20':
                    return 'purple';

                case '00:40':
                case '02:40':
                case '04:40':
                case '06:40':
                case '08:40':
                case '10:40':
                case '12:40':
                case '14:40':
                case '16:40':
                case '18:40':
                case '20:40':
                case '22:40':
                    return 'black';

                case '01:00':
                case '03:00':
                case '05:00':
                case '07:00':
                case '09:00':
                case '11:00':
                case '13:00':
                case '15:00':
                case '17:00':
                case '19:00':
                case '21:00':
                case '23:00':
                    return 'orange';

                case '01:20':
                case '03:20':
                case '05:20':
                case '07:20':
                case '09:20':
                case '11:20':
                case '13:20':
                case '15:20':
                case '17:20':
                case '19:20':
                case '21:20':
                case '23:20':
                    return 'yellow';

                case '01:40':
                case '03:40':
                case '05:40':
                case '07:40':
                case '09:40':
                case '11:40':
                case '13:40':
                case '15:40':
                case '17:40':
                case '19:40':
                case '21:40':
                case '23:40':
                    return 'green';

                default:
                    return 'white';
            }
        }
    };

    getPasseCouleurNom(tTime: string) {
        // var pc = _.toPlainObject(_.concat(this.pc_en, this.pc_fr));
        // console.log('pc', pc);
        // return pc[this.getPasseCouleur(tTime)];

        var pc_en = 'blue_purple_black_orange_yellow_green_white'.split('_');
        var pc_fr = 'bleu_mauve_noire_orange_jaune_verte_blanche'.split('_');
        //var pc_fr = 'bleu_mauve_rouge_orange_jaune_verte_blanche'.split('_'); // Laseraction

        try {
            if (this.configs.cedules && this.configs.cedules.nom_passes) {
                pc_fr = this.configs.cedules.nom_passes.split('_');
            }
        } catch (err) {

        }

        // var pc = [pc_en, ...pc_fr];

        return pc_fr[pc_en.indexOf(this.getPasseCouleur(tTime))];
    };


    /**
     * Aller chercher les parties cédulées pour une semaine donnée
     *
     * @param annee : année format YYYY
     * @param semaineAnnee : Semaine dans l'année (0-52)
     *
     * @returns {*}
     */
    getSemaine(annee: any, semaineAnnee: any, statutGroupe: any) {
        if (!statutGroupe) {
            statutGroupe = -1;
        }
        return this.http.get('/api/cedules/semaine/' + parseInt(annee) + '/' + parseInt(semaineAnnee) + '/' + statutGroupe).subscribe((response: any) => {
            return response.data[0];
        });
    };


    modifierInformations = (c: ScheduleInformation) => {
        const postData = {
            ceduleInformation: c.ceduleInformation,
            ceduleTemps: moment(c.ceduleTemps).format("YYYY-MM-DD HH:mm:00")
        }
        // console.log('c :', c);
        return this.http.post('/api/cedules/informations/', postData).subscribe((response) => {
            // this.appMessages.sendMessageCedule(c)
            this.appMessages.sendMessageCeduleGame(c)
            return response;
        });
    };

    /*
        effacerPartie(groupeId:number, partieId:number) {
            return this.http.get('/api/cedules/effacer/' + groupeId + '/' + partieId).subscribe( (response) => {
                //cedule.socket.emit('RafraichirReservations', {groupeId : groupeId});
                return response;
            });
        };
    */

    disponibilites(viewDate?: any, arena_id?: any, bypass?: any): Observable<ScheduleModel[] | any> {
        if (!viewDate) {
            viewDate = moment();
        }
        if (arena_id == undefined) {
            arena_id = 1;
        }
        if (bypass !== 1) {
            bypass = 0;
        }
        return this.http.get(this.apiUrl + '/disponibilites/' + arena_id + '/' + moment(viewDate).format('YYYY-MM-DD') + '/json?bypass=' + bypass).pipe(
            tap((r) => {
                console.log("disponibilites réponse", r);
            }),
            map((response: any) => {
                return response as ScheduleModel;
            })
        );
    };

    mediaListe() {
        return this.http.get("/api/d1channel/marshall.json");
    }

    async PlayVideo(videoURL: string) {
        try {
            const ret = await this.http.get("/api/d1channel/marshall/play/video/" + encodeURI(videoURL), { observe: "response" }).subscribe();
            return ret;
        } catch (error) {
            console.error(error)
        }
    };

    async ShowImage(videoURL: string) {
        try {
            const ret = await this.http.get("/api/d1channel/marshall/show/image/" + encodeURI(videoURL), { observe: "response" }).subscribe();
            return ret;
        } catch (error) {
            console.error(error)
        }
    };

    async ShowSlideshow(videoURL: string) {
        try {
            const ret = await this.http.get("/api/d1channel/marshall/show/list/" + encodeURI(videoURL), { observe: "response" }).subscribe();
            return ret;
        } catch (error) {
            console.error(error)
        }
    };

    getPassesStatus(includeallgroups:number = 1): Observable<any> {
        return this.http.get("/api/d1channel/passes/status", { params: { includeallgroups: includeallgroups } })
    };

    /**
     *
     * Va réajuster l'heure pour nos 20 minutes.
     * @param t Passer un objet "moment"
     *
     * @returns moment() ajusté
     */
    reajustTime(t: Moment, arena_id = 1): Moment {
        // Effacer les micros secondes
        t.seconds(0);
        t.milliseconds(0);

        const nextTime = this.nextGameTime(t, arena_id);
        const diffMinutes = nextTime.diff(t, 'minutes');

        if (diffMinutes > 0 && diffMinutes <= 5) {
            // Arrondir au prochain
            t = this.nextGameTime(t, arena_id);
        } else {
            // Prendre le temps actuel
            t = this.actualGameTime(t, arena_id);
        }
        console.log('reajustTime', t);
        return t;
    };

    /**
     *
     * Va réajuster l'heure pour nos 20 minutes mais pour la prochaine partie (futur).
     * @param t Passer un objet "moment"
     *
     * @returns moment() ajusté
     */
    nextGameTime(t: Moment, arena_id = 1): Moment {
        let rotationTime = 20;
        let tc = moment(t);
        let arena = _.find(this._arenas, { arena_id: arena_id });
        if (arena) {
            rotationTime = (arena.rotation_time ? arena.rotation_time : 20);
        }

        const numToursHeure = Math.floor(60 / rotationTime) - 1;
        const curTour = Math.floor(tc.minute() / rotationTime);

        // Arrondir au prochain
        if ((curTour + 1) < numToursHeure) {
            tc.minute((rotationTime * (curTour + 1)));
        } else {
            tc.minute(0);
        }

        return tc;
    };

    /**
     *
     * Va réajuster l'heure pour afficher la partie actuelle.
     * @param t Passer un objet "moment"
     *
     * @returns moment() ajusté
     */
    actualGameTime(t: Moment, arena_id = 1): Moment {

        let rotationTime = 20;
        let arena = _.find(this._arenas, { arena_id: arena_id });
        if (arena) {
            rotationTime = (arena.rotation_time ? arena.rotation_time : 20);
        }


        const curTour = Math.floor(t.minute() / rotationTime);

        // Arrondir à l'actuel
        t.minute(rotationTime * curTour);

        return t;
    };
}
