import { Injectable } from '@angular/core';
import { ToastMessage, ToastMessageData, ToastMessageOptions } from '@common/models';
import { v4 as uuid } from 'uuid';

@Injectable()
export class ToastService {
    constructor() {}

    toasts: ToastMessage[] = [];

    showSuccess(header: ToastMessageData, body: ToastMessageData, options?: ToastMessageOptions) {
        const opts = {     
            autohide: true,
            delay: 3000,
            headerClasses: 'bg-success text-white',
            bodyClasses: 'bg-white',
            ...options
        } as ToastMessageOptions
        this.toasts.push({ header, body, uuid: uuid(), options : opts });
    }

    showError(header: ToastMessageData, body: ToastMessageData, options?: ToastMessageOptions) {
        const opts = {     
            autohide: true,
            delay: 5000,
            headerClasses: 'bg-danger text-white',
            bodyClasses: 'bg-white',
            ...options
        } as ToastMessageOptions
        this.toasts.push({ header, body, uuid: uuid(), options : opts });
    }

    show(header: ToastMessageData, body: ToastMessageData, options: ToastMessageOptions) {
        this.toasts.push({ header, body, uuid: uuid(), options });
    }

    remove(toastID: string) {
        this.toasts = this.toasts.filter(toast => toast.uuid !== toastID);
    }
}
