// Divers
import * as _ from 'underscore';
import * as moment from 'moment';

angular.module('resources.breadcrumb', []);
var d1Breadcrumb = angular.module('resources.breadcrumb')
    .factory('Breadcrumb', ['$uibModal', '$http', '$interval', 'Usagers', function($uibModal, $http, $interval, Usagers) {

        var Bread = {
            pageTitle: 'Réservations',
            pageManuel: '',
            // socket: io('http://localhost:4200'),
            cyberblast: {}
        };

        // if (Usagers.voirModule('cyberblast')) {
        //     Bread.socket.on('RafraichirCyberblast', function(data) {
        //         console.log("BREAD Got a RafraichirCyberblast message", data);
        //         // $rootScope.$broadcast('RafraichirCyberblast', data);
        //         Bread.cyberblast = data;
        //     });
        // }

        Bread.afficherManuel = function(pageManuel) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'ng1/support/manuel/manuel.html',
                controller: 'D1BreadcrumbCtrl',
                size: 'lg',
                resolve: {
                    pageManuel: function() { return pageManuel }
                }
            });

            modalInstance.result.then(function() {}, function() {
                // Rien à faire
            });
        };

        // Ca se peut qu'on ait pas encore eu la réponse du profil, on va attendre
        Usagers.getProfile().then(function() {
            if (Usagers.voirModule('cyberblast')) {
                // Bread.CyberBlast = function () {
                //     $http.get("/api/cyberblast/status").then(function (resp) {
                //         Bread.cyberblast = resp.data;
                //     });
                // };

                // // Aller chercher les infos à toutes les 5 secondes
                // $interval(function () {
                //     Bread.CyberBlast();
                // }, 5000);
            }
        })

        return Bread;
    }]);

d1Breadcrumb.controller('D1BreadcrumbCtrl', ['$scope', 'Breadcrumb', '$interval', '$timeout', 'Reservation', 'Cedule', '$sce', '$window', '$translate', 'Usagers', '$location'
    , function($scope, Breadcrumb, $interval, $timeout, Reservation, Cedule, $sce, $window, $translate, Usagers, $location) {
    $scope.Breadcrumb = Breadcrumb;
    $scope.Cedule = Cedule;
    $scope.reload_id = '';

    //$window.document.title = $scope.Breadcrumb.pageTitle;
    $scope.$watch('Breadcrumb.pageTitle', function(newValue, oldValue) {
        $translate(newValue).then(function(translation) {
            Breadcrumb.pageTitle = translation;
        });
    });

    if (Usagers.voirModule('cyberblast')) {
        // Breadcrumb.socket.on('RafraichirCyberblast', function(data) {
        //     Breadcrumb.cyberblast = data;
        //     $scope.$apply();
        // });
    }

    $scope.voirManuel = function() {
        Breadcrumb.afficherManuel('/support/manuel/' + Breadcrumb.pageManuel);
    };

    $scope.getHeureCourante = function() {
        return moment().format('HH:mm');
    };

    $scope.getProchainePartie = function() {
        return Reservation.nextGameTime(moment()).format('HH:mm');
    };

    $scope.ajouterWalkin = function() {
        return Reservation.ajouterWalkin();
    };

    $scope.ouvrirReservation = function() {
        if (parseInt($scope.reload_id)) {
            $location.path('/reservations/' + $scope.reload_id);
            $scope.reload_id = '';
        }
    }

    var prochaineminute = moment();
    prochaineminute.add(1, 'minute').seconds(0);
    var t = (prochaineminute.diff(moment(), "second") + 2); // ajouter un buffer de 2 secondes

    $timeout(function() {
        $interval(function() {
            // Ça force un $apply()
        }, 60000);
    }, t * 1000);

}]);