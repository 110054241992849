angular.module('resources.formulaires', ['ngRoute']);
var D1Vestes = angular.module('resources.formulaires').factory('Formulaires', [ '$http', '$rootScope', function ($http, $rootScope) {

    var Formulaires = {

    };

    return Formulaires;
}]);

D1Vestes.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
    $routeProvider
        .when( '/ng1/formulaires', {
            templateUrl:'ng1/formulaires/formulaires-list.tpl.html',
            controller:'D1FormulairesCtrl',
            resolve: {
                pageTitle : ['Breadcrumb', function(Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des formulaires';
                }]
            }
        })
    ;

    // use the HTML5 History API
    $locationProvider.html5Mode(true);
}]);


D1Vestes.controller('D1FormulairesCtrl', ['$scope', 'Formulaires', function($scope, Formulaires) {
    $scope.Formulaires = Formulaires;
    $scope.showMessage = '';


}]);