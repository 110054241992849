/* tslint:disable: ordered-imports*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as rentalsComponents from './components';

/* Containers */
import * as rentalsContainers from './containers';

/* Guards */
import * as rentalsGuards from './guards';

/* Services */
import * as rentalsServices from './services';
import { ReservationsModule } from '@modules/reservations/reservations.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { GroupeModule } from '@modules/groupe/groupe.module';

/* Full Caldendar */
import { FullCalendarModule } from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
// import timePlugin from '@fullcalendar/timegrid'; // a plugin
// import interactionPlugin from '@fullcalendar/interaction'; // a plugin
// import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//     dayGridPlugin,
//     timePlugin,
//     interactionPlugin,
//     resourceTimeGridPlugin
//   ]);

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppCommonModule,
        NavigationModule,
        FullCalendarModule,
        GoogleMapsModule,
        GroupeModule,
        ReservationsModule,
    ],
    providers: [...rentalsServices.services, ...rentalsGuards.guards],
    declarations: [...rentalsContainers.containers, ...rentalsComponents.components],
    exports: [...rentalsContainers.containers, ...rentalsComponents.components],
})
export class RentalsModule {}
