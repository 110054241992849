// Divers
import * as _ from 'underscore';
import { io } from 'socket.io-client';

angular.module('resources.vestes', ['ngRoute']);
var D1Vestes = angular.module('resources.vestes').factory('Vestes', ['$http', '$rootScope', 'Notification', '$q', 'D1Configurations', function($http, $rootScope, Notification, $q, D1Configurations) {

    var Vestes = {
        intervalId: 0,
        init: function() {
            //this.nombreVestes = 0;
            Vestes.listeVestes = [];
            Vestes.getListe();
        },
        listeVestes: [],
        nombreVestesActives: function() {
            return Vestes.nombreVestes;
        },
        //nombreVestes : 0,
        nombreVestes: function(arena_id) {
            var aid = 1;
            if (arena_id != undefined) {
                aid = arena_id;
            }

            var a = _.where(Vestes.listeVestes, { arena_id: aid });
            if (a.length > 0) {
                return a[0].nombreVestesActives;
            } else {
                return 0;
            }
        },
        socket: io()
    };

    Vestes.socket.on('RafraichirVestes', function(data) {
        $rootScope.$broadcast('RafraichirVestes', data);
        Vestes.getListe().then(function(data) {

            var msg = "Attention! Le nombre de vestes disponibles vient d'être modifié";

            _.each(data, function(nb) {
                var aname = _.findWhere(D1Configurations.getArenas(), { arena_id: nb.arena_id });
                msg += "<br>" + aname.arena_name + " : " + Vestes.nombreVestes(aname.arena_id);
            });

            Notification.warning({
                message: msg,
                title: "Nombre de vestes",
                positionX: 'center',
                positionY: 'top',
                onClose: function() {
                    //console.log(msg);
                    // msg.nePlusAfficher = true;
                }
            });
        });
    });

    Vestes.RafraichirVestes = function() {
        return Vestes.getListe().then(function(data) {
            //this.nombreVestes = data.length;
            //console.log("Vestes.RafraichirVestes() Data", data)
            return data;
        });
    };

    Vestes.getListe = function(arena_id) {
        return $http.get('/api/vestes/list/').then(function(response) {
            Vestes.listeVestes = response.data;
            return Vestes.listeVestes;
        });
    };
    Vestes.getListe();


    Vestes.enregistrer = function(vestes) {
        var q = $q.defer();
        var lst = [];

        _.each(vestes, function(nb, arenaId) {
            if (arenaId) {
                var data = {
                    arena_id: arenaId,
                    nombreVestesActives: nb
                };
                lst.push($http.post('/api/vestes/enregistrer/' + arenaId, data));
            }
        });

        $q.all(lst).then(function(response) {
            Vestes.socket.emit('RafraichirVestes', vestes);
            q.resolve(response);
        });
        return q.promise;
    };


    return Vestes;
}]);

D1Vestes.config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {

    $routeProvider
        .when('/ng1/vestes', {
            templateUrl: 'ng1/vestes/vestes-list.tpl.html',
            controller: 'D1VestesCtrl',
            resolve: {
                listeVestes: ['Vestes', function(Vestes) {
                    return Vestes.getListe();
                }],
                pageTitle: ['Breadcrumb', function(Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des vestes';
                }]
            }
        });

    // use the HTML5 History API
    $locationProvider.html5Mode(true);
}]);


D1Vestes.controller('D1VestesCtrl', ['$scope', 'Vestes', 'listeVestes', 'D1Configurations', 'Notification', '$toastService', function($scope, Vestes, listeVestes, D1Configurations, Notification, $toastService) {
    $scope.Vestes = Vestes;
    $scope.listeVestes = listeVestes[0];
    $scope.showMessage = '';
    $scope.Configurations = D1Configurations;
    $scope.nombreVestes = [];


    _.each(listeVestes, function(v) {
        $scope.nombreVestes[v.arena_id] = v.nombreVestesActives;
    });

    $scope.enregistrer = function() {
        Vestes.enregistrer($scope.nombreVestes).then(function(response) {
            $toastService.showSuccess("", "Sauvegardé avec succès")
        });

    }

}]);