import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-reservations-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-view.component.html',
    styleUrls: ['reservations-view.component.scss'],
})
export class ReservationsViewComponent implements OnInit {
    // private groupeId: number = 0;
    public reservation: ReservationModel = new ReservationModel()
    public listeReservations: ReservationModel[] = []
    private mySubs: Subscription[] = [];

    constructor(private route: ActivatedRoute, private r$: ReservationsService, private ref: ChangeDetectorRef) {
        if (route.snapshot.data.reservation) {
            this.reservation = route.snapshot.data.reservation;
        }
        if (route.snapshot.data.reservations) {
            this.listeReservations = route.snapshot.data.reservations;
        }

        let el = document.getElementById('layoutSidenav_content');
        if (el) el.scrollTop = 0
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        _.each(this.mySubs, (s) => s.unsubscribe());
    }

    ngOnInit() {
        this.mySubs.push(this.route.params.subscribe( (params) => {
            const id = params['id'];
            if (this.route.snapshot.data.reservation) {
                this.reservation = this.route.snapshot.data.reservation;
            }
            this.ref.markForCheck();
        }));
    }
}
