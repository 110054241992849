import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceModel, ResourceTypeModel } from '../models';

@Injectable()
export class ResourcesService {
    private apiUrl = '/api/resources'; // URL to web api

    constructor(private http: HttpClient) {}

    /**
     * Resources
     * @returns
     */
    getResources$(params?:any): Observable<ResourceModel[]> {
        return this.http.get<ResourceModel[]>(this.apiUrl);
    }

    getResourceTypes$(typeId:number): Observable<ResourceTypeModel> {
        return this.http.get<ResourceTypeModel>(this.apiUrl + `/types?type=${typeId}`);
    }

    getResource$(id:number): Observable<ResourceModel> {
        return this.http.get<ResourceModel>(this.apiUrl + `/${id}`);
    }

    updateResource$(resourcemodel:ResourceModel): Observable<ResourceModel> {
        return this.http.put<ResourceModel>(this.apiUrl + `/${resourcemodel.ressourceId}`, resourcemodel);
    }

    createResource$(resourcemodel:ResourceModel): Observable<ResourceModel> {
        return this.http.post<ResourceModel>(this.apiUrl, resourcemodel);
    }

    deleteResource$(resourcemodel:ResourceModel): Observable<ResourceModel> {
        return this.http.delete<ResourceModel>(this.apiUrl + `/${resourcemodel.ressourceId}`);
    }
}
