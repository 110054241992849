<div class="no-gutters">
    <div class="row">
        <div class="pagecontenu">
            <div class="contenu">
                <div class="h3 text-center">DÉROULEMENT DE VOTRE VISITE</div>
                <div class="h4">Forfait : {{reservation.groupeTypeNom | titlecase }}</div>
                <div class="h4">Groupe : {{reservation.nomOrganisateur | titlecase }}</div>
                <div class="h4" *ngIf="reservation.nomFete">Fêté : {{reservation.nomFete | titlecase }}</div>
                <div class="h4">Date : {{reservation.reservationDate | date : 'd MMM yyyy'}}</div>
                <div class="h4" *ngIf="(reservation.salles || []).length">Salle réservée : <span *ngFor="let s of reservation.salles">{{ s.salleNom }}&nbsp;</span>de {{reservation.salleDebut | date : 'HH:mm'}} à {{reservation.salleFin | date : 'HH:mm'}}</div>
                <div class="h4" *ngIf="reservation.valomotionCode">1. Code de mur RA : <span class="fw-bold">{{reservation.valomotionCode }}</span></div>
                <div class="h4" *ngIf="reservation.valomotionBirthdayCode">2. Code Hullaballoon : <span class="fw-bold">{{reservation.valomotionBirthdayCode }}</span></div>
                <div class="w-100" *ngIf="(reservation.parties || []).length &gt; 0">
                    <div class="h4">LASERTAG</div>
                    <div class="h5">Voici l’horaire de vos parties :</div>
                    <div class="h5">Veuillez présenter cette carte au marshall en service afin qu’il puisse valider votre entrée dans la salle de briefing.</div>
                    <div class="d-inline-block border-primary partie-item" *ngFor="let p of reservation.parties; index as i">
                        <div class="row align-items-center">
                            <div class="col-3"><div class="h4">Partie #{{ (i + 1) }} :</div></div>
                            <div class="col-3"><d1-reservations-game-card [game]="p" [allowEdit]="false" [allowDelete]="false"></d1-reservations-game-card></div>
                            <div class="col-5"><d1-codebarre [bc-value]="getCodeBarre(p)" [bc-display-value]="false" [bc-height]="35" bc-format="CODE39" [bc-width]="1.2"></d1-codebarre></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reservation.nomFete">
                    <div class="h4">MUR DE RÉALITÉ AUGMENTÉE</div>
                    <div class="h5">Pour accéder au mur situé dans la zone des arcades, vous aurez besoin d’entrer un code sur l’écran de démarrage :</div>
                    <div class="info">La durée est de 10 min. sans interruption (voir le chrono en haut à droite du mur). Il est donc important de prévoir ce temps entre vos parties de Lasertag car il ne sera pas possible de récupérer les minutes perdues.</div>
                </div>
                <div *ngIf="(reservation.vr || []).length &gt; 0">
                    <div class="h4">LA RÉALITÉ VIRTUELLE</div>
                    <div class="h5">Vous aurez accès selon l’horaire suivant :</div>
                    <div class="info">Veuillez vous présenter au responsable de la réalité virtuelle au moins 15 min avant votre heure de début.</div>
                    <div class="row" *ngFor="let p in reservation.vr; index as i"><div class="h5">Session #{{i+1}} : {{p.nombreJoueurs}} station(s) à {{p.partieHeures}} ({{p.partieDuree}} min.)</div></div>
                </div>
            </div>
        </div>
    </div>
</div>
