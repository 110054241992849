export interface Note {
    created: Date | null;
    description: string;
    auteur: string;
}

export class NoteModel implements Note {
    created: Date | null;
    description: string;
    auteur: string;
    
    constructor(n?:Note) {
        this.created = (n ? n.created : new Date());
        this.description = (n ? n.description : '');
        this.auteur = (n ? n.auteur : '');
    }
}