<sb-breadcrumbs slim="true"></sb-breadcrumbs>
<div class="d-flex m-2">
    <div class="card w-75 mr-2">
        <div class="card-header">
            <div class="pr-2">Ressource #{{ resource.ressourceId }}</div>
            <button class="btn btn-sm btn-primary" [routerLink]="['edit']">Modifier</button>
        </div>
        <div class="card-body">
            <div class="w-100"><d1-display-info label="Type" [text]="resource.ressourceTypeName" [hideIfEmpty]="false"></d1-display-info></div>
            <div class="w-100"><d1-display-info label="Nom" [text]="resource.ressourceName" [hideIfEmpty]="false"></d1-display-info></div>
            <div class="w-100"><d1-display-info label="SKU" [text]="resource.ressourceSKU" [hideIfEmpty]="false"></d1-display-info></div>
            <div class="w-100"><d1-display-info label="Tags" [list]="resource.ressourceTags" [hideIfEmpty]="false"></d1-display-info></div>
            <div class="w-100"><img [src]="resource.ressourceTypeImage" /></div>
        </div>
    </div>
</div>
