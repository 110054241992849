import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '@common/services';
import { SalleModel } from '@modules/rooms/models';
import { RoomsService } from '@modules/rooms/services';
import _ from 'lodash';
import { AppNotificationService } from '@common/services/appnotification.service';

@Component({
    selector: 'd1-rooms-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-list.component.html',
    styleUrls: ['rooms-list.component.scss'],
})
export class RoomsListComponent implements OnInit {
    public ListeSalles: SalleModel[] = [];

    constructor(private r$: RoomsService
        , private ref: ChangeDetectorRef
        , private router: Router
        , private nav: ActivatedRoute
        , private notify$: AppNotificationService) {

    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }

    fetchData() {
        this.r$.list().subscribe((r) => {
            this.ListeSalles = r;
            this.ref.markForCheck()
        });
    }

    ngOnInit() {
        if (this.nav.snapshot.data.rooms) {
            this.ListeSalles = this.nav.snapshot.data.rooms;
        } else {
            this.nav.queryParams.subscribe((q) => {
                this.fetchData();
            })
        }
    }

    onChange() { }

    onRoomClick(salle: SalleModel) {
        this.router.navigate([salle.salleId, 'edit'], { relativeTo: this.nav });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.ListeSalles.forEach((s: SalleModel, i: number) => {
            this.r$.patch({ salleId: s.salleId, ordrePriorite: i }).subscribe();
        })
        this.notify$.showMessage(`L'ordre a été sauvegardé`)
    }
}
