import { RentalsAvailabilityEditComponent } from './rentals-availability-edit/rentals-availability-edit.component';
import { RentalsBookingEditComponent } from './rentals-booking-edit/rentals-booking-edit.component';
import { RentalsAvailabilityCalendarComponent } from './rentals-availability-calendar/rentals-availability-calendar.component';
import { RentalsBookingListTodayComponent } from './rentals-booking-list-today/rentals-booking-list-today.component';
import { RentalsBookingListFutureComponent } from './rentals-booking-list-future/rentals-booking-list-future.component';
import { RentalsBookingReturnFutureComponent } from './rentals-booking-return-future/rentals-booking-return-future.component';
import { RentalsResourceEditComponent } from './rentals-resource-edit/rentals-resource-edit.component';
import { RentalsScheduleCalendarComponent } from './rentals-schedule-calendar/rentals-schedule-calendar.component';

export const components = [RentalsAvailabilityEditComponent, RentalsBookingEditComponent, RentalsAvailabilityCalendarComponent, RentalsBookingListTodayComponent, RentalsBookingListFutureComponent, RentalsBookingReturnFutureComponent, RentalsResourceEditComponent, RentalsScheduleCalendarComponent];

export * from './rentals-availability-edit/rentals-availability-edit.component';
export * from './rentals-booking-edit/rentals-booking-edit.component';
export * from './rentals-availability-calendar/rentals-availability-calendar.component';
export * from './rentals-booking-list-today/rentals-booking-list-today.component';
export * from './rentals-booking-list-future/rentals-booking-list-future.component';
export * from './rentals-booking-return-future/rentals-booking-return-future.component';
export * from './rentals-resource-edit/rentals-resource-edit.component';
export * from './rentals-schedule-calendar/rentals-schedule-calendar.component';
