<ng-container *ngIf="!viewCompact"
    ><mat-card class="align-middle mr-2 p-2" [class]="['cedule', 'groupe-type-' + group.groupeTypeId, 'groupe-groupeid', 'groupe-partietype-1']" ngbTooltip="{{group.nomOrganisateur}} ({{group.groupeTypeNom}})" [style.background]="(group.groupeTypeCouleur ? group.groupeTypeCouleur : 'auto')"
        ><mat-card-title class="text-center" [class.groupe-paye]="(group.statutGroupe === 2)">{{ group.nombreJoueurs }}</mat-card-title><mat-card-subtitle *ngIf="group.notesMarshall"> <div class="badge bg-info p-2" [style.background]="(group.notesMarshallCouleur ? group.notesMarshallCouleur + ' !important' : 'auto')">{{group.notesMarshall}}</div></mat-card-subtitle><mat-card-subtitle *ngIf="group.braceletCouleur"><div class="badge bg-info" [style.background]="group.braceletCouleur">BRACELET</div></mat-card-subtitle><mat-card-subtitle>{{ (group.nomFete ? ('Fêté : ' + group.nomFete) : group.nomOrganisateur) | titlecase }}</mat-card-subtitle><mat-card-content><ng-content></ng-content></mat-card-content></mat-card></ng-container
><ng-container *ngIf="viewCompact"
    ><div class="card m-1 pr-2" ngbTooltip="{{group.nomOrganisateur | titlecase}} ({{group.groupeTypeNom}})">
        <div class="row no-gutters">
            <div class="card-players p-2 text-white text-center align-middle" [style.background]="(group.groupeTypeCouleur ? group.groupeTypeCouleur : 'auto')"><span class="p-2">{{ group.nombreJoueurs }}</span></div>
            <div class="col p-2">
                <div class="font-weight-bold">{{ group.nomOrganisateur | titlecase }}<mat-icon class="text-success" *ngIf="group.statutGroupe === 2" matTooltip="Le groupe est arrivé">verified_user</mat-icon><mat-icon class="text-success" *ngIf="group.presence == 1" matTooltip="Le groupe est présent">pan_tool</mat-icon><mat-icon class="text-danger" *ngIf="group.presence == 2" matTooltip="Le groupe est absent">do_not_touch</mat-icon></div>
                <div class="font-weight-normal" *ngIf="group.nomFete">Fêté : {{ group.nomFete | titlecase }}</div>
                <div class="badge bg-info p-2 m-2 text-white font-weight-bold" *ngIf="group.notesMarshall" [style.background]="(group.notesMarshallCouleur ? group.notesMarshallCouleur + ' !important' : 'auto')">{{group.notesMarshall}}</div>
                <div class="badge bg-info p-2 m-2 text-white font-weight-bold" *ngIf="group.braceletCouleur" [style.background]="group.braceletCouleur + ' !important'">BRACELET</div>
            </div>
        </div>
    </div></ng-container
>
