// Divers
import * as _ from 'underscore';
import * as moment from 'moment';


/**
 * Fonction globalle pour copier le squelette d'un objet
 * @param source
 * @param isArray
 * @returns {*}
 */
 function skeleton(source) {
	var o = Array.isArray(source) ? [] : {};
	for (var key in source) {
		if (source.hasOwnProperty(key)) {
			var t = typeof source[key];
			o[key] = t == 'object' ? skeleton(source[key]) : { string: '', number: 0, boolean: false }[t];
		}
	}
	return o;
}

function errorGetCallback(response) {
	//console.log("errorGet", response);
}

angular.module('resources.utils', [])

	.factory('Configurations', ['$http', function ($http) {
		var myConfig = {
		};

		return myConfig;
	}])

	.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {

	    $routeProvider
	        .when('/ng1/utils', {
	            templateUrl:'ng1/utils/utils.tpl.html',
	            controller:'D1UtilsCtrl',
	            resolve: {
	                pageTitle : ['Breadcrumb', function(Breadcrumb) {
	                    Breadcrumb.pageTitle = 'Utilitaires';
	                }],
	                routePrivee : function () { return false; }
	            }
	        })
			.when('/ng1/utils/chasses', {
				templateUrl:'ng1/utils/chasses.tpl.html',
				controller:'D1UtilsChassesCtrl',
				resolve: {
					pageTitle : ['Breadcrumb', function(Breadcrumb) {
						Breadcrumb.pageTitle = 'Chasses';
					}],
					routePrivee : function () { return false; }
				}
			})
			.when('/ng1/utils/link', {
				templateUrl:'ng1/utils/link.tpl.html',
				controller:'D1UtilsLinkCtrl',
				resolve: {
					pageTitle : ['Breadcrumb', function(Breadcrumb) {
						Breadcrumb.pageTitle = 'Lien externe';
					}],
					linkName : ['$location', function($location) {
						const l = ($location.search() && $location.search().name ? $location.search().name : undefined)
						return l;
					}],
					routePrivee : function () { return false; }
				}
			});

	    // use the HTML5 History API
	    //$locationProvider.html5Mode(true);
	}])

	.controller('D1UtilsCtrl', ['$scope', '$timeout', '$interval', 'Usagers', 'Breadcrumb', '$location', '$http', function($scope, $timeout, $interval, Usagers, Breadcrumb, $location, $http) {
		$scope.Usagers = Usagers;
		$scope.location = $location;
		$scope.listDevices = [];
		$scope.listRoles = [];

		$http.get("/api/d1channel/devices").then(function(resp) {
			$scope.listDevices = [];

			_.each(resp.data, function (t) {
				if (t.device_type == "roku") {
					// Query media info
					// $http.get("/api/d1channel/" + t.device_ip + "/query").then(function(resp) {
					// 	t.status = resp.data["device-info"];
					// 	console.log("Query response", t.status);
					// }, errorGetCallback);

				}

				$scope.listDevices.push(t);
			});


		});

		$http.get("/api/d1channel/screen_roles").then(function(resp) {
			$scope.listRoles = resp.data;
		});

		/**
		 * Roku
         */
		$scope.viewChannelInfo = function(device) {
			// Query media info
			console.log("Query device", device);
			if (!device.status) {
				device.loading = true;
				device.status = {
					"model-name" : '',
					"model-number" : '',
					"user-device-name" : '',
				};
				return $http.get("/api/d1channel/" + device.device_ip + "/query").then(function(resp) {
					const status = resp.data["device-info"];
					device.status = status;
					device.loading = false;
					console.log("Query response", status);
					$scope.$apply();
					return `
						<div>Model name : ${status["model-name"]}</div>
						<div>Model number : ${status["model-number"]}</div>
						<div>Device name : ${status["user-device-name"]}</div>
					`;
				}, (err) => {
					console.error('ERROR viewChannelInfo()', err);
				});

			} else {
				const status = device.status;
				return `
					<div>Model name : ${status["model-name"]}</div>
					<div>Model number : ${status["model-number"]}</div>
					<div>Device name : ${status["user-device-name"]}</div>
				`;
			}
			
		}
		$scope.StartScreen = function(ip, screen) {
			$http.get("/api/d1channel/" + ip + "/start/" + screen);
		};

		$scope.StartChannel = function(ip) {
			$http.get("/api/d1channel/" + ip + "/start");
		};

		$scope.AutoStartScreen = function(ip, screen) {
			$http.get("/api/d1channel/" + ip + "/autostart/" + screen);
		};

		$scope.QueryChannel = function(device) {
			$http.get("/api/d1channel/" + device.device_ip + "/query").then(function(resp) {
				device.status = resp.data["device-info"];
				console.log("Query response", device.status);
			});
		};
		$scope.PoweroffTV = function(ip) {
			$http.get("/api/d1channel/" + ip + "/power-off", function(ret) {
				device.status = ret;
				console.log(ret);
			});
		};
		$scope.ChangeInput = function(ip, inputType) {
			$http.get("/api/d1channel/" + ip + "/input/" + inputType, function(ret) {
				device.status = ret;
				console.log(ret);
			});
		};



		/**
		 * Computers
		 */
		$scope.Poweron = function(mac) {
			$http.get("/api/remote/" + mac + "/power-on", function(ret) {
				device.status = ret;
				console.log(ret);
			});
		};

		$scope.Poweroff = function(ip) {
			$http.get("/api/remote/" + ip + "/power-off", function(ret) {
				device.status = ret;
				console.log(ret);
			});
		};

		$scope.QueryState = function(device) {
			console.log('QueryState', device)
			$http.get("/api/remote/" + device.device_ip + "/status", function(ret) {
				device.status = ret;
				console.log(ret);
			});
		};
	}])

	/**
	 *  Link
	 */
	.controller('D1UtilsLinkCtrl', ['$scope', 'D1Configurations', '$sce', 'linkName', function($scope, D1Configurations, $sce, linkName) {
		// Init
		$scope.linkurl = '';
		
		D1Configurations.getConfigurations().then (
			r => {
				const l = _.findWhere(D1Configurations.links, {name : linkName})
				console.log('l, linkName, D1Configurations.links', l, linkName, D1Configurations.links)
				if (l) {
					$scope.linkurl = $sce.trustAsResourceUrl(l.url)
				}
				// $scope.linkurl = $sce.trustAsResourceUrl("https://cloud.hubitat.com/api/b8135482-2033-4453-b6bb-e5ff639e794e/apps/10/dashboard/10?access_token=1a391525-0714-428b-8fad-71ddc0c04c4d&local=false")
			}
		);
	}])

	/**
	 *  Chasses aux trésor
	 */
	.controller('D1UtilsChassesCtrl', ['$scope', '$timeout', '$interval', 'Usagers', 'Breadcrumb', '$location', '$http', function($scope, $timeout, $interval, Usagers, Breadcrumb, $location, $http) {
		// Init
		$scope.games1 = [
			{
				// Les Monstres
				name: 'Monstres'
				, description: 'Bla bla bla'
				, code_holder : ['','','','','','','']
				, code_validated : [-1,-1,-1,-1,-1,-1,-1]
				, code_keys : ["🔺",'⬜', '⚪', '⭐', "🔶",'❤️', "🌀", '⚡', '❌', "🌙"]
				, current_key : 0   // Pointer sur la position du caractère
				, current_code : ''  // Pointer sur le code.id
				, codes : [
					// Énumérer les codes possible pour cette chasse
					{id: 1, code_corrected : ["🔺", "⭐", "🔶", "⚪", "❤️", "❤️", "🌀"]},
					{id: 2, code_corrected : ["🔺", "⭐", "🔶", "⚪", "❤️", "❤️", "🌀"]},
					{id: 3, code_corrected : ["🔺", "⭐", "🔶", "⚪", "❤️", "❤️", "🌀"]},
					{id: 4, code_corrected : ["🔺", "⭐", "🔶", "⚪", "❤️", "❤️", "🌀"]}
				]
				, valider : function () {
					if (this.current_code.length) {
						var code = _.findWhere(this.codes, {id : parseInt(this.current_code)});
						if (code != undefined) {
							var code_holder = this.code_holder;
							var code_validated = this.code_validated;
							var bOk = true;
							_.each(code.code_corrected, function (char, i) {
								// Will be set to false upon first failure
								bOk = (char == code_holder[i] && bOk);
								code_validated[i] = (char == code_holder[i]);
								return bOk;
							});
							if (bOk) {
								console.log("CODE IS GOOD!!!");
							}
							else {
								var diff = _.without(code.code_corrected, code_holder);
								console.log("Difference entre les 2", bOk, code.code_corrected, code_holder);
								console.log("PAS CORRECT", diff);
							}
						}
						else {
							console.log("PAS DE CODE TROUVÉ", this.codes, code);
						}
					}
					else {
						alert('# de chasse invalide : ' + this.current_code);
					}
				}
				, reset : function () {
					var code_holder = this.code_holder;
					var code_validated = this.code_validated;
					this.current_key = 0;
					this.current_code = '';
					_.each(code_holder, function (v, i) {
						code_holder[i] = '';
						code_validated[i] = -1;
					});
				}
				, keySelected : function(key_index) {
					var game = $scope.games[game_index];

					if (game.current_key < game.code_holder.length) {
						game.code_holder[game.current_key] = game.code_keys[key_index];
						game.current_key++;
					}
				}
			},

			{
				// Chasse 2
				name: 'Chasse 2'
				, description: 'Bla bla bla'
				, code_holder : ['','','','','','','','','','','','','']
				, code_validated : [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
				, code_keys : ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
				, current_key : 0   // Pointer sur la position du caractère
				, current_code : ''  // Pointer sur le code.id
				, codes : [
					// Énumérer les codes possible pour cette chasse
					{id: 1, code_corrected : ['B', 'E', 'N', 'O', 'I', 'T', '0', '1', '2', '3', '4', '5', '6']},
					{id: 1, code_corrected : ['B', 'E', 'N', 'O', 'I', 'T', '0', '1', '2', '3', '4', '5', '6']},
					{id: 1, code_corrected : ['B', 'E', 'N', 'O', 'I', 'T', '0', '1', '2', '3', '4', '5', '6']}
				]
				, valider : function () {
					if (this.current_code.length) {
						var code = _.findWhere(this.codes, {id : parseInt(this.current_code)});
						if (code != undefined) {
							var code_holder = this.code_holder;
							var code_validated = this.code_validated;
							var bOk = true;
							_.each(code.code_corrected, function (char, i) {
								// Will be set to false upon first failure
								bOk = (char == code_holder[i] && bOk);
								code_validated[i] = (char == code_holder[i]);
								return bOk;
							});
							if (bOk) {
								console.log("CODE IS GOOD!!!");
							}
							else {
								var diff = _.without(code.code_corrected, code_holder);
								console.log("Difference entre les 2", bOk, code.code_corrected, code_holder);
								console.log("PAS CORRECT", diff);
							}
						}
						else {
							console.log("PAS DE CODE TROUVÉ", this.codes, code);
						}
					}
					else {
						alert('# de chasse invalide : ' + this.current_code);
					}
				}
				, reset : function () {
					var code_holder = this.code_holder;
					var code_validated = this.code_validated;
					this.current_key = 0;
					this.current_code = '';
					_.each(code_holder, function (v, i) {
						code_holder[i] = '';
						code_validated[i] = -1;
					});
				}
				, keySelected : function(key_index) {
					var game = $scope.games[game_index];

					if (game.current_key < game.code_holder.length) {
						game.code_holder[game.current_key] = game.code_keys[key_index];
						game.current_key++;
					}
				}
			},

			{
				// Boussole
				name: 'Boussole'
				, description: 'Bla bla bla'
				, draw : undefined
				, polyline : undefined
				, rect : undefined
				, code_holder : ['','','','','','','','','','','','','']
				, code_validated : [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
				, code_keys : ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
				, current_key : 0   // Pointer sur la position du caractère
				, current_code : ''  // Pointer sur le code.id
				, codes : [
					// Énumérer les codes possible pour cette chasse
					{id: 1, code_corrected : ['B', 'E', 'N', 'O', 'I', 'T', '0', '1', '2', '3', '4', '5', '6']}
				],
				valider : function () {
					if (this.current_code.length) {
						var code = _.findWhere(this.codes, {id : parseInt(this.current_code)});
						if (code != undefined) {
							var code_holder = this.code_holder;
							var code_validated = this.code_validated;
							var bOk = true;
							_.each(code.code_corrected, function (char, i) {
								// Will be set to false upon first failure
								bOk = (char == code_holder[i] && bOk);
								code_validated[i] = (char == code_holder[i]);
								return bOk;
							});
							if (bOk) {
								console.log("CODE IS GOOD!!!");
							}
							else {
								var diff = _.without(code.code_corrected, code_holder);
								console.log("Difference entre les 2", bOk, code.code_corrected, code_holder);
								console.log("PAS CORRECT", diff);
							}
						}
						else {
							console.log("PAS DE CODE TROUVÉ", this.codes, code);
						}
					}
					else {
						alert('# de chasse invalide : ' + this.current_code);
					}
				}
				, init : function() {
					console.log("Test init");
					this.draw = SVG('drawing-2').size(800, 400);
					this.rect = this.draw.rect(100, 100).attr({ fill: '#f06' }).attr({ x: 200, y: 100 });
					this.polyline = this.draw.polyline('50,0 60,40 100,50 60,60 50,100 40,60 0,50 40,40');
					this.polyline.fill('none').move(20, 20);
					this.polyline.stroke({ color: '#f06', width: 4, linecap: 'round', linejoin: 'round' });
					this.polyline.click(this.click);

					console.log(this.polyline);
				}
				, reset : function () {
					var code_holder = this.code_holder;
					var code_validated = this.code_validated;
					this.current_key = 0;
					this.current_code = '';
					_.each(code_holder, function (v, i) {
						code_holder[i] = '';
						code_validated[i] = -1;
					});
				}
				, click : function (element) {
					console.log("element", element);
					console.log("this", this);
					console.log("this.polyline", this.polyline);

					var ar = this.polyline.array();
					ar.value.push([element.x,element.y]);
					this.polyline.plot(ar);
					//polyline.array(ar);
				}
				, keySelected : function(key_index, element) {

					/*
					if (this.current_key < this.code_holder.length) {
						this.code_holder[this.current_key] = this.code_keys[key_index];
						this.current_key++;
					}
					*/
					console.log("key_index, element", key_index, element);
					console.log("element.position", element.offset());
					var pos = element.offset();

					var ar = polyline.array();
					//ar.value.push([Math.floor((Math.random() * 100) + 1),Math.floor((Math.random() * 100) + 1)]);
					ar.value.push([pos.top,pos.left]);
					polyline.plot(ar);
					//polyline.array(ar);
				}
			}
		];
		$scope.games = [
			{
				// Chasse 2
				name: 'Chasse'
				, description: 'Bla bla bla'
				, code_holder : ['','','','','','','','','','']
				, code_validated : [-1,-1,-1,-1,-1, -1, -1, -1 , -1 ,-1]
				, code_keys : ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
				, current_key : 0   // Pointer sur la position du caractère
				, current_code : ''  // Pointer sur le code.id
				, codes : [
					// Énumérer les codes possible pour cette chasse
					{id: 1, code_corrected : ['6', 'E', '8', 'F', '1', 'A', '9', 'G', 'C', 'E']},
					{id: 2, code_corrected : ['5', '1', '7', 'V', 'V', 'E', '5', 'F', 'C', 'E']},
					{id: 3, code_corrected : ['8', 'J', 'H', 'V', '1', '4', 'C', 'T', 'P', '5']}
				]
				, valider : function () {
					if (this.current_code.length) {
						var code = _.findWhere(this.codes, {id : parseInt(this.current_code)});
						if (code != undefined) {
							var code_holder = this.code_holder;
							var code_validated = this.code_validated;
							var bOk = true;
							_.each(code.code_corrected, function (char, i) {
								// Will be set to false upon first failure
								bOk = (char == code_holder[i] && bOk);
								code_validated[i] = (char == code_holder[i]);
								return bOk;
							});
							if (bOk) {
								console.log("CODE IS GOOD!!!");
							}
							else {
								var diff = _.without(code.code_corrected, code_holder);
								console.log("Difference entre les 2", bOk, code.code_corrected, code_holder);
								console.log("PAS CORRECT", diff);
							}
						}
						else {
							console.log("PAS DE CODE TROUVÉ", this.codes, code);
						}
					}
					else {
						alert('# de chasse invalide : ' + this.current_code);
					}
				}
				, reset : function () {
					var code_holder = this.code_holder;
					var code_validated = this.code_validated;
					this.current_key = 0;
					this.current_code = '';
					_.each(code_holder, function (v, i) {
						code_holder[i] = '';
						code_validated[i] = -1;
					});
				}
				, keySelected : function(game_index, key_index) {
					var game = $scope.games[game_index];

					if (game.current_key < game.code_holder.length) {
						game.code_holder[game.current_key] = game.code_keys[key_index];
						game.current_key++;
					}
				}
			},
			{
				// Les Monstres
				name: 'Monstres'
				, description: 'Bla bla bla'
				, code_holder : ['','','','','','','','','','','','','']
				, code_validated : [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
				//, code_keys : ["🔺",'⬜', '⚪', '⭐', "🔶",'❤️', "🌀", '⚡', '❌', "🌙"]
				, code_keys : ["🖤", '⚡', "🔺", '⚪', '⭐', "🌙", "🔆", "⬛"]
				, current_key : 0   // Pointer sur la position du caractère
				, current_code : ''  // Pointer sur le code.id
				, codes : [
				// Énumérer les codes possible pour cette chasse
				{id: 1, code_corrected : ["🖤","🖤", '⚡', "🔺", '⚪', '⭐', "🌙", "⬛", "🌙", '⚪', "⬛", "🔺", "🔆"]}
			]
				, valider : function () {
				if (this.current_code.length) {
					var code = _.findWhere(this.codes, {id : parseInt(this.current_code)});
					if (code != undefined) {
						var code_holder = this.code_holder;
						var code_validated = this.code_validated;
						var bOk = true;
						_.each(code.code_corrected, function (char, i) {
							// Will be set to false upon first failure
							bOk = (char == code_holder[i] && bOk);
							code_validated[i] = (char == code_holder[i]);
							return bOk;
						});
						if (bOk) {
							console.log("CODE IS GOOD!!!");
						}
						else {
							var diff = _.without(code.code_corrected, code_holder);
							console.log("Difference entre les 2", bOk, code.code_corrected, code_holder);
							console.log("PAS CORRECT", diff);
						}
					}
					else {
						console.log("PAS DE CODE TROUVÉ", this.codes, code);
					}
				}
				else {
					alert('# de chasse invalide : ' + this.current_code);
				}
			}
				, reset : function () {
				var code_holder = this.code_holder;
				var code_validated = this.code_validated;
				this.current_key = 0;
				this.current_code = '';
				_.each(code_holder, function (v, i) {
					code_holder[i] = '';
					code_validated[i] = -1;
				});
			}
				, keySelected : function(game_index, key_index) {
				var game = $scope.games[game_index];

				if (game.current_key < game.code_holder.length) {
					game.code_holder[game.current_key] = game.code_keys[key_index];
					game.current_key++;
				}
			}
			}
		]
		

		// Init
		_.each($scope.games, function (g) {
			g.reset();
		});


		$scope.keySelected = function(game_index, key_index) {
			//console.log("keySelected", game_index, key_index, $scope.games[game_index].code_keys[key_index]);
			var game = $scope.games[game_index];
			var element = $('#game-' + game_index + '-key-' + key_index);
			console.log("element", element);

			game.keySelected(game_index, key_index, element);
/*
			if (game.current_key < game.code_holder.length) {
				game.code_holder[game.current_key] = game.code_keys[key_index];
				game.current_key++;
			}
*/
		};

		$scope.caseSelected = function(game_index, key_index) {
			var game = $scope.games[game_index];

			//game.code_holder[game.current_key] = game.code_keys[key_index];
			game.current_key = key_index;
		};

		$scope.ValiderChasse = function (gameIndex) {
			var game = $scope.games[gameIndex];
			game.valider();
		};

		$scope.ResetChasse = function (gameIndex) {
			var game = $scope.games[gameIndex];
			game.reset();
		};

		$scope.Test = function () {
			var ar = polyline.array();
			console.log("ar", ar.value);

			ar.value.push([30,30]);

			console.log("#1 polyline", polyline);
			polyline.plot(ar);
			//polyline.array(ar);
			console.log("#2 polyline", polyline);

			rect.animate().rotate(45).skew(25, 0)
		};

		// TEST
		SVG.on(document, 'DOMContentLoaded', function() {
			//var draw = SVG('drawing');
			var draw = SVG('drawing-4').size(800, 400);
			var rect = draw.rect(100, 100).attr({ fill: '#f06' }).attr({ x: 200, y: 100 });
			var polyline = draw.polyline('50,0 60,40 100,50 60,60 50,100 40,60 0,50 40,40');
			polyline.fill('none').move(20, 20);
			polyline.stroke({ color: '#f06', width: 4, linecap: 'round', linejoin: 'round' });

			console.log(polyline);
		})
	}])
;

