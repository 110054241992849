import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AppSettingsService } from '@common/services';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { ScheduleInformation, ScheduleModel } from '@modules/schedule/models';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'd1-reservations-list-cedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reservations-list-cedule.component.html',
  styleUrls: ['./reservations-list-cedule.component.scss']
})
export class ReservationsListCeduleComponent implements OnInit {
  @Input() id!: string;
  @Input() hideEmptySchedule: boolean = false;
  @Input() viewActualTimeOnly: boolean = true;
  @Input() date: moment.Moment = moment();
  @Output() selected: EventEmitter<ReservationModel> = new EventEmitter();
  @Output() filtered: EventEmitter<ReservationModel[]> = new EventEmitter();

  public groupeTypes: any = [];
  public ceduleTimes: any[] = []
  public cedules: ScheduleModel[] = []
  public cedulesComplete: ScheduleModel[] = []
  public ListeReservations: ReservationModel[] = [];
  public ListeReservationsComplete: ReservationModel[] = [];
  public viewAll = false;

  arena_id = 1;
  partieType = 1;
  nombreVestes = 0;

  constructor(public s$:ScheduleService, public r$:ReservationsService, private ref: ChangeDetectorRef, private render:Renderer2, private el:ElementRef, private settings$:AppSettingsService) {
    this.settings$.TypeGroupes.subscribe( l => {
        this.groupeTypes = l
    })
  }

  ngOnInit() {
    this.s$.CeduleListe.subscribe( c => {
        const liste = c as ScheduleModel[];
        this.ceduleTimes = _.uniqBy(c, 'partieHeures');
        this.cedules = _.reject(liste, {ceduleNombreJoueurs : null}) as ScheduleModel[];
        this.cedulesComplete = this.cedules;

        if (this.cedules.length) {
            this.nombreVestes = this.cedules[0].nombreVestes;
        } else {
            this.nombreVestes = 0;
        }

        this.ref.markForCheck();
    })

    this.r$.ReservationsListe.subscribe( liste => {
        this.ListeReservationsComplete = liste;
        this.ListeReservations = [... this.filterReservations()];

        this.ref.markForCheck()
    })
  }

  findGroup(groupeId:number) {
    return _.find(this.ListeReservationsComplete, {groupeId : groupeId});
  }

  findGame(groupe:ReservationModel, partieTemps: string) {
    if (groupe.parties) {
        return _.findLast(groupe.parties, {partieTemps});
    } else {
        return undefined;
    }
  }

  filterReservations():ReservationModel[] {
    let liste = this.ListeReservationsComplete;
    if (this.viewActualTimeOnly === false || (this.viewActualTimeOnly === true && (this.date.format('YYYYMMDD') !== moment().format('YYYYMMDD')))) {
        // Rien à faire
        // return this.ListeReservationsComplete;
    } else {
        liste = _.reject(liste, (v:ReservationModel,i) => {
            const lastEvent = this.r$.getLastEvent(v);
            return (v.statutGroupe === 2 && lastEvent.isBefore(moment().subtract(30, 'm')));
        });
    }

    // Rejeter les annulation + effacés
    if (this.viewAll === false) {
        liste = _.reject(liste, (v:ReservationModel,i) => {
            return (v.estAnnule || v.estEfface);
        });
    }

    return liste;
}

  showSelected(group: ReservationModel) {
    this.selected.emit(group);
  }

  nombreJoueursFiltres(cedule: any) {
      const games = this.getGamesInSchedule(cedule.partieTemps)
      return _.sumBy(_.filter(games, (g) => (g.statutGroupe == 2)), 'nombreJoueurs');
  }

  filtrerCeduleTimes(): ScheduleModel[] {
      if (this.viewActualTimeOnly === false || (this.viewActualTimeOnly === true && (this.date.format('YYYYMMDD') !== moment().format('YYYYMMDD')))) {
          return [...this.ceduleTimes];
      } else {
          return this.ceduleTimes.filter( (v:ScheduleModel,i) => {
              return moment(v.journeeTemps) >= moment().subtract(30, 'm');;
          });
      }
  }

  changeFiltre(filtre: any) {
      if (typeof filtre === 'string' || filtre === null) {
        if (filtre && filtre.length > 0) {
            this.ListeReservations = _.filter(this.filterReservations(), (c) => {
                const findsalle = _.find(c.salles, s => {return (s.salleNom.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)})
                return (c.nomOrganisateur.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || ((c.telephone||'').search(filtre) !== -1)
                    || (c.groupeTitre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (c.courriel.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (c.depotReference.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (c.nomFete.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (c.notes.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (c.notesMarshall.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (c.notesAdmin.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(filtre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                    || (findsalle !== undefined)
                })
        } else {
          this.ListeReservations = this.filterReservations()
        }

        // Indiquer qu'on est filtré
        this.filtered.emit(this.ListeReservations)
      }

  }

  getGamesInSchedule(partieHeures: string):ReservationModel[] {
    // const heure = moment(partieHeures);
    const games: any = _.filter(this.ListeReservations, l => {
        const game = _.find(l.parties, {partieTemps : partieHeures})
        return (game !== undefined)
    })
    return games;
  }


  highlight(event:Event, el:any) {
      const elListe = (<HTMLElement>this.el.nativeElement).querySelectorAll(
          `.groupe-groupeid-${el.groupeId}`
          );
      _.each(elListe, (el) => {
          this.render.addClass(el, 'surbrillance_groupe')
      })
  };

  unhighlight(event:Event, el:any) {
      const elListe = (<HTMLElement>this.el.nativeElement).querySelectorAll(
          `.groupe-groupeid-${el.groupeId}`
          );
      _.each(elListe, (el) => {
          this.render.removeClass(el, 'surbrillance_groupe')
      })
  };

  ceduleTypeJeu(event:Event, cedule: any) {
      const newInfo:ScheduleInformation = {
          ceduleInformation: cedule.ceduleInformation,
          ceduleTemps: moment(cedule.journeeTemps).toDate()
      };
      this.s$.modifierInformations(newInfo);
  }

    getGroupeTypeCouleur(groupe:ReservationModel) {
        const gt = _.find(this.groupeTypes, {groupeTypeId : groupe.groupeTypeId})
        return (gt && gt.groupeTypeCouleur ? gt.groupeTypeCouleur : '#0061f2');
    }

    getGroupeTypeNom(groupe:ReservationModel) {
        const gt = _.find(this.groupeTypes, {groupeTypeId : groupe.groupeTypeId})
        return (gt ? gt.groupeTypeNom : 'TYPE INCONNU');
    }


}
