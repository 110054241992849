import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@common/services';
import { ResourceModel } from '@modules/rentals/models';
import { ResourcesService } from '@modules/rentals/services';

@Component({
    selector: 'd1-rentals-resources-detail-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-resources-detail-edit.component.html',
    styleUrls: ['rentals-resources-detail-edit.component.scss'],
})
export class RentalsResourcesDetailEditComponent implements OnInit {
    resource!:ResourceModel
    constructor(
        private r$: ResourcesService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private t$:ToastService
    ) {
        if (this.nav.snapshot.data.resource) {
            this.resource = this.nav.snapshot.data.resource;
        } else {
            var id = parseInt(this.nav.snapshot.params.id)
            if (id.toString() === 'NaN') {
                this.resource = {
                    ressourceId : 0,
                    ressourceName: '',
                    ressourceTypeId : 0,
                    ressourceTypeName : '',
                    ressourceSKU: '',
                    ressourceImage: '',
                    ressourceTypeImage: ''
                } as ResourceModel

            } else {
                this.r$.getResource$(id).subscribe((r) => {
                    this.resource = r as ResourceModel;
                    this.ref.markForCheck();
                });
            }
        }
    }

    ngOnInit() {}

    onSave() {
        if (this.resource.ressourceId) {
            this.r$.updateResource$(this.resource).subscribe( r => {
                console.log('Après update', r);
                this.t$.showSuccess('Sauvegardé', 'Ressource sauvegardée avec succès');
                this.router.navigate(['..'], {relativeTo: this.nav});
            }, error => {
                console.error('ERREUR', error)
            })
        } else {
            this.r$.createResource$(this.resource).subscribe( r => {
                console.log('Après insert', r);
                this.t$.showSuccess('Sauvegardé', 'Ressource ajoutée avec succès');
                this.router.navigate(['..', r.ressourceId], {relativeTo: this.nav});
            }, error => {
                console.error('ERREUR', error)
            })
        }
    }

    onDelete() {}
    onCancel() {
        this.router.navigate(['..'], {relativeTo: this.nav});
    }
}
