import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-rooms-view-detail',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-view-detail.component.html',
    styleUrls: ['rooms-view-detail.component.scss'],
})
export class RoomsViewDetailComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
