import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { ResourceModel, ResourceTypeListe } from '@modules/rentals/models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-rentals-resource-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-resource-edit.component.html',
    styleUrls: ['rentals-resource-edit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RentalsResourceEditComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: RentalsResourceEditComponent,
        },
    ],
})
export class RentalsResourceEditComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy, Validator {
    @Input() resource!:ResourceModel;
    @Output() resourceChange: EventEmitter<ResourceModel> = new EventEmitter()

    onChangeSubs: Subscription[] = [];
    myForm!: FormGroup;

    public typeListe = ResourceTypeListe

    constructor(private fb: FormBuilder) {
        if (this.resource === undefined) {
            this.resource = {
                ressourceId : 0,
                ressourceName: '',
                ressourceTypeId : 1,
                ressourceSKU: '',
                ressourceTags: '',
            } as ResourceModel
        }

        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue) {
            if (changes.resource.firstChange) {
                this.initForm(); 
            } else {
                // Patch
                this.myForm.patchValue({
                    ressourceId: this.resource.ressourceId,
                    ressourceName: this.resource.ressourceName,
                    ressourceTypeId: this.resource.ressourceTypeId,
                    ressourceSKU : this.resource.ressourceSKU,
                    ressourceTags : this.resource.ressourceTags,
                })
            }
        }
    }

    initForm() {
        this.myForm = this.fb.group({
            ressourceId: [this.resource.ressourceId],
            ressourceName: [this.resource.ressourceName, [Validators.minLength(5)]],
            ressourceTypeId: [this.resource.ressourceTypeId, [Validators.required]],
            ressourceSKU : this.resource.ressourceSKU,
            ressourceTags : this.resource.ressourceTags,
        });

        this.myForm.valueChanges.subscribe( v => {
            this.resource = {...this.resource, ...v};
            this.resourceChange.emit(this.resource);
        })

        this.myForm.updateValueAndValidity();
    }
    changeTags(tags: any) {
        console.log('changeTags', tags);
        this.resource.ressourceTags = tags;
        this.resourceChange.emit(this.resource);
    }

    ngOnInit() {}

    onTouched: Function = () => {
        console.log("onTouched mark it!")
    };

    writeValue(value: any): void { };

    registerOnChange(fn: any): void {
        const sub = this.myForm.valueChanges.subscribe(fn);
        this.onChangeSubs.push(sub);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.myForm.errors;
    }

    // Clean up subscription
    ngOnDestroy(): void {
        for (const sub of this.onChangeSubs) {
            sub.unsubscribe();
        }
    }
}
