import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from '@common/services';
import { ReservationModel } from '@modules/reservations/models';
import { GameModel } from '@modules/schedule/models/game.model';
import _ from 'lodash';

@Component({
  selector: 'd1-reservations-group-item',
  templateUrl: './reservations-group-item.component.html',
  styleUrls: ['./reservations-group-item.component.scss']
})
export class ReservationsGroupItemComponent implements OnInit {
  @Input() group: ReservationModel = new ReservationModel()
  @Input() game!: GameModel;
  @Output() selected: EventEmitter<ReservationModel> = new EventEmitter();
  public groupeType:any;
  public salleString: string[] = [];

  constructor(public appSettings:AppSettingsService) { }

  ngOnInit() {
    if (!this.group) {
      this.group = new ReservationModel();
    }
    this.appSettings.TypeGroupes.subscribe( groupes => {
      this.groupeType = _.find(groupes, {groupeTypeId: this.group.groupeTypeId})
    })

    this.salleString = _.map(this.group.salles, s => s.salleNom)
  }

  groupClicked() {
    this.selected.emit(this.group)
  }

}
