import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-rooms-main',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-main.component.html',
    styleUrls: ['rooms-main.component.scss'],
})
export class RoomsMainComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
