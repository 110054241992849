import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReservationModel } from '@modules/reservations/models';
import { ReservationsService } from '@modules/reservations/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'd1-reservations-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-edit.component.html',
    styleUrls: ['reservations-edit.component.scss'],
})
export class ReservationsEditComponent implements OnInit {
    public reservation: ReservationModel = new ReservationModel()
    // public listeReservations: ReservationModel[] = []
    // private mySubs: Subscription[] = [];

    constructor(private route: ActivatedRoute, private r$: ReservationsService, private ref: ChangeDetectorRef) {
        if (route.snapshot.data.reservation) {
            this.reservation = route.snapshot.data.reservation;

            this.r$.lockReservation$(this.reservation).subscribe((r: any) => {
                console.log('Retour du locking!', r);
                if (r) {
                    console.log('Goood', r)
                } else {
                    console.log('Oooppps', r)
                }
            })
        }
    }

    ngOnInit() {

        // this.mySubs.push(this.route.params.subscribe( (params) => {
        //     const id = params['id'];
        //     this.r$.getReservations$(null, id).subscribe( (r) => {
        //         this.reservation = r;
        //         this.ref.markForCheck();
        //     })
        // }));
    }
}
