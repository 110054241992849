import { ReservationsListComponent } from './reservations-list/reservations-list.component';
import { ReservationsFilterComponent } from './reservations-filter/reservations-filter.component';
import { ReservationsViewDetailComponent } from './reservations-view-detail/reservations-view-detail.component';
import { ReservationsActionBarComponent } from './reservations-action-bar/reservations-action-bar.component';
import { ReservationsConfirmationStatusComponent } from './reservations-confirmation-status/reservations-confirmation-status.component';
import { ReservationsStatusComponent } from './reservations-status/reservations-status.component';
import { ReservationsGamesComponent } from './reservations-games/reservations-games.component';
import { ReservationsTimelineComponent } from './reservations-timeline/reservations-timeline.component';
import { ReservationsEditDetailComponent } from './reservations-edit-detail/reservations-edit-detail.component';
import { ReservationsListGamesComponent } from './reservations-list-games/reservations-list-games.component';
import { ReservationsChartsComponent } from './reservations-charts/reservations-charts.component';
import { ReservationQuickViewComponent } from './reservation-quick-view/reservation-quick-view.component';
import { ReservationsPaymentStatusComponent } from './reservations-payment-status/reservations-payment-status.component';
import { ReservationsGameCardComponent } from './reservations-game-card/reservations-game-card.component';
import { ReservationsListCeduleComponent } from './reservations-list-cedule/reservations-list-cedule.component';
import { ReservationsGroupItemComponent } from './reservations-group-item/reservations-group-item.component';
import { ReservationsAddWalkinComponent } from './reservations-add-walkin/reservations-add-walkin.component';
import { ReservationsStatsPeriodeComponent } from './reservations-stats-periode/reservations-stats-periode.component';
import { ReservationSheetViewComponent } from './reservation-sheet-view/reservation-sheet-view.component';

export const components = [ReservationsListComponent, ReservationsFilterComponent, ReservationsViewDetailComponent, ReservationsActionBarComponent, ReservationsConfirmationStatusComponent, ReservationsStatusComponent, ReservationsGamesComponent, ReservationsTimelineComponent, ReservationsEditDetailComponent, ReservationsListGamesComponent, ReservationsChartsComponent, ReservationQuickViewComponent, ReservationsPaymentStatusComponent, ReservationsGameCardComponent, ReservationsListCeduleComponent, ReservationsGroupItemComponent, ReservationsAddWalkinComponent, ReservationsStatsPeriodeComponent, ReservationSheetViewComponent];

export * from './reservations-list/reservations-list.component';
export * from './reservations-filter/reservations-filter.component';
export * from './reservations-view-detail/reservations-view-detail.component';
export * from './reservations-action-bar/reservations-action-bar.component';
export * from './reservations-confirmation-status/reservations-confirmation-status.component';
export * from './reservations-status/reservations-status.component';
export * from './reservations-games/reservations-games.component';
export * from './reservations-timeline/reservations-timeline.component';
export * from './reservations-edit-detail/reservations-edit-detail.component';
export * from './reservations-list-games/reservations-list-games.component';
export * from './reservations-charts/reservations-charts.component';
export * from './reservation-quick-view/reservation-quick-view.component';
export * from './reservations-payment-status/reservations-payment-status.component';
export * from './reservations-game-card/reservations-game-card.component';
export * from './reservations-list-cedule/reservations-list-cedule.component';
export * from './reservations-group-item/reservations-group-item.component';
export * from './reservations-add-walkin/reservations-add-walkin.component';
export * from './reservations-stats-periode/reservations-stats-periode.component';
export * from './reservation-sheet-view/reservation-sheet-view.component';
