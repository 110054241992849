import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';

@Component({
    selector: 'd1-date-picker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './date-picker.component.html',
    styleUrls: ['date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
    @Input() label = 'Choisir une date';
    @Input() date = moment().utc(false).endOf('day');
    @Input() showRefresh = false;
    @Output() dateChange = new EventEmitter<moment.Moment>();
    @Output() change = new EventEmitter<moment.Moment>();
    @Output() refresh = new EventEmitter();

    constructor() {}
    ngOnInit() {}

    OnDateChange(event: MatDatepickerInputEvent<Date>) {
        this.date = moment(event.value).utc(false).endOf('day');
        this.dateChange.emit(this.date)
        this.change.emit(this.date)
    }

    today() {
        this.date = moment().startOf('day');
        this.dateChange.emit(this.date)
        this.change.emit(this.date)
    }

    prevDay() {
        this.date = moment(this.date).utc(false).subtract(1, 'day');
        this.dateChange.emit(this.date)
        this.change.emit(this.date)
    }

    nextDay() {
        this.date = moment(this.date).utc(false).add(1, 'day');
        this.dateChange.emit(this.date)
        this.change.emit(this.date)
    }

    onRefresh() {
        this.refresh.emit();
    }
}
