import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-schedule-list-filter',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-list-filter.component.html',
    styleUrls: ['schedule-list-filter.component.scss'],
})
export class ScheduleListFilterComponent implements OnInit {
    videoListe = [];
    imageListe = [];
    showListe = [];
    arenaListe = [];

    constructor() {}
    ngOnInit() {}

    PlayVideo(arg: any) {}
    ShowImage(arg: any) {}
    ShowSlideshow(arg: any) {}
    changerArena(arg: any) {}
}
