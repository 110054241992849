<nav class="sidenav shadow-right" id="sidenavAccordion" [ngClass]="sidenavStyle" data-cy="sideNav">
    <div class="sidenav-menu">
        <div class="nav">
            <ng-container *ngFor="let section of sideNavSections"
                ><ng-container *ngIf="section.feature === undefined || (section.feature &amp;&amp; features.includes(section.feature))"
                    ><div class="sidenav-menu-heading" *ngIf="section.text"><i-feather class="mr-2" *ngIf="section.icon" [name]="section.icon"></i-feather>{{ section.text }}</div>
                    <ng-container *ngFor="let item of section.items"><sbpro-side-nav-item [sideNavItem]="sideNavItems[item]"></sbpro-side-nav-item></ng-container></ng-container
            ></ng-container>
        </div>
    </div>
    <div class="sidenav-footer">
        <div class="sidenav-footer-content">
            <div class="d-flex justify-content-between">
                <div class="sidenav-footer-subtitle p-0" *ngIf="userService.user$ | async as user">Connecté:</div>
                <div class="sidenav-footer-subtitle p-0 text-right">v{{utils.version$ | async}}</div>
            </div>
            <div class="sidenav-footer-title" *ngIf="userService.user$ | async as user">
                {{user.nom}} ({{ user.initiales }})<a class="text-white ml-2" *ngIf="user.realm === 'admin'" [routerLink]="['/', 'settings']" title="Settings"><i-feather class="nav-link-icon" name="settings"></i-feather></a>
            </div>
        </div>
    </div>
</nav>
