import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';

import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class AppNotificationService {
  private messageList:appNotificationMessage[] = [];

    constructor (private _snackBar: MatSnackBar, private router:Router) {
      // Monitor route change to maybe display messages after the new route has been done
      router.events.subscribe( (ev) => {
        if (ev instanceof NavigationEnd){
          if (this.messageList.length) {
            this.messageList.forEach( (m) => {
              m.pushForNextRoute = false;
              this.showMessage(m);
            })

            // Clear messages
            this.messageList = [];
          }
        }
      })
    }

    // Display a message on the screen
    showMessage(message:appNotificationMessage|string, pushForNextRoute?:boolean) {
      let msg:appNotificationMessage;

      if (typeof(message) == 'string') {
        msg = new appNotificationMessage()
        msg.message = message;
      }
      else {
        msg = message;
      }
      if (pushForNextRoute !== undefined ) {
        msg.pushForNextRoute = pushForNextRoute;
      }

      if (msg.pushForNextRoute) {
        this.messageList.push(msg);
      } else {
        if (msg.messageType = appNotificationMessageTypes.DEFAULT) {
          // Default
          this._snackBar.open(msg.message, msg.action, {
            duration: msg.duration,
          });
  
        } else if (msg.messageType = appNotificationMessageTypes.ERROR) {
          // Same for the moment!!!
          this._snackBar.open(msg.message, msg.action, {
            duration: msg.duration,
          });
  
        }
      }
    }

    // Show error
    showError(message:appNotificationMessage|string, pushForNextRoute?:boolean) {
      let msg:appNotificationMessage;

      if (typeof(message) == 'string') {
        msg = new appNotificationMessage()
        msg.message = message;
      }
      else {
        msg = message;
      }
      msg.messageType = appNotificationMessageTypes.ERROR;
      this.showMessage(msg);
    }
}

export enum appNotificationMessageTypes {
  DEFAULT,
  ERROR
}

export class appNotificationMessage {
  message: string = '';
  messageType: appNotificationMessageTypes = appNotificationMessageTypes.DEFAULT
  duration: number = 2000;
  action: string = 'Fermer';
  pushForNextRoute: boolean = false;
}