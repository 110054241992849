<div class="no-gutters">
    <div class="pagecontenu">
        <div class="contenu">
            <div class="text-center"><img src="/assets/img/logo-noir.png" /></div>
            <div class="titre1">Forfait : {{getGroupeTypeNom(reservation) | titlecase }}</div>
            <div class="titre1">Groupe : {{reservation.nomOrganisateur | titlecase }}</div>
            <div class="titre1" *ngIf="reservation.nomFete">Fêté : {{reservation.nomFete | titlecase }}</div>
            <div class="titre1">Date : {{reservation.reservationDate | date : 'd MMM yyyy'}}</div>
            <div class="titre1" *ngIf="(reservation.salles || []).length">Salle <span *ngFor="let s of reservation.salles">{{ s.salleNom }}&nbsp;</span>de {{reservation.salleDebut | date : 'HH:mm'}} à {{reservation.salleFin | date : 'HH:mm'}}</div>
            <br />
            <div class="titre2" *ngIf="reservation.valomotionCode">1. Code de mur RA : <span class="fw-bold">{{reservation.valomotionCode }}</span></div>
            <div class="titre2" *ngIf="reservation.valomotionBirthdayCode">2. Code Hullaballoon : <span class="fw-bold">{{reservation.valomotionBirthdayCode }}</span></div>
            <div class="w-100 mt-2" *ngIf="(reservation.parties || []).length &gt; 0">
                <hr />
                <div class="d-block border-primary partie-item" *ngFor="let p of reservation.parties; index as i">
                    <div class="titre2">Partie #{{ (i + 1) }} : {{ p.partieTemps | date : 'HH:mm' }} {{getPasseCouleurNom(p) | titlecase}}</div>
                    {{p.nombreJoueurs}} joueurs <d1-codebarre [bc-value]="getCodeBarre(p)" [bc-display-value]="true" [bc-height]="45" bc-format="CODE39" [bc-width]="1" bc-type="svg" [bc-font-size]="12"></d1-codebarre>
                </div>
            </div>
            <div class="titre1 contenu">Veuillez présenter cette carte au marshall en service afin qu’il puisse valider votre entrée dans la salle de briefing.</div>
        </div>
    </div>
</div>
