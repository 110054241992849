import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { RoomsListComponent } from '@modules/rooms/components';

@Component({
    selector: 'd1-rooms-overview',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-overview.component.html',
    styleUrls: ['rooms-overview.component.scss'],
})
export class RoomsOverviewComponent implements OnInit, AfterViewInit {
    @ViewChild('matDrawer', {static: true}) matDrawer!: MatDrawer;
    @ViewChild('roomList', {static: true}) _roomList!: RoomsListComponent;
    
    constructor(private _changeDetectorRef:ChangeDetectorRef) { }
        
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }

    ngOnInit() {
        // Subscribe to MatDrawer opened change
        this.matDrawer.openedChange.subscribe((opened) => {
            if ( !opened )
            {
                this._roomList.fetchData();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    ngAfterViewInit(): void {
        
    }

}
