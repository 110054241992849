import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReservationModel } from '@modules/reservations/models';
import _ from 'lodash';

@Component({
    selector: 'd1-reservations-view-detail',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-view-detail.component.html',
    styleUrls: ['reservations-view-detail.component.scss'],
})
export class ReservationsViewDetailComponent implements OnInit {
    @Input() reservation: ReservationModel = new ReservationModel();

    constructor() {  }

    ngOnInit() {}
    
    getSalles() {
        return _.map(this.reservation.salles, (s) => {
            return (s?.salleNom || 'Inconnu');
        } )
    }

    OnChange(event: any) {
        console.log("OnChange($event)", event, this.reservation.statutGroupe)
        // this.reservation.statutGroupe = event;
    }

    updateStatutPaiement(event:any) {

    }

    changeTags(event:any) {

    }
}
