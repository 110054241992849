<form [formGroup]="myForm">
    <div class="form-group">
        <tsng-form-group-input class="w-50 mr-2" label="Nom complet" type="text" formControlName="nomOrganisateur"></tsng-form-group-input
        ><span id="search-container"
            ><div class="d-inline search-fields">
                <mat-form-field class="open-close-container" [@openClose]="isOpen ? 'open' : 'closed'"
                    ><mat-label>Nom/Courriel/Téléphone</mat-label><input matInput id="inputSearch" aria-label="State" [matAutocomplete]="auto" #autoCompleteInput autocomplete="off" placeholder="Nom de l'organisateur" [formControl]="groupeCtrl" /><mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectedGroupe($event)" panelWidth="50%"
                        ><mat-option *ngFor="let result of filteredSearchResults | async" [value]="result"><span>{{result.nomOrganisateur}} #{{result.groupeId}}</span><span *ngIf="result.courriel"> ({{ result.courriel }})</span><span *ngIf="result.telephone"> | Tel : {{ result.telephone | phone }} </span><span *ngIf="result.rue"> | Adr. : {{ result.rue}} {{ result.nocivique}} </span></mat-option></mat-autocomplete
                    ></mat-form-field
                ><button class="btn btn-primary toggleBtn" type="button" (click)="toggleSearch()">Chercher<mat-icon>{{ searchIcon }}</mat-icon></button>
            </div></span
        >
    </div>
    <div class="form-group"><tsng-form-group-input class="w-50 pr-2" label="Courriel" type="email" formControlName="courriel"> </tsng-form-group-input><tsng-form-group-input class="w-25" label="Téléphone" type="phone" formControlName="telephone"> </tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="w-100" label="Notes" type="TextArea" formControlName="notes"></tsng-form-group-input></div>
    <div class="form-group"><tsng-form-group-input class="w-100" label="Notes administrateurs" type="TextArea" formControlName="notesAdmin"></tsng-form-group-input></div>
</form>
