import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timePlugin from '@fullcalendar/timegrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import frLocale from '@fullcalendar/core/locales/fr';
import moment from 'moment';
import { RoomsService } from '@modules/rooms/services';
import _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { SalleModel } from '@modules/rooms/models';
import { ConfigService } from '@common/services/config.service';
import { AppSettingsService } from '@common/services';
import { timeout } from 'rxjs';
import { ReservationsService } from '@modules/reservations/services';

@Component({
    selector: 'd1-rooms-dialog-pick',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-dialog-pick.component.html',
    styleUrls: ['rooms-dialog-pick.component.scss'],
})
export class RoomsDialogPickComponent implements OnInit {
    public info: RoomPickupInfo = {
        showDate: false,
        multiple: false,
        date: new Date(),
        duration: 60,
        dialogTitle: "Choisir une salle",
        rooms: {}
    }
    public togCalendar: boolean = true
    private _selectedItems: any[] = []
    roomList?: SalleModel[];

    @ViewChild('timeline', { static: true }) timelineContainer!: ElementRef;
    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

    public caloptions: CalendarOptions = {
        plugins: [
            dayGridPlugin,
            timePlugin,
            interactionPlugin,
            resourceTimeGridPlugin,
            resourceTimelinePlugin,
        ],
        timeZone: 'local',
        /* @ts-ignore */
        schedulerLicenseKey: '1234567890-fcs-20034567890',
        initialView: 'resourceTimeGridDay',
        datesAboveResources: true,
        slotDuration: '00:20:00',
        allDaySlot: false,
        slotMinTime: '08:00:00',
        headerToolbar: {
            left: '',
            center: 'title',
            right: 'resourceTimeGridDay,resourceTimelineDay'
        },
        views: {
            resourceTimelineDay: {
                slotDuration: '00:20:00',
                buttonText: 'Timeline',
                resourceAreaWidth: "20%"
            }
        },
        locale: frLocale,
        height: "auto",
        editable: false,
        eventDurationEditable: false,
        snapDuration: '01:20:00',
        eventOverlap: false,
        eventSources: [],
        resourceAreaHeaderContent: 'Salles',
        resources: [],
        events: [],
        selectable: false,
        selectOverlap: false
    }
    public ceduleList: any;

    calendarApi!: Calendar


    constructor(@Inject(MAT_DIALOG_DATA) data: RoomPickupInfo, private r$: RoomsService, public dialogRef: MatDialogRef<RoomsDialogPickComponent>, private ref: ChangeDetectorRef, private c$: ConfigService, private d1$: AppSettingsService, private res$: ReservationsService) {
        if (data) {
            this.info = { ...this.info, ...data }
        }

        if (this.info.rooms) {
            this.roomList = this.info.rooms;
            if (this.roomList) {
                const activeRooms = this.roomList.filter((s) => s.estDisponible && s.visiblePublic)
                /* @ts-ignore */
                this.caloptions.resources = _.map(activeRooms, (s) => {
                    return { id: s.salleId.toString(), title: s.salleNom }
                });
            } else {
                /* @ts-ignore */
                this.caloptions.resources = [];
            }
            /* @ts-ignore */
        }
        
    }

    fetchData() {
        let date = moment(this.info.date)
        this.d1$.Horaires.subscribe(h => {
            var horairesLatest = this.c$.findLatestSchedule(h, date);
            var heuresLatest = this.c$.findLatestScheduleHours(horairesLatest, date);
            this.caloptions.slotMinTime = moment(heuresLatest.start_date).format('HH:mm:ss').toString()
            this.caloptions.slotMaxTime = moment(heuresLatest.end_date).format('HH:mm:ss').toString()
        })
        this.caloptions.events = [];

        this.r$.getRoomsUsage$(date).subscribe((r) => {
            this.ceduleList = r;

            this.caloptions.events = _.map(this.ceduleList, (s) => {
                return {
                    resourceId: s.salleId.toString(),
                    title: (s.nomFete ? 'Fête : ' + s.nomFete : s.nomOrganisateur) + ' - (' + s.groupeNombre + ')', 
                    extendedProps: s,
                    start: moment(s.salleDebut).toISOString(),
                    end: moment(s.salleFin).toISOString(),
                    color: 'blue'
                }
            })
            // this.calendarApi.updateSize();
            this.calendarApi.render()
            this.ref.markForCheck();
        })

        this.r$.getTimeSlots$(this.info.date, this.info.duration).subscribe(t => {
            // Prenre la liste complète des salles
            this.roomList = t;
            let activeRooms = t.filter((s: SalleModel) => (s.estDisponible && s.visiblePublic))
            activeRooms.forEach((r: SalleModel) => {
                this.caloptions.events = [
                    ...(this.caloptions.events as any),
                    ..._.map(r.timeslots?.filter(ts => (ts.available)), (s) => {
                        return {
                            resourceId: s.salleId.toString(),
                            title: "LIBRE",
                            extendedProps: s,
                            start: moment(s.moment).toISOString(),
                            end: moment(s.moment).add(s.duration, 'minutes').toISOString(),
                            color: 'white',
                            borderColor: 'green',
                            textColor: 'green'
                        }
                    })
                ]
            });

            // this.calendarApi.updateSize();
            this.calendarApi.render()
            this.ref.markForCheck();
        })
    }

    ngOnInit() {
        let info = this.res$.getStorage();
        this.togCalendar = (info.utilSallesView === 'calendar')
        this.caloptions.initialView = info.modeCalendar ? info.modeCalendar : 'resourceTimeGridDay'
    }

    ngAfterViewInit(): void {
        this.calendarApi = this.calendarComponent.getApi();
        this.onDateChange(this.info.date)
        // this.calendarApi.updateSize();
        this.calendarApi.on('datesSet', () => {
            let info = this.res$.getStorage();
            info.modeCalendar = this.calendarApi.view.type
            this.res$.saveStorage(info);
        })
    }

    onCancel() {
        this.dialogRef.close(undefined)
    }

    onClose() {
        this.dialogRef.close(this._selectedItems)
    }

    onDateChange(event: any) {
        this.info.date = moment(event).hour(12).toDate()
        this.fetchData();
        this.calendarApi.gotoDate(moment(this.info.date).toDate())
        this.calendarApi.render()
    }

    onSelected(item: any) {
        if (!this.info.multiple) {
            this.dialogRef.close(item)
        } else {
            this._selectedItems = item;
        }
    }

    toggleCalendar() {
        this.togCalendar = !this.togCalendar;
        if (this.togCalendar) {
            setTimeout( () => {
                this.calendarApi.updateSize()
            }, 100)
        }

        // save to local storage
        let info = this.res$.getStorage();
        info.utilSallesView = (this.togCalendar ? 'calendar' : 'list')
        this.res$.saveStorage(info);
    }

}



export type RoomPickupInfo = {
    showDate: boolean,
    multiple: boolean,
    date: Date,
    duration: number,
    dialogTitle: string,
    rooms: any
}
