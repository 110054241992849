import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import * as _ from 'lodash';
import moment from 'moment';

import {
    trigger,
    state,
    style,
    animate,
    transition,
    // ...
  } from '@angular/animations';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GroupeService } from '@modules/groupe/services';
import { MessageBoxService } from '@common/services/messagebox.service';
import { APIparams, GroupeModel } from '@modules/groupe/models';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'd1-groupe-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './groupe-edit.component.html',
    styleUrls: ['groupe-edit.component.scss'],
    animations: [
        trigger('openClose', [
          // ...
          state('open', style({
            width: '350px'
          })),
          state('closed', style({
            width: '0px'
          })),
          transition('open => closed', [
            animate('0.25s')
          ]),
          transition('closed => open', [
            animate('0.25s')
          ]),
        ]),
    ]
})
export class GroupeEditComponent implements OnInit, OnChanges {
    @Input() groupe!: GroupeModel;
    @Output() groupeChange:EventEmitter<GroupeModel> = new EventEmitter();
    @Output() change:EventEmitter<GroupeModel> = new EventEmitter();

    myForm!: FormGroup;
    public searchFor = '';
    groupeCtrl = new FormControl()
    filteredSearchResults: Observable<any>;
    searchIcon = 'search';
    isOpen = false;
    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete!: MatAutocompleteTrigger;

    interactedWithSearch = false;

    sexeListe = [
        { text: 'Homme', value: 'Homme'},
        { text: 'Femme', value: 'Femme'},
    ];

    constructor(
        private ref: ChangeDetectorRef,
        private fb: FormBuilder,
        private o$:GroupeService,
        private mbox: MessageBoxService
    ) {

        if (!this.groupe) {
            this.groupe = new GroupeModel();
        } else {
            this.groupe = new GroupeModel(this.groupe)
        }

        this.myForm = this.fb.group({
            groupeId: this.groupe.groupeId,
            courriel: this.groupe.courriel,
            nomOrganisateur: this.groupe.nomOrganisateur,
            notes: this.groupe.notes,
            notesAdmin: this.groupe.notesAdmin,
            telephone: this.groupe.telephone
        });

        // this.filteredSearchResults = this.myForm.controls["nomOrganisateur"].valueChanges
        this.filteredSearchResults = this.groupeCtrl.valueChanges
            .pipe(
                filter(text => (text || '').length > 2),
                debounceTime(10),
                distinctUntilChanged(),
                map( () => []),
                switchMap(
                    r => {
                        // const value = this.myForm.controls["nomOrganisateur"].value;
                        const value = this.groupeCtrl.value;
                        // filter : `courriel=${this.groupeCtrl.value},telephone=${this.groupeCtrl.value},nomOrganisateur=${this.groupeCtrl.value}`,
                        const search: APIparams = {
                            fields : ['*'],
                            filter : `courriel=${value},telephone=${value},nomOrganisateur=${value}`,
                            skip : 0,
                            take : 30
                        }
                        return this.o$.findGroupes$(search);
                    }
                )
        );

        this.myForm.valueChanges.subscribe( v => {
            // On merge les données
            this.groupe = {...this.groupe, ...v}
            // this.groupeChange.emit(this.groupe);
            // this.change.emit(this.groupe);
            this.emitChanges();
        })
    }

    emitChanges() {
        // On merge les données
        // this.groupe = {...this.groupe, ...v}
        this.groupeChange.emit(this.groupe);
        this.change.emit(this.groupe);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.groupe) {
            const selGroupe = new GroupeModel(changes.groupe.currentValue as any);
            this.myForm.patchValue({
                groupeId: selGroupe.groupeId,
                courriel: selGroupe.courriel,
                nomOrganisateur: selGroupe.nomOrganisateur,
                notes: selGroupe.notes,
                notesAdmin: selGroupe.notesAdmin,
                telephone: selGroupe.telephone
            }, { emitEvent : false });

            this.groupe = changes.groupe.currentValue;
        }
    }
    public refresh() {
        this.ref.markForCheck();
    }

    displayFn(result: any): string {
        return result ? `Client : ${result.nomOrganisateur} | Tel : ${result.telephone} | Adresse : ${result.rue} ${result.nocivique} | Courriel : ${result.courriel}` : '';
    }

    selectedGroupe(event: any) {
        const selGroupe = event.option.value as any;
        if (this.groupe.groupeId) {
            this.mbox.open('Attention!', 'Cette réservation est déjà associée avec un client<br/>Vous vous apprêtez à le remplacer avec un autre groupe.\nÊtes-vous certain?', 'Oui', 'Non').subscribe(
                result => {
                    if (result === 'Oui') {
                        this.myForm.patchValue({
                            groupeId: selGroupe.groupeId,
                            courriel: selGroupe.courriel,
                            nomOrganisateur: selGroupe.nomOrganisateur,
                            notes: selGroupe.notes,
                            notesAdmin: selGroupe.notesAdmin,
                            telephone: selGroupe.telephone
                        }, { emitEvent : false });
                        this.ref.markForCheck();
                        this.toggleSearch();

                        // Mettre à jour
                        this.groupe = selGroupe;
                        this.emitChanges();
                    }
                }
            )
        } else {
            this.myForm.patchValue({
                groupeId: selGroupe.groupeId,
                courriel: selGroupe.courriel,
                nomOrganisateur: selGroupe.nomOrganisateur,
                notes: selGroupe.notes,
                notesAdmin: selGroupe.notesAdmin,
                telephone: selGroupe.telephone
            }, { emitEvent : false });
            this.ref.markForCheck();
            this.toggleSearch();

            // Mettre à jour
            this.groupe = selGroupe;
            this.emitChanges();
        }
    }

    ngOnInit() {
        // const selGroupe = this.groupe;
        // this.myForm.patchValue({
        //     groupeid: selGroupe.groupeId,
        // });
    }

    toggleSearch() {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            let el = document.getElementById('inputSearch');
            this.groupeCtrl.setValue('');
            if (el) {
                el.focus();
            }
        } else {
            // On était ouvert et on ferme
            if (this.autoComplete) {
                this.autoComplete.closePanel();
            }
        }
    }
}
