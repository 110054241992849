import moment from "moment";

export interface APIparams {
    skip : number | 0;
    take : number | 0;
    filter : string | '';
    fields : string[] | string | [];
}

export class GroupeModel {
    groupeId : number = 0;
    nomOrganisateur: string = '';
    createDate: Date | null = null;
    updateDate: Date | null = null;
    courriel: string = '';
    telephone: string = '';
    notes: string = '';
    notesAdmin: string = '';

    constructor(r? : GroupeModel) {
        if (r) {
            let groupe = this;
            groupe.groupeId = r.groupeId;
            groupe.nomOrganisateur = r.nomOrganisateur;
            groupe.createDate = (r.createDate ? moment(r.createDate).toDate() : moment().toDate());
            groupe.updateDate = (r.updateDate ? moment(r.updateDate).toDate() : moment().toDate());
            groupe.courriel = r.courriel;
            groupe.telephone = (r.telephone || '');
            groupe.notes = r.notes;
            groupe.notesAdmin = r.notesAdmin;
        }
    }
};
