<div class="container-fluid m-2 p-2">
    <div class="row">
        <div class="col-lg-8 col-xl-8">
            <sbpro-card
                ><div class="card-header">Réservation</div>
                <div class="card-body">
                    <d1-reservations-status class="d-block" [(statutGroupe)]="reservation.statutGroupe"></d1-reservations-status><d1-reservations-confirmation-status class="d-block" [(statutConfirmation)]="reservation.statutConfirmation"></d1-reservations-confirmation-status><d1-reservations-payment-status class="d-block" [statutPaiement]="reservation.statutPaiement" (change)="updateStatutPaiement($event)"></d1-reservations-payment-status>
                    <div class="d-flex flex-column mr-4"><d1-display-info label="Date réservée" [icon]='["fas", "calendar"]' [text]="reservation.reservationDate | date : 'dd MMM @ HH:mm'"></d1-display-info><d1-display-info label="# joueurs" [icon]='["fas", "running"]' [text]="reservation.nombreJoueurs"></d1-display-info><d1-display-info label="Notes marshall" [icon]='["fas", "id-card-alt"]' [hideIfEmpty]="false" [text]="reservation.notesMarshall"></d1-display-info><d1-display-info label="Responsable" [icon]='["fas", "id-card-alt"]' [hideIfEmpty]="false" [text]="reservation.responsable"></d1-display-info><d1-form-chip-list class="d-block" header="Tags" [selectedItems]="reservation.groupe_tags" (selectedItemsChange)="changeTags($event)"></d1-form-chip-list></div>
                    <div class="d-flex flex-column"><d1-display-info label="Fêté" [icon]='["fas", "birthday-cake"]' [text]="reservation.nomFete | titlecase"></d1-display-info><d1-display-info label="Salles" [icon]='["fas", "door-open"]' [list]="getSalles()"></d1-display-info></div>
                    <sbpro-alert *ngIf="reservation.notes" [icon]="true" classes="alert-info" heading="Notes"
                        ><div class="alert-icon-aside"><fa-icon [icon]='["far", "flag"]'></fa-icon></div>
                        {{ reservation.notes}}</sbpro-alert
                    >
                </div></sbpro-card
            ><sbpro-card
                ><div class="card-header">Parties</div>
                <div class="card-body"><d1-reservations-games [(games)]="reservation.parties" [date]="reservation.reservationDate" [groupSize]="reservation.nombreJoueurs"></d1-reservations-games></div
            ></sbpro-card>
        </div>
        <div class="col-lg-4 col-xl-4">
            <sbpro-card
                ><div class="card-header">Client</div>
                <div class="card-body">
                    <d1-display-info label="" [icon]='["fas", "child"]' [text]="reservation.nomOrganisateur | titlecase"></d1-display-info><d1-display-info label="" [icon]='["fas", "at"]' [hideIfEmpty]="false" [text]=""><a [href]="'mailto:' + reservation.courriel">{{reservation.courriel}}</a></d1-display-info
                    ><d1-display-info label="" [icon]='["fas", "phone"]' [hideIfEmpty]="false"><a [href]="reservation.telephone | phoneUri">{{reservation.telephone | phone}}</a></d1-display-info
                    ><mat-label>Évaluez ce client<ngb-rating class="text-warning" rate="3" max="5"></ngb-rating></mat-label></div></sbpro-card
            ><sbpro-card
                ><div class="card-header">Paiement</div>
                <div class="card-body">
                    <d1-display-info label="Dépôt" [hideIfEmpty]="false" [icon]='["fas", "dollar-sign"]'><fa-icon class="text-success" *ngIf="reservation.depotFait" [icon]="['fas', 'check']"></fa-icon><fa-icon class="text-danger" *ngIf="!reservation.depotFait" [icon]="['far', 'square']"></fa-icon></d1-display-info><d1-display-info label="Référence" [icon]='["fas", "dollar-sign"]' [text]="reservation.depotReference"></d1-display-info><d1-display-info label="Montant" [icon]='["fas", "dollar-sign"]' [text]="reservation.depotMontant | currency"></d1-display-info><d1-display-info label="Type" [text]="'Shopify'"><img class="img-thumbnail" width="48" heigth="48" src="https://cdn.shopify.com/shopifycloud/web/assets/v1/2217fb04df073033ccce8d125b0ea020.svg"/></d1-display-info></div
            ></sbpro-card>
        </div>
    </div>
</div>
<div class="container-fluid">
    <sbpro-card
        ><div class="card-header">Timeline</div>
        <div class="card-body"><d1-reservations-timeline [timeline]="reservation.timeline"></d1-reservations-timeline></div
    ></sbpro-card>
</div>
