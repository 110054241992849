import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AppSettingsService } from '@common/services';
import { ReservationModel } from '@modules/reservations/models';
import { SalleModel } from '@modules/rooms/models';
import _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'd1-reservations-print-birthday',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-print-birthday.component.html',
    styleUrls: ['reservations-print-birthday.component.scss'],
})
export class ReservationsPrintBirthdayComponent implements OnInit {
    public reservation:ReservationModel = new ReservationModel();
    public DateFete = '';
    public RoomName = '';
    public NomFete = '';
    public FeteHeuresDebut = '';
    public FeteHeuresFin = '';

    constructor(public appSettings:AppSettingsService, private route:ActivatedRoute) {
        if (route.snapshot.data.reservation) {
            this.reservation = route.snapshot.data.reservation;

            /*
                $scope.RoomName = r.salleNom;
                $scope.FeteHeuresDebut = moment(r.salleDebut).format('H:mm');
                $scope.FeteHeuresFin = moment(r.salleFin).format('H:mm');
                $scope.DateFete = moment(r.salleDebut).format('dddd D MMMM');
                $scope.NomFete = (r.nomFete ? r.nomFete : '');
            */

            if (this.reservation.salleDebut) {
                this.FeteHeuresDebut = moment(this.reservation.salleDebut).format('HH:mm');
                this.DateFete = moment(this.reservation.salleDebut).format('dddd D MMMM');
            }
            if (this.reservation.salleFin) this.FeteHeuresFin = moment(this.reservation.salleFin).format('HH:mm');
            if (this.reservation.salles && this.reservation.salles.length > 0) this.RoomName = _.map(this.reservation.salles, (s) => s.salleNom).join(',');
            if (this.reservation.nomFete) this.NomFete = this.reservation.nomFete;
        }
    }

    ngOnInit() {}

}
