import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-shopify-customer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-customer.component.html',
    styleUrls: ['shopify-customer.component.scss'],
})
export class ShopifyCustomerComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
