import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectionModel } from '@angular/cdk/collections'
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageBoxService } from '@common/services/messagebox.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';


export interface ListData {
    value: number | string;
    text: string;
}

export interface ColumnDefinitionsModel {
    name: string;
    headerText: string;
    visible: boolean;
    sortable: boolean;
    showAsPills?: boolean;
    associatedList?: ListData[];
    format?: (value: any) => void;
}

export interface FilterColumnDefinitionsModel {
    column: string;
    value: string;
}
export interface FilterDefinitionsModel {
    label: string;
    filters: FilterColumnDefinitionsModel[];
    active: boolean;
    filter?: (value: any, data: any) => [];
}

export interface ClickEventArg {
    event: string;
    rowData: Object;
}

export interface RowActionEventArg {
    event: string;
    rowData: Object;
}

export interface FilterEventArg {
    filter: FilterDefinitionsModel;
    data: [];
}

@Component({
    selector: 'd1-simple-grid',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './simple-grid.component.html',
    styleUrls: ['simple-grid.component.scss'],
})
export class SimpleGridComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    // @ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort!: CdkVirtualScrollViewport;

    @Input() data: any[] = [];
    @Input() width = '100%';
    @Input() showAction = true;
    @Input() showPager = true;
    @Input() disabled = false;
    @Input() allowAdd = true;
    @Input() allowEdit = true;
    @Input() allowDelete = true;
    @Input() showFilter = true;
    @Input() multiSelection = false;
    @Input() deleteMessage = undefined;
    @Input() pageSize = 25;
    @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
    @Input('columnDefinitions') columnDefs: ColumnDefinitionsModel[] = [];
    @Input() displayedColumns: string[] = [];
    @Input() quickFilters: FilterDefinitionsModel[] = [];

    // Output
    @Output() rowclick = new EventEmitter<ClickEventArg>();
    @Output() rowDoubleclick = new EventEmitter<ClickEventArg>();
    @Output() onEdit = new EventEmitter<RowActionEventArg>();
    @Output() onDelete = new EventEmitter<RowActionEventArg>();
    @Output() onAdd = new EventEmitter<RowActionEventArg>();
    @Output() onFiltered = new EventEmitter<FilterEventArg>();

    // Stuff pour les sélections
    selection = new SelectionModel<any[]>(true, []);
    @Input() selectOnRowClick = this.multiSelection;
    @Input() selected:any[] = []
    @Output() selectedChange = new EventEmitter<any[]>();
    @Output() onSelection = new EventEmitter<RowActionEventArg>();
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));

        this.selected = _.clone(this.selection.selected)
        this.selectedChange.emit(this.selected);
    }
    onSelectionChange(event: any, element: any) {
        event ? this.selection.toggle(element) : null
        const selectEvent: RowActionEventArg = {
            event: event,
            rowData: element
        }
        this.onSelection.emit(selectEvent);
        this.selected = this.selection.selected.splice(0)
        this.selectedChange.emit(this.selected);
    }

    // ITEM_SIZE = 48;
    // offset: number = 0;
    dataSource!: MatTableDataSource<any>;

    applyQuickFilter(filter: FilterDefinitionsModel) {
        // Reset active
        this.quickFilters.forEach(f => (f.active = false));
        filter.active = true;

        // Faire le filtre à l'interne
        if (filter.filter === undefined) {
            if (filter.filters.length > 0) {
                this.dataSource.data = this.data.filter(r => {
                    let bOk = true;
                    filter.filters.forEach((f: FilterColumnDefinitionsModel) => {
                        if (r[f.column] !== undefined) {
                            switch (typeof r[f.column]) {
                                case 'number':
                                    bOk = bOk && r[f.column] == f.value;
                                    break;

                                case 'string':
                                    bOk =
                                        bOk &&
                                        r[f.column]
                                            .trim()
                                            .toLowerCase()
                                            .search(f.value.trim().toLowerCase()) >= 0;
                                    break;

                                default:
                                    break;
                            }
                        }
                    });

                    return bOk;
                });
                this.onFiltered.emit({ filter, data: this.dataSource.data } as FilterEventArg);
            } else {
                // Pas de fltres... on montre tout
                this.dataSource.data = this.data;
                this.onFiltered.emit({ filter, data: this.dataSource.data } as FilterEventArg);
            }
        } else {
            // Emettre l'event, le code externe s'occupe du data
            const ret = filter.filter(filter, this.data);
            this.dataSource.data = ret;
            this.onFiltered.emit({ filter, data: this.dataSource.data } as FilterEventArg);
        }
    }

    constructor(private mbox:MessageBoxService, private ref: ChangeDetectorRef) {
        if (this.quickFilters == undefined) {
            this.quickFilters = [];
        }
        if (this.data == undefined) {
            this.data = [];
        }
    }

    ngAfterViewInit() {
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        if (this.paginator) {
            this.dataSource = new MatTableDataSource(this.data);
            this.dataSource.sort = this.sort;

            // console.log('#1 data lenght', this.dataSource.data.length, this.dataSource.paginator);
            this.dataSource.paginator = this.paginator;
            this.paginator._intl.firstPageLabel = 'Première page';
            this.paginator._intl.itemsPerPageLabel = 'Items par page';
            this.paginator._intl.lastPageLabel = 'Dernière page';
            this.paginator._intl.nextPageLabel = 'Prochaine page';
            this.paginator._intl.previousPageLabel = 'Page précédente';
            this.paginator._intl.getRangeLabel = (
                page: number,
                pageSize: number,
                length: number
            ) => {
                return `Page #${page + 1} - ${pageSize} sur ${length}`;
            };
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            if (changes.data.currentValue !== undefined) {
                this.data = changes.data.currentValue;
                this.dataSource = new MatTableDataSource(this.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                // console.log('#2 data lenght', this.dataSource.data.length, this.dataSource.paginator?.length);
                this.ref.markForCheck();
            } else {
                this.data = [];
            }
        }
    }

    ngOnInit() {
        if (this.showAction === true && this.columnDefs) {
            // On doit rajouter la colonne des actions
            if (this.columnDefs.length > 0 && this.columnDefs[(this.columnDefs.length-1)].name !== '_action_') {
                this.columnDefs.push({
                    headerText: 'Action',
                    visible: true,
                    name: '_action_',
                    sortable: false,
                });
            }
        }

        if (this.multiSelection === true && this.columnDefs && this.columnDefs.length > 0 && this.columnDefs[0].name !== '_select_') {
            // On doit rajouter la colonne de sélection
            this.columnDefs.unshift({
                headerText: 'Selection',
                visible: true,
                name: '_select_',
                sortable: false,
            });
        }
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        if (this.columnDefs == undefined || this.columnDefs.length == 0) {
            // Auto générer les colonnes
            const autoDisplay = this.displayedColumns.length > 0 ? false : true;
            _.each(this.data[0], (f, k: any) => {
                this.columnDefs.push({
                    headerText: k,
                    name: k,
                    visible: autoDisplay ? true : this.displayedColumns[k] ? false : true,
                    sortable: true,
                });
                if (autoDisplay) this.displayedColumns.push(k);
            });

            // console.log('this.columnDefs', this.columnDefs);
            // console.log('this.displayedColumns', this.displayedColumns);
        } else if (this.columnDefs.length && this.displayedColumns.length == 0) {
            // Composer la liste des colonnes à afficher selon les columnsDefs
            _.each(this.columnDefs, (cd: ColumnDefinitionsModel) => {
                if (cd.visible) {
                    this.displayedColumns.push(cd.name);
                }
            });
        }
        // this.viewPort.scrolledIndexChange
        //     .pipe(
        //         tap( v => {
        //             console.log('tapping scrolledIndexChange', v);
        //         } ),
        //         distinctUntilChanged(),
        //     )
        //     .subscribe(offset => (this.offset = offset));

        // this.viewPort.renderedRangeStream.subscribe(range => {
        //         this.offset = range.start * -this.ITEM_SIZE;
        //     });
    }

    RenderElement(element: any, cd: ColumnDefinitionsModel) {
        if (cd.associatedList) {
            return _.find(cd.associatedList, { value: element })?.text;
        } else {
            if (cd.format) {
                return cd.format(element);
            } else {
                return element;
            }
        }
    }

    getSortable() {
        if (this.columnDefs) {
            return this.columnDefs.filter(i => i.sortable === true);
        } else {
            return [];
        }
    }

    getNotSortable() {
        if (this.columnDefs) {
            return this.columnDefs.filter(i => i.sortable === false && i.name !== '_select_');
        } else {
            return [];
        }
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    onClick(row: any, event: any) {
        if (!event.defaultPrevented) {
            event.preventDefault();
            this.rowclick.emit({ event: 'click', rowData: row });
            if (this.selectOnRowClick && this.multiSelection) {
                this.selection.toggle(row);
                this.selected = this.selection.selected.splice(0)
                this.selectedChange.emit(this.selected);
            }
        }
    }

    onAddClick() {
        this.onAdd.emit();
    }

    refresh() {
        this.ref.markForCheck();
    }

    action(row: any, type: string, event: any) {
        if (type === 'edit') {
            this.onEdit.emit({ event: type, rowData: row });
            event.preventDefault();
        } else if (type === 'delete') {
            this.mbox.ConfirmDelete().subscribe(
                result => {
                    if (result === 'Effacer') {
                        this.onDelete.emit({ event: type, rowData: row } as ClickEventArg);
                    }
                }
            );

            event.preventDefault();
        } else {
            console.log('action discarded', type);
        }
    }
}
