import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourceModel } from '../models';
import { ResourcesService } from './resources.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceResolve implements Resolve<ResourceModel> {

  constructor(private http: HttpClient, private r$: ResourcesService ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ResourceModel | Observable<ResourceModel> | Promise<ResourceModel> {
    if (route.params.id === undefined) {
      const newResource: ResourceModel = {
        ressourceId : 0,
        ressourceName: '',
        ressourceTypeId : 0,
        ressourceTypeName : '',
        ressourceSKU: '',
        ressourceTags: '',
        ressourceImage: '',
        ressourceTypeImage: ''
      }
      return newResource;
    } else {
      return this.r$.getResource$(route.params.id);
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class ResourcesResolve implements Resolve<ResourceModel[]> {

  constructor(private http: HttpClient, private r$: ResourcesService ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ResourceModel[] | Observable<ResourceModel[]> | Promise<ResourceModel[]> {
      return this.r$.getResources$();
  }
}
