import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from '@common/services/config.service';
import { AppMessageService } from '@common/services/messages.service';
import { UserService } from '@modules/auth/services';
import { GameModel } from '@modules/schedule/models/game.model';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ReservationsAddWalkinComponent } from '../components';
import { ReservationModel, ReservationStorage, SalleInfo } from '../models';

const STORAGE_KEY= 'reservations'

@Injectable()
export class ReservationsService implements Resolve<{}>  {
    private apiUrl = '/api/reservations'; // URL to web api
    private _selectedDate: moment.Moment= moment();
    private _listeSalles = [];
    private _loaded = false;
    public SelectedDate: BehaviorSubject<moment.Moment> = new BehaviorSubject(this._selectedDate);
    private _listeReservations:ReservationModel[] = [];
    public ReservationsListe: BehaviorSubject<ReservationModel[]> = new BehaviorSubject(this._listeReservations);

    private reservationStorage:ReservationStorage = new ReservationStorage();

    constructor(private http: HttpClient, private c$: ConfigService, private msg$:AppMessageService, private u$:UserService, private dialog$: MatDialog) {
        // this.SelectedDate.subscribe( (date) => {
        //     console.log("ReservationsService() Constructor, date change", date)
        //     // this._selectedDate = date;
        //     // return this.getReservations$(date);
        // })

        this.c$.getTypeSalles(true).subscribe( (s) => {
            this._listeSalles = s;
        })

        // Subscribe sur la date pour voir si quelqu'un la modifie
        this.SelectedDate.pipe(distinctUntilChanged(),debounceTime(200)).subscribe( (newDate:moment.Moment) => {
            if ((newDate.format("YYYYMMDD") !== this._selectedDate.format("YYYYMMDD")) || !this._loaded) {
                this._selectedDate = newDate;
                this.getReservations$(newDate).subscribe( (liste: ReservationModel[]) => {
                    // Changer notre liste interne
                    this._listeReservations = liste;
                    this._loaded = true;
                    this.ReservationsListe.next(this._listeReservations);
                });
            }
        });

        // On va écouter les messages des réservations pour voir s'il y a des changements
        // On se calme le ponpon sur les messages trop rapides,, debounce de 500 ms;
        // TODO : Trouver pourquoi les messages sont multipliés!
        this.msg$.ReservationMessage.pipe(distinctUntilChanged(),debounceTime(200)).subscribe( r => {
            if (moment(r.partiesDate).format("YYYY-MM-DD") === this._selectedDate.format("YYYY-MM-DD")) {
                // On va travailler fort pour ne pas avoir à relire le data "live".
                const found = _.findIndex(this._listeReservations, {groupeId: r.groupeId})
                if (found !== -1) {
                    // La réservation existe dans notre liste, on va juste remplacer l'item
                    this.getReservations$(undefined, r.groupeId).subscribe( booking => {
                        this._listeReservations[found] = {...booking};
                        this.ReservationsListe.next(this._listeReservations);
                    })

                } else {
                    this.getReservations$(this._selectedDate).subscribe( (liste: ReservationModel[]) => {
                        // Changer notre liste interne
                        this._listeReservations = liste;
                        this._loaded = true;
                        this.ReservationsListe.next(this._listeReservations);
                    });
                }
            }
        })
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): {} | Observable<{}> | Promise<{}> {
        if (route.paramMap.get('id')) {
            return this.getReservations$(undefined, route.paramMap.get('id'));
        } else {
            return this.getReservations$();
        }
    }

    getReservations$(state?: any, id?: number | string | null): Observable<ReservationModel[] | any> {
        const params:any[] = [];
        let paramsQuery = '';

        if (state && state instanceof Object) {
            // { skip: 0, take: 30 }
            if (state.skip || state.take || state.filter) {
                if (state.skip) params.push(`skip=${state.skip}`);
                if (state.take) params.push(`take=${state.take}`);
                if (state.filter) params.push(`filter=${state.filter}`);

                paramsQuery = '?' + params.join('&');
            }
            if (moment.isMoment(state) || moment.isDate(state)) {
                paramsQuery = '?date=' + moment(state).format("YYYY-MM-DD")
            } else {
            }
        }

        if (id) {
            // return this.http.get<ReservationModel>(this.apiUrl + `/${id}?fetchAll=1`).pipe(
            return this.get(+id, true).pipe(
                map((response: ReservationModel) => {
                    return response;
                })
            );
        } else {
            // paramsQuery = '?date=2020-09-06'
            paramsQuery += (paramsQuery.length ? '&' : '?') + 'fetchAll=1'
            return this.http.get<ReservationModel[]>(this.apiUrl + '/list' + paramsQuery).pipe(
                map((response: ReservationModel[]) => {
                    return response;
                })
            );
        }
    }

    // Aller chercher une réservation
    get(id: number, fetchAll: boolean): Observable<ReservationModel> {
        return this.http.get<ReservationModel>(this.apiUrl + `/${id}`, {params: { fetchAll: (fetchAll ? 1 : 0)}});
    }

    lockReservation$(reservation:ReservationModel): Observable<any> {
        return (this.u$.user$
            .pipe(
                switchMap( (user) => {
                    if (reservation.groupeId && user && reservation.userLockToken.length == 0) {
                        return (this.http.post(this.apiUrl + '/' + reservation.groupeId + '/lock/' + this.u$.user?.auth_token, {})
                            .pipe(
                                map( (response) => {
                                    reservation.userLockTime = new Date();
                                    reservation.userLockToken = ( user ? user.auth_token : '');
                                    // reservation.socket.emit('RafraichirReservations', r);
                                    this.msg$.sendMessageReservation(reservation);
                                    return reservation;
                                } )
                            )
                        );
                    } else if (reservation.groupeId > 0) {
                        return of(false);
                    } else {
                        reservation.userLockTime = new Date();
                        reservation.userLockToken = ( user ? user.auth_token : '');
                        return of(reservation);
                    }
                })
            )
        )
    }


    getCedules$(params?: any): any {
        // paramsQuery = '?date=2020-09-06'
        const day = (params && params.day ? moment(params.day).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') )
        // const partieType = (params && params.partieType ? params.partieType : 1);
        // const arenaId = (params && params.arenaId ? params.arenaId : 1);
        return this.http.get<any>(`/api/cedules/disponibilites/${day}/json`);
    }


    /** POST: add a new record  to the server */
    addRecord(reservations: ReservationModel): Observable<ReservationModel> {
        // you can apply empty string instead of state.data to get failure(error)
        return this.http.post<ReservationModel>(`${this.apiUrl}/ajouter`, reservations).pipe( tap(r => this.msg$.sendMessageReservation(r)));
    }

    /** DELETE: delete the record from the server */
    deleteRecord(reservations: ReservationModel): Observable<ReservationModel> {
        const id = reservations.groupeId;
        const url = `${this.apiUrl}/${id}`;

        return this.http.delete<ReservationModel>(url).pipe( tap(r => this.msg$.sendMessageReservation(r)));
    }

    /** PUT: update the record on the server */
    updateRecord(reservations: ReservationModel): Observable<ReservationModel> {
        return this.http.put<ReservationModel>(this.apiUrl, reservations).pipe( tap(r => this.msg$.sendMessageReservation(r)));
    }

    /** PUT: update the record on the server */
    patchRecord(groupeId: number, reservations: Partial<ReservationModel>): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}/${groupeId}`, reservations).pipe( tap(r => this.msg$.sendMessageReservation(r)));
    }

    /** PUT: update the record on the server */
    patchGameRecord(groupeId: number, partieId: number, game: Partial<GameModel>): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}/${groupeId}/partie/${partieId}`, game);
    }

    /** PUT: update the record on the server */
    updateStatut(groupeId:number, statut:number): Subscription {
        return this.http.post(`${this.apiUrl}/${groupeId}/statutgroupe/${statut}`, {}).subscribe ( (r:any) => {
            return r;
        });
    }

    /** PUT: update the record on the server */
    updateStatutPaiement(groupeId:number, statut:number): Subscription {
        return this.http.post(`${this.apiUrl}/${groupeId}/statutpaiement/${statut}`, {}).subscribe ( (r:any) => {
            return r;
        });
    }

    /** PUT: update the record on the server */
    updateStatutConfirmation(groupeId:number, statut:number): Subscription {
        return this.http.post(`${this.apiUrl}/${groupeId}/statutconfirmation/${statut}`, {}).subscribe ( (r:any) => {
            return r;
        });
    }

    /** PUT: update the record on the server */
    updateTags(reservations: ReservationModel): Subscription {
        return this.http.put(`${this.apiUrl}/tags`, reservations).subscribe ( (r:any) => {
            return r;
        });
    }


    /**
     * Fonction pour extraire l'événement la plus tardive
     */
     getLastEvent(r:ReservationModel): moment.Moment {
        let lastOne: moment.Moment = moment(r.reservationDate);

        // La fin de la salle
        lastOne = (r.salleFin && lastOne.isBefore(r.salleFin) ? moment(r.salleFin) : lastOne);

        // Les parties
        if (r.parties && r.parties.length>0) {
            const lstDate = _.map(r.parties, (p) => moment(p.partieTemps));
            _.each(lstDate, (d) => {
                lastOne = ( d.isAfter(lastOne) ? d : lastOne);
            })
        }

        return lastOne
    }

    getCodeBarre(r:ReservationModel, partie:GameModel) {
        return r.groupeId.toString() + '-' + partie.partieId.toString();
    }


    /**
     * STORAGE FUNCTIONS
     */
    saveStorage(info:ReservationStorage) {
        this.reservationStorage = info;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.reservationStorage))
    }

    getStorage():ReservationStorage {
        const info = localStorage.getItem(STORAGE_KEY);
        if (info) {
            this.reservationStorage = JSON.parse(info);
        }
        return this.reservationStorage;
    }

    showWalkin() {
        const dialogRef = this.dialog$.open(ReservationsAddWalkinComponent, { data: '', maxWidth: 640 });
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    updateSalle(groupeId: number, salleInfo: SalleInfo): Observable<any> {
        return this.http.put(`${this.apiUrl}/${groupeId}/salle`, salleInfo).pipe(
            tap( () => {
                let details = "Salle réservée : " + salleInfo.salleId + " de " + moment(salleInfo.salleDebut).format("DD-MM-YYYY HH:mm") + " à " + moment(salleInfo.salleFin).format("DD-MM-YYYY HH:mm")
                this.ajoutHistorique(groupeId, 'Modification', details)
            })
        );
    }

    ajoutHistorique(groupeId: number, action: string, detail: string): Subscription {
        var histo = {
            groupeId: groupeId,
            user: {},
            historiqueDate: moment().toDate(),
            action: action,
            detail: detail
        };
        return this.http.post(`/api/historique`, histo).subscribe(r => {
            return r
        })
    }

    getStatsTypeGroupeParJour(debut: Date, fin: Date): Observable<any> {
        return this.http.get(`/api/rapports/typegroupejournee/${debut.toISOString()}/${fin.toISOString()}`);
    }

    getStatistiques(debut: Date, fin: Date, type: 'jour' | 'weekday' = 'jour'): Observable<any> {
        return this.http.get(`/api/rapports/statistiques`, { params: { type: type, debut: debut.toISOString(), fin : fin.toISOString()}}).pipe(
            map( (d: any) => {
                // On manipule pour mettre le dimanche à la fin au lieu du début
                if (type === 'weekday' && d[0].Journee === 'Sunday') {
                    const sunday = d[0];
                    d = d.splice(1);
                    d.push(sunday);
                }
                return d;
            } )
        );
    }


}

@Injectable()
export class ReservationsAllResolve implements Resolve<{}>  {
    constructor(private r$: ReservationsService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): {} | Observable<{}> | Promise<{}> {
        return this.r$.getReservations$(moment(route.queryParams.date));
    }

}
