import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { User } from '../models';

const userSubject: ReplaySubject<User> = new ReplaySubject(1);

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor() {
        this.user = undefined;
    }

    set user(user: User | undefined) {
        if (user) {
            userSubject.next(user);
        }
    }

    get user$(): Observable<User> {
        return userSubject.asObservable();
    }
}
