import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ReservationsService } from '@modules/reservations/services';
import { ScheduleService } from '@modules/schedule/services';
import { ChartConfiguration } from 'chart.js';
import _ from 'lodash';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import { AppSettingsService } from '@common/services';

@Component({
    selector: 'd1-reservations-charts',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-charts.component.html',
    styleUrls: ['reservations-charts.component.scss'],
})
export class ReservationsChartsComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective | undefined;
    @Input() height: string | undefined = undefined;
    @Input() width: string | undefined = '100%';
    @Input() date!: Date;
    @Input() viewComptact: boolean = false;
    chart2!: any;
    data: any[] = [];
    arenas: any[] = [];
    // labels: string[] = [];
    
    public barChartData: ChartConfiguration<'bar' | 'line'>['data'] = {
        labels: [],
        datasets: [],
    };
    public barChartOptions: ChartConfiguration<'bar'>['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                fill: false,
            },
            point: {
                radius: 0,
                hitRadius: 0
            }
        },
        scales: {
            x:  {
                axis: 'x', 
                type: 'time', 
                display: !this.viewComptact,
                time: {
                    unit: 'minute', 
                    tooltipFormat: 'HH:mm'
                }, 
                offset: true,
                ticks: {
                    display: (!this.viewComptact),
                    source: 'data',
                    callback(tickValue, index, ticks) {
                        return moment(tickValue).format('HH:mm')
                    },
                },
            },
        },
        plugins: {
            legend: {
              display: (!this.viewComptact),
              position: 'bottom',
            },
            tooltip: {
                titleMarginBottom: 10,
                titleColor: '#6e707e',
                titleFont : { size: 14 },
                backgroundColor: 'rgb(255,255,255)',
                bodyColor: '#858796',
                borderColor: '#dddfeb',
                borderWidth: 1,
                padding: {x: 15, y : 15},
                displayColors: false,
                caretPadding: 10,
                callbacks: {
                    label(tooltipItem) {
                        let datasetLabel = tooltipItem.dataset.label;
                        return (
                            datasetLabel + ': ' + tooltipItem.formattedValue
                        );
                    },
                },
            },
            datalabels : {
                align : 'end',
                anchor: 'end'
            }
        },
    };
    public barChartPlugins = [
        DataLabelsPlugin
    ];

    constructor(private r$:ReservationsService, private s$:ScheduleService, private ref: ChangeDetectorRef, private appSettings$:AppSettingsService) {
        if (this.date == undefined) {
            this.date = new Date();
        }

        this.appSettings$.Arenas.subscribe( a => {
            this.arenas = a;
        })
        // this.fetchData()

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date && changes.date.currentValue && (changes.date.currentValue !== changes.date.previousValue)) {
            // this.fetchData()
            // Trigger change date
            this.s$.SelectedDate.next(moment(changes.date.currentValue));
        }
    }

    fetchData() {
        const params = {
            day : this.date
        }
        this.r$.getCedules$(params).subscribe( (r: any) => {
            // this.data = data;
            // this.data = _.map(r, 'nbJoueurs');
            // this.labels = _.map(r, 'journeeTemps');
            // this.barChartData.labels = r.map( (v:any) => moment(v.journeeTemps).format('HH:mm') );
            this.barChartData.labels = _.map(r, 'journeeTemps');
            // console.log("getCedules data, labels", this.data, this.labels);
            this.data = r.map( (v:any) => {
                return {x: v.journeeTemps, y: v.nbJoueurs, label: v.nbJoueurs}
            });
            this.buildChart();
        });
       

    }

    
    ngOnInit() {
        
        // On va écouter pour des changements dans la cédule pour la journée sélectionnée
        this.s$.CeduleListe.pipe(distinctUntilChanged(), debounceTime(200)).subscribe( () => {
            this.fetchData()
        })

    }
    
    buildChart() {
        if (this.barChartOptions && this.barChartOptions.plugins && this.barChartOptions.plugins.legend) {
            this.barChartOptions.plugins.legend.display = !this.viewComptact
        }

        if (this.barChartOptions && this.barChartOptions.scales && this.barChartOptions.scales.x) {
            this.barChartOptions.scales.x.display = !this.viewComptact;
        }
        this.barChartData.datasets = [
            {
                fill: false,
                label: 'Nombre de joueurs',
                backgroundColor: _.times(this.data.length, (i) => (this.data[i].y > this.arenas[0].nombreVestesActives ? 'rgba(232, 21, 0, 0.75)' : 'rgba(0, 97, 242, 0.5)')),
                hoverBackgroundColor: _.times(this.data.length, (i) => (this.data[i].y > this.arenas[0].nombreVestesActives ? 'rgba(232, 21, 0, 1)' : 'rgba(0, 97, 242, 1)')),
                data: this.data,
                borderWidth : 0,
                datalabels: {
                    display: !this.viewComptact
                }
            },
            {
                fill: false,
                type : 'line',
                label: 'Limite de vestes',
                backgroundColor: '#e81500',
                borderColor: '#e81500',
                data: _.times(this.data.length, () => this.arenas[0].nombreVestesActives),
                pointRadius: 0,
                datalabels: {
                    display: false
                }
            },
        ];

        this.chart?.render();
        this.ref.markForCheck();
    }

    buildChart_OLD() {
        // console.log("this.labels, this.data", this.labels, this.data);
        if (this.chart2) this.chart2.destroy(); //destroy prev chart instance
        // this.chart2 = new this.chartsService.Chart(this.myBarChart.nativeElement, {
        const temp = {
            type: 'bar',
            data: {
                // labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                labels: [],
                datasets: [
                    {
                        fill: false,
                        label: 'Nombre de joueurs',
                        // backgroundColor: 'rgba(0, 97, 242, 1)',
                        backgroundColor: _.times(this.data.length, (i) => (this.data[i] > 30 ? 'rgba(232, 21, 0, 0.75)' : 'rgba(0, 97, 242, 0.5)')),
                        hoverBackgroundColor: _.times(this.data.length, (i) => (this.data[i] > 30 ? 'rgba(232, 21, 0, 1)' : 'rgba(0, 97, 242, 1)')),
                        // borderColor: '#4e73df',
                        // data: [11, 12, 3, 18, 16, 3, 12, 14, 7, 12, 3, 5, 33, 12, 4,11, 12, 3, 18, 16, 3, 12, 14, 7, 12, 3, 5, 33, 12, 4,11, 12, 3, 18, 16, 3, 12, 14, 7, 12, 3, 5, 33, 12, 4,11, 12, 3, 18, 16, 3, 12, 14, 7, 12, 3, 5, 33, 12, 4,1,2,3,4,5,6,7,8,9,10],
                        data: this.data,
                        // maxBarThickness: 25,
                        borderWidth : 0,
                    },
                    {
                        fill: false,
                        type : 'line',
                        label: 'Limite de vestes',
                        backgroundColor: '#e81500',
                        borderColor: '#e81500',
                        data: _.times(this.data.length, () => 30),
                        //data: this.data,
                        // maxBarThickness: 25,
                        // borderWidth : 10
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                elements: {
                    line: {
                        fill: false
                    },
                    point : { 
                        radius : 0,
                        hitRadius : 0
                    }
                },
                layout: {
                    // padding: {
                    //     left: 10,
                    //     right: 25,
                    //     top: 25,
                    //     bottom: 0,
                    // },
                },
                // scales: {
                //     xAxes: [
                //         {
                //             type: 'time',
                //             distribution: 'series',
                //             time: {
                //                 unit: 'minute',
                //                 tooltipFormat:'HH:mm',
                //             },
                //             gridLines: {
                //                 display: true,
                //                 drawBorder: false,
                //             },
                //             ticks : {
                //                 beginAtZero : false,
                //                 callback: (value: any, index: string | number, ticks: { [x: string]: { value: moment.MomentInput; }; }) => {
                //                     return (this.viewComptact ? moment(ticks[index].value).format('HH:mm') : value);
                //                 }
                //             }, 
                //             offset : true
                //         },
                //     ],
                //     yAxes: [
                //         {
                //             ticks: {
                //                 // max: 40,
                //                 // maxTicksLimit: 5,
                //                 // min : 0,
                //                 // padding: 10,
                //                 beginAtZero : false,
                //             },
                //             gridLines: {
                //                 color: 'rgb(234, 236, 244)',
                //                 zeroLineColor: 'rgb(234, 236, 244)',
                //                 drawBorder: false,
                //                 borderDash: [2],
                //                 zeroLineBorderDash: [2]
                //             },
                //             offset : false
                //         },
                //     ],
                // },
                // legend: {
                //     display: (!this.viewComptact),
                //     position : 'bottom',
                // },
                // tooltips: {
                //     titleMarginBottom: 10,
                //     titleFontColor: '#6e707e',
                //     titleFontSize: 14,
                //     backgroundColor: 'rgb(255,255,255)',
                //     bodyFontColor: '#858796',
                //     borderColor: '#dddfeb',
                //     borderWidth: 1,
                //     xPadding: 15,
                //     yPadding: 15,
                //     displayColors: false,
                //     caretPadding: 10,
                //     callbacks: {
                //         label: (tooltipItem: { datasetIndex: number; yLabel: string; }, chart: { datasets: { label: string; }[]; }) => {
                //             let datasetLabel = '';
                //             if (chart && chart.datasets) {
                //                 datasetLabel = chart.datasets[tooltipItem.datasetIndex as number].label as string;
                //             }
                //             return (
                //                 datasetLabel + ': ' + tooltipItem.yLabel
                //             );
                //         },
                //     },
                // },
            },
        };
    }

    ngAfterViewInit() {
        
    }
}
