import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CopyToClipboard, CopyToClipboardOptions } from '@common/models';
import copyToClipboard from 'copy-to-clipboard';
import { Observable, of } from 'rxjs';
import curPackage from '../../../../package.json'
import { AppNotificationService } from './appnotification.service';

@Injectable()
export class UtilityService {
    _window: Window;
    parse: JSON['parse'];
    stringify: JSON['stringify'];
    localStorage: Storage;
    _copyToClipboard: CopyToClipboard;

    constructor(private http: HttpClient, private notify$:AppNotificationService) {
        this._window = window;
        this.parse = JSON.parse;
        this.stringify = JSON.stringify;
        this.localStorage = localStorage;
        this._copyToClipboard = copyToClipboard;
    }

    get window(): Window {
        return this._window;
    }
    get version$(): Observable<string> {
        return of(curPackage.version)
    }

    getStoredObject<T>(objectName: string): T | undefined {
        const objectString = this.localStorage.getItem(objectName);
        if (!objectString) {
            return undefined;
        }
        return this.parse(objectString) as T;
    }

    storeObject(objectName: string, objectToStore: {}): void {
        this.localStorage.setItem(objectName, this.stringify(objectToStore));
    }

    copyToClipboard(text: string, options?: CopyToClipboardOptions) {
        this._copyToClipboard(text, options);
        this.notify$.showMessage('Texte copié');
    }

    sendEmail(email: any): Observable<any> {
        const data = {
            to : email.courriel,
            to_name : email.nom,
            contenu : email.message,
            sujet : email.sujet
        }
        return this.http.post('/api/courriel/envoyer', data);
    }

}
