import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APIparams, GroupeModel } from '../models';

@Injectable()
export class GroupeService {
    private apiUrl = '/api/groupes'; // URL to web api

    constructor(private http: HttpClient) {}

    getList$(): Observable<GroupeModel[]> {
        return this.http.get<GroupeModel[]>(this.apiUrl)
    }

    findGroupes$(state?:APIparams): Observable<GroupeModel[]> {
        const params:any[] = [];
        let paramsQuery = '';
        if (state && state instanceof Object) {
            if (state.skip) params.push(`skip=${state.skip}`);
            if (state.take) params.push(`take=${state.take}`);
            if (state.filter) params.push(`filter=${state.filter}`);
            if (state.fields) params.push(`fields=${(Array.isArray(state.fields) ? state.fields.join(',') : state.fields ) }`);

            if (params.length) paramsQuery = '?' + params.join('&');
        }
        return this.http.get<GroupeModel[]>(this.apiUrl + `/${paramsQuery}`)
    }

    get(): Observable<{}> {
        return of({});
    }

    update(): Observable<{}> {
        return of({});
    }

    create(): Observable<{}> {
        return of({});
    }

    delete(): Observable<{}> {
        return of({});
    }
}
