import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-rentals-main',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-main.component.html',
    styleUrls: ['rentals-main.component.scss'],
})
export class RentalsMainComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
