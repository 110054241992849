import { RoomsMainComponent } from './rooms-main/rooms-main.component';
import { RoomsOverviewComponent } from './rooms-overview/rooms-overview.component';
import { RoomsEditComponent } from './rooms-edit/rooms-edit.component';
import { RoomsSchedulesComponent } from './rooms-schedules/rooms-schedules.component';
import { RoomsDialogPickComponent } from './rooms-dialog-pick/rooms-dialog-pick.component';

export const containers = [RoomsMainComponent, RoomsOverviewComponent, RoomsEditComponent, RoomsSchedulesComponent, RoomsDialogPickComponent];

export * from './rooms-main/rooms-main.component';
export * from './rooms-overview/rooms-overview.component';
export * from './rooms-edit/rooms-edit.component';
export * from './rooms-schedules/rooms-schedules.component';
export * from './rooms-dialog-pick/rooms-dialog-pick.component';
