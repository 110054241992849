import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceModel } from '@modules/rentals/models';
import { ResourcesService } from '@modules/rentals/services';

@Component({
    selector: 'd1-rentals-resources-detail-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-resources-detail-view.component.html',
    styleUrls: ['rentals-resources-detail-view.component.scss'],
})
export class RentalsResourcesDetailViewComponent implements OnInit {
    resource!:ResourceModel
    constructor(
        private r$: ResourcesService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
    ) {
        if (this.nav.snapshot.data.resource) {
            this.resource = this.nav.snapshot.data.resource;
        } else {
            var id = parseInt(this.nav.snapshot.params.id)
            if (id.toString() === 'NaN') {
                this.resource = {
                    ressourceId : 0,
                    ressourceName: '',
                    ressourceTypeId : 0,
                    ressourceTypeName : '',
                    ressourceSKU: '',
                    ressourceImage: '',
                    ressourceTypeImage: ''
                } as ResourceModel

            } else {
                this.r$.getResource$(id).subscribe((r) => {
                    this.resource = r as ResourceModel;
                    this.ref.markForCheck();
                });
            }
        }
    }

    ngOnInit() {}
}
