import { ChangeDetectorRef } from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Slot } from '@modules/rentals/models';
import * as _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'd1-rentals-availability-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-availability-edit.component.html',
    styleUrls: ['rentals-availability-edit.component.scss'],
})
export class RentalsAvailabilityEditComponent implements OnInit {
    slot!:Slot;
    myForm!: FormGroup;
    InterventionID = new FormControl('');

    constructor(
        @Inject(MAT_DIALOG_DATA) args: any,
        public dialogRef: MatDialogRef<RentalsAvailabilityEditComponent>,
        private ref: ChangeDetectorRef,
        private fb: FormBuilder
    ) {
        this.slot = args.slot;
        this.myForm = this.fb.group({
            slot_id: [this.slot.slot_id],
            start_sec: [this.slot.start_sec, [Validators.required]],
            start_time: [moment(this.slot.start_sec).format('HH:mm'), [Validators.required]],
            availability_count: [ this.slot.availability_count, [Validators.required, Validators.min(0)]],
            availability_tag: [ this.slot.availability_tag],
            inventory_count: [ this.slot.inventory_count, [Validators.required, Validators.min(0)]],
            confirmation_mode: [this.slot.confirmation_mode, [Validators.required, Validators.min(0)]],
            duration_sec: [this.slot.duration_sec, [Validators.required, Validators.min(0)]],
            status: [this.slot.status, [Validators.required, Validators.min(0)]],
            service_id: [this.slot.service_id, [Validators.required, Validators.min(0)]],
        });

    }

    public refresh() {
        this.ref.markForCheck();
    }

    _fetchDefault(liste: any, value: number): number {
        if (value !== -1) {
            return value;
        } else {
            // On cherche la DEfaut
            let valDefaut = value;
 
            _.each(liste, l => {
                if (l.Defaut === 1) {
                    valDefaut = l.DescriptionID;
                }
            })
            return valDefaut;
        }
    }

    ngOnInit() {
        /**
         *  slot_id : 0,
            availability_count : 0,
            availability_tag : '',
            confirmation_mode : 0,
            duration_sec : 0, 
            status : 0, 
            inventory_count : 0,
            service_id : '1',
            start_sec : moment().valueOf()
         */
        // console.log(moment(this.slot.start_sec).format('MM-DD-YYYY HH:mm:ss'))
        this.myForm.patchValue({
            slot_id: this.slot.slot_id,
            start_sec: moment(this.slot.start_sec).toDate(),
            start_time: moment(this.slot.start_sec).format('HH:mm'),
            availability_count: this.slot.availability_count,
            availability_tag: this.slot.availability_tag,
            confirmation_mode: this.slot.confirmation_mode,
            duration_sec: this.slot.duration_sec,
            status: this.slot.status,
            inventory_count: this.slot.inventory_count,
            service_id: this.slot.service_id,
        });
    }

    onSubmit() {
    }

    onAction(action: string) {
        if (this.myForm.valid && action === 'save') {
            this.slot = this.myForm.value as Slot;
            const startSec = moment(this.slot.start_sec);
            const startTime = moment(startSec.format('YYYY-MM-DD ') + this.myForm.value.start_time).toDate()
            const retSlot = {
                slot_id: this.slot.slot_id,
                start_sec: startTime,
                availability_count: this.slot.availability_count,
                availability_tag: this.slot.availability_tag,
                confirmation_mode: this.slot.confirmation_mode,
                duration_sec: this.slot.duration_sec,
                status: this.slot.status,
                inventory_count: this.slot.inventory_count,
                service_id: this.slot.service_id,
            } as Slot;
            this.dialogRef.close({action, data : retSlot});
        }
    }

    onCancel() { }

    changeTimeDebut(event:any) {
        console.log('changeTimeDebut change', event)
        // this.myForm.controls.Heure_Debut_Op.setValue(event.replace(/ /g, '').replace('h', ':'));
    }

}
