import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService, private route: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let myHeader = req.headers;
        // if (this.authService.getToken() && req.url.startsWith('/api/')) {
        if (req.url.startsWith('/api/') || req.url == '/users/refresh_token') {
            if (this.authService.getToken()) {
                myHeader = myHeader.set('Authorization', this.authService.getToken());
            } else {
                // console.log('AuthInterceptorService /api NO TOKEN', req.url, this.authService);
            }
            const newReq = req.clone({
                headers: myHeader,
            });
            return next.handle(newReq).pipe(
                tap(
                    () => {},
                    (error: any) => {
                        console.error('AuthInterceptorService error', error);
                        if (error instanceof HttpErrorResponse) {
                            if (error.status !== 401) {
                                return;
                            }
                            return this.route.navigate(['/', 'auth', 'login']);
                        }
                    }
                )
            );
        } else {
            const newReq = req.clone({ headers: myHeader });
            return next.handle(newReq).pipe(
                tap(
                    () => {},
                    (error: any) => {
                        console.error('AuthInterceptorService error', error);
                        if (error instanceof HttpErrorResponse) {
                            if (error.status !== 401) {
                                return;
                            }
                            return this.route.navigate(['/', 'auth', 'login']);
                        }
                    }
                )
            );
        }
    }
}
