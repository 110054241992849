<sb-breadcrumbs slim="true"></sb-breadcrumbs>
<div class="d-flex m-2">
    <div class="card w-75 mr-2">
        <div class="card-header">
            <div class="pr-2">Location #{{ rental.booking_id }}</div>
            <button class="btn btn-sm btn-primary" [routerLink]="['edit']">Modifier</button>
        </div>
        <div class="card-body">
            <h3>Groupe</h3>
            <div class="w-100"><d1-display-info label="Organisateur" [text]="rental.nomOrganisateur" [hideIfEmpty]="false"></d1-display-info></div>
            <div class="w-100"><d1-display-info label="Courriel" [text]="rental.courriel" [hideIfEmpty]="true"></d1-display-info></div>
            <div class="w-100"><d1-display-info label="Téléphone" [text]="rental.telephone" [hideIfEmpty]="true"></d1-display-info></div>
            <div class="w-100"><d1-display-info class="pr-2" label="Date de location" [text]="rental.start_sec | date : 'd MMM, YYYY @ HH:mm'" [hideIfEmpty]="false"></d1-display-info><d1-display-info label="Durée" [text]="(rental.duration_sec || 0)/60" suffix="heures" [hideIfEmpty]="false"></d1-display-info><a class="btn btn-primary" [routerLink]="['/rentals','availabilities', rental.slot_id]">Voir la disponibilité</a></div>
            <div class="w-100">
                <mat-label>Catégories<d1-form-chip-list header="" [selectedItems]="rental.booking_tags" (change)="changeTags($event)"></d1-form-chip-list></mat-label>
            </div>
            <div class="w-100">
                <mat-label
                    >Statut de réservation :<mat-button-toggle-group name="statutGroupe" [(ngModel)]="rental.statutConfirmation" (ngModelChange)="changeStatut($event)"><mat-button-toggle *ngFor="let s of statusGroupes; let i = index" [value]="i" [class.active]="rental.statutConfirmation === i">{{ s }}</mat-button-toggle></mat-button-toggle-group></mat-label
                >
            </div>
            <div class="w-100">
                <mat-label
                    >Type de livraison :<mat-button-toggle-group name="typeLivraison" [(ngModel)]="rental.shipping_type" (ngModelChange)="changeTypeLivraison($event)"><mat-button-toggle *ngFor="let s of typeLivraison; let i = index" [value]="i" [class.active]="rental.shipping_type === i">{{ s }}</mat-button-toggle></mat-button-toggle-group></mat-label
                >
            </div>
        </div>
        <div class="card-body">
            <h3>Location ({{rental.nombreJoueurs}} équipements)</h3>
            <table class="w-100" mat-table [dataSource]="rental.ressources">
                <ng-container matColumnDef="id"
                    ><th mat-header-cell *matHeaderCellDef>No.</th>
                    <td mat-cell *matCellDef="let element; let i = index">{{ i+1 }}</td></ng-container
                ><ng-container matColumnDef="ressourceId"
                    ><th mat-header-cell *matHeaderCellDef>Équipement</th>
                    <td mat-cell *matCellDef="let element"><tsng-form-group-input class="w-100" label="" type="ComboBox" [value]="element.ressourceId" [model]="{ dataSource: listResources }" (blur)="changeResourceId($event, element)"></tsng-form-group-input></td></ng-container
                ><ng-container matColumnDef="commentaire"
                    ><th mat-header-cell *matHeaderCellDef>Commentaire</th>
                    <td mat-cell *matCellDef="let element"><tsng-form-group-input class="w-100" label="" [value]="element.commentaire" (blur)="changeComment($event, element)" [disabled]="rental.status&gt;4"></tsng-form-group-input></td></ng-container
                ><ng-container matColumnDef="inspecte"
                    ><th mat-header-cell *matHeaderCellDef>Inspecté</th>
                    <td mat-cell *matCellDef="let element"><d1-checkbox [checked]="element.inspecte === 1" (checkedChange)="changeInspect($event, element)" [disabled]="rental.status&gt;1"></d1-checkbox></td></ng-container
                ><ng-container matColumnDef="commentaire_retour"
                    ><th mat-header-cell *matHeaderCellDef>Commentaire de retour</th>
                    <td mat-cell *matCellDef="let element"><tsng-form-group-input class="w-100" label="" [value]="element.commentaire_retour" (blur)="changeCommentReturn($event, element)" [disabled]="rental.status&lt;5"></tsng-form-group-input></td></ng-container
                ><ng-container matColumnDef="need_repair"
                    ><th mat-header-cell *matHeaderCellDef>À réparer!</th>
                    <td mat-cell *matCellDef="let element"><d1-checkbox [checked]="element.need_repair === 1" (checkedChange)="changeNeedRepair($event, element)" [disabled]="rental.status!==6"></d1-checkbox></td
                ></ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
        <div class="card-body">
            <google-map [center]="center" width="100%" [zoom]="zoom" (mapClick)="moveMap($event)" (mapMousemove)="move($event)"></google-map>
            <div>Latitude: {{display?.lat}} Longitude: {{display?.lng}}</div>
        </div>
    </div>
    <div class="w-25">
        <div class="card">
            <div class="card-header">Coordonnées</div>
            <div class="card-body">
                <div>{{ rental.nocivique }} {{ rental.rue }}</div>
                <div>{{ rental.ville }}, {{ rental.province }}</div>
                <div>{{ rental.codepostal }}</div>
                <div>{{ rental.pays }}</div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">Statut de location</div>
            <mat-button-toggle-group name="statutGroupe" [(ngModel)]="rental.status" vertical (ngModelChange)="changeStatutLocation($event)"><mat-button-toggle *ngFor="let s of status; let i = index" [value]="i" [class.active]="rental.status === i">{{ s }}</mat-button-toggle></mat-button-toggle-group>
        </div>
        <div class="card">
            <div class="card-header">Disponibilité</div>
            <div class="card-body"><div>{{ rental.availability_count }} / {{ rental.inventory_count }}</div></div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <sbpro-card
        ><div class="card-header">Timeline</div>
        <div class="card-body"><d1-reservations-timeline [timeline]="rental.timeline"></d1-reservations-timeline></div
    ></sbpro-card>
</div>
