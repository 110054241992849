<footer class="footer mt-auto d-print-none" [ngClass]='{"footer-light": !dark, "footer-dark": dark}'>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 small">Copyright &copy; RELOAD v2.0</div>
            <div class="col-md-6 text-md-right small">
                <a [routerLink]="">Politique de confidentialité</a>
                &middot;
                <a [routerLink]="">Termes &amp; Conditions</a>
            </div>
        </div>
    </div>
</footer>
