<div class="container">
    <div class="row p-1">
        <div class="card p-1 text-white bg-dark"><div class="card-body p-1 font-weight-light font-weight-light">Id: {{ reservation.groupeId }} Inséré le : {{ reservation.insertionDate | date : 'dd MMMM YYYY à HH:mm' }}</div></div>
    </div>
    <div class="row p-1"><d1-reservations-action-bar [reservation]="reservation" (backClick)="goBackClick($event)"></d1-reservations-action-bar></div>
    <div class="row p-1"><d1-form-chip-list class="w-100" header="Tags" [selectedItems]="reservation.groupe_tags" (selectedItemsChange)="changeTags($event)"></d1-form-chip-list></div>
    <div class="row p-1"><d1-reservations-status [statutGroupe]="reservation.statutGroupe" (change)="updateStatut($event)"></d1-reservations-status></div>
    <div class="row p-1"><d1-reservations-confirmation-status [statutConfirmation]="reservation.statutConfirmation" (change)="updateStatutConfirmation($event)"></d1-reservations-confirmation-status></div>
    <div class="row p-1"><d1-reservations-payment-status [statutPaiement]="reservation.statutPaiement" (change)="updateStatutPaiement($event)"></d1-reservations-payment-status></div>
    <div class="row p-1" *ngIf="reservation.notesAdmin">
        <sbpro-card-icon class="w-100" iconBackground="bg-warning"
            ><div class="card-icon"><fa-icon [icon]='["far", "flag"]'></fa-icon></div>
            <div class="card-body">
                <div class="h4">Notes importantes</div>
                {{ reservation.notesAdmin}}
            </div></sbpro-card-icon
        >
    </div>
    <div class="row p-1">
        <div class="col p-1">
            <sbpro-card *ngIf="reservation &amp;&amp; reservation.parties"
                ><div class="card-header">Parties pour {{ reservation.nombreJoueurs }} joueurs</div>
                <div class="card-body">
                    <d1-reservations-list-games [games]="reservation.parties" [includePlayerNumber]="true" [allowEdit]="true"> </d1-reservations-list-games>
                    <div>
                        Responsable :
                        <div class="badge badge-info mr-2 p-2 text-white">{{ reservation.responsable }}</div>
                    </div>
                    <div>
                        Notes marshall :
                        <div class="badge p-2 text-white" [style.background-color]="(reservation.notesMarshallCouleur ? reservation.notesMarshallCouleur : '#5bc0de')">{{ reservation.notesMarshall }}</div>
                    </div>
                </div></sbpro-card
            ><tsng-display-info class="w-100" label="Notes" [text]="reservation.notes"></tsng-display-info>
        </div>
        <div class="col p-1">
            <sbpro-card
                ><div class="card-body">
                    <tsng-display-info class="w-100" label="" [icon]='["fas", "child"]' [text]="reservation.nomOrganisateur | titlecase"></tsng-display-info><tsng-display-info class="w-100" label="" [icon]='["fas", "at"]' [hideIfEmpty]="false" [text]=""><a [href]="'mailto:' + reservation.courriel">{{ reservation.courriel }}</a></tsng-display-info
                    ><tsng-display-info class="w-100" label="" [icon]='["fas", "phone"]' [hideIfEmpty]="false"><a [href]="reservation.telephone | phoneUri">{{ reservation.telephone | phone }}</a></tsng-display-info
                    ><tsng-display-info class="w-100" label="Paiement" [hideIfEmpty]="false" [icon]='["fas", "dollar-sign"]'
                        ><ng-container *ngIf="reservation.depotFait"><fa-icon class="text-success" [icon]="['fas', 'check']"></fa-icon>{{ reservation.depotMontant | currency : '' : 'symbol-narrow' }}<tsng-display-info label="Réf." [text]="reservation.depotReference"></tsng-display-info></ng-container><ng-container *ngIf="!reservation.depotFait"><fa-icon class="text-danger" [icon]="['far', 'square']"></fa-icon></ng-container
                    ></tsng-display-info></div></sbpro-card
            ><sbpro-card-icon class="w-100" *ngIf="reservation.nomFete || (reservation.salles &amp;&amp; reservation.salles.length &gt; 0)" iconBackground="bg-primary"
                ><div class="card-icon"><fa-icon [icon]='["fas", "birthday-cake"]'></fa-icon></div>
                <div class="card-body">
                    <div class="h4" *ngIf="reservation.nomFete">{{ reservation.nomFete | titlecase }}</div>
                    <d1-display-info label="Salles" [list]="getSalles()" (click)="pickupSalle()"></d1-display-info>
                    <div></div>
                    <d1-display-info label="Heures">{{ reservation.salleDebut | date : 'HH:mm' }} à {{ reservation.salleFin | date : 'HH:mm' }}</d1-display-info>
                </div></sbpro-card-icon
            >
        </div>
    </div>
</div>
