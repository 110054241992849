import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DialogInfo, ShopifyCustomerDialogComponent } from '../components/shopify-customer-dialog/shopify-customer-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ShopifyService {

    constructor(private http:HttpClient, public dialog: MatDialog) {
        // console.log('ShopifyService constructor()')
    }

    getProducts(): Observable<any> {
        // return this.http.get('/api/shopify/products', {params: {product_type : 'lasertag'}}).pipe( tap( r => {
        return this.http.get('/api/shopify/products');
    }

    getClients(): Observable<any> {
        return this.http.get('/api/shopify/clients');
    }

    getClient(id: string): Observable<any> {
        return this.http.get(`/api/shopify/clients/${id}`);
    }

    voirClient(id: string) {
        console.log('voirClient', id);
        this.getClient(id).subscribe(customer => {
            this.dialog.open(ShopifyCustomerDialogComponent, {
                width: 'auto',
                data: {customer : customer} as DialogInfo,
            });
        })
    }    

    getOrders(): Observable<any> {
        return this.http.get('/api/shopify/orders');
    }

    getDraftOrders(): Observable<any> {
        return this.http.get('/api/shopify/draft_orders');
    }

    updateOrder(orderid: string, data: any): Observable<any> {
        return this.http.put(`/api/shopify/orders/${orderid}`, data);
    }
}
