import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'd1-reservations-main',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-main.component.html',
    styleUrls: ['reservations-main.component.scss'],
})
export class ReservationsMainComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
