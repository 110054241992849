<form class="form-inline mr-auto d-none d-lg-block">
    <input class="form-control form-control-solid mr-sm-2" type="search" placeholder="Recherche" aria-label="Recherche" [matAutocomplete]="auto" #autoCompleteInput autocomplete="off" [formControl]="searchFC" /><mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedGroupe($event)" panelWidth="50%"
        ><mat-option class="multiline-mat-option" *ngFor="let result of filteredSearchResults | async" [value]="result"
            ><div mat-line><span>{{result.nomOrganisateur}} </span><span *ngIf="result.telephone"> | Tel : {{ result.telephone | phone }} </span><span *ngIf="result.courriel">| {{ result.courriel }}</span></div>
            <div class="d-flex justify-content-between" mat-line>
                <div *ngIf="result.groupeId">Reload #{{result.groupeId}}</div>
                <div *ngIf="result.nomFete">Fêté : {{result.nomFete}}</div>
                <div *ngIf="result.reservationDate">Date : {{result.reservationDate | date: 'd MMM YYYY à HH:mm'}}</div>
            </div>
            <div mat-line><span *ngIf="result.rue"> | Adr. : {{ result.rue}} {{ result.nocivique}} </span></div></mat-option
        ></mat-autocomplete
    >
</form>
