import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShopifyService } from '@modules/shopify/services';
import { ColumnDefinitionsModel, SimpleGridComponent } from 'ts-ng-lib';

@Component({
    selector: 'd1-shopify-admin-products',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-admin-products.component.html',
    styleUrls: ['shopify-admin-products.component.scss'],
})
export class ShopifyAdminProductsComponent implements OnInit, AfterViewInit {
    @ViewChild('grille', { static: true}) grille!:SimpleGridComponent;
    public lstProducts!:[];
    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: '#', visible: true, name: 'id', sortable: true, arrowPosition: 'after' },
        { headerText: 'Type', visible: true, name: 'product_type', sortable: true, arrowPosition: 'after' },
        { headerText: 'Titre', visible: true, name: 'title', sortable: true, arrowPosition: 'after' },
        { headerText: 'Tags', visible: true, name: 'tags', showAsPills:true, sortable: false, arrowPosition: 'after' },
        { headerText: 'Image', visible: true, name: 'image', sortable: false, arrowPosition: 'after', format: (v) => {
            if (v) {
                // const test = this.dom.bypassSecurityTrustHtml(`<img src="${this.dom.bypassSecurityTrustResourceUrl(v.src)}" />`)
                return `<img class="img-thumbnail img-fluid" src="${v.src}" />`;
            } else {
                return v;
            }
        } },

    ];

    constructor(private s$:ShopifyService, private ref:ChangeDetectorRef, private dom:DomSanitizer) {
        
    }
    ngAfterViewInit(): void {
        this.grille.loading = true;
        this.s$.getProducts().subscribe( products => {
            this.lstProducts = products ;
            // this.grille.loading = false;
            this.ref.markForCheck()
        })
    }

    ngOnInit() {
    }
}
