import { Inject, Input } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReservationModel } from '@modules/reservations/models';

@Component({
    selector: 'd1-dialog-email',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dialog-email.component.html',
    styleUrls: ['dialog-email.component.scss'],
})
export class DialogEmailComponent implements OnInit {
    public selectedReservation?:ReservationModel = undefined;

    constructor(@Inject(MAT_DIALOG_DATA) args: any, private dialogRef: MatDialogRef<DialogEmailComponent>, ) {
        if (args.reservation) {
            this.selectedReservation = args.reservation
        }
    }
    
    ngOnInit() {}

    onSend(info: any) {
        this.dialogRef.close(info);
    }

    onCancel() {
        this.dialogRef.close(false);
    }
}
