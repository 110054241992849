angular.module('resources.prochaineParties', ['resources.cedule']);
angular.module('resources.prochaineParties').factory('ProchaineParties', ['Cedule', function (Cedule) {

    var prochaineParties = {};

    prochaineParties.getListe = function () {
        prochaineParties.cedules = [];
        return $http.get('/api/cedules/next').then(function(response) {
            var liste = [];
            response.data.forEach(function(item, i) {
                var t = Cedule.parseFromDB(item);
                liste.push(t);
            });
            prochaineParties.cedules = liste;
            return liste;
        });
    };

    return prochaineParties;

}]);
