// Divers
import * as _ from 'underscore';
import * as moment from 'moment';
import { io } from 'socket.io-client';

/**
 * Fonction globalle pour copier le squelette d'un objet
 * @param source
 * @param isArray
 * @returns {*}
 */
 function skeleton(source) {
    var o = Array.isArray(source) ? [] : {};
    for (var key in source) {
        if (source.hasOwnProperty(key)) {
            var t = typeof source[key];
            o[key] = t == 'object' ? skeleton(source[key]) : { string: '', number: 0, boolean: false }[t];
        }
    }
    return o;
}

const modelHoraire = {
    "name": "Nouvel horaire",
    "start_date": moment().format("YYYY-MM-DD"),
    "end_date": moment().add(1, 'y').format("YYYY-MM-DD"),
    "business_days": [
        { "days": [1, 1, 1, 1, 1, 1, 1], "start": "09:00", "end": "18:00" }
    ],
    "exception_days": [

    ]
};

angular.module('resources.config', [])
.factory('D1Configurations', ['$http', '$q', '$messages', function($http, $q, $messages) {

    var myConfig = {};

    myConfig.socket = io();
    myConfig.TypeGroupes = [];
    myConfig.TypeSalles = [];
    myConfig.Attributs = [];
    myConfig.configurations = {};
    myConfig.Horaires = [];
    myConfig.Arenas = [];
    myConfig.links = [];
    myConfig.features = [];
    myConfig.arena_loading = false;

    // myConfig.socket.on('RafraichirConfig', function(data) {
    $messages.SettingsMessage.subscribe( r => {
        // On doit reloader les infos
        myConfig.getTypeGroupes(true);
        myConfig.getConfigurations();
        myConfig.getAttributs();
        myConfig.getTypeSalles();
    });

    // Aller chercher les configs
    myConfig.getConfigurations = function() {
        //var me = this;
        return $http.get('/api/settings').then(function(response) {
            myConfig.configurations = response.data;
            console.log('response.data', response.data)

            myConfig.features = myConfig.configurations.features

            // Shortcuts
            if (myConfig.configurations.horaires) {
                myConfig.Horaires = myConfig.configurations.horaires.horaires;
            }

            // Shortcuts
            if (myConfig.configurations.api) {
                myConfig.api = myConfig.configurations.api;
            }

            // Shortcuts
            if (myConfig.configurations.links) {
                myConfig.links = myConfig.configurations.links;
            }

            // Shortcuts
            if (myConfig.configurations.whitelist) {
                myConfig.whitelist = myConfig.configurations.whitelist;
            }

            // Shortcuts
            if (myConfig.configurations.app) {
                myConfig.app = myConfig.configurations.app;
            }

            // Shortcuts
            myConfig.getArenas();

            return myConfig.configurations;
        });
    };
    myConfig.getConfigurations();


    /**
     * Aller chercher les attributs possibles pour une résrevation
     * @returns {*}
     */
    myConfig.getAttributs = function(refresh) {
        if (myConfig.Attributs.length == 0 || refresh) {
            myConfig.Attributs = [];
            return $http.get('/api/reservations/attributs').then(function(response) {
                myConfig.Attributs = response.data;
                return myConfig.Attributs;
            });
        } else {
            return myConfig.Attibuts;
        }
    };
    myConfig.getAttributs();

    /**
     * Aller chercher les types de salles possibles pour une résrevation
     * @returns {*}
     */
    myConfig.getTypeSalles = function(refresh) {
        if (myConfig.TypeSalles.length == 0 || refresh) {
            myConfig.TypeSalles = [];
            return $http.get('/api/reservations/typesalles').then(function(response) {
                myConfig.TypeSalles = response.data;
                return myConfig.TypeSalles;
            });
        } else {
            return myConfig.TypeSalles;
        }
    };
    myConfig.getTypeSalles();


    /**
     * Aller chercher les types de groupes possible pour une réservation
     * @returns {*}
     */
    myConfig.getTypeGroupes = function(refresh) {
        if (myConfig.TypeGroupes.length == 0 || refresh) {
            myConfig.TypeGroupes = [];
            return $http.get('/api/reservations/typegroupes').then(function(response) {
                myConfig.TypeGroupes = response.data;
                return myConfig.TypeGroupes;
            });
        } else {
            var q = $q.defer();
            q.resolve(myConfig.TypeGroupes)
            return q.promise;
        }
    };
    myConfig.getTypeGroupes();

    myConfig.saveTypeGroupe = function(groupeType) {
        return $http.post('/api/config/groupeType', groupeType).then(function(response) {
            $messages.sendMessageSettings(groupeType)
            // myConfig.socket.emit('RafraichirConfig', groupeType);
            return response.data;
        });

    };

    myConfig.effacerTypeGroupe = function(groupeType) {
        return $http.delete('/api/config/groupeType/' + groupeType.groupeTypeId).then(function(response) {
            $messages.sendMessageSettings(groupeType)
            // myConfig.socket.emit('RafraichirConfig', groupeType);
            return response.data;
        });

    };


    /**
     * Aller chercher les arenes
     * @returns {*}
     */
    myConfig.getArenas = function(refresh) {
        if ((myConfig.Arenas.length == 0 || refresh) && !myConfig.arena_loading) {
            myConfig.Arenas = [];
            myConfig.arena_loading = true;
            return $http.get('/api/settings/arenas').then(function(response) {
                myConfig.Arenas = response.data;
                myConfig.arena_loading = false;

                return myConfig.Arenas;
            });
        } else {
            return myConfig.Arenas;
        }
    };

    /**
     * API
     */
    myConfig.saveAPI = function(api) {
        myConfig.api = api;
        return $http.put('/api/config/api/', myConfig.api).then(function(response) {
            $messages.sendMessageSettings(myConfig.api)
            // myConfig.socket.emit('RafraichirConfig', myConfig.api);
            return response.data;
        });
    };

    /**
     * API
     */
    myConfig.saveWhitelist = function(whitelist) {
        myConfig.whitelist = whitelist;
        return $http.put('/api/config/whitelist/', myConfig.whitelist).then(function(response) {
            $messages.sendMessageSettings(myConfig.api)
            // myConfig.socket.emit('RafraichirConfig', myConfig.api);
            // Afficher l'erreur
            $toastService.showSuccess("Sauvegarde", "La liste des adresses a été sauvegardée avec succès")

            return response.data;
        });
    };

    /**
     * HORAIRES
     */
    myConfig.saveHoraire = function(horaires) {
        myConfig.Horaires = horaires;
        return $http.put('/api/config/horaires/', myConfig.Horaires).then(function(response) {
            $messages.sendMessageSettings(myConfig.Horaires)
            // myConfig.socket.emit('RafraichirConfig', myConfig.Horaires);
            return response.data;
        });
    };

    myConfig.findLatestSchedule = function(horaires, viewDate) {
        var horairesLatest = [];

        // Trouver parmis les horaire, lequel on est le plus proche du début en autant qu'on soit dans la période de l'horaire
        _.each(horaires, function(h) {
            if (viewDate.isBetween(h.start_date, h.end_date, 'days', '[]')) {
                if (horairesLatest.length == 0) {
                    horairesLatest = h;
                } else {
                    // Comparer le start date et prendre le plus près de notre date désirée
                    if (viewDate.diff(h.start_date, 'days') <= viewDate.diff(horairesLatest.start_date, 'days')) {
                        horairesLatest = h;
                    }
                }
            } else {
                //onsole.log("horaire inactif (start, end)", h.start_date, h.end_date);
            }
        });

        // À ce point, on a l'horaire le plus approprié
        return horairesLatest;
    };

    myConfig.findLatestScheduleHours = function(horairesLatest, viewDate) {
        // Trouver la journée actuelle dans l'horaire
        var dayLookup = viewDate.day();
        var heuresLatest = [];

        // On fouille les journées normales
        _.each(horairesLatest.business_days, function(d) {
            if (d.days[dayLookup] == 1) {
                heuresLatest = d;
            }
        });

        // On fouille les exceptions
        _.each(horairesLatest.exception_days, function(d) {
            if (viewDate.isBetween(d.start_date, d.end_date, 'days', '[]')) {
                if (d.days[dayLookup] == 1) {
                    heuresLatest = d;
                }
            }
        });

        // À ce point, on a les heures de début et de fin
        var mDebut = moment(heuresLatest.start);
        var hDebut = moment(viewDate).hours(mDebut.hours()).minutes(mDebut.minutes()).seconds(0).milliseconds(0);
        var mFin = moment(heuresLatest.end);
        var hFin = moment(viewDate).hours(mFin.hours()).minutes(mFin.minutes()).seconds(0).milliseconds(0);
        if (mFin.hours() < 6) {
            // On va plus loin que la journée, petites heures du mat
            hFin.add(1, 'days');
        }
        heuresLatest.start_date = hDebut;
        heuresLatest.end_date = hFin;

        return heuresLatest;
    };

    // GMAIL
    myConfig.getGmailToken = function() {
        return $http.get('/api/gmail/authenticate');
    };
    myConfig.getGmailTest = function(token) {
        return $http.get('/api/gmail/test?token=' + token);
    };

    
    /**
    * Usagers
    */
    myConfig.saveUser = function(user) {
        return $http.put('/api/config/user', user).then(function(response) {
            $messages.sendMessageSettings(user)
            return response.data;
        });
    };
    myConfig.createUser = function(user) {
        return $http.post('/api/config/user', user).then(function(response) {
            $messages.sendMessageSettings(user)
            return response.data;
        });
    };
    myConfig.deleteUser = function(user) {
        return $http.delete(`/api/config/user/${user.id}`).then(function(response) {
            $messages.sendMessageSettings(user)
            return response.data;
        });
    };

    /**
    * Liens externes
    */
    myConfig.saveLinks = function(links) {
        return $http.put('/api/config/links', links).then(function(response) {
            $messages.sendMessageSettings({type: 'links', links })
            return response.data;
        });
    };

    myConfig.syncTemplates = function() {
        return $http.get(`/api/courriel/gabarits/sync`).then(function(response) {
            return response.data;
        });
    };

    /**
     * Save appSettings
     * @param {*} appSettings 
     * @returns 
     */
    myConfig.saveAppSettings = function(appSettings) {
        myConfig.app = appSettings;
        return $http.put('/api/config/app', myConfig.app).then(function(response) {
            $messages.sendMessageSettings({type: 'app', appSettings })
            return response.data;
        });
    };    

    return myConfig;
}])

.config(['$routeProvider', function($routeProvider) {

    $routeProvider
        .when('/ng1/configurations', {
            templateUrl: 'ng1/config/config.tpl.html',
            controller: 'D1ConfigurationCtrl',
            resolve: {
                pageTitle: ['Breadcrumb', function(Breadcrumb) {
                    Breadcrumb.pageTitle = 'Configurations';
                }],
                usagersListe : ['Usagers', function (Usagers) {
                    return Usagers.getListe();
                }],
                routePrivee: function() { return true; }
            }
        });
}])

.controller('D1ConfigurationCtrl', ['$scope', 'Usagers', '$location', 'usagersListe', 'D1Configurations', '$toastService', 
    function($scope, Usagers, $location, usagersListe, D1Configurations, $toastService) {
        $scope.Usagers = Usagers;
        $scope.location = $location;

        $scope.usagersListe = usagersListe;

        $scope.syncTemplates = function() {
            D1Configurations.syncTemplates().then( 
                () => {
                    $toastService.showSuccess("Gabartis synchronisés", "<i class='fa fa-2x fa-check-circle'></i> Les gabartis ont été synchronisé avec succès")
                }
            )
    }

}])

.controller('D1ConfigProfileCtrl', ['$scope', 'Usagers', '$location', '$translate', '$locale', function($scope, Usagers, $location, $translate, $locale) {
    $scope.Usagers = Usagers;
    $scope.location = $location;
    $scope.monprofil = "Profile";

    $scope.changerLangue = function(lg) {
        $translate.use(lg);
        moment.locale(lg);
        $locale.id = lg;

    };
}])

.controller('D1ConfigCedulesCtrl', ['$scope', 'D1Configurations', '$location', function($scope, D1Configurations, $location) {
    $scope.location = $location;
    $scope.monprofil = "Cedules";

    $scope.config = D1Configurations.configurations;

}])


.controller('D1ConfigRokuCtrl', ['$scope', 'Usagers', '$location', '$http', function($scope, Usagers, $location, $http) {
    $scope.Usagers = Usagers;
    $scope.location = $location;
    $scope.monprofil = "Roku";

    $scope.ReloadConfig = function() {
        $http.get("/api/d1channel/reload");
    }

}])

.controller('D1ConfigReservationsCtrl', ['$scope', function($scope) {
    $scope.monprofil = "reserv";


}])

/**
 * Types de groupe
 */
.controller('D1ConfigGroupeTypeCtrl', ['$scope', 'D1Configurations', '$uibModal', function($scope, Configurations, $uibModal) {
        $scope.listeItems = Configurations.TypeGroupes;
        $scope.orderedListe = {};

        $scope.rafraichir = function() {
            // Forcer un refresh
            Configurations.getTypeGroupes(true).then(function(resp) {
                $scope.listeItems = resp;
            })
        }

        $scope.effacer = function(item) {
            if (item.groupeTypeId == 0) {
                alert("Il n'est pas possible d'effacer ce groupe");
            } else {
                if (confirm('Vous allez effacer un type de groupe. Toutes les réservations associées seront trasnférées sous le groupe de défaut."')) {
                    Configurations.effacerTypeGroupe(item).then(function(resp) {
                        $scope.rafraichir();
                    })
                }
            }
        };

        $scope.ajouter = function() {
            var dlg = $uibModal.open({
                templateUrl: 'ng1/config/config-groupetype-edit.tpl.html',
                controller: 'ModalGroupeTypeEditCtrl',
                //controllerAs : '$ctrl',
                size: 'lg', //'sm','lg'
                backdrop: 'static',
                windowClass: 'validation-modal',
                resolve: {
                    item: function() {
                        // Donner une copie
                        var copie = skeleton($scope.listeItems[0]);
                        copie.estNouveau = true;
                        copie.groupeTypeId = Math.floor((Math.random() * 100000) + 1);
                        return copie;
                    },
                    Attributs: function() {
                        // Donner une copie
                        return Configurations.Attributs;
                    }                    , 
                    gabarits: ['D1Courriel', function(D1Courriel) {
                        return D1Courriel.getGabarits();
                    }]
                }
            });
            dlg.result.then(function(dlgItem) {
                // Sauvegarder, ajouter la copie dans la liste
                dlgItem.groupeTypeId = undefined;
                Configurations.saveTypeGroupe(dlgItem).then(function(resp) {
                    $scope.rafraichir();
                });

            }, function(selectedItem) {
                // DISMISS FUNCTION
            });

        };

        $scope.modifier = function(i) {

            var curIndex = i;

            var dlg = $uibModal.open({
                templateUrl: 'ng1/config/config-groupetype-edit.tpl.html',
                controller: 'ModalGroupeTypeEditCtrl',
                //controllerAs : '$ctrl',
                size: 'lg', //'sm','lg'
                backdrop: 'static',
                windowClass: 'validation-modal',
                resolve: {
                    item: function() {
                        // Donner une copie
                        return angular.copy($scope.orderedListe[i]);
                    },
                    Attributs: function() {
                        // Donner une copie
                        return Configurations.Attributs;
                    },
                    gabarits: ['D1Courriel', function(D1Courriel) {
                        return D1Courriel.getGabarits();
                    }]
                }
            });
            dlg.result.then(function(dlgItem) {
                // Sauvegarder, on prend la copie et on remplace dans la liste
                _.each($scope.listeItems, function(item, index) {
                    if (item.groupeTypeId === $scope.orderedListe[curIndex].groupeTypeId) {
                        $scope.listeItems[index] = dlgItem;
                        curIndex = index;
                    }
                });

                Configurations.saveTypeGroupe(dlgItem).then(function(resp) {
                    $scope.rafraichir();
                });

            }, function(selectedItem) {
                // DISMISS FUNCTION
            });

        };

    }])
    .controller('ModalGroupeTypeEditCtrl', ['$scope', '$http', '$uibModalInstance', 'item', 'Attributs', 'gabarits', function($scope, $http, $uibModalInstance, item, Attributs, gabarits) {
        // Commun à tous?
        $scope.item = item;
        $scope.lstGabarits = [];
        $scope.gabarit = undefined;

        _.sortBy(gabarits, 'name');
        gabarits.forEach( (g) => {
            $scope.lstGabarits.push({ 'id': g.id, 'name': g.name, 'sujet': g.versions[0].subject });
        });

        $scope.gabarit = _.findWhere($scope.lstGabarits, {id: item.confirmationTemplate });

        // Désactiver les attributs qui sont pas déjà inclus
        _.each(Attributs, function(attr) {
            attr.desactiver = false;
            if (_.find(item.attributs, function(it) {
                    return (it && it.attributId == attr.attributId);
                })) {
                attr.desactiver = true;
            }
        });
        $scope.Attributs = Attributs;

        $scope.ok = function() {
            // Copier notre gabarit
            $scope.item.confirmationTemplate = ($scope.gabarit ? $scope.gabarit.id : undefined)
            $uibModalInstance.close($scope.item);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ajouterAttributs = function() {
            // Insérer une copie dans les items
            var copie = angular.copy($scope.newAttributs);
            copie.estNouveau = true;
            copie.attributId = Math.floor((Math.random() * 100000) + 1);
            item.attributs.push(copie);


            // Désactiver dans les attributs
            _.find(Attributs, function(attr) { return attr.attributId === $scope.newAttributs.attributId; }).desactiver = true;
        };

        $scope.effacerAttributs = function(attr) {
            _.each(item.attributs, function(it) {
                return it.attributId == attr.attributId;
            });
            if (attr.estNouveau) {

            }
            // Réactiver dans les attributs
            _.find(Attributs, function(at) { return at.attributId === $scope.newAttributs.attributId; }).desactiver = false;
        };
    }])

/**
 * Horaires
 */
.controller('D1ConfigHoraireCtrl', ['$scope', '$timeout', '$interval', 'D1Configurations', '$uibModal', function($scope, $timeout, $interval, Configurations, $uibModal) {
        $scope.listeItems = Configurations.Horaires;
        $scope.orderedListe = {};

        $scope.getDayName = function(day) {
            if (typeof day == "object") {
                //var list = _.map(day, function (sday) { return moment.weekdays(sday)});
                var list = [];
                _.each(day, function(sday, i) {
                    if (sday == 1) {
                        list.push(moment.weekdays(i));
                    }
                });
                return list.join(",");
            } else {
                // Soustraire 1 car 0 based
                //return moment.weekdays(day - 1);
                return moment.weekdays(day);
            }
        };

        $scope.rafraichir = function() {
            // Forcer un refresh
            Configurations.getConfigurations().then(function(resp) {
                $scope.listeItems = Configurations.Horaires;
            })
        };

        $scope.effacer = function(item, idx) {
            if (confirm('Etes-vous certain de vouloir effacer l\'horaire [' + item.name + ']?')) {
                // Sauvegarder, ajouter la copie dans la liste
                $scope.listeItems.splice(idx, 1);
                Configurations.saveHoraire($scope.listeItems).then(function(resp) {
                    $scope.rafraichir();
                });
            }
        };

        $scope.ajouter = function() {
            var dlg = $uibModal.open({
                templateUrl: 'ng1/config/config-horaire-edit.tpl.html',
                controller: 'ModalHoraireEditCtrl',
                //controllerAs : '$ctrl',
                size: 'lg', //'sm','lg'
                backdrop: 'static',
                windowClass: 'validation-modal',
                resolve: {
                    item: function() {
                        // Donner une copie
                        var copie = _.clone(modelHoraire);
                        return copie;
                    }
                }
            });
            dlg.result.then(function(dlgItem) {
                // Sauvegarder, ajouter la copie dans la liste
                $scope.listeItems.push(dlgItem);
                Configurations.saveHoraire($scope.listeItems).then(function(resp) {
                    $scope.rafraichir();
                });

            }, function(selectedItem) {
                // DISMISS FUNCTION
            });

        };

        $scope.modifier = function(item, idx) {
            var dlg = $uibModal.open({
                templateUrl: 'ng1/config/config-horaire-edit.tpl.html',
                controller: 'ModalHoraireEditCtrl',
                //controllerAs : '$ctrl',
                size: 'lg', //'sm','lg'
                backdrop: 'static',
                windowClass: 'validation-modal',
                resolve: {
                    item: function() {
                        // Donner une copie
                        return angular.copy(item);
                    }
                }
            });
            dlg.result.then(function(newItem) {
                $scope.listeItems[idx] = newItem;

                Configurations.saveHoraire($scope.listeItems).then(function(resp) {
                    $scope.rafraichir();
                });

            }, function(selectedItem) {
                // DISMISS FUNCTION
            });

        };

    }])
    .controller('ModalHoraireEditCtrl', ['$scope', '$http', '$uibModalInstance', 'item', function($scope, $http, $uibModalInstance, item) {
        // Commun à tous?
        $scope.item = item;

        // Repasser au travers des journée pour faire une date avec les "start" et "end"
        item.start_date = moment(item.start_date).toDate();
        item.end_date = moment(item.end_date).toDate();

        var time = [];
        _.each(item.business_days, function(d) {
            d.start = moment(d.start).toDate();
            d.end = moment(d.end).toDate();
        });

        _.each(item.exception_days, function(d) {
            d.start = moment(d.start).toDate();
            d.end = moment(d.end).toDate();
            d.start_date = moment(d.start_date).toDate();
            d.end_date = moment(d.end_date).toDate();
            if (d.ferme == undefined) {
                d.ferme = false;
            }
        });
        $scope.ceduleOptions = {
            timeStripZeroSeconds: true
        };

        $scope.getMonthName = function(month) {
            // Soustraire 1 car 0 based
            return moment.months(month - 1);
        };

        $scope.weekdays = function(days) {
            return moment.weekdays();
        };

        $scope.getWeekday = function(day) {
            return moment.weekdays(day);
        };

        $scope.ok = function() {
            $uibModalInstance.close($scope.item);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ajoutCedule = function() {
            var newDay = {
                start: null,
                end: null,
                days: [0, 0, 0, 0, 0, 0, 0]
            };
            $scope.item.business_days.push(newDay);
        };

        $scope.ajoutException = function() {
            var newDay = {
                start: null,
                end: null,
                days: [0, 0, 0, 0, 0, 0, 0]
            };
            $scope.item.exception_days.push(newDay);
        };

        $scope.effacerJournee = function(liste, index) {
            if (confirm('Êtes-vous ceertain de vouloir effacer ces heures?')) {
                liste.splice(index, 1);
            }
        };


    }])

/**
 * API
 */
.controller('D1ConfigAPICtrl', ['$scope', '$timeout', '$interval', 'D1Configurations', '$uibModal', function($scope, $timeout, $interval, Configurations, $uibModal) {
        $scope.listeItems = Configurations.api;

        $scope.rafraichir = function() {
            // Forcer un refresh
            Configurations.getConfigurations().then(function(resp) {
                $scope.listeItems = Configurations.api;
            })
        };

        $scope.effacer = function(item, idx) {
            if (confirm('Etes-vous certain de vouloir effacer l\'API [' + item.name + ']?')) {
                // Sauvegarder, ajouter la copie dans la liste
                delete($scope.listeItems[idx]);
                Configurations.saveAPI($scope.listeItems).then(function(resp) {
                    $scope.rafraichir();
                });
            }
        };

        $scope.ajouter = function() {
            var dlg = $uibModal.open({
                templateUrl: 'ng1/config/config-api-edit.tpl.html',
                controller: 'ModalAPIEditCtrl',
                //controllerAs : '$ctrl',
                //size: 'lg', //'sm','lg'
                backdrop: 'static',
                windowClass: 'validation-modal',
                resolve: {
                    item: function() {
                        // Donner une copie
                        return angular.copy({ name: '', item: {} });
                    }
                }
            });
            dlg.result.then(function(dlgItem) {
                // Sauvegarder, ajouter la copie dans la liste
                $scope.listeItems[dlgItem.name] = dlgItem.item;
                Configurations.saveAPI($scope.listeItems).then(function(resp) {
                    $scope.rafraichir();
                });

            }, function(selectedItem) {
                // DISMISS FUNCTION
            });

        };

        $scope.modifier = function(item, idx) {

            var dlg = $uibModal.open({
                templateUrl: 'ng1/config/config-api-edit.tpl.html',
                controller: 'ModalAPIEditCtrl',
                //controllerAs : '$ctrl',
                //size: 'lg', //'sm','lg'
                backdrop: 'static',
                windowClass: 'validation-modal',
                resolve: {
                    item: function() {
                        // Donner une copie
                        return angular.copy({ name: idx, item: item });
                    }
                }
            });
            dlg.result.then(function(newItem) {
                $scope.listeItems[newItem.name] = newItem.item;
                if (idx !== newItem.name) {
                    // Renamed, remove previous key!
                    delete($scope.listeItems[idx]);
                }

                Configurations.saveAPI($scope.listeItems).then(function(resp) {
                    $scope.rafraichir();
                });

            }, function(selectedItem) {
                // DISMISS FUNCTION
            });

        };

    }])
    .controller('ModalAPIEditCtrl', ['$scope', '$http', '$uibModalInstance', 'item', 'Notification', '$filter', '$toastService', function($scope, $http, $uibModalInstance, item, Notification, $filter, $toastService) {
        // Commun à tous?
        $scope.item = $filter('json')(item.item);
        $scope.name = item.name;

        $scope.ok = function() {
            try {
                var parsed = JSON.parse($scope.item);
                $uibModalInstance.close({ name: $scope.name, item: parsed });
            } catch (err) {
                // Afficher l'erreur
                $toastService.showError("Erreur de validation", "Le format de JSON est invalide! Vérifiez la structure! : " + err.toString())
            }
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }])

/**
 * Whitelist
 */
.controller('D1ConfigWhitelistCtrl', ['$scope', '$timeout', '$interval', 'D1Configurations', '$uibModal', function($scope, $timeout, $interval, Configurations, $uibModal) {
    $scope.listeItems = Configurations.whitelist;

    if ($scope.listeItems === undefined) {
        $scope.listeItems = [];
    }

    $scope.rafraichir = function() {
        // Forcer un refresh
        Configurations.getConfigurations().then(function(resp) {
            $scope.listeItems = Configurations.whitelist;
        })
    };

    $scope.effacer = function(item, idx) {
        if (confirm('Etes-vous certain de vouloir effacer l\'adresse [' + item + ']?')) {
            // Sauvegarder, ajouter la copie dans la liste
            $scope.listeItems.splice(idx, 1);
            // Configurations.saveAPI($scope.listeItems).then(function(resp) {
            //     $scope.rafraichir();
            // });
        }
    };

    $scope.sauvegarder = function() {
        Configurations.saveWhitelist($scope.listeItems).then((resp) => {
            $scope.rafraichir();
        })

    };

    $scope.ajouter = function() {
        $scope.listeItems.push("");
    };

    $scope.modifier = function(item, idx) {
    };

}])
/**
 * API
 */
 .controller('D1ConfigUserCtrl', ['$scope', 'D1Configurations', '$uibModal', function($scope, Configurations, $uibModal) {
    $scope.listeItems = $scope.usagersListe;

    $scope.rafraichir = function() {
        // Forcer un refresh
        $scope.Usagers.getListe().then(function(resp) {
            $scope.listeItems = resp;
        })
    };

    $scope.effacer = function(item, idx) {
        if (confirm('Etes-vous certain de vouloir effacer l\'usager [' + item.username + ']?')) {
            // Sauvegarder, ajouter la copie dans la liste
            delete($scope.listeItems[idx]);
            Configurations.deleteUser(item).then(function() {
                $scope.rafraichir();
            });
        }
    };

    $scope.ajouter = function() {
        var dlg = $uibModal.open({
            templateUrl: 'ng1/config/config-user-edit.tpl.html',
            controller: 'ModalUserEditCtrl',
            //controllerAs : '$ctrl',
            //size: 'lg', //'sm','lg'
            backdrop: 'static',
            windowClass: 'validation-modal',
            resolve: {
                item: function() {
                    // Donner une copie
                    return angular.copy({ index: '', item: {restraintWhitelistIP:1} });
                }
            }
        });
        dlg.result.then(function(dlgItem) {
            // Sauvegarder, ajouter la copie dans la liste
            $scope.listeItems[dlgItem.name] = dlgItem.item;
            Configurations.createUser(dlgItem.item).then(function() {
                $scope.rafraichir();
            });

        }, function() {
            // DISMISS FUNCTION
        });

    };

    $scope.modifier = function(item, idx) {
        var dlg = $uibModal.open({
            templateUrl: 'ng1/config/config-user-edit.tpl.html',
            controller: 'ModalUserEditCtrl',
            //controllerAs : '$ctrl',
            //size: 'lg', //'sm','lg'
            backdrop: 'static',
            windowClass: 'validation-modal',
            resolve: {
                item: function() {
                    // Donner une copie
                    return angular.copy({ index: idx, item: item });
                }
            }
        });
        dlg.result.then(function(newItem) {
            $scope.listeItems[newItem.index] = newItem.item;
            Configurations.saveUser(newItem.item).then(function() {
                $scope.rafraichir();
            });

        }, function() {
            // DISMISS FUNCTION
        });

    };

}])
.controller('ModalUserEditCtrl', ['$scope', '$uibModalInstance', 'item', '$toastService', function($scope, $uibModalInstance, item, $toastService) {
    // Commun à tous?
    $scope.item = item.item;
    $scope.modifier_pwd = false;
    $scope.pwd = {password1 : '', password2 : ''};

    $scope.ok = function() {
        try {
            let bOk = true;
            if ($scope.modifier_pwd || (!$scope.item.id && $scope.item.username)) {
                bOk = false;

                // Valider que tout est beau
                if (($scope.pwd.password1.length && $scope.pwd.password2.length) && ($scope.pwd.password1 === $scope.pwd.password2) ) {
                    bOk = true;
                    $scope.item.new_password = $scope.pwd.password1;
                } else {
                    // Afficher l'erreur
                    $toastService.showError("Erreur de validation", "Vérifiez les mots de passes!")
                }
            } 
            
            if (bOk) $uibModalInstance.close({ index: item.index, item: $scope.item });
        } catch (err) {
            // Afficher l'erreur
            $toastService.showError("Erreur de validation", "Vérifiez la structure! : " + err.toString())
        }
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.modifierPwd = function() {
        // Toggle la modifcation
        $scope.modifier_pwd = !$scope.modifier_pwd;
    }
}])

/**
 * API
 */
 .controller('D1ConfigLinksCtrl', ['$scope', 'D1Configurations', '$uibModal', function($scope, D1Configurations, $uibModal) {
    $scope.listeItems = D1Configurations.links;

    $scope.rafraichir = function() {
        // Forcer un refresh
        D1Configurations.getConfigurations().then(function() {
            $scope.listeItems = D1Configurations.links;
        })
    };

    $scope.effacer = function(item, idx) {
        if (confirm('Etes-vous certain de vouloir effacer le lien?')) {
            // Sauvegarder, ajouter la copie dans la liste
            // delete($scope.listeItems[idx]);
            $scope.listeItems.splice(idx,1)
            D1Configurations.saveLinks($scope.listeItems).then(function() {
                $scope.rafraichir();
            });
        }
    };

    $scope.ajouter = function() {
        var dlg = $uibModal.open({
            templateUrl: 'ng1/config/config-links-edit.tpl.html',
            controller: 'ModalLinksEditCtrl',
            //controllerAs : '$ctrl',
            //size: 'lg', //'sm','lg'
            backdrop: 'static',
            windowClass: 'validation-modal',
            resolve: {
                item: function() {
                    // Donner une copie
                    return angular.copy({ name: '', item: {} });
                }
            }
        });
        dlg.result.then(function(dlgItem) {
            // Sauvegarder, ajouter la copie dans la liste
            $scope.listeItems.push(dlgItem.item);
            D1Configurations.saveLinks($scope.listeItems).then(function() {
                $scope.rafraichir();
            });

        }, function() {
            // DISMISS FUNCTION
        });

    };

    $scope.modifier = function(item, idx) {
        var dlg = $uibModal.open({
            templateUrl: 'ng1/config/config-links-edit.tpl.html',
            controller: 'ModalLinksEditCtrl',
            //controllerAs : '$ctrl',
            //size: 'lg', //'sm','lg'
            backdrop: 'static',
            windowClass: 'validation-modal',
            resolve: {
                item: function() {
                    // Donner une copie
                    return angular.copy({ name: idx, item: item });
                }
            }
        });
        dlg.result.then(function(newItem) {
            $scope.listeItems[idx] = newItem.item;
            D1Configurations.saveLinks($scope.listeItems).then(function() {
                $scope.rafraichir();
            });

        }, function() {
            // DISMISS FUNCTION
        });

    };

}])
.controller('ModalLinksEditCtrl', ['$scope', '$uibModalInstance', 'item', '$toastService', function($scope, $uibModalInstance, item, $toastService) {
    // Commun à tous?
    $scope.item = item.item;

    $scope.ok = function() {
        try {
            let bOk = true;

            if (bOk) $uibModalInstance.close({ index: item.index, item: $scope.item });

        } catch (err) {
            // Afficher l'erreur
            $toastService.showError("Erreur de validation", "Le format de JSON est invalide! Vérifiez la structure! : " + err.toString())
        }
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}])

/**
 * D1ConfigAppCtrl 
 */
.controller('D1ConfigAppCtrl', ['$scope', 'D1Configurations', '$toastService', function($scope, Configurations, $toastService) {
        const app = Configurations.app;
        $scope.presetColors = app.presetColors;
    
        if ($scope.presetColors === undefined) {
            $scope.presetColors = "";
        }
    
        $scope.sauvegarder = function() {
            try {
                const valid = $scope.presetColors.trim().split(',')
                if (valid) {
                    app.presetColors = valid.join(',');
                    Configurations.saveAppSettings(app).then(() => {
                        $toastService.showSuccess("Sauvegarde", "<i class='fa fa-2x fa-check-circle'></i> Configurations sauvegardées")
                    })
                }
                
            } catch (error) {
                console.error(error)
            }
    
        };

    }])

;