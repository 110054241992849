<span class="mycontainer" *ngIf="!disabled &amp;&amp; type !== 'Info'"
    ><mat-form-field *ngIf="type !== 'Time1'" [class]="classes" [hintLabel]="hint"
        ><mat-label *ngIf="label">{{ label }} : </mat-label><mat-error *ngFor="let err of hasErrors">{{ err }}</mat-error><span class="prefix" *ngIf="prefix" matPrefix>{{ prefix }} </span><input class="text-primary" [type]="type" *ngIf="type !== 'Time' &amp;&amp; type !== 'Date' &amp;&amp; type !== 'TextArea' &amp;&amp; type !== 'ComboBox'" #editInput matInput [id]="id" [value]="value" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" (blur)="onblur()" (change)="onChange($event)" /><input class="text-primary" [type]="'datetime-local'" *ngIf="type === 'DateTime'" #editInput matInput [id]="id" [required]="required" [formControl]="monControle" [disabled]="disabled" (blur)="onblur()" (change)="onChange($event)" /><input class="text-primary" *ngIf="type === 'Date'" matInput [matDatepicker]="picker" [id]="id" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" (blur)="onblur()" (change)="onChange($event)" /><mat-datepicker-toggle
            *ngIf="type == 'Date'"
            matSuffix
            [for]="picker"
        ></mat-datepicker-toggle
        ><mat-datepicker #picker></mat-datepicker><input class="text-primary" *ngIf="type === 'Time'" matInput [id]="id" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" (change)="onChange($event)" [ngxTimepicker]="timepicker" (blur)="onblur()" [format]="hoursFormat" /><ngx-material-timepicker-toggle *ngIf="type === 'Time'" matSuffix [for]="timepicker"></ngx-material-timepicker-toggle><ngx-material-timepicker #timepicker [minutesGap]="minuteSteps" (blur)="onTouched()" (timeChanged)="onChangeTime($event)"></ngx-material-timepicker><mat-select *ngIf="type === 'ComboBox'" #editInput [id]="id" [required]="required" [formControl]="monControle" (blur)="onblur()" (selectionChange)="onComboChange($event)"><mat-option *ngFor="let item of model.dataSource" [value]="item[model.fields.value]">{{ item[model.fields.text] }}</mat-option></mat-select
        ><textarea class="text-primary" *ngIf="type == 'TextArea'" #editInput matInput [id]="id" [required]="required" [formControl]="monControle" (blur)="onblur()" (change)="onChange($event)" cdkTextareaAutosize>{{ value }}</textarea><a class="close" mat-button *ngIf="value &amp;&amp; type !== 'Time'" matSuffix mat-icon-button aria-label="Vider" (click)="clearValue($event)"><i-feather class="text-default" name="x-square" mat-icon></i-feather></a><span class="suffix" *ngIf="suffix" matSuffix>&nbsp; {{ suffix }}</span></mat-form-field
    ></span
><span *ngIf="(type === 'Info' || disabled)"><mat-label *ngIf="label">{{ label }} : </mat-label><span class="prefix" *ngIf="prefix" matPrefix>{{ prefix }}</span><span class="text-primary" *ngIf="(type === 'Info' || disabled) &amp;&amp; type !== 'ComboBox'" [matTooltip]="tooltip" [matTooltipPosition]="TooltipPosition" [innerHTML]="value | nl2br"></span><span class="text-primary" *ngIf="(type === 'Info' || disabled) &amp;&amp; type === 'ComboBox'" [matTooltip]="tooltip" [matTooltipPosition]="TooltipPosition">{{ valueComboText }}</span><span class="text-primary suffix" *ngIf="suffix" matSuffix>&nbsp; {{ suffix }}</span></span
><ng-content></ng-content>
