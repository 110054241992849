// Divers
import * as _ from 'underscore';
import * as moment from 'moment';

var d1Salles = angular.module('resources.salles', ['resources.reservation', 'ngVis']);
angular.module('resources.salles').factory('Salles', ['$http', 'Reservation', '$uibModal', '$q', function ($http, Reservation, $uibModal, $q) {

    var Salles = {
        Email : {
            to : '',
            sujet : '',
            contenu : ''
        },
        listeSalles : [],
        listeVR : [],

        init : function() {
            this.listeSalles = [];
        },
        getListe : function(dateCedule) {
            if (!dateCedule) {
                dateCedule = new Date();
            }
            var dc = moment(dateCedule).format('YYYY-MM-DD');
            return $http.get('/api/salles/list/' + dc).then((response) => {
                this.listeSalles = response.data;
                return this.listeSalles;
            });
        },

        getListeVR : function(dateCedule) {
            if (!dateCedule) {
                dateCedule = new Date();
            }
            var dc = moment(dateCedule).format('YYYY-MM-DD');
            return $http.get('/api/vr/sessions/' + dc).then((response) => {
                this.listeVR = response.data;
                return this.listeVR;
            });
        },

        /**
         * Aller chercher les types de salles possibles pour une résrevation
         * @returns {*}
         */
        TypeSalles : function(refresh) {
            if (this.listeSalles.length == 0 || refresh) {
                this.listeSalles = [];
                return $http.get('/api/reservations/typesalles').then( (response) => {
                    this.listeSalles = response.data;
                    return this.listeSalles;
                });
            }
            else {
                var q = $q.defer();
                q.resolve(this.listeSalles);
                return q.promise;
            }
        },

        /**
         * Vérifier quelles salles sont disponibles selon une période
         *
         * @param debut
         * @param fin
         *
         * @return array : Liste des salles complètes avec une colonne "disponible".
         */
        verifierDisponibilite : function (debut, fin, groupeId) {
            if (!groupeId) {
                groupeId = 0;
            }

            var sd = moment(debut);
            var sf = moment(fin);
            if (sd.isValid() && sf.isValid()) {
                return $http.get('/api/salles/disponibilites/' +sd.format("YYYY-MM-DD HH:mm:00") + "/" +sf.format("YYYY-MM-DD HH:mm:00") + "/" + groupeId).then((response) => {
                    this.listeSalles = response.data;
                    return this.listeSalles;
                });
            }
        },
        afficherUtilisation : function(dateCedule){

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'ng1/salles/salles-list.tpl.html',
                controller: 'D1SallesCtrl',
                size: 'lg',
                resolve: {
                    listeSalles:['Salles', function (Salles) {
                        return Salles.getListe(dateCedule);
                    }],
                    listeTypesSalles:['Salles', function (Salles) {
                        return Salles.TypeSalles(true);
                    }],
                    dateUtilisation : dateCedule,
                }
            });

            modalInstance.result.then(function () {
            }, function () {
                // Rien à faire
            });
        },
        interchanger : function(dateCedule){

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'ng1/salles/salles-echange.tpl.html',
                controller: 'D1SallesEchangeCtrl',
                size: 'lg',
                resolve: {
                    listeSalles:['Salles', function (Salles) {
                        return Salles.getListe(dateCedule);
                    }],
                    dateUtilisation : dateCedule
                }
            });

            modalInstance.result.then(function () {
            }, function () {
                // Rien à faire
            });
        },
        udpateGroupeSalle : function(groupeId, salleIdTo, salleIdFrom) {
            return $http.post('/api/salles/update/' + groupeId, {salleIdTo : salleIdTo, salleIdFrom : salleIdFrom}).then((response) => {
                Reservation.loadGroupe(groupeId, true).then((r) => {
                    Reservation.socket.emit('RafraichirReservations', r);
                });

                return response;
            });
        }
    };

    return Salles;
}]);

d1Salles.controller('D1SallesEchangeCtrl',['$scope', '$sce', 'Salles', 'listeSalles', '$uibModalInstance', 'dateUtilisation', function ($scope, $sce, Salles, listeSalles, $uibModalInstance, dateUtilisation) {
    $scope.listeSalles = listeSalles;
    $scope.salle_no1 = {};
    $scope.salle_no2 = {};



    $scope.ok = function () {
        if (confirm('Êtes-vous certain de vouloir échanger les salles entre ces deux réservations?')) {
            // Donner la salle du #2 au #1
            Salles.udpateGroupeSalle($scope.salle_no1.groupeId, $scope.salle_no2.salleId, $scope.salle_no1.salleId);

            // Donner la salle du #1 au #2
            Salles.udpateGroupeSalle($scope.salle_no2.groupeId, $scope.salle_no1.salleId, $scope.salle_no2.salleId);
            $uibModalInstance.close($scope.salle_no1, $scope.salle_no2);
        }
    };


    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    }
}]);


d1Salles.controller('D1SallesCtrl',['$scope', '$sce', 'Salles', 'listeSalles', '$compile', 'VisDataSet', 'dateUtilisation', 'listeTypesSalles', 'Usagers', '$timeout', '$uibModalInstance', function ($scope, $sce, Salles, listeSalles, $compile, VisDataSet, dateUtilisation, listeTypesSalles, Usagers, $timeout, $uibModalInstance) {
    $scope.listeSalles = listeSalles;
    //$scope.m = {dateReservation : moment().toDate()};
    $scope.m = {dateReservation : dateUtilisation};
    $scope.listeTypeSalles = listeTypesSalles;
    $scope.dateDebut = moment().format("YYYY-MM-DD");
    $scope.dateFin = moment().format("YYYY-MM-DD");
    $scope.visdata = {groups: [], items: []};
    $scope.visdataVR = {groups: [], items: []};
    $scope.refreshing = 0;
    $scope.Usagers = Usagers;
    $scope.timeline = undefined;
    $scope.timelineVR = undefined;
    var doInit = false;
    
    // Filter
    $scope.listeTypeSalles = [];
    _.each(listeTypesSalles, function (s) {
        if (s.estDisponible == 1) {
            $scope.listeTypeSalles.push(s);
        }
    })

    $scope.visevents = {
        'onload' : function(timeline) {
            // Garder une référence au timeline pour passer des infos.
            //if (!$scope.timeline) {
                $scope.timeline = timeline;

            //}

            if (doInit) {
                $scope.changerDate();
                doInit = false;
            }
        },
        'rangechange': function (event) {
            // console.log('rangechange', event)
        },
        'rangechanged': function (event) {
            if (event.byUser && $scope.refreshing == 0) {
                var diff = 0;
                if ($scope.dateFin != moment(event.end).format('YYYY-MM-DD')) {
                    diff = moment(moment(event.end).format('YYYY-MM-DD')).diff($scope.dateFin, 'days');
                    if (diff > 0) {
                        var s = moment($scope.dateFin).add(1, 'd');
                        for (var i = 0; i < diff; i++) {
                            $scope.dateFin = s.format('YYYY-MM-DD');
                            $scope.refreshing++;
                            Salles.getListe(s).then((data) => {
                                $scope.refreshing--;
                                preparerVisuel(data);
                            });
                            s.add(1, 'd');
                        }
                        // tous les events sont terminés.
                        //$scope.dateFin = moment(event.end);
                    }
                }

                if ($scope.dateDebut != moment(event.start).format('YYYY-MM-DD')) {
                    var s = moment($scope.dateDebut).subtract(1, 'd');
                    diff = moment($scope.dateDebut).diff(moment(event.start).format('YYYY-MM-DD'), 'days');
                    if (diff > 0) {
                        for (var i = 0; i < diff; i++) {
                            $scope.dateDebut = s.format('YYYY-MM-DD');
                            $scope.refreshing++;
                            Salles.getListe(s).then((data) => {
                                $scope.refreshing--;
                                preparerVisuel(data);
                            });
                            s.subtract(1, 'd');
                        }
                        // tous les events sont terminés.
                        //$scope.dateDebut = moment(event.start);
                    }
                }
            }
        },
        'click': function (event) {
            //console.log('click', event)
        },
        'doubleClick': function (event) {
            //console.log('doubleClick', event)
        },
        'select': function (event) {
            //console.log('select', event)
        }
    };

    function preparerVisuel(liste) {
        // Faire les cédules de salles maintenant
        var lst = [];
        angular.forEach(liste, function(i) {
            /**
             * Exemples de lignes
             *
             * Créer un background : {id: 'A', content: 'Passes noires', start: '2016-10-17 08:20:00', end: '2016-10-17 08:40:00', type: 'background', group: 1, className: 'passes-noires'},
             * Entrée style boite : {id: '7a', content: 'Entrée des joueurs', start: '2016-10-17 08:58', group: 1, type:'box'},
             * Range : {id: 8, content: 'Règlements <i class="fa fa-phone"></i>', start: '2016-10-17 08:19:02', end: '2016-10-17 08:23:05', group: 1, className: 'bg-success', type : 'range'},
             */
            lst.push(
                {id: i.groupeId + '_' + i.salleId
                    , content: i.nomFete  + ' (' + i.groupeTypeNom + ')<br>' + i.nomOrganisateur + ( i.responsable ? '<br>Responsable : ' + i.responsable : '' ) + '<br>' + i.nombreParties + ' parties / ' + i.groupeNombre + ' joueurs <a href1="#" href="/reservations/' + i.groupeId + '" class="btn btn-default btn-xs" title="Modifier le groupe"><i class="fa fa-edit"></i></a>'
                    , start: i.salleDebut, end: i.salleFin, group: i.salleId
                    , className: 'groupe-type-'+ i.groupeTypeId, type : 'range'}
            );
        });

        if ($scope.visdata.items.length) {
            // Append
            if (lst.length) {
                $scope.visdata.items.add(lst);
            }
        }
        else {
            // Init
            var items = [];
            if (lst.length) {
                items = VisDataSet(lst)
            }
            $scope.visdata = {groups: $scope.groups, items:items};
        }

        //$scope.visdata = {groups: $scope.groups, items:items};
    }

    // INIT
    var lst = [];
    angular.forEach($scope.listeTypeSalles, function(i) {
        lst.push({
            id : i.salleId,
            content : i.salleNom
        });
    });
    $scope.groups = VisDataSet(lst);

    // Init
    // preparerVisuel($scope.listeSalles)
    if ($uibModalInstance) {
        $uibModalInstance.opened.then( r => {
            preparerVisuel($scope.listeSalles);
        })
   
    } else {
        preparerVisuel($scope.listeSalles);
    }



    $scope.visoptions = {
        "align": "center",
        "autoResize": false,
        "editable": false,
        "selectable": true,
        "orientation": 'both',
        "showMajorLabels": true,
        "showMinorLabels": true,
        "maxMinorChars" : 6,
        "hiddenDates" : {start: '2016-10-20 00:01:00', end: '2016-10-20 09:00:00', repeat: 'daily'},
        "showCurrentTime" : false
        //start" : moment($scope.m.dateReservation).format('YYYY-MM-DD 10:00'),
        //"end" : moment($scope.m.dateReservation).format('YYYY-MM-DD 23:59')
        //"max" : "2016-10-23",
        //"min" : "2016-10-08",
        //"maxHeight" : '80%'
        /*
         , template : function(item) {
         return '<h1>' + item.content + '</h1>';
         }
         */
    };

    $scope.changerDate = function() {
        if (moment($scope.m.dateReservation).format('YYYY-MM-DD') > $scope.dateDebut && moment($scope.m.dateReservation).format('YYYY-MM-DD') < $scope.dateFin) {
            if ($scope.timeline) {
                // Toujours scroller au milieu de la journée
                //$scope.timeline.moveTo(moment($scope.m.dateReservation).format('YYYY-MM-DD 12:00:00'));
            }
        }
        else {
            $scope.dateDebut = moment($scope.m.dateReservation).format('YYYY-MM-DD');
            $scope.dateFin = $scope.dateDebut;
            Salles.getListe($scope.m.dateReservation).then((data) => {
                //$scope.listeSalles = data;
                preparerVisuel(data);
            });
            // Salles.getListeVR($scope.m.dateReservation).then(function(data) {
            //     //$scope.listeSalles = data;
            //     preparerVisuelVR(data);
            // })
        }
        if ($scope.timeline) {
            $scope.visoptions.start = moment($scope.m.dateReservation).subtract(0, "d").subtract(3, 'h');
            $scope.visoptions.end = moment($scope.m.dateReservation).add(0, "d").add(3, 'h');

            $scope.timeline.moveTo($scope.m.dateReservation);
        }
        if ($scope.timelineVR) {
            $scope.visoptionsVR.start = moment($scope.m.dateReservation).subtract(0, "d").subtract(3, 'h');
            $scope.visoptionsVR.end = moment($scope.m.dateReservation).add(0, "d").add(3, 'h');

            $scope.timelineVR.moveTo($scope.m.dateReservation);
        }
    };


    if (Usagers.voirModule('vr')) {
        /**
         * VR SESSIONS
         * DATA : visdataVR
         * OPTIONS : visoptionsVR
         * EVENT : viseventsVR
         *
         *     "sessions": {
            "70f7878d-ffae-59d3-b57b-dc2dd69c853a": {
                "cost": "41.98CAD",
                "crdate": "2019-12-29 14:14:51.848977",
                "customer": "Guest",
                "customer_extra": {},
                "customer_id": null,
                "date": "2020-01-03",
                "duration": "60 mins",
                "email": null,
                "end_time": "15:29:59",
                "experience": "Immersive",
                "games": "",
                "payments": [],
                "pending_payment": "0",
                "start_time": "14:30:00",
                "stations": 2,
                "used": false
            }
        }
         */
        $scope.viseventsVR = {
            'onload': function (timelineVR) {
                // Garder une référence au timeline pour passer des infos.
                //if (!$scope.timeline) {
                $scope.timelineVR = timelineVR;

                //}

                if (doInit) {
                    $scope.changerDate();
                    doInit = false;
                }
            },
            'rangechange': function (event) {
                //console.log('rangechange', event)
            },
            'rangechanged': function (event) {
                if (event.byUser && $scope.refreshing == 0) {
                    var diff = 0;
                    if ($scope.dateFin != moment(event.end).format('YYYY-MM-DD')) {
                        diff = moment(moment(event.end).format('YYYY-MM-DD')).diff($scope.dateFin, 'days');
                        if (diff > 0) {
                            var s = moment($scope.dateFin).add(1, 'd');
                            for (var i = 0; i < diff; i++) {
                                $scope.dateFin = s.format('YYYY-MM-DD');
                                // $scope.refreshing++;
                                // Salles.getListeVR(s).then(function (data) {
                                //     $scope.refreshing--;
                                //     preparerVisuelVR(data);
                                // });
                                s.add(1, 'd');
                            }
                        }
                    }

                    if ($scope.dateDebut != moment(event.start).format('YYYY-MM-DD')) {
                        var s = moment($scope.dateDebut).subtract(1, 'd');
                        diff = moment($scope.dateDebut).diff(moment(event.start).format('YYYY-MM-DD'), 'days');
                        if (diff > 0) {
                            for (var i = 0; i < diff; i++) {
                                $scope.dateDebut = s.format('YYYY-MM-DD');
                                // $scope.refreshing++;
                                // Salles.getListeVR(s).then(function (data) {
                                //     $scope.refreshing--;
                                //     preparerVisuelVR(data);
                                // });
                                s.subtract(1, 'd');
                            }
                        }
                    }
                }
            },
            'click': function (event) {
                //console.log('click', event)
            },
            'doubleClick': function (event) {
                //console.log('doubleClick', event)
            },
            'select': function (event) {
                //console.log('select', event)
            }
        };

        // INIT
        lst = [];

        // FORCÉ POUR LE MOMENT
        //angular.forEach(['VR', 'Station 2', 'Station 3', 'Station 4'], function(s, i) {
        angular.forEach(['VR'], function (s, i) {
            lst.push({
                id: i,
                content: s
            });
        });
        $scope.groupsVR = VisDataSet(lst);

        $scope.visoptionsVR = {
            "align": "center",
            "autoResize": false,
            "editable": false,
            "selectable": true,
            "orientation": 'both',
            "showMajorLabels": true,
            "showMinorLabels": true,
            "maxMinorChars": 6,
            "hiddenDates": {start: '2016-10-20 00:01:00', end: '2016-10-20 09:00:00', repeat: 'daily'},
            "showCurrentTime": false
        };

        function preparerVisuelVR(liste) {
            // Faire les cédules de salles maintenant
            var lst = [];
            angular.forEach(liste.sessions, function (i, id) {

                /**
                 * Exemples de lignes
                 *
                 *
                 * "70f7878d-ffae-59d3-b57b-dc2dd69c853a": {
                 *
                "cost": "41.98CAD",
                "crdate": "2019-12-29 14:14:51.848977",
                "customer": "Guest",
                "customer_extra": {},
                "customer_id": null,
                "date": "2020-01-03",
                "duration": "60 mins",
                "email": null,
                "end_time": "15:29:59",
                "experience": "Immersive",
                "games": "",
                "payments": [],
                "pending_payment": "0",
                "start_time": "14:30:00",
                "stations": 2,
                "used": false
            }
                 *
                 * Créer un background : {id: 'A', content: 'Passes noires', start: '2016-10-17 08:20:00', end: '2016-10-17 08:40:00', type: 'background', group: 1, className: 'passes-noires'},
                 * Entrée style boite : {id: '7a', content: 'Entrée des joueurs', start: '2016-10-17 08:58', group: 1, type:'box'},
                 * Range : {id: 8, content: 'Règlements <i class="fa fa-phone"></i>', start: '2016-10-17 08:19:02', end: '2016-10-17 08:23:05', group: 1, className: 'bg-success', type : 'range'},
                 */
                for (j = 0; j < i.stations; j++) {
                    lst.push(
                        {
                            id: id + '-' + j.toString()
                            , content: i.duration
                            , start: i.date + ' ' + i.start_time
                            , end: i.date + ' ' + i.end_time
                            //, group : 0
                            , className: 'groupe-type-vr'
                            , type: 'range'
                        }
                    );
                }
            });

            if ($scope.visdataVR.items.length) {
                // Append
                if (lst.length) {
                    $scope.visdataVR.items.add(lst);
                }
            }
            else {
                // Init
                var items = [];
                if (lst.length) {
                    items = VisDataSet(lst)
                }
                //$scope.visdataVR = {groups: $scope.groupsVR, items:items};
                $scope.visdataVR = {items: items};
            }

        }
    }

}]);