import { Inject, Input } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'icm-dialog-confirmation',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dialog-confirmation.component.html',
    styleUrls: ['dialog-confirmation.component.scss'],
})
export class DialogConfirmationComponent implements OnInit {
    @Input() header = 'Confirmer';
    @Input() message = 'Êtes-vous certain de vouloir effacer cet item?';
    @Input() button1 = 'Effacer';
    @Input() button2 = 'Annuler';

    constructor(@Inject(MAT_DIALOG_DATA) args: any, private dialogRef: MatDialogRef<DialogConfirmationComponent>, ) {
        if (args.header) this.header = args.header
        if (args.message) this.message = args.message.replace(/\n/g, '<br/>')
        if (args.button1) this.button1 = args.button1
        if (args.button2) this.button2 = args.button2
    }
    
    ngOnInit() {}

    onButton1() {
        this.dialogRef.close(this.button1);
    }

    onButton2() {
        this.dialogRef.close(this.button2);
    }
}
