import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ClickEventArg, ColumnDefinitionsModel } from '@common/components/simple-grid/simple-grid.component';
import { Rental, Slot } from '@modules/rentals/models';
import { RentalsService } from '@modules/rentals/services';
import moment from 'moment';


@Component({
    selector: 'd1-rentals-booking-return-future',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rentals-booking-return-future.component.html',
    styleUrls: ['rentals-booking-return-future.component.scss'],
})
export class RentalsBookingReturnFutureComponent implements OnInit {
    liste!:Rental[];

    ngOnInit() {}

    public columnDefs: ColumnDefinitionsModel[] = [
        { headerText: 'Booking #', visible: true, name: 'booking_id', sortable: true },
        { headerText: 'Date', visible: true, name: 'start_sec', sortable: true, format: f => moment(f).format('DD-MM-YYYY @ HH:mm') },
        { headerText: 'Durée', visible: true, name: 'duration_sec', sortable: true, format: f => { return `${Math.floor(f/60)}h${(f % 60).toString().length<2 ? '0' :''}${(f % 60)}`; } },
        { headerText: 'Groupe', visible: true, name: 'nomOrganisateur', sortable: true },
        { headerText: 'Nombre', visible: true, name: 'nombreJoueurs', sortable: true },
        { headerText: 'Tags', visible: true, name: 'booking_tags', showAsPills: true, sortable: true },
        { headerText: 'Tel', visible: true, name: 'telephone', sortable: true },
        { headerText: 'Courriel', visible: true, name: 'courriel', sortable: true },
        { headerText: 'status', visible: true, name: 'status', sortable: true },
    ];

    constructor(
        private rentals$: RentalsService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private nav: ActivatedRoute,
        private dialog$: MatDialog
    ) {
        this.fetchData();
    }

    fetchData() {
        const start = moment().hours(0).minutes(0).second(0).subtract(1, 'y').toDate();
        const end = moment().hours(0).minutes(0).second(0).add(1, 'month').toDate();
        this.rentals$.getBookings$({ start : start, end: end, query: "b.status=4"}).subscribe((r) => {
            this.liste = r as Rental[];
            this.ref.markForCheck();
        });
    }

    convertMinuteToHours(minutes: number) {
        if (minutes) {
            return `${Math.floor(minutes/60)}h${(minutes % 60).toString().length<2 ? '0' :''}${(minutes % 60)}`;
        } else {
            return '';
        }
    }

    OnRowClick(event: ClickEventArg) {
        const booking = event.rowData as Rental;
        if (event.event === 'edit') {
            this.router.navigate(['rentals', 'bookings', booking.booking_id, 'edit']);
        } else {
            this.router.navigate(['rentals', 'bookings', booking.booking_id]);
        }
    }

    OnAdd() {
        this.router.navigate(['rentals', 'bookings', 'add']);
    }

}
