<mat-toolbar class="sticky-toolbar">
    <div class="d-none d-lg-inline-block d-md-inline-block mr-2">Réservations</div>
    <mat-button-toggle-group class="ml-2" [(value)]="viewSchedule"
        ><mat-button-toggle [value]="true" matTooltip="Vue en grille"><mat-icon>grid_view</mat-icon></mat-button-toggle
        ><mat-button-toggle [value]="false" matTooltip="Vue en liste"><mat-icon>view_list</mat-icon></mat-button-toggle></mat-button-toggle-group
    ><mat-button-toggle-group class="ml-2" [(value)]="viewAll"
        ><mat-button-toggle [value]="true" matTooltip="Montrer toutes les réservations"><mat-icon>visibility</mat-icon></mat-button-toggle
        ><mat-button-toggle [value]="false" matTooltip="Cacher les réservations invalides"><mat-icon>visibility_off</mat-icon></mat-button-toggle></mat-button-toggle-group
    ><mat-button-toggle-group class="ml-2" [(value)]="viewActualTimeOnly" *ngIf="isSameDay()"
        ><mat-button-toggle [value]="true" matTooltip="Automatiquement filtrer les réservations complétées"><mat-icon>schedule</mat-icon></mat-button-toggle
        ><mat-button-toggle [value]="false" matTooltip="Afficher les réservations complétées"><mat-icon>history</mat-icon></mat-button-toggle></mat-button-toggle-group
    ><button class="ml-2 mat-stroked-button" mat-icon-button (click)="voirSallesDisponibilites()" matTooltip="Voir les disponibilités des salles" aria-label="Voir les disponibilités des salles"><mat-icon>event_available</mat-icon></button><d1-reservations-charts class="ml-2 d-none d-lg-block" [date]="selectedDate" width="100%" height="80px" [viewComptact]="true"></d1-reservations-charts></mat-toolbar
><sbpro-card
    ><div class="card-body p-2">
        <mat-sidenav-container
            ><mat-sidenav-content id="drawerContent"
                ><div class="d-flex">
                    <div class="col1"><d1-reservations-filter id="reservationFiltre" [date]="selectedDate" (dateChange)="OnDateChange($event)"></d1-reservations-filter></div>
                </div>
                <d1-reservations-list [date]="selectedDate" (selected)="selectedBooking($event)" [viewActualTimeOnly]="viewActualTimeOnly" [viewAll]="viewAll" [viewSchedule]="viewSchedule"></d1-reservations-list></mat-sidenav-content
            ><mat-sidenav class="nav-content p-2" mode="over" position="end" [fixedInViewport]="true" fixedTopGap="68" #drawer
                ><mat-toolbar class="sticky-toolbar1"
                    ><ng-container *ngIf="selectedReservation"
                        ><div class="mr-2">{{selectedReservation.nomOrganisateur.slice(0,30)}} (#{{selectedReservation.groupeId}}<i-feather class="pointer" name="copy" ngbTooltip="Copier" (click)="copyToClipboard(selectedReservation.groupeId.toString())"></i-feather>) {{ selectedReservation.reservationDate | date : 'dd MMMM @ HH:mm' }}</div>
                        <div class="badge p-2 text-white bg-primary" [style.background-color]="getGroupeTypeCouleur(selectedReservation)" [class]="['groupe-type-'+selectedReservation.groupeTypeId]">{{ getGroupeTypeNom(selectedReservation) }}</div></ng-container
                    ><span class="menu-spacer"></span><button *ngIf="selectedReservation &amp;&amp; isDevMode" mat-icon-button aria-label="Modifier la réservation" [routerLink]="[selectedReservation.groupeId, 'edit']"><mat-icon>edit</mat-icon></button><button mat-icon-button aria-label="Fermer la fenêtre" (click)="drawer.close()"><mat-icon>close</mat-icon></button></mat-toolbar
                ><d1-reservation-quick-view [booking]="selectedReservation" (backClick)="drawer.close()"></d1-reservation-quick-view></mat-sidenav
        ></mat-sidenav-container></div
></sbpro-card>
