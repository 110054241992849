<sb-breadcrumbs [slim]="true"></sb-breadcrumbs>
<div class="container-fluid d-flex justify-content-around">
    <div class="w-100 pr-2">
        <sbpro-card
            ><div class="card-header">Disponibilité<a class="btn btn-primary text-white ml-2" (click)="OnEdit()">Modifier</a></div>
            <div class="card-body">
                Date : {{ slot.start_sec | date : 'd MMM, YYYY @ HH:mm'}}
                <div>Durée : {{ convertMinuteToHours(slot.duration_sec) }}</div>
            </div></sbpro-card
        >
    </div>
    <div class="w-100 pr-2">
        <sbpro-card
            ><div class="card-header">Nombres</div>
            <div class="card-body">
                Utilisés : {{ slot.inventory_count - slot.availability_count }} / {{ slot.inventory_count }}
                <div>Disponibles : {{ slot.availability_count }}</div>
            </div></sbpro-card
        >
    </div>
    <div class="w-100">
        <sbpro-card
            ><div class="card-header">Divers</div>
            <div class="card-body">{{ slot.rentals?.length }} locations</div></sbpro-card
        >
    </div>
</div>
<div class="card m-2">
    <div class="card-body p-0">
        <div class="card-header">Liste des locations</div>
        <d1-simple-grid class="w-100" [data]="slot.rentals" [columnDefinitions]="columnDefs" [showAction]="true" (rowclick)="OnRowClick($event)" (onEdit)="OnRowClick($event)" (onAdd)="OnAdd()" (onDelete)="OnRowClick($event)"></d1-simple-grid>
    </div>
</div>
