import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TimelineModel } from '@modules/reservations/models';
import _ from 'lodash';

@Component({
    selector: 'd1-reservations-timeline',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-timeline.component.html',
    styleUrls: ['reservations-timeline.component.scss'],
})
export class ReservationsTimelineComponent implements OnInit {
    @Input() timeline: TimelineModel[] = [] 
    @ViewChild('inputNoteInitiales') inputNoteInitiales!: ElementRef<HTMLInputElement>;
    @ViewChild('inputNote') inputNote!: ElementRef<HTMLInputElement>;

    externalVariable = 'hello';
    public _timeline: TimelineModel[] = [] 

    constructor() {}
    ngOnInit() {
        if (this.timeline == undefined) {
          this.timeline = [];
        }
        this._timeline = this.timeline;
    }

    applyFilter(event: Event) {
      try {
        const filterValue = (event.target as HTMLInputElement).value;
        // On filtre dans les détails
        this._timeline = _.filter(this.timeline, (h: TimelineModel) => {
          return h.detail.trim().toLowerCase().search(filterValue.trim().toLowerCase()) >= 0
        });
        
      } catch (error) {
        // Catch all, on retourne la totale
        this._timeline = this.timeline;
      }
    }

    ajouterNote(note: HTMLInputElement) {
      if (note.value.length) {
        const newNote: TimelineModel = new TimelineModel();
        newNote.action = 'Note';
        newNote.detail = this.inputNote.nativeElement.value;
        newNote.user = this.inputNoteInitiales.nativeElement.value

        this.timeline.unshift(newNote);
        this._timeline = this.timeline;

        // Clear
        this.inputNoteInitiales.nativeElement.value = ''
        this.inputNote.nativeElement.value = ''
      }
    }

    log(msg:any) {
      console.log(msg)
    }
}
