import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ShopifyService } from '@modules/shopify/services';

@Component({
    selector: 'd1-shopify-admin-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './shopify-admin-view.component.html',
    styleUrls: ['shopify-admin-view.component.scss'],
})
export class ShopifyAdminViewComponent implements OnInit {
    constructor(private  nav: ActivatedRoute, private s$:ShopifyService, private router:Router) {
        // this.nav.queryParamMap.subscribe( (q:any) => {
        //     if (q.params.hmac) {
        //         this.s$.setShopify$(q.params);
        //     }
        // })
    }
    
    ngOnInit() {}

    shopifyAuth() {
        this.router.navigate(['/shopify/auth'],{queryParams:{url:'/api/shopify/login'}});
        // this.router.navigate(['/shopify/auth'],{queryParams:{url:'/api/shopify/login'}});
    }
}
