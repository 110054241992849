<form class="form" [formGroup]="myForm">
    <div class="container-fluid">
        <div class="hidden-print alert alert-info" *ngIf="!myForm.controls['courriel'].value"><i class="glyphicon glyphicon-exclamation-sign"></i>Attention, il n'y a pas d'adresse courriel de spécifié.</div>
    </div>
    <div class="container-fluid well">
        <div class="row">
            <div class="col-lg-12"><tsng-form-group-input class="w-100" label="Gabarit" type="ComboBox" [model]="gabaritModel" formControlName="gabarit"></tsng-form-group-input><tsng-form-group-input class="w-25 mr-2" label="Nom" type="text" formControlName="nom"></tsng-form-group-input><tsng-form-group-input class="w-50 mr-2" label="Courriel" type="email" formControlName="courriel" autocomplete="off"></tsng-form-group-input><tsng-form-group-input class="d-block" label="Sujet" type="text" formControlName="sujet"></tsng-form-group-input><editor class="d-block" apiKey="9i3myxaq3klfyj6isjy5r6tla1qyju4tj3lrdjjqmmdcw5sg" [init]="{plugins: 'link'}" formControlName="message"></editor></div>
        </div>
        <div class="row"><button class="btn btn-success" id="sendMail" (click)="ok()" [disabled]="!myForm.controls['courriel'].value" translate>Envoyer courriel</button><button class="btn btn-warning" id="cancel" (click)="cancel()" translate>Annuler</button></div>
    </div>
</form>
