import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

export enum ConfirmationStatutListe {
    NonConfirme = 0,
    Confirme = 1,
    EnAttente = 2,
    EnAttentePartiesBloquees = 4
}

@Component({
    selector: 'd1-reservations-confirmation-status',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reservations-confirmation-status.component.html',
    styleUrls: ['reservations-confirmation-status.component.scss'],
})
export class ReservationsConfirmationStatusComponent implements OnInit, OnChanges {
    @Input() statutConfirmation: number = 0;
    @Input() showToggle: boolean = true;
    @Input() showIcon: boolean = false;
    @Input() showColor: boolean = false;
    @Input() showLabel = true;
    @Output() statutConfirmationChange: EventEmitter<number> = new EventEmitter<number>()
    @Output() change: EventEmitter<number> = new EventEmitter<number>()

    constructor(private ref:ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        // console.log('test')
        if (changes.statutConfirmation) {
            this.statutConfirmation = changes.statutConfirmation.currentValue
        }

        this.ref.markForCheck();
    }

    getLabel() {
        if (!this.showLabel) {
            return '';
        }

        switch (this.statutConfirmation) {
            case 0:
                return 'Non confirmé'
            case 1:
                return 'Confirmé'
            case 2:
                return 'En Attente'
            case 4:
                return 'En attente et parties bloquées'
        
            default:
                return '';
        }
    }

    ngOnInit() {
        
    }

    OnChange(event: any) {
        this.statutConfirmation = parseInt(event.value)
        this.statutConfirmationChange.emit(this.statutConfirmation)
        this.change.emit(this.statutConfirmation)
    }
}
