import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AppMessageService } from '@common/services';
import { GameBookingModel, ScheduleInformation } from '@modules/schedule/models';
import { ScheduleService } from '@modules/schedule/services';
import moment from 'moment';

@Component({
    selector: 'd1-schedule-sheet-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-sheet-view.component.html',
    styleUrls: ['schedule-sheet-view.component.scss'],
})
export class ScheduleSheetViewComponent implements OnInit {
    @Output() presenceChange:EventEmitter<number> = new EventEmitter<number>();
    public group:GameBookingModel = new GameBookingModel();
    public schedules:GameBookingModel[] = [];
    public voirPresence = false;

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<ScheduleSheetViewComponent>, private s$:ScheduleService, private m$: AppMessageService) {
        if (data) {
            this.group = data.group;
            this.schedules = data.schedules;

            // Validation
            this.voirPresence = moment().startOf('day').isBefore(this.group.partieTemps);
            if (this.group.presence === undefined) this.group.presence = 0;
        }
    }

    ngOnInit() { }

    closeLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
        event.preventDefault();
    }

    getPasseCouleur(g:GameBookingModel) {
        return this.s$.getPasseCouleur(g.partieHeures)
    }

    getClasses(g:GameBookingModel) {
        return [`bg-${this.getPasseCouleur(g)}`]
    }

    changePresence(event: MatButtonToggleChange) {
        // let curGroup: ScheduleInformation = {
        //     ceduleTemps: moment(this.group.partieTemps).toDate(),
        //     ceduleInformation: ''
        // }
        // this.m$.sendMessageCeduleGame(curGroup)
        this.presenceChange.emit(+event.value);
    }
}
