import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CdkDragEnd, CdkDragStart, CdkDragMove } from '@angular/cdk/drag-drop';
import moment from 'moment';
import { ScheduleService } from '@modules/schedule/services';
import { Observable } from 'rxjs';
import { ScheduleModel } from '@modules/schedule/models';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { C } from '@angular/cdk/keycodes';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'd1-schedule-list-vertical',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-list-vertical.component.html',
    styleUrls: ['schedule-list-vertical.component.scss'],
})
export class ScheduleListVerticalComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() id!: string;
    @Input() hideEmptySchedule: boolean = true;
    @Input() viewActualTimeOnly: boolean = true;
    @Input() date: moment.Moment = moment();
    @ViewChild('VerticalTimeLine', { static: true }) verticalTimeLine?: ElementRef;
    //@ViewChild('contentRef', { static: true }) _contentRef?: ElementRef;

    private _data: Observable<ScheduleModel[]> = new Observable<ScheduleModel[]>();
    public ceduleTimes: any[] = []
    public cedules: ScheduleModel[] = []
    private _grabDiv:HTMLDivElement | undefined = undefined;
    private _isMouseDown = false;
    private _oldXPos = 0;
    private _oldCursor  = '';

    constructor(private s$:ScheduleService, private ref: ChangeDetectorRef, private viewportScroller: ViewportScroller, private _renderer: Renderer2, private element: ElementRef) {
        this.s$.CeduleListe.subscribe( c => {
            console.log('New cedulelist', c);
            const liste = _.reject(c, {ceduleId : null}) as ScheduleModel[];
            // const liste = c as ScheduleModel[];
            if (this.hideEmptySchedule && false) {
                const group = _.countBy(liste, 'partieHeures');
                this.ceduleTimes = [];
                // this.ceduleTimes = _.uniqBy(c, 'partieHeures');
                _.each(group, (gameCount, gameTime) => {
                    // let games = _.filter(liste, {partieHeures : gameTime})
                    // console.log("Games in " + gameTime, games);
                    this.ceduleTimes.push({partieHeures : gameTime});
                })
            } else {
                // this.cedules = _.reject(c, {ceduleId : null}) as ScheduleModel[];
                this.ceduleTimes = _.uniqBy(c, 'partieHeures');
            }
            // this.cedules = liste;
            this.cedules = _.reject(liste, {ceduleId : null}) as ScheduleModel[];
            this.ref.markForCheck();
        })
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        //this.fetchData()
        console.log('ngOnChanges', changes);
    }

    ngAfterViewInit(): void {
        this._renderer.listen(this.verticalTimeLine?.nativeElement, 'mousedown', this.startGrab.bind(this));
        this._renderer.listen(this.verticalTimeLine?.nativeElement, 'mousemove', this.moveGrab.bind(this));
        this._renderer.listen(this.verticalTimeLine?.nativeElement, 'mouseup', this.endGrab.bind(this));
        this._grabDiv = this.verticalTimeLine?.nativeElement;
        //this.fetchData()

    }

    startGrab(event:any) {
        if (this._grabDiv && this.verticalTimeLine && !this._isMouseDown) {
            this.verticalTimeLine.nativeElement.style.cursor = 'grabbing'
            this._isMouseDown = true;
        }
      }
      
      moveGrab(event:any) {
        if (this._grabDiv && this.verticalTimeLine) {
            if (this._isMouseDown) {
                // event.preventDefault();
                // if (this._oldXPos > event.movementX) {
                //     // Going left, so scroll right
                //     this.verticalTimeLine.nativeElement.scrollLeft = (event.pageX + event.movementX)
                // } else {
                //     // Going right, scroll left
                //     this.verticalTimeLine.nativeElement.scrollLeft = (event.pageX - event.movementX)
                // }
                this.verticalTimeLine.nativeElement.parentElement.scrollBy(event.movementX * -1, 0)
                // console.log("moveGrab", event.pageX, event.movementX, (event.movementX * 5));
                // this._oldXPos = this.verticalTimeLine.nativeElement.parentElement.scrollLeft;
            }
        }
      }
      
      endGrab(event:any) {
        if (this._grabDiv && this.verticalTimeLine && this._isMouseDown) {
            // event.preventDefault();
            this._oldXPos = 0;
            // this._grabDiv.style.cursor = this._oldCursor;
            // this._renderer.removeClass(this.verticalTimeLine.nativeElement, 'grabbing')
            this.verticalTimeLine.nativeElement.style.cursor = 'grab'
            this._isMouseDown = false;
        }
      }

    ngOnInit() {
        
        
    }

    getGamesInSchedule(partieHeures: string) {
        const games = _.filter(this.cedules, {partieHeures : partieHeures})
        return games;
    }

    fetchData() {
        this._data = this.s$.getSchedule$(moment(this.date));
        this._data.subscribe( (c) => {
            const liste = _.reject(c, {ceduleId : null}) as ScheduleModel[];
            // const liste = c as ScheduleModel[];
            if (this.hideEmptySchedule) {
                const group = _.countBy(liste, 'partieHeures');
                this.ceduleTimes = [];
                // this.ceduleTimes = _.uniqBy(c, 'partieHeures');
                _.each(group, (gameCount, gameTime) => {
                    // let games = _.filter(liste, {partieHeures : gameTime})
                    // console.log("Games in " + gameTime, games);
                    this.ceduleTimes.push({partieHeures : gameTime});
                })
            } else {
                // this.cedules = _.reject(c, {ceduleId : null}) as ScheduleModel[];
                this.ceduleTimes = _.uniqBy(c, 'partieHeures');
            }
            // this.cedules = liste;
            this.cedules = _.reject(liste, {ceduleId : null}) as ScheduleModel[];
            this.ref.markForCheck();
        });
    }
    
    toggleEmptySchedule() {
        this.fetchData()
    }

    toggleActualTime() {
    }
}
