import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@common/services';
import { SalleModel } from '@modules/rooms/models';
import { RoomsService } from '@modules/rooms/services';
import { MessageBoxService } from 'ts-ng-lib';
import { RoomsOverviewComponent } from '../rooms-overview/rooms-overview.component';

@Component({
    selector: 'd1-rooms-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './rooms-edit.component.html',
    styleUrls: ['rooms-edit.component.scss'],
})
export class RoomsEditComponent implements OnInit {
    public myForm!: FormGroup;
    public room: SalleModel = new SalleModel();

    constructor(private fb: FormBuilder, private r$: RoomsService, private route: ActivatedRoute,
        private nav: Router, private t$: ToastService, private m$: MessageBoxService, private _roomsOverviewComponent: RoomsOverviewComponent, private _changeDetectorRef: ChangeDetectorRef) {
        if (this.route.snapshot.data.room) {
            this.room = this.route.snapshot.data.room;
        }

        // Ce qu'on peut éditer
        this.myForm = this.fb.group({
            salleNom: [this.room.salleNom, [Validators.required]],
            estDisponible: [this.room.estDisponible],
            ordrePriorite: [this.room.ordrePriorite],
            visiblePublic: [this.room.visiblePublic],
        })
    }

    ngOnInit() {
        this._roomsOverviewComponent.matDrawer.open();

        this.route.params.subscribe((p) => {
            if (+p.id !== this.room.salleId) {
                // Ce qu'on peut éditer
                this.room = this.route.snapshot.data.room;
                this.myForm.patchValue({
                    salleNom: this.room.salleNom,
                    estDisponible: this.room.estDisponible,
                    ordrePriorite: this.room.ordrePriorite,
                    visiblePublic: this.room.visiblePublic,
                })
                this._changeDetectorRef.markForCheck();
            }
        })
    }
    /**
     * Close the drawer
     */
    closeDrawer(): Promise<MatDrawerToggleResult> {
        this._changeDetectorRef.markForCheck();
        return this._roomsOverviewComponent.matDrawer.close();
    }

    doSave() {
        if (this.myForm.valid) {
            let newRoom = { ...this.room, ...this.myForm.value }
            if (newRoom.salleId) {
                this.r$.update(newRoom).subscribe(ret => {
                    this.t$.showSuccess('Sauvegardé', 'La salle a été sauvegardée avec succès')
                    this.nav.navigate(['..'], { relativeTo: this.route })
                })
            } else {
                this.r$.add(newRoom).subscribe(ret => {
                    this.t$.showSuccess('Sauvegardé', 'La salle a été ajoutée avec succès')
                    this.nav.navigate(['..'], { relativeTo: this.route })
                })
            }
        }
    }

    doDelete() {
        this.m$.ConfirmDelete('ATTENTION!', `Effacer une salle peut avoir beaucoup d'impacts sur vos réservations.<br>Êtes-vous absolument certain de vouloir l'effacer?`).subscribe(r => {
            if (r === 'Effacer') {
                this.r$.delete(this.room).subscribe(ret => {
                    this.t$.showSuccess('Effacée', 'La salle a été effacée avec succès')
                    this.nav.navigate(['..'], { relativeTo: this.route })
                })
            }
        })
    }
    
    doAnnuler() {
        if (this.myForm.dirty && this.myForm.touched) {
            this.m$.Confirm('Pertes de données!', `Vous allez perdre vos données.<br>Êtes-vous certain?`, 'Oui', 'Non').subscribe(r => {
                if (r === 'Oui') {
                    this.nav.navigate(['..'], { relativeTo: this.route })
                }
            })
        } else {
            this.nav.navigate(['..'], { relativeTo: this.route })
        }
    }
}
