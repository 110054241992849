import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppMessageService } from '@common/services';
import { ReservationsService } from '@modules/reservations/services';
import { GameBookingModel, ScheduleInformation, ScheduleModel } from '@modules/schedule/models';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScheduleSheetViewComponent } from '../schedule-sheet-view/schedule-sheet-view.component';

@Component({
    selector: 'd1-schedule-list-horizontal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-list-horizontal.component.html',
    styleUrls: ['schedule-list-horizontal.component.scss'],
})
export class ScheduleListHorizontalComponent implements OnInit {
    @Input() id!: string;
    @Input() hideEmptySchedule: boolean = true;
    @Input() viewActualTimeOnly: boolean = true;
    @Input() viewCompact: boolean = true;
    @Input() date: moment.Moment = moment();

    public ceduleTimes: any[] = []
    public cedules: ScheduleModel[] = []
    arena_id = 1;
    partieType = 1;
    nombreVestes = 0;

    constructor(public s$:ScheduleService, private ref: ChangeDetectorRef, private render:Renderer2, private el:ElementRef, private _bottomSheet: MatBottomSheet, private r$:ReservationsService, private m$: AppMessageService) {
        this.s$.CeduleListe.subscribe( c => {
            const liste = c as ScheduleModel[];
            this.ceduleTimes = _.uniqBy(c, 'partieHeures');
            this.cedules = _.reject(liste, {ceduleNombreJoueurs : null}) as ScheduleModel[];
            if (this.cedules.length) {
                this.nombreVestes = this.cedules[0].nombreVestes;
            } else {
                this.nombreVestes = 0;
            }

            this.ref.markForCheck();
        })
        this.m$.CeduleGameMessage.subscribe(r => {
            this.s$.getGameSchedule(moment(r.ceduleTemps), r.ceduleInformation).subscribe(s => {
                this.cedules = _.reject(this.cedules, {journeeTemps : r.ceduleTemps}) as ScheduleModel[];
                this.cedules = [...this.cedules, ...s]
                this.ceduleTimes = _.uniqBy(this.cedules, 'partieHeures');
                this.ceduleTimes = _.sortBy(this.ceduleTimes, "partieHeures")
                this.ref.markForCheck()
            })
        })
    }
    ngOnInit() {}

    nombreJoueursFiltres(cedule: any) {
        const games = this.getGamesInSchedule(cedule.partieHeures)
        return _.sumBy(_.filter(games, (g) => (g.statutGroupe == 2)), 'nombreJoueurs');
    }

    filtrerCeduleTimes(): ScheduleModel[] {
        if (this.viewActualTimeOnly === false || (this.viewActualTimeOnly === true && (this.date.format('YYYYMMDD') !== moment().format('YYYYMMDD')))) {
            // console.log('this.ceduleTimes :', this.ceduleTimes);
            return this.ceduleTimes;
        } else {
            return this.ceduleTimes.filter( (v:ScheduleModel,i) => {
                return moment(v.journeeTemps) >= moment().subtract(30, 'm');;
            });
        }
    }
    
    getGamesInSchedule(partieHeures: string) {
        const games = _.filter(this.cedules, {partieHeures : partieHeures})
        return _.sortBy(games, "nomOrganisateur");
    }

    
    highlight(event:Event, el:any) {
        const elListe = (<HTMLElement>this.el.nativeElement).querySelectorAll(
            `.groupe-groupeid-${el.groupeId}`
            );
        _.each(elListe, (el) => {
            this.render.addClass(el, 'surbrillance_groupe')
        })
    };

    unhighlight(event:Event, el:any) {
        const elListe = (<HTMLElement>this.el.nativeElement).querySelectorAll(
            `.groupe-groupeid-${el.groupeId}`
            );
        _.each(elListe, (el) => {
            this.render.removeClass(el, 'surbrillance_groupe')
        })
    };

    ceduleTypeJeu(event:Event, cedule: any) {
        const newInfo:ScheduleInformation = {
            ceduleInformation: cedule.ceduleInformation,
            ceduleTemps: moment(cedule.journeeTemps).toDate()
        };
        this.s$.modifierInformations(newInfo);
    }

    
    openScheduleSheet(group: GameBookingModel) {
        const schedules = this.cedules.filter( c => c.groupeId === group.groupeId);
        const ref = this._bottomSheet.open(ScheduleSheetViewComponent, {
            data : {
                group : group,
                schedules : schedules
            }
        });

        // On va écouter pour des changements
        ref.instance.presenceChange.subscribe( presence => {
            const index = this.cedules.findIndex( c => c.id === group.id);
            this.cedules[index] = {...this.cedules[index], ...{ presence : presence }}
            
            this.r$.patchGameRecord(group.groupeId, group.id, { presence : presence }).subscribe( () => {
                let curGroup: ScheduleInformation = {
                    ceduleTemps: moment(group.partieTemps).toDate(),
                    ceduleInformation: ''
                }                
                this.m$.sendMessageCeduleGame(curGroup)
                this.ref.markForCheck();
            });
        })
    }
}
