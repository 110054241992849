<ng-container *ngIf="!rtl"
    ><nav class="topnav navbar navbar-expand shadow navbar-dark bg-dark">
        <a class="navbar-brand d-none d-sm-block" routerLink="/dashboard"><img class="img-fluid" src="/assets/img/logo.png" alt="RELOAD v2"/></a><button class="btn btn-icon btn-transparent-light order-1 order-lg-0 mr-lg-2" id="sidebarToggle" data-cy="topNavToggleSideNav" (click)="toggleSideNav()"><i-feather name="menu"></i-feather></button><sbpro-top-nav-search></sbpro-top-nav-search>
        <ul class="navbar-nav">
            <button class="pr-2 pl-2 bg-secondary mr-1" mat-stroked-button [matMenuTriggerFor]="menuPasses"><span class="d-none d-md-inline">Prochaine passe : </span><fa-icon class="fa-2x passe-{{nextColor}}" [icon]="['fas', 'mobile']"></fa-icon></button
            ><mat-menu class="pl-2" #menuPasses="matMenu"><d1-schedule-next-passes placement="bottom" passClass="pr-2"></d1-schedule-next-passes></mat-menu
            ><button class="btn btn-success mr-2" (click)="onWalkin()">Ajout walk-in</button
            ><sbpro-top-nav-user> </sbpro-top-nav-user>
        </ul></nav
></ng-container>
