import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduleModel } from '@modules/schedule/models';
import { GameModel } from '@modules/schedule/models/game.model';
import { ScheduleModule } from '@modules/schedule/schedule.module';
import { ScheduleService } from '@modules/schedule/services';
import _ from 'lodash';
import { Observable } from 'rxjs';

@Component({
    selector: 'd1-schedule-pick-lasertag',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule-pick-lasertag.component.html',
    styleUrls: ['schedule-pick-lasertag.component.scss'],
})
export class SchedulePickLasertagComponent implements OnInit {
    @Input() games: GameModel[] = [];
    @Input() date?: Date;
    @Input() groupSize: number = -1;
    @Input() hideUnavailable: boolean = true;
    @Output() gamesValue: EventEmitter<GameModel[]> = new EventEmitter<GameModel[]>();
    public _gamesCopy: GameModel[] = [];

    loading = true;

    // public listePartiesDisponibles: Observable<ScheduleModel[]> = new Observable<ScheduleModel[]>();
    public listePartiesDisponibles: ScheduleModel[] = [];
    private _fullListe: ScheduleModel[] = [];
    
    constructor(private s$:ScheduleService, private ref: ChangeDetectorRef) {

    }

    OnSelect(cedule: ScheduleModel) {
        const index = _.findIndex(this.games, (g) => g.partieTemps == cedule.partieHeures);
        if (index !== -1) {
            this.games.splice(index,1)
            this.gamesValue.emit(this.games);
        }
        else {
            const newGame = new GameModel();
            newGame.partieTemps = cedule.partieHeures
            newGame.nombreJoueurs = (this.groupSize > 0 ? this.groupSize : 1 )
            this.games.push(newGame);
            this.gamesValue.emit(this.games);
        }
    }

    ShowUnavailable(cedule:ScheduleModel) {
        if (this.hideUnavailable == false) {
            return true;
        } else {
            // On doit décider si on cache ou non
            const g = _.find(this.games, {partieHeures : cedule.partieHeures})
            if (g !== undefined) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    OnShowUnavailable() {
        if (this.hideUnavailable) {
            this.listePartiesDisponibles = _.filter(this._fullListe, (c: ScheduleModel) => {
                const g = _.find(this.games, {partieHeures : c.partieHeures})
                if (g === undefined && (c.numDisponible == 0 || c.numDisponible < this.groupSize)) {
                    return false;
                } else {
                    return true;
                }
            }) as ScheduleModel[];
        } else {
            this.listePartiesDisponibles = this._fullListe;
        }
        // this.ref.markForCheck();
    }

    IsScheduleDisabled(cedule:ScheduleModel) {
        let inCurrentGame = 0;
        const g = _.find(this._gamesCopy, {partieHeures : cedule.partieHeures})
        // if (g !== undefined) {
        //     inCurrentGame = g.partieTemps
        // }
        return ((this.groupSize > 0 && cedule.numDisponible < this.groupSize) || cedule.numDisponible == 0) && inCurrentGame == 0;
    }

    OnSelectNbJoueurs(cedule:ScheduleModel, el: HTMLInputElement) {
        console.log("OnSelectNbJoueurs", cedule, el.valueAsNumber)
    }

    maxGroupSize(cedule:ScheduleModel) {
        // Pas encore cédulée donc le groupe n'est pas dans le total de joueurs
        if (this.groupSize > 0) {
            return Math.min(cedule.numDisponible, this.groupSize);
        } else {
            return cedule.numDisponible;
        }
    }

    GroupSizeInScheduledGame(cedule:ScheduleModel) {
        let selGroupSize = this.groupSize;
        const index = _.findIndex(this.games, (g) => g.partieTemps == cedule.partieHeures);
        if (index !== -1) {
            selGroupSize = this.games[index].nombreJoueurs
        }
        return selGroupSize;
    }

    isScheduleSelected(cedule:ScheduleModel) {
        return _.findIndex(this.games, (g) => g.partieTemps == cedule.partieHeures) !== -1;
    }

    ngOnInit() {
        this.s$.disponibilites(this.date).subscribe( (liste) => {
            // Garder uen copie?
            this._fullListe = liste;

            this.OnShowUnavailable()

            // if (this.hideUnavailable) {
            //     this.listePartiesDisponibles = _.filter(liste, (c: ScheduleModel) => {
            //         const g = _.find(this.games, {partieHeures : c.partieHeures})
            //         if (g === undefined && c.numDisponible == 0) {
            //             return false;
            //         } else {
            //             return true;
            //         }
            //     }) as ScheduleModel[];
            // } else {
            //     this.listePartiesDisponibles = liste;
            // }
            this.loading = false;
            this.ref.markForCheck();
        });

        console.log("this.games", this.games);
        this._gamesCopy = this.games;

        // this.listePartiesDisponibles.subscribe( () => {
        //     this.loading = false;
        //     this.ref.markForCheck();

        // })

    }
}
