<sb-breadcrumbs slim="true"></sb-breadcrumbs
><sbpro-card
    ><div class="card-body p-2">
        <d1-reservations-action-bar [reservations]="listeReservations" [reservation]="reservation"></d1-reservations-action-bar
        ><sbpro-alert *ngIf="reservation.notesAdmin" [icon]="true" classes="alert-warning" heading="Notes importantes"
            ><div class="alert-icon-aside"><fa-icon [icon]='["far", "flag"]'></fa-icon></div>
            {{ reservation.notesAdmin}}</sbpro-alert
        ><d1-reservations-view-detail [reservation]="reservation"></d1-reservations-view-detail>
    </div>
    <div class="card-footer"><a class="link" [routerLink]="['/booking']" queryParamsHandling="preserve">Retourner à la liste</a></div></sbpro-card
>
