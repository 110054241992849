import { Injectable } from '@angular/core';
import { ReservationModel } from '@modules/reservations/models';
import { ScheduleModel } from '@modules/schedule/models';
// import { Socket } from 'ngx-socket-io';
import { io } from 'socket.io-client';

import { BehaviorSubject, Subject } from 'rxjs';

/* Environment */
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppMessageService {
    public CeduleMessage: Subject<ScheduleModel> = new Subject();
    public CeduleGameMessage: Subject<any> = new Subject(); //new model
    public ReservationMessage: Subject<ReservationModel> = new Subject();
    public SettingsMessage: Subject<any> = new Subject();
    public VestesMessage: Subject<any> = new Subject();

    private socket: any;

    constructor() {
        // Séparer les messages pour pouvoir écouter sur ceux qu'on désire.
        this.socket = io(environment.socketURL);
        this.socket.on("RafraichirCedule", this.onMessageCedule.bind(this));
        this.socket.on("RafraichirCeduleGame", this.onMessageCeduleGame.bind(this));
        this.socket.on("RafraichirReservations", this.onMessageReservation.bind(this));
        this.socket.on("RafraichirConfig", this.onMessageSettings.bind(this));
        this.socket.on("RafraichirVestes", this.onMessageVestes.bind(this));
    }


    /**
     * Envoyer un message comme quoi la cédule a changée
     * @param cedule 
     */
    sendMessageCedule(cedule: ScheduleModel) {
        this.socket.emit("RafraichirCedule", cedule);
    }

    /**
     * Envoyer un message comme quoi les parties de la cédule a changée
     * @param ceduleGame
     */
    sendMessageCeduleGame(ceduleGame: any) {
        this.socket.emit("RafraichirCeduleGame", ceduleGame);
    }

    /**
     * Envoyer un message comme quoi la réservation a changée
     * @param reservation 
     */
    sendMessageReservation(reservation: ReservationModel) {
        this.socket.emit("RafraichirReservations", reservation);
    }

    /**
     * Envoyer un message comme quoi un setting a changé
     * @param setting 
     */
    sendMessageSettings(setting: any) {
        this.socket.emit("RafraichirConfig", setting);
    }

    /**
     * Envoyer un message comme quoi le nombre de vestes a changé
     * @param setting 
     */
    sendMessageVestes(setting: any) {
        this.socket.emit("RafraichirVestes", setting);
    }

    onMessageCedule(message: any) {
        this.CeduleMessage.next(message as ScheduleModel)
    }

    onMessageCeduleGame(message: any) {
        this.CeduleGameMessage.next(message as any)
    }

    onMessageReservation(message: any) {
        this.ReservationMessage.next(message as ReservationModel)
    }

    onMessageSettings(message: any) {
        this.SettingsMessage.next(message)
    }

    onMessageVestes(message: any) {
        console.log('Message de vestes', message)
        this.VestesMessage.next(message)
    }

    // Display a message on the screen

}
