// Divers
import * as _ from 'underscore';
import * as moment from 'moment';
import { io } from 'socket.io-client';

angular.module('resources.cedule', []);
var d1Cedule = angular.module('resources.cedule').factory('Cedule', ['$http', '$uibModal', 'D1Configurations', function($http, $uibModal, D1Configurations) {

    var cedule = {};
    cedule.listeCedules = [];
    cedule.listeCedulesOverbooked = [];
    cedule.socket = io();

    cedule.getPasseCouleur = function(tTime, arena_id = 1) {
        let arena = _.findWhere(D1Configurations.getArenas(), { arena_id: arena_id });
        // console.log("getPasseCouleur", arena);
        if (arena && arena.passes) {

            // On calcule le temps depuis minuit jusqu'au moment demandé (ex: 810 minutes)
            // Ensuite on va diviser ça par le temps de nos rotations  (ex : 810 / 30 minutes = 27)
            // On prend ce nombre de rotations et on modulo selon le nombre de couleurs de passes (ex : 27 % 6 = 3)
            // Le résultat donne l'index à retourner.
            try {
                const rotationTime = (arena.rotation_time ? arena.rotation_time : 20)
                const lstPasses = arena.passes.split('_');
                const diffMinutes = moment("2020-06-15 " + tTime).diff("2020-06-15 00:00:00", 'minutes');
                const sequence = (diffMinutes / rotationTime) % lstPasses.length;
                return lstPasses[sequence];
            } catch (e) {
                // Default
                return "white";
            }

        } else {
            // Catch-all
            switch (tTime) {
                case '00:00':
                case '02:00':
                case '04:00':
                case '06:00':
                case '08:00':
                case '10:00':
                case '12:00':
                case '14:00':
                case '16:00':
                case '18:00':
                case '20:00':
                case '22:00':
                    return 'blue';

                case '00:20':
                case '02:20':
                case '04:20':
                case '06:20':
                case '08:20':
                case '10:20':
                case '12:20':
                case '14:20':
                case '16:20':
                case '18:20':
                case '20:20':
                case '22:20':
                    return 'purple';

                case '00:40':
                case '02:40':
                case '04:40':
                case '06:40':
                case '08:40':
                case '10:40':
                case '12:40':
                case '14:40':
                case '16:40':
                case '18:40':
                case '20:40':
                case '22:40':
                    return 'black';

                case '01:00':
                case '03:00':
                case '05:00':
                case '07:00':
                case '09:00':
                case '11:00':
                case '13:00':
                case '15:00':
                case '17:00':
                case '19:00':
                case '21:00':
                case '23:00':
                    return 'orange';

                case '01:20':
                case '03:20':
                case '05:20':
                case '07:20':
                case '09:20':
                case '11:20':
                case '13:20':
                case '15:20':
                case '17:20':
                case '19:20':
                case '21:20':
                case '23:20':
                    return 'yellow';

                case '01:40':
                case '03:40':
                case '05:40':
                case '07:40':
                case '09:40':
                case '11:40':
                case '13:40':
                case '15:40':
                case '17:40':
                case '19:40':
                case '21:40':
                case '23:40':
                    return 'green';

                default:
                    return 'white';
            }
        }
    };

    cedule.getPasseCouleurNom = function(tTime) {

        var pc_en = 'blue_purple_black_orange_yellow_green_white'.split('_');
        var pc_fr = 'bleu_mauve_noire_orange_jaune_verte_blanche'.split('_');
        //var pc_fr = 'bleu_mauve_rouge_orange_jaune_verte_blanche'.split('_'); // Laseraction

        try {
            if (D1Configurations.configurations.cedules && D1Configurations.configurations.cedules.nom_passes) {
                pc_fr = D1Configurations.configurations.cedules.nom_passes.split('_');
            }
        } catch (err) {

        }

        var pc = _.object(pc_en, pc_fr);

        return pc[cedule.getPasseCouleur(tTime)];

    };

    cedule.creer = function(partie_date) {
        if (!partie_date) {
            partie_date = moment();
        }
        var PartieModel = {
            id: 0,
            ceduleNombre: 0,
            groupeId: 0,
            groupeNombreJoueur: 0,
            groupeTitre: '',
            groupeTypeId: 0,
            groupeTypeNom: '',
            nomOrganisateur: '',
            notesMarshall: '',
            notesMarshallCouleur: '',
            groupeTypeCouleur: '',
            nombreJoueurs: 0,
            partieDate: moment(partie_date).format("YYYY-MM-DD"),
            partieHeures: '',
            partieTemps: moment(partie_date).toDate(),
            ceduleId: null,
            ceduletemps: null,
            ceduleInformation: null,
            braceletCouleur: null,
            passe: '',
            arena_id: 1,
            braceletCouleur: null
        };
        return PartieModel;
    };

    cedule.creerPartieFromReservation = function(r) {
        var PartieModel = {
            id: 0,
            ceduleNombre: 0,
            groupeId: r.groupeId,
            groupeNombreJoueur: r.nombreJoueurs,
            groupeTitre: r.groupeTitre,
            groupeTypeId: r.groupeTypeId,
            groupeTypeNom: r.groupeTypeNom,
            statutGroupe: r.statutGroupe,
            nomOrganisateur: r.nomOrganisateur,
            nombreJoueurs: r.nombreJoueurs,
            notesMarshall: r.notesMarshall,
            notesMarshallCouleur: (!r.notesMarshallCouleur ? 'inherit' : r.notesMarshallCouleur),
            partieDate: moment(r.event_date).format("YYYY-MM-DD"),
            partieHeures: moment(r.event_date).format("H:mm"),
            partieTemps: moment(r.event_date).toDate(),
            ceduleId: null,
            ceduletemps: null,
            ceduleInformation: null,
            braceletCouleur: r.braceletCouleur,
            passe: cedule.getPasseCouleur(moment(r.event_date).format("H:mm"), (r.arena_id ? r.arena_id : 1)),
            arena_id: (r.arena_id ? r.arena_id : 1),
            braceletCouleur : r.braceletCouleur
        };
        return PartieModel;
    };

    /**
     * Fonction qui permet de prendre une valeur de la BD vers notre objet
     *
     * @param c Row d'une DB (parties)
     */
    cedule.parseFromDB = function(c) {
        var PartieModel = {
            id: c.groupeId,
            ceduleNombre: 0,
            groupeId: c.groupeId,
            groupeNombreJoueur: c.groupeNombre,
            groupeTitre: c.groupeTitre,
            groupeTypeId: c.groupeTypeId,
            groupeTypeNom: c.groupeTypeNom,
            nomFete: c.nomFete,
            //partieTemps: c.partieTemps,
            nomOrganisateur: c.nomOrganisateur,
            nombreJoueurs: c.nombreJoueurs,
            numDisponible: c.numDisponible,
            groupeAvecExclusif: c.groupeAvecExclusif,
            notesMarshall: c.notesMarshall,
            notesMarshallCouleur: (!c.notesMarshallCouleur ? 'auto' : c.notesMarshallCouleur),
            groupeTypeCouleur: (!c.groupeTypeCouleur ? 'auto' : c.groupeTypeCouleur),
            partieId: c.id,
            partieDate: c.partieDate,
            partieHeures: c.partieHeures,
            //partieHeures: moment(c.series).format("H:mm"),
            partieTemps: (c.partieTemps ? c.partieTemps : c.journeeTemps),
            statutGroupe: c.statutGroupe,
            ceduleId: c.ceduleId,
            ceduleTemps: (c.ceduleTemps ? c.ceduleTemps : c.journeeTemps),
            ceduleInformation: c.ceduleInformation,
            passe: cedule.getPasseCouleur(c.partieHeures, c.arena_id),
            partieDuree: c.partieDuree,
            partieType: c.partieType,
            nombreVestes: c.nombreVestes,
            arena_id: c.arena_id,
            braceletCouleur: c.braceletCouleur
        };
        return PartieModel;
    };

    /**
     * Aller chercher le nombre de joueurs total dans une cédule
     *
     * @returns {*}
     */
    cedule.partieNombreJoueurs = function(partieDate) {
        var t = moment(partieDate);
        var r = {
            partieTemps: partieDate,
            nbGroupes: 'Inconnu',
            nbJoueurs: 'Inconnu'
        };
        var q = '';

        if (t.isValid()) {
            q = '?date=' + moment(partieDate).format('YYYY-MM-DD HH:mm:00');
            return $http.get('/api/cedules/nombre' + q).then(function(response) {
                var d = response.data[0];
                r.partieTemps = t;
                r.nbGroupes = (d ? d.nbGroupes : 0);
                r.nbJoueurs = (d ? d.nbJoueurs : 0);
                r.arena_id = (d ? d.arena_id : 1);
                return r;
            });
        } else {
            console.log("cedule.partieNombreJoueurs INVALIDE! partieDate=", partieDate);
            return r;
        }
    };

    /**
     * Aller chercher les parties cédulées pour un groupe
     *
     * @returns {*}
     */
    cedule.partieGroupe = function(groupeId) {
        return $http.get('/api/cedules/groupe/' + parseInt(groupeId)).then(function(response) {
            var l = response.data[0];

            angular.forEach(l, function(partie) {
                partie.partieTemps = moment(partie.partieTemps).toDate();
                partie.groupeNombreJoueur_Copy = partie.groupeNombreJoueur;
                partie.nombreJoueurs_Copy = partie.nombreJoueurs;
            });
            //return response.data[0];
            return l;
        });
    };

    cedule.getListe = function(dateCedule, statutGroupe, partieType, arena_id) {
        var dc = moment(dateCedule).format('YYYY-MM-DD');

        if (!statutGroupe) {
            statutGroupe = -1;
        }

        if (!partieType) {
            partieType = 1;
        }

        if (!arena_id) {
            arena_id = 1;
        }

        return $http.get('/api/cedules/list/' + dc + '/' + partieType + '/' + arena_id + '?bypass=1').then(function(response) {
            cedule.listeCedules = [];
            var lc = [];

            
            response.data.forEach(function(item) {
                
                var t = cedule.parseFromDB(item);
                if (statutGroupe == -1 || statutGroupe == 0 || (statutGroupe > 0 && statutGroupe == t.statutGroupe)) {
                    lc.push(t);
                }
            });
            
            // Regroupe par heures de parties
            var lst = _.groupBy(lc, "partieHeures");
            
            // Aller chercher les couleurs de passes
            cedule.listeCedulesOverbooked = [];
            _.each(lst, function(el, i) {
                var couleur = cedule.getPasseCouleur(i, arena_id);
                el.passeCouleur = couleur;
                el.nombreJoueur = 0;
                el.ceduleId = el[0].ceduleId;
                el.nombreVestes = el[0].nombreVestes;
                el.numDisponible = el[0].numDisponible;
                el.groupeAvecExclusif = el[0].groupeAvecExclusif;
                el.ceduleTemps = el[0].ceduleTemps;
                el.ceduleInformation = el[0].ceduleInformation;
                _.each(el, function(g) { el.nombreJoueur += g.nombreJoueurs; });
                if (el.nombreVestes < el.nombreJoueur) {
                    cedule.listeCedulesOverbooked.push(el);
                }
            });

            cedule.listeCedules = lst;

            return cedule.listeCedules;
        });
    };

    /**
     * Aller chercher les parties cédulées pour une semaine donnée
     *
     * @param annee : année format YYYY
     * @param semaineAnnee : Semaine dans l'année (0-52)
     *
     * @returns {*}
     */
    cedule.getSemaine = function(annee, semaineAnnee, statutGroupe) {
        if (!statutGroupe) {
            statutGroupe = -1;
        }
        return $http.get('/api/cedules/semaine/' + parseInt(annee) + '/' + parseInt(semaineAnnee) + '/' + statutGroupe).then(function(response) {
            /*
                        var lc = [];

                        response.data.forEach(function(t) {
                            if (statutGroupe == -1 || (statutGroupe > -1 && statutGroupe == t.statutGroupe) ) {
                                lc.push(t);
                            }
                        });
                        return lc;
            */
            return response.data[0];
        });
    };


    cedule.modifierInformations = function(c) {
        return $http.post('/api/cedules/informations/', c).then(function(response) {
            return response;
        });
    };

    cedule.afficherCedule = function(dateCedule, arena_id, showDate) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ng1/cedules/cedules-list-small.tpl.html',
            controller: 'CeduleAfficheCtrl',
            size: 'lg',
            resolve: {
                listeCedules: ['Cedule', function(Cedule) {
                    return Cedule.getListe(dateCedule, -1, 1, arena_id);
                }],
                dateCedule: dateCedule,
                listeVestes: ['Vestes', function(Vestes) {
                    return Vestes.getListe(arena_id);
                }],
                arena_id: arena_id,
                showDate: (showDate ? showDate : false),
            }
        });

        modalInstance.result.then(function() {}, function() {
            // Rien à faire
        });
    };

    cedule.effacerPartie = function(groupeId, partieId) {
        return $http.get('/api/cedules/effacer/' + groupeId + '/' + partieId).then(function(response) {
            cedule.socket.emit('RafraichirReservations', { groupeId: groupeId });
            return response;
        });
    };

    cedule.disponibilites = function(viewDate, arena_id, bypass) {
        if (!viewDate) {
            viewDate = moment();
        }
        if (arena_id == undefined) {
            arena_id = 1;
        }
        if (bypass !== 1) {
            bypass = 0;
        }
        return $http.get('/api/cedules/disponibilites/' + arena_id + '/' + moment(viewDate).format('YYYY-MM-DD') + '/json?bypass=' + bypass).then(function(response) {
            return response.data;
        });
    };

    return cedule;
}]);


d1Cedule.config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {
    $routeProvider
        .when('/ng1/cedules', {
            templateUrl: 'ng1/cedules/cedules-list.tpl.html',
            controller: 'D1CeduleCtrl',
            resolve: {
                listeCedules: ['Cedule', function(Cedule) {
                    return Cedule.getListe(moment(), 0, 1);
                }],
                pageTitle: ['Breadcrumb', function(Breadcrumb) {
                    Breadcrumb.pageTitle = 'Liste des cédules';
                }],
                pageManuel: ['Breadcrumb', function(Breadcrumb) {
                    Breadcrumb.pageManuel = '/support/manuel/reservations/reservations.html';
                }]
            }
        });

    // use the HTML5 History API
    $locationProvider.html5Mode(true);
}]);

d1Cedule.controller('D1CeduleCtrl', ['$scope', '$http', '$sce', '$timeout', 'Cedule', 'listeCedules', 'Vestes', 'Reservation', 'Usagers', 'D1Configurations', '$uibModal', 'Notification', function($scope, $http, $sce, $timeout, Cedule, listeCedules, Vestes, Reservation, Usagers, D1Configurations, $uibModal, Notification) {
    $scope.lstCedules = _.toArray(listeCedules);
    $scope.lstCeduleSemaine = [];
    $scope.modeCedule = 1;
    $scope.partieType = 1; // Mettre sur le lasertag par défaut
    $scope.statutGroupe = 0;
    $scope.Vestes = Vestes;
    $scope.Usagers = Usagers;
    $scope.Cedule = Cedule;
    $scope.infoChange = false;
    $scope.voirTout = false;
    $scope.forceClose = false;
    $scope.dd_ouvert = false;
    $scope.dateReservation = moment().toDate();

    $scope.videoListe = [];
    $scope.imageListe = [];
    $scope.currentDelay = 0;
    $scope.currentVideo = "";
    $scope.currentImage = "";
    $scope.currentListe = "";
    $scope.arena_id = 1;
    $scope.Configurations = D1Configurations;
    $scope.arena = _.findWhere(D1Configurations.getArenas(), { arena_id: 1 });
    if ($scope.arena && $scope.arena.disable_overbooked === undefined) {
        $scope.arena.disable_overbooked = false;
    }

    // Désactiver les notifications
    Reservation.DesactiverNotification = true;
    $scope.$on('$locationChangeStart', function(event) {
        Reservation.DesactiverNotification = false;
    });

    $http.get("/api/d1channel/marshall.json").then(function(response) {
        $scope.videoListe = response.data.slideShowItems;
        $scope.imageListe = response.data.slideShowLists;
    });

    $scope.PlayVideo = function(videoURL) {
        if ($scope.currentVideo || videoURL) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'ng1/cedules/cedule-device.tpl.html',
                controller: 'D1PickDeviceCtrl',
                size: 'auto',
                backdrop: 'static',
                resolve: {}
            });

            modalInstance.result.then((role) => {
                console.log("Pick role", role);
                if (videoURL) {
                    $http.get("/api/d1channel/marshall/play/video/" + encodeURI(videoURL) + "?role=" + role);
                } else {
                    $http.get("/api/d1channel/marshall/play/video/" + encodeURI($scope.currentVideo) + "?role=" + role);
                }
                // Rien à faire
                Notification.info({
                    message: "La vidéo a été envoyé aux écrans",
                    title: "Castiing",
                    positionX: 'center',
                    positionY: 'top',
                    onClose: function() {
                        //msg.nePlusAfficher = true;
                    }
                });
            }, (cancel) => {
                // Rien à faire
                Notification.warning({
                    message: "Rien n'a été envoyé aux écrans",
                    title: "Annulation",
                    positionX: 'center',
                    positionY: 'top',
                    onClose: function() {
                        //msg.nePlusAfficher = true;
                    }
                });
            });


        }
    };

    $scope.ShowImage = function(imageURL) {
        if ($scope.currentImage || imageURL) {
            if (imageURL) {
                $http.get("/api/d1channel/marshall/show/image/" + encodeURI(imageURL));
            } else {
                $http.get("/api/d1channel/marshall/show/image/" + encodeURI($scope.currentImage));
            }
        }
    };

    $scope.ShowListe = function(imageURL) {
        if ($scope.currentListe || imageURL) {
            if (imageURL) {
                $http.get("/api/d1channel/marshall/show/list/" + encodeURI(imageURL));
            } else {
                $http.get("/api/d1channel/marshall/show/list/" + encodeURI($scope.currentListe));
            }
        }
    };

    $scope.SetDelay = function() {
        $http.get("/api/d1channel/marshall/passes/delay/" + $scope.currentDelay);
    };

    $scope.CallPasses = function() {
        $http.get("/api/d1channel/marshall/passes/call");
    };

    /*
     Calendrier
     */
    $scope.cal_options = {
        showWeeks: false
    };
    $scope.ouvrirDD = function() {
        $scope.dd_ouvert = true;
    };
    $scope.ouvrirDF = function() {
        $scope.df_ouvert = true;
    };
    $scope.aujourdhui = function() {
        $scope.dateReservation = moment().toDate();
        $scope.changerDate();
    };

    $scope.afficher = function() {
        console.log($scope.lstCedules);
    };

    $scope.highlight = function(gr) {
        $('.groupe-groupeid-' + gr).addClass('surbrillance_groupe');
    };

    $scope.unhighlight = function(gr) {
        $('.groupe-groupeid-' + gr).removeClass('surbrillance_groupe');
    };

    $scope.nombreJoueursFiltres = function(cedule) {
        var t = _.find($scope.filtered, function(item) {
            return item.ceduleTemps == cedule.ceduleTemps
        });

        if (t !== undefined) {
            var totGroupe = 0;
            _.each(t, function(g) {
                totGroupe += (g.statutGroupe == 1 || g.statutGroupe == 2 ? g.nombreJoueurs : 0);
            });
            return totGroupe;
        }
    };

    $scope.changerCeduleInfos = function(c) {
        $scope.infoChange = true;
    };

    $scope.modifierCeduleInfos = function(c) {
        if ($scope.infoChange) {
            var d = {
                ceduleId: c.ceduleId,
                ceduleTemps: moment(c.ceduleTemps).format('YYYY-MM-DD HH:mm'),
                ceduleInformation: c.ceduleInformation
            };
            Cedule.modifierInformations(d).then(function(response) {
                if (response.data.insertId) {
                    c.ceduleId = response.data.insertId;
                }
            });
            $scope.infoChange = false;
        }
    };

    var tid = setInterval(function() {
        if (document.readyState !== 'complete') return;
        clearInterval(tid);
    });

    $scope.dateLundi = function() {
        // Calculer le lundi du calendrier
        var t = moment($scope.dateReservation);
        //if (t.day() == 0) {
        // Moment 'en' commence avec le dimanche pour 0, réajuster
        //    t.subtract(1,'d');
        //}
        t.day(1);
        return t;
        //return t.format('YYYY-MM-DD');
    };


    $scope.dateWeekNumber = function() {
        var t = moment($scope.dateReservation);
        if (t.day() == 0) {
            // Moment 'en' commence avec le dimanche pour 0, réajuster
            //return t.week()-1;
        }
        return t.week();
    };

    $scope.changerDate = function(dateSelected) {
        $scope.lstCedules = [];
        $scope.lstCeduleSemaine = [];

        $scope.dateReservation = moment(dateSelected).toDate()
        
        //if ($scope.modeCedule == 1) {
        Cedule.getListe($scope.dateReservation, -1, $scope.partieType, $scope.arena_id).then(function(data) {
            $scope.forceClose = false;
            $scope.lstCedules = _.toArray(data);
        });
        //}

        //if ($scope.modeCedule == 2) {
        var t = moment($scope.dateReservation);
        //t.day(4); // Parce que moment dépend de locale et que dimanche = 7 au lieu de 1 pour MySQL, je force au milieu de la smeaine
        var wn = $scope.dateWeekNumber();

        Cedule.getSemaine(t.year(), wn, -1).then(function(data) {
            $scope.lstCeduleSemaine = data;
        });
        //}

    };

    $scope.changerTypePartie = function(partieType) {
        $scope.partieType = partieType;
        $scope.changerDate();
    };

    $scope.filtrerGroupes = function() {
        $scope.changerDate();
    };

    $scope.$on('RafraichirReservations', function(data, r) {
        // Rafraichir si on affecte la journée actuelle ou encore, que c'est un groupe dans notre liste qui aurait changé de date.
        var eventGroupe = undefined;
        _.each($scope.lstCedules, (c) => {
            if (!eventGroupe) {
                eventGroupe = _.findWhere(c, {groupeId : r.groupeId})
            }
        })

        if (moment(r.event_date).format("MM-DD-YYYY") == moment($scope.dateReservation).format("MM-DD-YYYY") || eventGroupe !== undefined) {
            $scope.changerDate();
        } else {
        }
    });

    // Init
    Cedule.getSemaine(moment().year(), $scope.dateWeekNumber(), -1).then(function(data) {
        $scope.lstCeduleSemaine = data;
    });

    $scope.changerArena = function(arena_id) {
        console.log("changerArena", arena_id);
        $scope.arena_id = arena_id;
        Cedule.getListe($scope.dateReservation, -1, $scope.partieType, $scope.arena_id).then(function(data) {
            $scope.lstCedules = _.toArray(data);
        });
    };

    /**
     * Filtrer la liste selon les critères
     */
    $scope.filtrerListe = function(item, index, array) {
        var bOk = true;

        if ($scope.voirTout == false) {
            if (moment($scope.dateReservation).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                bOk = (moment(item.ceduleTemps) >= Reservation.actualGameTime(moment().subtract(45, 'minute')));
                //console.log(item, index, array);
            }
        }

        return bOk;
    };


    $scope.ShowScanCodePopup = function(temps) {
        Reservation.ShowScanCodePopup(temps);
    };

    /**
     * Filtrer la liste selon les critères
     */
    $scope.filtrerGroupe = function(item, index, array) {
        var bOk = true;

        // Les sous-filtres maintenant
        if ($scope.statutGroupe > 0) {
            bOk = (item.statutGroupe == 1 || item.statutGroupe == 2);
        }

        return bOk;
    };

    $scope.ceduleTemps = function(item) {
        return moment(item.ceduleTemps).format('HH:mm');
    };

}]);

d1Cedule.controller('CeduleAfficheCtrl', ['$scope', 'Cedule', 'listeCedules', 'dateCedule', 'Vestes', 'arena_id', 'showDate', function($scope, Cedule, listeCedules, dateCedule, Vestes, arena_id, showDate) {
    $scope.lstCedules = listeCedules;
    $scope.Vestes = Vestes;
    $scope.arena_id = arena_id;
    $scope.showDate = showDate;
    $scope.dd_ouvert = false;
    $scope.df_ouvert = false;
    $scope.dateReservation = dateCedule;
    $scope.reservation = { event_date: dateCedule };

    // Calendrier
    $scope.ouvrirDD = function() {
        $scope.dd_ouvert = true;
    };
    $scope.ouvrirDF = function() {
        $scope.df_ouvert = true;
    };
    $scope.changerDate = function(e) {
        $scope.dateReservation = e.toDate();
        $scope.rafraichir()
    }
    $scope.aujourdhui = () => { 
        $scope.dateReservation = new Date(); 
        $scope.rafraichir() 
    }
    $scope.rafraichir = () => { 
        Cedule.getListe($scope.dateReservation, -1, 1, arena_id).then( l => {
            $scope.lstCedules = l;
        }) 
    }

    $scope.highlight = function(gr) {
        $('.groupe-groupeid-' + gr).addClass('surbrillance_groupe');
    };

    $scope.unhighlight = function(gr) {
        $('.groupe-groupeid-' + gr).removeClass('surbrillance_groupe');
    };

    $scope.effacerPartie = function(i, groupe) {
        console.log(i, groupe);
        if (confirm("Vous allez effacer cette partie pour ce groupe.\nÊtes-vous vraiment certain?")) {
            Cedule.effacerPartie(groupe.groupeId, groupe.partieId).then(function(response) {
                $scope.lstCedules[groupe.partieHeures].splice(i, 1);
            });
            $scope.unhighlight(groupe.groupeId);
        }
    }

}]);

d1Cedule.controller('D1PickDeviceCtrl', ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
    $scope.choices = [];

    $scope.ok = function(role) {
        // Vars
        $uibModalInstance.close(role);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

}]);
/**
 * Created by benoit on 04/10/2016.
 */