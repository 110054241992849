export class SalleModel {
    salleId: number = 0;
    salleNom = '';
    disponible: number = 0;
    ordrePriorite: number = 0;
    estDisponible: boolean = true;
    salleIdExterne: number = 0;
    visiblePublic: boolean = true;
    timeslots?:any[] | undefined = undefined

    constructor(s?: SalleModel | undefined) {
        if (s) {
            this.disponible = s.disponible
            this.estDisponible = s.estDisponible
            this.salleId = s.salleId
            this.salleIdExterne = s.salleIdExterne
            this.salleNom = s.salleNom
            this.ordrePriorite = s.ordrePriorite
            this.visiblePublic = s.visiblePublic
            this.timeslots = (s.timeslots ? s.timeslots : undefined)
        }
    }
}

export class SalleCeduleModel {
    depotFait: number = 0;
    depotReference = '';
    groupeId: number = 0;
    groupeNombre: number = 0;
    groupeTitre = '';
    groupeTypeId: number = 0;
    groupeTypeNom = '';
    nomFete = '';
    nomOrganisateur = '';
    nombreParties: number = 0;
    responsable = '';
    salleDebut: Date | undefined;
    salleFin: Date | undefined;
    salleId: number = 0;
    salleIdExterne: number = 0;
    salleNom = '';
    statutGroupe: number = 0;
    
    constructor(s: SalleCeduleModel | undefined) {
        if (s) {
            this.depotFait = s.depotFait;
            this.depotReference = s.depotReference
            this.groupeId = s.groupeId
            this.groupeNombre = s.groupeNombre
            this.groupeTitre = s.groupeTitre
            this.groupeTypeId = s.groupeTypeId;
            this.groupeTypeNom = s.groupeTypeNom;
            this.nomFete = s.nomFete;
            this.nomOrganisateur = s.nomOrganisateur;
            this.nombreParties = s.nombreParties;
            this.responsable = s.responsable;
            this.salleDebut = (s.salleDebut ? new Date(s.salleDebut) : new Date());
            this.salleFin = (s.salleFin ? new Date(s.salleFin) : new Date());
            this.salleId = s.salleId;
            this.salleIdExterne = s.salleIdExterne;
            this.salleNom = s.salleNom;
            this.statutGroupe = s.groupeTypeId;
        }
    }
}