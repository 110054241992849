import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { APIparams } from '@modules/groupe/models';
import { GroupeService } from '@modules/groupe/services';
import { debounceTime, distinctUntilChanged, filter, Observable, switchMap, tap } from 'rxjs';

@Component({
    selector: 'sbpro-top-nav-search',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-search.component.html',
    styleUrls: ['top-nav-search.component.scss'],
})
export class TopNavSearchComponent implements OnInit {

    public searchFC: FormControl = new FormControl('');
    filteredSearchResults: Observable<any>;

    constructor(private g$:GroupeService, private route: Router) {
        this.filteredSearchResults = this.searchFC.valueChanges.pipe(
            filter(text => (text || '').length > 2),
            debounceTime(10),
            distinctUntilChanged(),
            switchMap( (value: string) => {
                const search: APIparams = {
                    fields : ['*'],
                    filter : `courriel=${value},telephone=${value},nomOrganisateur=${value},nomFete=${value},groupeId=${value}`,
                    skip : 0,
                    take : 30
                }
                return this.g$.findGroupes$(search).pipe( 
                    tap( (l: any) => {
                        // console.log('Liste of groupes', l)
                    })
                );
            })
        )
    }

    ngOnInit() {
        
    }

    displayFn(result: any): string {
        return result ? `Client : ${result.nomOrganisateur} | Tel : ${result.telephone} | Adresse : ${result.rue} ${result.nocivique} | Courriel : ${result.courriel}` : '';
    }

    selectedGroupe(event: any) {
        const selGroupe = event.option.value as any;
        this.route.navigate(['ng1', 'reservations', selGroupe.groupeId])
        this.searchFC.setValue('')
    }
}
